import { Params, useSelectId } from "../../../../hooks/use-select-id";

export const useDependencyGroupModal = () => {
  const { selectedId, setSelectId } = useSelectId({
    fieldName: Params.DEPENDENCY_GROUP_ID_SEARCH_PARAM_NAME
  });

  return {
    dependencyGroupId: selectedId ?? undefined,
    openNewDependencyGroup: () => setSelectId(Params.CREATE_DEPENDENCY_GROUP_PARAM),
    openEditDependencyGroup: (dependencyGroupId: string) => setSelectId(dependencyGroupId),
    onClose: (event?: object, reason?: string) => {
      if (reason === "backdropClick" || reason === "escapeKeyDown") {
        // do not close service config modal on backdrop click or escape key
        return;
      }
      setSelectId(
        undefined,
        new URLSearchParams({
          [Params.DEPENDENCY_GROUP_ID_SEARCH_PARAM_NAME]: ""
        })
      );
    }
  };
};
