import React, { FC, useMemo } from "react";
import {
  Autocomplete,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid2,
  IconButton,
  InputAdornment,
  TextField,
  useTheme
} from "@mui/material";
import { useTranslationWrapper } from "../../../../hooks/use-translation-wrapper";
import {
  Checkbox,
  Chip,
  Input,
  ParagraphBold,
  ParagraphSmall,
  Select
} from "@likemagic-tech/sv-magic-library";
import {
  AddOnServiceConfig,
  AddOnType,
  DefaultDatePickerSelection,
  DefaultTargetAudience,
  ServiceConfigGranularity,
  ServiceTag
} from "../../../../graphql-tenantconfig/generated/graphql";
import { useFormikContext } from "formik";
import { BaseServiceConfigFields } from "./base-service-config-fields";
import { useCanEditServiceConfig } from "../use-can-edit-service-config";
import CloseIcon from "@mui/icons-material/Close";
import ClearIcon from "@mui/icons-material/Clear";
import { useIsMobile } from "../../../../hooks/use-is-mobile";

interface AddonFieldsProps {}

export const AddonFields: FC<AddonFieldsProps> = () => {
  const theme = useTheme();
  const { t } = useTranslationWrapper();
  const isMobile = useIsMobile();
  const { canEditServiceConfig } = useCanEditServiceConfig();

  const { values, errors, handleChange, setFieldValue } = useFormikContext<AddOnServiceConfig>();

  const addOnTypeOptions = Object.values(AddOnType).map((type) => ({
    label: t("labels__settings_service_config_addontype_" + type),
    value: type
  }));

  const granularityOptions = Object.values(ServiceConfigGranularity).map((type) => ({
    label: t("labels__settings_service_config_granularity_" + type),
    value: type
  }));

  const defaultTargetAudienceOptions = Object.values(DefaultTargetAudience).map((type) => ({
    label: t("labels__settings_service_config_defaulttargetaudience_" + type),
    value: type
  }));

  const defaultDatePickerSelectionOptions = Object.values(DefaultDatePickerSelection).map(
    (type) => ({
      label: t("labels__settings_service_config_defaultdatepickerselection_" + type),
      value: type
    })
  );

  const serviceTagOptions = useMemo(() => {
    return AddOnType.Parking === values.addOnType ? [ServiceTag.Key] : Object.values(ServiceTag);
  }, [values.addOnType]);

  return (
    <>
      <Grid2 container spacing={2}>
        <Grid2 size={isMobile ? 12 : 6}>
          <ParagraphBold>{t("labels__service_config_form_addon_addontype")} *</ParagraphBold>
          <ParagraphSmall sx={{ mt: theme.spacing(0.5) }}>
            {t("labels__service_config_form_addon_addontype_description")}
          </ParagraphSmall>
          <FormControl fullWidth={true} sx={{ mt: theme.spacing(0.75) }}>
            <Select
              id="addOnType"
              name="addOnType"
              variant="outlined"
              value={values.addOnType ?? ""}
              options={addOnTypeOptions}
              onChange={handleChange}
              error={!!errors.addOnType}
              disabled={!canEditServiceConfig}
            />
            <FormHelperText error required>
              {errors.addOnType?.toString()}
            </FormHelperText>
          </FormControl>
        </Grid2>

        <Grid2 size={isMobile ? 12 : 6}>
          <ParagraphBold>{t("labels__service_config_form_addon_granularity")} *</ParagraphBold>
          <ParagraphSmall sx={{ mt: theme.spacing(0.5) }}>
            {t("labels__service_config_form_addon_granularity_description")}
          </ParagraphSmall>
          <FormControl fullWidth={true} sx={{ mt: theme.spacing(0.75) }}>
            <Select
              id="granularity"
              name="granularity"
              variant="outlined"
              value={values.granularity ?? ""}
              options={granularityOptions}
              onChange={handleChange}
              error={!!errors.granularity}
              disabled={!canEditServiceConfig}
            />
            <FormHelperText error required>
              {errors.granularity?.toString()}
            </FormHelperText>
          </FormControl>
        </Grid2>

        <Grid2 size={isMobile ? 12 : 6}>
          <ParagraphBold>{t("labels__service_config_form_addon_canbookmultiple")}</ParagraphBold>
          <ParagraphSmall sx={{ mt: theme.spacing(0.5) }}>
            {t("labels__service_config_form_addon_canbookmultiple_description")}
          </ParagraphSmall>
          <FormControl fullWidth={true} sx={{ mt: theme.spacing(0.75), ml: theme.spacing(1.25) }}>
            <FormControlLabel
              control={
                <Checkbox
                  id="canBookMultiple"
                  name="canBookMultiple"
                  checked={!!values.canBookMultiple}
                  value={true}
                  disabled={!canEditServiceConfig}
                  onChange={handleChange}
                />
              }
              label={t("labels__service_config_form_addon_canbookmultiple_checkbox")}
            />
          </FormControl>
        </Grid2>

        <Grid2 size={isMobile ? 12 : 6}>
          <ParagraphBold>
            {t("labels__service_config_form_addon_bookableondepartureday")}
          </ParagraphBold>
          <ParagraphSmall sx={{ mt: theme.spacing(0.5) }}>
            {t("labels__service_config_form_addon_bookableondepartureday_description")}
          </ParagraphSmall>
          <FormControl fullWidth={true} sx={{ mt: theme.spacing(0.75), ml: theme.spacing(1.25) }}>
            <FormControlLabel
              control={
                <Checkbox
                  id="bookableOnDepartureDay"
                  name="bookableOnDepartureDay"
                  checked={!!values.bookableOnDepartureDay}
                  value={true}
                  disabled={!canEditServiceConfig}
                  onChange={handleChange}
                />
              }
              label={t("labels__service_config_form_addon_bookableondepartureday_checkbox")}
            />
          </FormControl>
        </Grid2>

        <Grid2 size={isMobile ? 12 : 6}>
          <ParagraphBold>{t("labels__service_config_form_addon_postonnextday")}</ParagraphBold>
          <ParagraphSmall sx={{ mt: theme.spacing(0.5) }}>
            {t("labels__service_config_form_addon_postonnextday_description")}
          </ParagraphSmall>
          <FormControl fullWidth={true} sx={{ mt: theme.spacing(0.75), ml: theme.spacing(1.25) }}>
            <FormControlLabel
              control={
                <Checkbox
                  id="postOnNextDay"
                  name="postOnNextDay"
                  checked={!!values.postOnNextDay}
                  value={true}
                  disabled={!canEditServiceConfig}
                  onChange={handleChange}
                />
              }
              label={t("labels__service_config_form_addon_postonnextday_checkbox")}
            />
          </FormControl>
        </Grid2>

        <Grid2 size={isMobile ? 12 : 6}>
          {values.granularity &&
            (values.granularity === ServiceConfigGranularity.WholeStay ||
              values.granularity === ServiceConfigGranularity.WholeStayPayOnce) && (
              <>
                <ParagraphBold>
                  {t("labels__service_config_form_addon_bookonextendstay")}
                </ParagraphBold>
                <ParagraphSmall sx={{ mt: theme.spacing(0.5) }}>
                  {t("labels__service_config_form_addon_bookonextendstay_description")}
                </ParagraphSmall>
                <FormControl
                  fullWidth={true}
                  sx={{ mt: theme.spacing(0.75), ml: theme.spacing(1.25) }}
                >
                  <FormControlLabel
                    control={
                      <Checkbox
                        id="bookOnExtendStay"
                        name="bookOnExtendStay"
                        checked={!!values.bookOnExtendStay}
                        value={true}
                        disabled={!canEditServiceConfig}
                        onChange={handleChange}
                      />
                    }
                    label={t("labels__service_config_form_addon_bookonextendstay_checkbox")}
                  />
                </FormControl>
              </>
            )}
        </Grid2>

        <Grid2 size={isMobile ? 12 : 6}>
          <ParagraphBold>
            {t("labels__service_config_form_addon_availableaftermidnight")}
          </ParagraphBold>
          <ParagraphSmall sx={{ mt: theme.spacing(0.5) }}>
            {t("labels__service_config_form_addon_availableaftermidnight_description")}
          </ParagraphSmall>
          <FormControl fullWidth={true} sx={{ mt: theme.spacing(0.75) }}>
            <Input
              id="availableAfterMidnight"
              name="availableAfterMidnight"
              variant="outlined"
              value={values.availableAfterMidnight ?? ""}
              onChange={handleChange}
              error={errors.availableAfterMidnight?.toString()}
              disabled={!canEditServiceConfig}
              sx={{
                ".MuiInputBase-input": {
                  paddingY: theme.spacing(2)
                }
              }}
              endAdornment={
                canEditServiceConfig &&
                !!values.availableAfterMidnight && (
                  <InputAdornment sx={{ position: "absolute", right: 7 }} position="end">
                    <IconButton
                      sx={{ p: "4px" }}
                      onClick={() => {
                        setFieldValue("availableAfterMidnight", undefined);
                      }}
                    >
                      <ClearIcon sx={{ fontSize: 20 }}></ClearIcon>
                    </IconButton>
                  </InputAdornment>
                )
              }
            />
          </FormControl>
        </Grid2>

        <Grid2 size={isMobile ? 12 : 6}>
          <ParagraphBold>
            {t("labels__service_config_form_addon_defaulttargetaudience")}
          </ParagraphBold>
          <ParagraphSmall sx={{ mt: theme.spacing(0.5) }}>
            {t("labels__service_config_form_addon_defaulttargetaudience_description")}
          </ParagraphSmall>
          <FormControl fullWidth={true} sx={{ mt: theme.spacing(0.75) }}>
            <Select
              id="defaultTargetAudience"
              name="defaultTargetAudience"
              variant="outlined"
              value={values.defaultTargetAudience ?? ""}
              options={defaultTargetAudienceOptions}
              onChange={handleChange}
              error={!!errors.defaultTargetAudience}
              disabled={!canEditServiceConfig}
              endAdornment={
                canEditServiceConfig &&
                !!values.defaultTargetAudience && (
                  <InputAdornment sx={{ position: "absolute", right: 32 }} position="end">
                    <IconButton
                      sx={{ p: "4px" }}
                      onClick={() => {
                        setFieldValue("defaultTargetAudience", undefined);
                      }}
                    >
                      <ClearIcon sx={{ fontSize: 20 }}></ClearIcon>
                    </IconButton>
                  </InputAdornment>
                )
              }
            />
            <FormHelperText error required>
              {errors.defaultTargetAudience?.toString()}
            </FormHelperText>
          </FormControl>
        </Grid2>

        <Grid2 size={isMobile ? 12 : 6}>
          <ParagraphBold>
            {t("labels__service_config_form_addon_defaultdatepickerselection")}
          </ParagraphBold>
          <ParagraphSmall sx={{ mt: theme.spacing(0.5) }}>
            {t("labels__service_config_form_addon_defaultdatepickerselection_description")}
          </ParagraphSmall>
          <FormControl fullWidth={true} sx={{ mt: theme.spacing(0.75) }}>
            <Select
              id="defaultDatePickerSelection"
              name="defaultDatePickerSelection"
              variant="outlined"
              value={values.defaultDatePickerSelection ?? ""}
              options={defaultDatePickerSelectionOptions}
              onChange={handleChange}
              error={!!errors.defaultDatePickerSelection}
              disabled={!canEditServiceConfig}
              endAdornment={
                canEditServiceConfig &&
                !!values.defaultDatePickerSelection && (
                  <InputAdornment sx={{ position: "absolute", right: 32 }} position="end">
                    <IconButton
                      sx={{ p: "4px" }}
                      onClick={() => {
                        setFieldValue("defaultDatePickerSelection", undefined);
                      }}
                    >
                      <ClearIcon sx={{ fontSize: 20 }}></ClearIcon>
                    </IconButton>
                  </InputAdornment>
                )
              }
            />
            <FormHelperText error required>
              {errors.defaultDatePickerSelection?.toString()}
            </FormHelperText>
          </FormControl>
        </Grid2>

        <Grid2 size={isMobile ? 12 : 6}>
          <ParagraphBold>{t("labels__service_config_form_addon_tags")}</ParagraphBold>
          <ParagraphSmall sx={{ mt: theme.spacing(0.5) }}>
            {t("labels__service_config_form_addon_tags_description")}
          </ParagraphSmall>
          <FormControl fullWidth={true} sx={{ mt: theme.spacing(0.75) }}>
            <Autocomplete
              multiple
              freeSolo={false}
              value={values.tags ?? []}
              options={serviceTagOptions}
              disabled={!canEditServiceConfig}
              onChange={(_, newValue) => setFieldValue("tags", newValue)}
              getOptionLabel={(o) => t("labels__settings_service_config_servicetag_" + o)}
              renderTags={(value, getTagProps) => {
                return (
                  <>
                    {value.map((item, index) => {
                      return (
                        <Chip
                          sx={{ mx: 0.5 }}
                          color={errors.tags && errors.tags[index] ? "error" : "default"}
                          {...getTagProps({ index })}
                          label={t("labels__settings_service_config_servicetag_" + item)}
                          size="small"
                          rightIcon={canEditServiceConfig ? <CloseIcon /> : <></>}
                          onRightIconClick={() => getTagProps({ index }).onDelete({})}
                        />
                      );
                    })}
                  </>
                );
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  error={!!errors.tags}
                  disabled={!canEditServiceConfig}
                />
              )}
              defaultValue={[]}
            />
            <FormHelperText error required>
              {errors.tags?.toString()}
            </FormHelperText>
          </FormControl>
        </Grid2>
        <BaseServiceConfigFields />
      </Grid2>
    </>
  );
};
