export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = {
  [_ in K]?: never;
};
export type Incremental<T> =
  | T
  | { [P in keyof T]?: P extends " $fragmentName" | "__typename" ? T[P] : never };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string | number; output: string };
  String: { input: string; output: string };
  Boolean: { input: boolean; output: boolean };
  Int: { input: number; output: number };
  Float: { input: number; output: number };
  BigDecimal: { input: any; output: any };
  Date: { input: any; output: any };
  DateTime: { input: any; output: any };
  Instant: { input: any; output: any };
  JSON: { input: any; output: any };
};

export type Actor = {
  __typename?: "Actor";
  actorGroup?: Maybe<ActorGroup>;
  actorGroupId?: Maybe<Scalars["ID"]["output"]>;
  /** @deprecated No longer supported */
  actorId?: Maybe<Scalars["ID"]["output"]>;
  actorType: ActorType;
  displayName?: Maybe<Scalars["String"]["output"]>;
  employeeOrGuestIdentityOrGroupId?: Maybe<Scalars["Int"]["output"]>;
  firstName?: Maybe<Scalars["String"]["output"]>;
  id: Scalars["Int"]["output"];
  lastName?: Maybe<Scalars["String"]["output"]>;
};

export type ActorFilter = {
  typeId?: InputMaybe<Scalars["String"]["input"]>;
};

export type ActorGroup = {
  __typename?: "ActorGroup";
  actorGroupId: Scalars["ID"]["output"];
  actorGroupType: ActorGroupType;
  /** not needed */
  employees?: Maybe<Array<Maybe<Employee>>>;
  name: Scalars["String"]["output"];
};

export enum ActorGroupType {
  EmployeeGroup = "EMPLOYEE_GROUP",
  System = "SYSTEM"
}

export type ActorInput = {
  actorId?: InputMaybe<Scalars["ID"]["input"]>;
};

export enum ActorType {
  ActorGroup = "ACTOR_GROUP",
  Employee = "EMPLOYEE",
  GuestIdentity = "GUEST_IDENTITY"
}

export type Attachment = {
  fileName: Scalars["String"]["input"];
  mediaType: Scalars["String"]["input"];
  mediaUrl: Scalars["String"]["input"];
};

export type Channel = {
  __typename?: "Channel";
  channelId: Scalars["String"]["output"];
  name?: Maybe<Scalars["String"]["output"]>;
  platform: Platform;
};

export enum CommunicationChannel {
  Email = "EMAIL",
  Sms = "SMS",
  Whatsapp = "WHATSAPP"
}

export type Conversation = {
  __typename?: "Conversation";
  assignee?: Maybe<Actor>;
  conversationId: Scalars["ID"]["output"];
  guest: Actor;
  guestIdentity?: Maybe<GuestIdentity>;
  lastReceivedMessage?: Maybe<Scalars["DateTime"]["output"]>;
  messageBirdVersion?: Maybe<Scalars["String"]["output"]>;
  messages: Array<Maybe<Message>>;
  numberOfUnreadMessages: Scalars["Int"]["output"];
  pmsPropertyId: Scalars["String"]["output"];
  priority: Priority;
  replyToPlatform?: Maybe<Platform>;
  status: ConversationStatus;
  updatedAt?: Maybe<Scalars["String"]["output"]>;
};

export type ConversationMessagesArgs = {
  filter?: InputMaybe<MessageFilter>;
};

export type ConversationFilter = {
  conversationId?: InputMaybe<Scalars["ID"]["input"]>;
  filters?: InputMaybe<Scalars["JSON"]["input"]>;
  guestIdentityId?: InputMaybe<Scalars["Int"]["input"]>;
  pageable: Pageable;
  pmsPropertyIds?: InputMaybe<Array<Scalars["ID"]["input"]>>;
  tab?: InputMaybe<MessagingTab>;
};

export type ConversationInput = {
  assignee?: InputMaybe<ActorInput>;
  conversationId: Scalars["ID"]["input"];
  pmsPropertyId?: InputMaybe<Scalars["ID"]["input"]>;
  status?: InputMaybe<ConversationStatus>;
};

/**  https://developers.messagebird.com/api/conversations/#conversationstatus-object */
export enum ConversationStatus {
  /** v1: active */
  InProgress = "IN_PROGRESS",
  New = "NEW",
  /** v1: active */
  Resolved = "RESOLVED"
}

export type ConversationsPage = {
  __typename?: "ConversationsPage";
  content: Array<Conversation>;
  totalElements: Scalars["Int"]["output"];
};

/** https://developers.messagebird.com/api/conversations/#messagedirection-object */
export enum Direction {
  Received = "RECEIVED",
  Sent = "SENT"
}

export type Employee = {
  __typename?: "Employee";
  actorId: Scalars["Int"]["output"];
  firstName?: Maybe<Scalars["String"]["output"]>;
  id: Scalars["ID"]["output"];
  keycloakUUID?: Maybe<Scalars["String"]["output"]>;
  lastName?: Maybe<Scalars["String"]["output"]>;
  pmsPropertyIds: Array<Scalars["String"]["output"]>;
};

export enum Gender {
  Female = "FEMALE",
  Male = "MALE",
  Other = "OTHER"
}

export type GrossPrice = {
  __typename?: "GrossPrice";
  currency?: Maybe<Scalars["String"]["output"]>;
  grossPrice?: Maybe<Scalars["BigDecimal"]["output"]>;
  grossPriceInCents?: Maybe<Scalars["Int"]["output"]>;
};

export type GuestIdentity = {
  __typename?: "GuestIdentity";
  conversations: Array<Conversation>;
  email?: Maybe<Scalars["String"]["output"]>;
  firstName?: Maybe<Scalars["String"]["output"]>;
  gender?: Maybe<Gender>;
  id: Scalars["ID"]["output"];
  lastName?: Maybe<Scalars["String"]["output"]>;
  person?: Maybe<Person>;
  phone?: Maybe<Scalars["String"]["output"]>;
  pmsId?: Maybe<Scalars["String"]["output"]>;
  reservations?: Maybe<Array<Maybe<Reservation>>>;
  userProfile?: Maybe<UserProfile>;
};

export type GuestIdentityConversationsArgs = {
  page: PageableWithoutSorting;
};

export type Message = {
  __typename?: "Message";
  channel: Channel;
  content: Scalars["JSON"]["output"];
  conversationId: Scalars["ID"]["output"];
  createdAt: Scalars["DateTime"]["output"];
  direction: Direction;
  from?: Maybe<Actor>;
  /** https://developers.messagebird.com/api/conversations/#message-object */
  lastReaction?: Maybe<Scalars["String"]["output"]>;
  messageBirdVersion?: Maybe<Scalars["String"]["output"]>;
  messageId: Scalars["ID"]["output"];
  read: Scalars["Boolean"]["output"];
  /** https://developers.messagebird.com/api/conversations/#messagecontent-object */
  source?: Maybe<Scalars["JSON"]["output"]>;
  status: MessageStatus;
  to?: Maybe<Actor>;
  type: MessageType;
  updatedAt: Scalars["DateTime"]["output"];
};

export type MessageFilter = {
  conversationId?: InputMaybe<Scalars["String"]["input"]>;
  filters?: InputMaybe<Scalars["JSON"]["input"]>;
  messageId?: InputMaybe<Scalars["String"]["input"]>;
  pageable: Pageable;
};

/**
 * https://developers.messagebird.com/api/conversations/#messagestatus-object
 *  any changes here also need to be applied in the messagestatus file
 */
export enum MessageStatus {
  Accepted = "ACCEPTED",
  Bounce = "BOUNCE",
  Buffered = "BUFFERED",
  /** Email specific statuses */
  Clicked = "CLICKED",
  Delayed = "DELAYED",
  Deleted = "DELETED",
  Delivered = "DELIVERED",
  /** SMS specific statuses */
  DeliveryFailed = "DELIVERY_FAILED",
  Dispatched = "DISPATCHED",
  Expired = "EXPIRED",
  Failed = "FAILED",
  ListUnsubscribe = "LIST_UNSUBSCRIBE",
  Opened = "OPENED",
  Other = "OTHER",
  OutOfBounded = "OUT_OF_BOUNDED",
  Pending = "PENDING",
  Read = "READ",
  Received = "RECEIVED",
  Rejected = "REJECTED",
  SendingFailed = "SENDING_FAILED",
  Sent = "SENT",
  SpamComplaint = "SPAM_COMPLAINT",
  /** WA specific statuses */
  Transmitted = "TRANSMITTED",
  Unknown = "UNKNOWN",
  Unsupported = "UNSUPPORTED"
}

export type MessageToSend = {
  content: MessageToSendContent;
  conversationId: Scalars["ID"]["input"];
  pmsPropertyId?: InputMaybe<Scalars["ID"]["input"]>;
  type: MessageType;
};

export type MessageToSendContent = {
  /**  v1 */
  attachmentIds?: InputMaybe<Array<Scalars["String"]["input"]>>;
  /**  v2 */
  attachments?: InputMaybe<Array<Attachment>>;
  html?: InputMaybe<Scalars["String"]["input"]>;
  text?: InputMaybe<Scalars["String"]["input"]>;
};

export enum MessageType {
  BotMessage = "BOT_MESSAGE",
  ChatMessage = "CHAT_MESSAGE",
  ConversationMessage = "CONVERSATION_MESSAGE",
  FlowMessage = "FLOW_MESSAGE",
  InboxMessage = "INBOX_MESSAGE",
  IncomingMessage = "INCOMING_MESSAGE",
  InternalMessage = "INTERNAL_MESSAGE",
  NotificationMessage = "NOTIFICATION_MESSAGE",
  Other = "OTHER"
}

export enum MessagingTab {
  All = "ALL",
  AssignedToMe = "ASSIGNED_TO_ME",
  Inbox = "INBOX",
  Resolved = "RESOLVED"
}

export type Mutation = {
  __typename?: "Mutation";
  Reply: Array<Message>;
  SetConversationAsRead: Scalars["Boolean"]["output"];
  ToggleChatBot: Scalars["Boolean"]["output"];
  UpdateConversation: Conversation;
};

export type MutationReplyArgs = {
  message: MessageToSend;
};

export type MutationSetConversationAsReadArgs = {
  conversationId: Scalars["String"]["input"];
};

export type MutationToggleChatBotArgs = {
  conversationId: Scalars["String"]["input"];
  enableChatBot: Scalars["Boolean"]["input"];
};

export type MutationUpdateConversationArgs = {
  conversation: ConversationInput;
};

export type Pageable = {
  pageNumber?: InputMaybe<Scalars["Int"]["input"]>;
  pageSize?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Scalars["String"]["input"]>;
};

export type PageableWithoutSorting = {
  pageNumber: Scalars["Int"]["input"];
  pageSize: Scalars["Int"]["input"];
};

export type Person = {
  __typename?: "Person";
  birthdate?: Maybe<Scalars["Date"]["output"]>;
  email?: Maybe<Scalars["String"]["output"]>;
  firstName?: Maybe<Scalars["String"]["output"]>;
  gender?: Maybe<Gender>;
  id: Scalars["ID"]["output"];
  lastName?: Maybe<Scalars["String"]["output"]>;
  phone?: Maybe<Scalars["String"]["output"]>;
  pmsId: Scalars["String"]["output"];
  preferredCommunicationChannel?: Maybe<CommunicationChannel>;
  preferredLanguage?: Maybe<Scalars["String"]["output"]>;
};

export enum Platform {
  Chat = "CHAT",
  Email = "EMAIL",
  Event = "EVENT",
  Sms = "SMS",
  Whatsapp = "WHATSAPP"
}

export type Price = {
  __typename?: "Price";
  currency?: Maybe<Scalars["String"]["output"]>;
  grossPrice?: Maybe<Scalars["BigDecimal"]["output"]>;
  grossPriceInCents?: Maybe<Scalars["Int"]["output"]>;
  netPrice?: Maybe<Scalars["BigDecimal"]["output"]>;
  netPriceInCents?: Maybe<Scalars["Int"]["output"]>;
};

export enum Priority {
  High = "HIGH",
  Low = "LOW",
  Medium = "MEDIUM"
}

export type Query = {
  __typename?: "Query";
  GetConversations: ConversationsPage;
  GetEmployees: Array<Employee>;
};

export type QueryGetConversationsArgs = {
  filter: ConversationFilter;
};

export type QueryGetEmployeesArgs = {
  pmsPropertyIds?: InputMaybe<Array<Scalars["String"]["input"]>>;
};

export type Reservation = {
  __typename?: "Reservation";
  arrival: Scalars["Instant"]["output"];
  confirmationNumber?: Maybe<Scalars["String"]["output"]>;
  departure: Scalars["Instant"]["output"];
  magicId: Scalars["String"]["output"];
  pmsId: Scalars["String"]["output"];
  pmsPropertyId: Scalars["String"]["output"];
  services?: Maybe<Array<Maybe<Service>>>;
  status: ReservationStatus;
};

export enum ReservationStatus {
  Cancelled = "CANCELLED",
  CheckedOut = "CHECKED_OUT",
  Confirmed = "CONFIRMED",
  InHouse = "IN_HOUSE",
  NoShow = "NO_SHOW"
}

export type Service = {
  __typename?: "Service";
  magicServiceCodeEnum?: Maybe<Scalars["String"]["output"]>;
  name?: Maybe<Scalars["String"]["output"]>;
  price?: Maybe<GrossPrice>;
  quantity: Scalars["Int"]["output"];
};

export type Subscription = {
  __typename?: "Subscription";
  StreamConversation: ConversationsPage;
  StreamMessage: Array<Message>;
  StreamUnreadMessagesStat: UnreadMessagesStat;
};

export type SubscriptionStreamConversationArgs = {
  filter: ConversationFilter;
};

export type SubscriptionStreamMessageArgs = {
  filter: MessageFilter;
};

export type SubscriptionStreamUnreadMessagesStatArgs = {
  pmsPropertyIds?: InputMaybe<Array<Scalars["ID"]["input"]>>;
};

export type Task = {
  __typename?: "Task";
  /** based on user profile */
  id: Scalars["String"]["output"];
};

export type UnreadMessagesStat = {
  __typename?: "UnreadMessagesStat";
  numberOfUnreadMessagesInAssignedToMe: Scalars["Int"]["output"];
  numberOfUnreadMessagesInInbox: Scalars["Int"]["output"];
  numberOfUnreadMessagesInResolved: Scalars["Int"]["output"];
  totalNumberOfUnreadMessages: Scalars["Int"]["output"];
};

export type UserProfile = {
  __typename?: "UserProfile";
  birthdate?: Maybe<Scalars["Date"]["output"]>;
  /** release 2 start */
  conversations?: Maybe<Array<Maybe<Conversation>>>;
  email?: Maybe<Scalars["String"]["output"]>;
  firstName?: Maybe<Scalars["String"]["output"]>;
  gender?: Maybe<Gender>;
  guestIdentityId?: Maybe<Scalars["Int"]["output"]>;
  guestPreferences?: Maybe<Scalars["JSON"]["output"]>;
  guestPreferencesText?: Maybe<Scalars["String"]["output"]>;
  /** release 2 end */
  id: Scalars["ID"]["output"];
  identificationDocumentNumber?: Maybe<Scalars["String"]["output"]>;
  identificationDocumentType?: Maybe<Scalars["String"]["output"]>;
  identificationDocumentUploaded: Scalars["Boolean"]["output"];
  isEmailVerified?: Maybe<Scalars["Boolean"]["output"]>;
  lastName?: Maybe<Scalars["String"]["output"]>;
  nationality?: Maybe<Scalars["String"]["output"]>;
  phone?: Maybe<Scalars["String"]["output"]>;
  preferredCommunicationChannel?: Maybe<CommunicationChannel>;
  preferredLanguage?: Maybe<Scalars["String"]["output"]>;
  signatureDocumentUploaded: Scalars["Boolean"]["output"];
  /**
   * sub filter example
   * reservations(filter:Filter): [Reservation]
   */
  tasks?: Maybe<Array<Maybe<Task>>>;
  termsAndConditionsGeneral: Scalars["Boolean"]["output"];
  termsAndConditionsMarketingConsent: Scalars["Boolean"]["output"];
  termsAndConditionsOnline: Scalars["Boolean"]["output"];
  title?: Maybe<Scalars["String"]["output"]>;
  userAccountUuid?: Maybe<Scalars["String"]["output"]>;
};

export type ActorFragment = {
  __typename?: "Actor";
  id: number;
  actorId?: string | null;
  employeeOrGuestIdentityOrGroupId?: number | null;
  actorGroupId?: string | null;
  displayName?: string | null;
  actorGroup?: { __typename?: "ActorGroup"; actorGroupType: ActorGroupType; name: string } | null;
};

export type ActorGroupFragment = {
  __typename?: "ActorGroup";
  actorGroupType: ActorGroupType;
  name: string;
};

export type MessageFragment = {
  __typename?: "Message";
  conversationId: string;
  messageBirdVersion?: string | null;
  messageId: string;
  content: any;
  direction: Direction;
  read: boolean;
  createdAt: any;
  status: MessageStatus;
  to?: {
    __typename?: "Actor";
    id: number;
    actorId?: string | null;
    employeeOrGuestIdentityOrGroupId?: number | null;
    actorGroupId?: string | null;
    displayName?: string | null;
    actorGroup?: { __typename?: "ActorGroup"; actorGroupType: ActorGroupType; name: string } | null;
  } | null;
  from?: {
    __typename?: "Actor";
    id: number;
    actorId?: string | null;
    employeeOrGuestIdentityOrGroupId?: number | null;
    actorGroupId?: string | null;
    displayName?: string | null;
    actorGroup?: { __typename?: "ActorGroup"; actorGroupType: ActorGroupType; name: string } | null;
  } | null;
  channel: { __typename?: "Channel"; platform: Platform; name?: string | null };
};

export type ReplyMessageMutationVariables = Exact<{
  message: MessageToSend;
}>;

export type ReplyMessageMutation = {
  __typename?: "Mutation";
  Reply: Array<{ __typename?: "Message"; createdAt: any }>;
};

export type SetConversationAsReadMutationVariables = Exact<{
  conversationId: Scalars["String"]["input"];
}>;

export type SetConversationAsReadMutation = {
  __typename?: "Mutation";
  SetConversationAsRead: boolean;
};

export type ToggleChatBotMutationVariables = Exact<{
  conversationId: Scalars["String"]["input"];
  enableChatBot: Scalars["Boolean"]["input"];
}>;

export type ToggleChatBotMutation = { __typename?: "Mutation"; ToggleChatBot: boolean };

export type UpdateConversationMutationVariables = Exact<{
  conversation: ConversationInput;
}>;

export type UpdateConversationMutation = {
  __typename?: "Mutation";
  UpdateConversation: { __typename?: "Conversation"; conversationId: string };
};

export type GetEmployeesQueryVariables = Exact<{
  pmsPropertyIds?: InputMaybe<Array<Scalars["String"]["input"]> | Scalars["String"]["input"]>;
}>;

export type GetEmployeesQuery = {
  __typename?: "Query";
  GetEmployees: Array<{
    __typename?: "Employee";
    id: string;
    actorId: number;
    keycloakUUID?: string | null;
    firstName?: string | null;
    lastName?: string | null;
    pmsPropertyIds: Array<string>;
  }>;
};

export type GetConversationDetailsQueryVariables = Exact<{
  filter: ConversationFilter;
}>;

export type GetConversationDetailsQuery = {
  __typename?: "Query";
  GetConversations: {
    __typename?: "ConversationsPage";
    totalElements: number;
    content: Array<{
      __typename?: "Conversation";
      conversationId: string;
      lastReceivedMessage?: any | null;
      messageBirdVersion?: string | null;
      pmsPropertyId: string;
      status: ConversationStatus;
      numberOfUnreadMessages: number;
      replyToPlatform?: Platform | null;
      assignee?: {
        __typename?: "Actor";
        id: number;
        actorId?: string | null;
        employeeOrGuestIdentityOrGroupId?: number | null;
        actorGroupId?: string | null;
        displayName?: string | null;
        actorGroup?: {
          __typename?: "ActorGroup";
          actorGroupType: ActorGroupType;
          name: string;
        } | null;
      } | null;
      guest: {
        __typename?: "Actor";
        id: number;
        actorId?: string | null;
        employeeOrGuestIdentityOrGroupId?: number | null;
        actorGroupId?: string | null;
        displayName?: string | null;
        actorGroup?: {
          __typename?: "ActorGroup";
          actorGroupType: ActorGroupType;
          name: string;
        } | null;
      };
      guestIdentity?: {
        __typename?: "GuestIdentity";
        email?: string | null;
        firstName?: string | null;
        id: string;
        lastName?: string | null;
        phone?: string | null;
        gender?: Gender | null;
        pmsId?: string | null;
        lastConversations: Array<{
          __typename?: "Conversation";
          conversationId: string;
          status: ConversationStatus;
          lastReceivedMessage?: any | null;
          pmsPropertyId: string;
          assignee?: {
            __typename?: "Actor";
            id: number;
            actorId?: string | null;
            employeeOrGuestIdentityOrGroupId?: number | null;
            actorGroupId?: string | null;
            displayName?: string | null;
            actorGroup?: {
              __typename?: "ActorGroup";
              actorGroupType: ActorGroupType;
              name: string;
            } | null;
          } | null;
          messages: Array<{
            __typename?: "Message";
            updatedAt: any;
            conversationId: string;
            messageBirdVersion?: string | null;
            messageId: string;
            content: any;
            direction: Direction;
            read: boolean;
            createdAt: any;
            status: MessageStatus;
            to?: {
              __typename?: "Actor";
              id: number;
              actorId?: string | null;
              employeeOrGuestIdentityOrGroupId?: number | null;
              actorGroupId?: string | null;
              displayName?: string | null;
              actorGroup?: {
                __typename?: "ActorGroup";
                actorGroupType: ActorGroupType;
                name: string;
              } | null;
            } | null;
            from?: {
              __typename?: "Actor";
              id: number;
              actorId?: string | null;
              employeeOrGuestIdentityOrGroupId?: number | null;
              actorGroupId?: string | null;
              displayName?: string | null;
              actorGroup?: {
                __typename?: "ActorGroup";
                actorGroupType: ActorGroupType;
                name: string;
              } | null;
            } | null;
            channel: { __typename?: "Channel"; platform: Platform; name?: string | null };
          } | null>;
        }>;
        reservations?: Array<{
          __typename?: "Reservation";
          arrival: any;
          departure: any;
          pmsId: string;
          pmsPropertyId: string;
          magicId: string;
          status: ReservationStatus;
          confirmationNumber?: string | null;
          services?: Array<{
            __typename?: "Service";
            name?: string | null;
            magicServiceCodeEnum?: string | null;
            quantity: number;
            price?: {
              __typename?: "GrossPrice";
              currency?: string | null;
              grossPrice?: any | null;
              grossPriceInCents?: number | null;
            } | null;
          } | null> | null;
        } | null> | null;
      } | null;
      messages: Array<{
        __typename?: "Message";
        conversationId: string;
        messageBirdVersion?: string | null;
        messageId: string;
        content: any;
        direction: Direction;
        read: boolean;
        createdAt: any;
        status: MessageStatus;
        to?: {
          __typename?: "Actor";
          id: number;
          actorId?: string | null;
          employeeOrGuestIdentityOrGroupId?: number | null;
          actorGroupId?: string | null;
          displayName?: string | null;
          actorGroup?: {
            __typename?: "ActorGroup";
            actorGroupType: ActorGroupType;
            name: string;
          } | null;
        } | null;
        from?: {
          __typename?: "Actor";
          id: number;
          actorId?: string | null;
          employeeOrGuestIdentityOrGroupId?: number | null;
          actorGroupId?: string | null;
          displayName?: string | null;
          actorGroup?: {
            __typename?: "ActorGroup";
            actorGroupType: ActorGroupType;
            name: string;
          } | null;
        } | null;
        channel: { __typename?: "Channel"; platform: Platform; name?: string | null };
      } | null>;
    }>;
  };
};

export type GetConversationsQueryVariables = Exact<{
  filter: ConversationFilter;
}>;

export type GetConversationsQuery = {
  __typename?: "Query";
  GetConversations: {
    __typename?: "ConversationsPage";
    totalElements: number;
    content: Array<{
      __typename?: "Conversation";
      conversationId: string;
      messageBirdVersion?: string | null;
      lastReceivedMessage?: any | null;
      status: ConversationStatus;
      numberOfUnreadMessages: number;
      updatedAt?: string | null;
      pmsPropertyId: string;
      assignee?: {
        __typename?: "Actor";
        id: number;
        actorId?: string | null;
        employeeOrGuestIdentityOrGroupId?: number | null;
        actorGroupId?: string | null;
        displayName?: string | null;
        actorGroup?: {
          __typename?: "ActorGroup";
          actorGroupType: ActorGroupType;
          name: string;
        } | null;
      } | null;
      guest: {
        __typename?: "Actor";
        id: number;
        actorId?: string | null;
        employeeOrGuestIdentityOrGroupId?: number | null;
        actorGroupId?: string | null;
        displayName?: string | null;
        actorGroup?: {
          __typename?: "ActorGroup";
          actorGroupType: ActorGroupType;
          name: string;
        } | null;
      };
      lastMessage: Array<{
        __typename?: "Message";
        messageId: string;
        messageBirdVersion?: string | null;
        content: any;
        updatedAt: any;
        channel: { __typename?: "Channel"; platform: Platform };
        from?: {
          __typename?: "Actor";
          id: number;
          actorId?: string | null;
          employeeOrGuestIdentityOrGroupId?: number | null;
          actorGroupId?: string | null;
          displayName?: string | null;
          actorGroup?: {
            __typename?: "ActorGroup";
            actorGroupType: ActorGroupType;
            name: string;
          } | null;
        } | null;
      } | null>;
    }>;
  };
};

export type StreamConversationSubscriptionVariables = Exact<{
  filter: ConversationFilter;
}>;

export type StreamConversationSubscription = {
  __typename?: "Subscription";
  StreamConversation: {
    __typename?: "ConversationsPage";
    totalElements: number;
    content: Array<{
      __typename?: "Conversation";
      conversationId: string;
      lastReceivedMessage?: any | null;
      messageBirdVersion?: string | null;
      status: ConversationStatus;
      numberOfUnreadMessages: number;
      updatedAt?: string | null;
      pmsPropertyId: string;
      assignee?: {
        __typename?: "Actor";
        id: number;
        actorId?: string | null;
        employeeOrGuestIdentityOrGroupId?: number | null;
        actorGroupId?: string | null;
        displayName?: string | null;
        actorGroup?: {
          __typename?: "ActorGroup";
          actorGroupType: ActorGroupType;
          name: string;
        } | null;
      } | null;
      guest: {
        __typename?: "Actor";
        id: number;
        actorId?: string | null;
        employeeOrGuestIdentityOrGroupId?: number | null;
        actorGroupId?: string | null;
        displayName?: string | null;
        actorGroup?: {
          __typename?: "ActorGroup";
          actorGroupType: ActorGroupType;
          name: string;
        } | null;
      };
      lastMessage: Array<{
        __typename?: "Message";
        messageId: string;
        messageBirdVersion?: string | null;
        content: any;
        updatedAt: any;
        channel: { __typename?: "Channel"; platform: Platform };
        from?: {
          __typename?: "Actor";
          id: number;
          actorId?: string | null;
          employeeOrGuestIdentityOrGroupId?: number | null;
          actorGroupId?: string | null;
          displayName?: string | null;
          actorGroup?: {
            __typename?: "ActorGroup";
            actorGroupType: ActorGroupType;
            name: string;
          } | null;
        } | null;
      } | null>;
    }>;
  };
};

export type StreamUnreadMessagesStatSubscriptionVariables = Exact<{
  propertyIds: Array<Scalars["ID"]["input"]> | Scalars["ID"]["input"];
}>;

export type StreamUnreadMessagesStatSubscription = {
  __typename?: "Subscription";
  StreamUnreadMessagesStat: {
    __typename?: "UnreadMessagesStat";
    numberOfUnreadMessagesInResolved: number;
    numberOfUnreadMessagesInAssignedToMe: number;
    numberOfUnreadMessagesInInbox: number;
    totalNumberOfUnreadMessages: number;
  };
};

export type StreamMessageSubscriptionVariables = Exact<{
  conversationId?: InputMaybe<Scalars["String"]["input"]>;
}>;

export type StreamMessageSubscription = {
  __typename?: "Subscription";
  StreamMessage: Array<{
    __typename?: "Message";
    conversationId: string;
    messageBirdVersion?: string | null;
    messageId: string;
    content: any;
    direction: Direction;
    read: boolean;
    createdAt: any;
    status: MessageStatus;
    to?: {
      __typename?: "Actor";
      id: number;
      actorId?: string | null;
      employeeOrGuestIdentityOrGroupId?: number | null;
      actorGroupId?: string | null;
      displayName?: string | null;
      actorGroup?: {
        __typename?: "ActorGroup";
        actorGroupType: ActorGroupType;
        name: string;
      } | null;
    } | null;
    from?: {
      __typename?: "Actor";
      id: number;
      actorId?: string | null;
      employeeOrGuestIdentityOrGroupId?: number | null;
      actorGroupId?: string | null;
      displayName?: string | null;
      actorGroup?: {
        __typename?: "ActorGroup";
        actorGroupType: ActorGroupType;
        name: string;
      } | null;
    } | null;
    channel: { __typename?: "Channel"; platform: Platform; name?: string | null };
  }>;
};
