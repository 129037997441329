import * as Types from "../generated/graphql";

export type ServiceConfigConflictFragment = {
  __typename?: "ServiceConfigConflict";
  type: Types.ConflictType;
  relatedServiceId?: string | null;
  relatedUnitGroupId?: string | null;
  relatedFieldName?: string | null;
};

export const ServiceConfigConflictFragmentDoc = `
    fragment ServiceConfigConflict on ServiceConfigConflict {
  type
  relatedServiceId
  relatedUnitGroupId
  relatedFieldName
}
    `;
