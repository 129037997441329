import * as React from "react";
import { FC, useCallback, useMemo, useState } from "react";
import {
  Autocomplete,
  Box,
  createFilterOptions,
  List,
  ListItemButton,
  ListItemText,
  TextField,
  useTheme
} from "@mui/material";
import {
  ServiceConfigConflict,
  ServiceContract,
  ServiceIdentifier
} from "../../../../graphql-tenantconfig/generated/graphql";
import {
  Chip,
  Paragraph,
  ParagraphSmall,
  ParagraphSmallBold
} from "@likemagic-tech/sv-magic-library";
import CloseIcon from "@mui/icons-material/Close";
import { containsConflictRelatedToServiceId, sortedServices } from "../service-config-utils";
import Paper from "@mui/material/Paper";
import { useTranslationWrapper } from "../../../../hooks/use-translation-wrapper";

interface MultipleServiceInputProps {
  value: Array<ServiceIdentifier>;
  setValue: (arg: Array<ServiceIdentifier> | null) => void;
  error?: boolean;
  options?: Array<ServiceContract>;
  conflicts: ServiceConfigConflict[];
  disabled?: boolean;
}

export const MultipleServiceInput: FC<MultipleServiceInputProps> = ({
  value,
  setValue,
  error,
  options,
  conflicts,
  disabled
}) => {
  const theme = useTheme();
  const { t } = useTranslationWrapper();

  const [open, setOpen] = useState(false);
  const closePopper = () => setOpen(false);
  const openPopper = () => setOpen(true);

  const serviceIdentifierOptions = useMemo(() => {
    const serviceIdentifiers = options?.map((o) => ({ serviceId: o.pmsId, details: o })) ?? [];
    return sortedServices(serviceIdentifiers);
  }, [options]);

  const onChange = useCallback(
    (newValue: Array<ServiceIdentifier> | null) => {
      setValue(newValue);
    },
    [setValue]
  );

  const handleSelectAll = useCallback(() => {
    setValue([...serviceIdentifierOptions]);
    closePopper();
  }, [setValue, serviceIdentifierOptions]);

  const filterOptions = createFilterOptions({
    stringify: (option: ServiceIdentifier) => `${option.details?.name} ${option.serviceId}`
  });

  return (
    <Autocomplete
      multiple
      open={open}
      onOpen={openPopper}
      onClose={closePopper}
      freeSolo={false}
      value={sortedServices(value || [])}
      options={serviceIdentifierOptions}
      filterOptions={filterOptions}
      onChange={(_, newValue) => {
        onChange(newValue);
      }}
      renderOption={(props, option) => {
        const { key, ...optionProps } = props;
        return (
          <Box key={key} component="li" {...optionProps}>
            <Box display="inline-flex" flexDirection="column">
              <Paragraph>{option.details?.name || option.serviceId}</Paragraph>
              <ParagraphSmall sx={{ color: theme.palette.text.secondary }}>
                {option.serviceId}
              </ParagraphSmall>
            </Box>
          </Box>
        );
      }}
      getOptionKey={(option) => option.serviceId}
      getOptionLabel={(option) => option.details?.name || option.serviceId}
      disabled={disabled}
      renderTags={(value, getTagProps) => {
        return (
          <>
            {value.map((item, index) => {
              return (
                <Chip
                  color={
                    containsConflictRelatedToServiceId(conflicts, item.serviceId)
                      ? "error"
                      : "default"
                  }
                  sx={{ mx: 0.5 }}
                  {...getTagProps({ index })}
                  label={item.details?.name || item.serviceId}
                  size="small"
                  rightIcon={disabled ? <></> : <CloseIcon />}
                  onRightIconClick={() => getTagProps({ index }).onDelete({})}
                />
              );
            })}
          </>
        );
      }}
      renderInput={(params) => <TextField {...params} variant="outlined" error={error} />}
      defaultValue={[]}
      isOptionEqualToValue={(option, value) => option.serviceId === value.serviceId}
      slots={{
        paper: (paperProps) => {
          const { children, ...restPaperProps } = paperProps;
          return (
            <Paper {...restPaperProps}>
              <Box
                onMouseDown={(e) => e.preventDefault()} // prevent blur
              >
                <List>
                  <ListItemButton
                    onClick={(e) => {
                      handleSelectAll();
                    }}
                  >
                    <ListItemText>{t("labels__services_select_all")}</ListItemText>
                  </ListItemButton>
                </List>
              </Box>
              <ParagraphSmallBold
                sx={{
                  color: theme.palette.text.secondary,
                  px: theme.spacing(2)
                }}
              >
                {t("labels__services_available_services")}
              </ParagraphSmallBold>
              {children}
            </Paper>
          );
        }
      }}
    />
  );
};
