import React, { FC, useCallback, useEffect, useRef, useState } from "react";
import { Box, DialogContent, Divider, Grid2, useTheme } from "@mui/material";
import { Button, Heading3, usePropertyConfig } from "@likemagic-tech/sv-magic-library";
import { TotalPricePreview } from "./total-price-preview";
import { PaymentSelection } from "./payment-selection";
import { PaymentOption } from "./domain/payment-option";
import { DialogModal } from "../../../components/submit-modal/dialog";
import { DialogTitleModal } from "../../../components/submit-modal/dialog-title";
import { PaymentComponent } from "./payment-component";
import {
  FolioPreview,
  ReservationDetailsDTO
} from "../../../store/endpoints/reservation-table.endpoints";
import { usePaymentMethodOptions } from "./payment-component-handlers/use-payment-method-options";
import { isFolioClosed } from "./payment-utils";
import { useTranslationWrapper } from "../../../hooks/use-translation-wrapper";
import { FolioItemsDetailsCharges } from "./folio-items-details-charges";
import { FolioItemsDetailsPayments } from "./folio-items-details-payments";
import { useDispatch } from "src/store";
import { openBanner } from "src/slices/banner.slice";

interface FolioItemDetailsProps {
  folio: FolioPreview;
  folios: Array<FolioPreview>;
  propertyId: string;
  propertyTimeZone: string;
  magicId: string;
  reservation: ReservationDetailsDTO;
}

export const FolioItemDetails: FC<FolioItemDetailsProps> = ({
  folio,
  magicId,
  propertyId,
  propertyTimeZone,
  reservation,
  folios
}) => {
  const { t } = useTranslationWrapper();
  const dispatch = useDispatch();
  const [paymentOptionHashCode, setPaymentOptionHashCode] = useState<string | undefined>();
  const [openModal, setOpenModal] = useState(false);
  const [showPaymentOption, setShowPaymentOption] = useState(false);
  const { features } = usePropertyConfig({
    propertyId: propertyId
  });
  const { options, getPaymentOptionDetails, defaultSelectedHashId } = usePaymentMethodOptions({
    propertyId,
    magicId,
    skip: !features?.billingTabAddPaymentEnabled
  });
  const { palette } = useTheme();
  //defaultSelectedHashId is calculated when we get data from GetPaymetnMethods
  useEffect(() => {
    if (defaultSelectedHashId) {
      setPaymentOptionHashCode(defaultSelectedHashId);
    }
  }, [defaultSelectedHashId]);

  const paymentComponentRef = useRef<HTMLDivElement>(null);

  const clearSelection = () => {
    setOpenModal(false);
  };

  const onFailed = useCallback(() => {
    setPaymentOptionHashCode(undefined);
  }, []);

  const onSuccess = useCallback(() => {
    setShowPaymentOption(false);
    setPaymentOptionHashCode(undefined);
    dispatch(
      openBanner({
        type: "success",
        title: t("labels__action_successfully_performed")
      })
    );
  }, [t, dispatch]);

  const paymentOption = getPaymentOptionDetails(paymentOptionHashCode);

  return (
    <>
      <Grid2
        container
        sx={{ border: `1px solid ${palette.grey[300]}`, opacity: isFolioClosed(folio) ? 0.5 : 1 }}
      >
        <FolioItemsDetailsCharges
          folios={folios}
          folio={folio}
          reservationId={reservation.id}
          propertyTimeZone={propertyTimeZone}
          propertyId={propertyId}
          setShowPaymentOption={setShowPaymentOption}
        />
        <FolioItemsDetailsPayments
          folios={folios}
          folio={folio}
          magicId={magicId}
          reservation={reservation}
          propertyTimeZone={propertyTimeZone}
          setShowPaymentOption={setShowPaymentOption}
          paymentComponentRef={paymentComponentRef}
        />
      </Grid2>
      <Box
        sx={{
          mt: 3
        }}
      >
        <TotalPricePreview
          price={{
            amount: folio.balance.amount === 0 ? 0 : folio.balance.amount * -1,
            currency: folio.balance.currency
          }}
          label={t("labels__balance")}
        />
      </Box>
      <div ref={paymentComponentRef}>
        {showPaymentOption && (
          <>
            <Divider sx={{ my: 3 }} />
            <Heading3 mb={4}>{t("subtitle__payment")}</Heading3>
            <PaymentSelection
              options={options.filter(
                (item) => item?.type && ![PaymentOption.PreAuthorization].includes(item.type)
              )}
              value={paymentOptionHashCode}
              setValue={setPaymentOptionHashCode}
            />

            <Grid2
              container
              spacing={3}
              sx={{
                justifyContent: "flex-end",
                mt: 0
              }}
            >
              <Grid2
                sx={{
                  minWidth: 140
                }}
              >
                <Button variant="secondary" onClick={() => setShowPaymentOption(false)} fullWidth>
                  {t("buttons__cancel")}
                </Button>
              </Grid2>
              <Grid2
                sx={{
                  minWidth: 140
                }}
              >
                <Button
                  variant="primary"
                  onClick={() => setOpenModal(true)}
                  fullWidth
                  type="submit"
                >
                  {t("buttons__pay")}
                </Button>
              </Grid2>
            </Grid2>
          </>
        )}
      </div>
      {paymentOptionHashCode && openModal && (
        <DialogModal isOpen={true} handleClose={clearSelection} overflow="visible">
          <Box
            sx={{
              p: 3,
              width: "100%"
            }}
          >
            <DialogTitleModal
              title={t(`modals__title_${paymentOption?.type}`)}
              onClose={clearSelection}
            />
            <DialogContent sx={{ padding: 0, pt: 1 }}>
              <PaymentComponent
                paymentOption={paymentOption}
                onSuccess={onSuccess}
                onFailed={onFailed}
                closeModal={clearSelection}
                magicId={magicId}
                price={{
                  amount: Math.abs(folio.balance.amount),
                  currency: folio.balance.currency
                }}
                propertyId={propertyId}
                isPreAuthorisation={false}
                folioMetadata={folio.metadata}
              />
            </DialogContent>
          </Box>
        </DialogModal>
      )}
    </>
  );
};
