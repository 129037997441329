import * as React from "react";
import { FC } from "react";
import { Helmet } from "react-helmet-async";
import { useSelector } from "../../store";
import { selectAllUnreadMessageCount } from "../../slices/unread-message-count.slice";

interface HelmetWrapperProps {
  title: string;
}

export const HelmetWrapper: FC<HelmetWrapperProps> = ({ title }) => {
  const noOfAllUnreadMessages = useSelector(selectAllUnreadMessageCount);
  const prefix = noOfAllUnreadMessages ? `(${noOfAllUnreadMessages}) ` : "";

  return (
    <Helmet>
      <title>
        {prefix}
        {title}
      </title>
    </Helmet>
  );
};
