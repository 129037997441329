import { fetchDoorAccessLogs, selectDoorAccessLogs } from "../slices/door-access-logs.slice";
import { formatDate, formatTime, useApiVersion } from "@likemagic-tech/sv-magic-library";
import { ChangeEvent, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "../store";
import { useProperty } from "./use-property";
import { DoorAccessLog } from "../domain/door-access-log";
import { useTranslationWrapper } from "./use-translation-wrapper";
import { Maybe } from "graphql/jsutils/Maybe";
import { getI18nSelectedLanguage } from "../utils/language";

const statusLabelsMap = {
  OK: "labels__door_access_log_status_ok",
  NOT_OK: "labels__door_access_log_status_failed_to_open"
};

const guestTypeLabelsMap: Record<string, string> = {
  PRIMARY_GUEST: "labels__reservation_PRIMARY_GUEST",
  TRAVEL_BUDDY: "labels__reservation_TRAVEL_BUDDY",
  ADMIN: "labels__admin"
};

const formatDoorAccessLogGuestType = (role: Maybe<string> | undefined): string => {
  if (!role) return "";
  return guestTypeLabelsMap[role] || role;
};

const formatDoorAccessLogDate = (timestamp: string, language: string, timeZone: string): string =>
  `${formatDate(timestamp, language, timeZone)} ${formatTime(timestamp, language, timeZone)}`;

interface PaginationConfig {
  rowsPerPage?: number;
  defaultPage?: number;
}

export const useDoorAccessLogs = (
  reservationId: string,
  { rowsPerPage = 10, defaultPage = 0 }: PaginationConfig = {}
) => {
  const { t } = useTranslationWrapper();
  const language = getI18nSelectedLanguage();
  const { isRESTVersion: isV1 } = useApiVersion();
  const { selectedProperty } = useProperty();
  const dispatch = useDispatch();

  const [page, setPage] = useState(defaultPage);
  const [itemsPerPage, setItemsPerPage] = useState(rowsPerPage);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      if (reservationId) {
        setIsLoading(true);
        try {
          await dispatch(
            fetchDoorAccessLogs({
              reservationId,
              isV1
            })
          );
        } finally {
          setIsLoading(false);
        }
      }
    };

    fetchData();
  }, [reservationId, dispatch, isV1]);

  const allDoorAccessLogs = useSelector(selectDoorAccessLogs);

  const formattedDoorAccessLogs: DoorAccessLog[] = useMemo(() => {
    return allDoorAccessLogs
      .slice()
      .reverse()
      .map((log) => ({
        doorId: log.doorId,
        name: log.name,
        status: t(statusLabelsMap[log.status]),
        guestType: t(formatDoorAccessLogGuestType(log.requestor.role)),
        timestamp: formatDoorAccessLogDate(
          log.timestamp,
          language,
          selectedProperty.details.timeZone
        )
      }));
  }, [allDoorAccessLogs, language, selectedProperty.details.timeZone, t]);

  const doorAccessLogs = useMemo(() => {
    const startIndex = page * itemsPerPage;
    return formattedDoorAccessLogs.slice(startIndex, startIndex + itemsPerPage);
  }, [page, itemsPerPage, formattedDoorAccessLogs]);

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    const newRowsPerPage = parseInt(event.target.value, 10);
    setItemsPerPage(newRowsPerPage);
    setPage(0);
  };

  return {
    doorAccessLogs,
    page,
    rowsPerPage: itemsPerPage,
    totalCount: allDoorAccessLogs.length,
    onPageChange: handleChangePage,
    onRowsPerPageChange: handleChangeRowsPerPage,
    isLoading
  };
};
