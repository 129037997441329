export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = {
  [_ in K]?: never;
};
export type Incremental<T> =
  | T
  | { [P in keyof T]?: P extends " $fragmentName" | "__typename" ? T[P] : never };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string | number; output: string };
  String: { input: string; output: string };
  Boolean: { input: boolean; output: boolean };
  Int: { input: number; output: number };
  Float: { input: number; output: number };
  BigDecimal: { input: any; output: any };
  Date: { input: any; output: any };
  Instant: { input: any; output: any };
  JSON: { input: any; output: any };
  LocalTime: { input: any; output: any };
};

export type AddOnServiceConfig = BaseServiceConfig &
  ServiceConfig & {
    __typename?: "AddOnServiceConfig";
    addOnType: AddOnType;
    availableAfterMidnight?: Maybe<Scalars["String"]["output"]>;
    blacklistedDateRanges?: Maybe<Array<DateRange>>;
    bookOnExtendStay?: Maybe<Scalars["Boolean"]["output"]>;
    bookableDays?: Maybe<Array<BookableDay>>;
    bookableOnDepartureDay?: Maybe<Scalars["Boolean"]["output"]>;
    bookingChannels?: Maybe<Array<Scalars["String"]["output"]>>;
    canBookMultiple?: Maybe<Scalars["Boolean"]["output"]>;
    conflicts?: Maybe<Array<ServiceConfigConflict>>;
    defaultDatePickerSelection?: Maybe<DefaultDatePickerSelection>;
    defaultTargetAudience?: Maybe<DefaultTargetAudience>;
    granularity?: Maybe<ServiceConfigGranularity>;
    id: Scalars["String"]["output"];
    maximum?: Maybe<Scalars["Int"]["output"]>;
    maximumBefore?: Maybe<Scalars["String"]["output"]>;
    minimumBefore?: Maybe<Scalars["String"]["output"]>;
    payAtCheckout?: Maybe<Scalars["Boolean"]["output"]>;
    postOnNextDay?: Maybe<Scalars["Boolean"]["output"]>;
    reservationStatuses?: Maybe<Array<ReservationStatus>>;
    service: ServiceIdentifier;
    serviceConfigHash: Scalars["Int"]["output"];
    tags?: Maybe<Array<ServiceTag>>;
    task?: Maybe<TaskConfig>;
    type: ServiceConfigType;
    unitGroups?: Maybe<Array<UnitGroupIdentifier>>;
    visibilities?: Maybe<Array<Visibility>>;
    whitelistedDateRanges?: Maybe<Array<DateRange>>;
  };

export enum AddOnType {
  Other = "OTHER",
  Parking = "PARKING"
}

export type AdditionalCleaningServiceConfig = BaseServiceConfig &
  ServiceConfig & {
    __typename?: "AdditionalCleaningServiceConfig";
    blacklistedDateRanges?: Maybe<Array<DateRange>>;
    bookableDays?: Maybe<Array<BookableDay>>;
    bookingChannels?: Maybe<Array<Scalars["String"]["output"]>>;
    conflicts?: Maybe<Array<ServiceConfigConflict>>;
    defaultDatePickerSelection?: Maybe<DefaultDatePickerSelection>;
    granularity?: Maybe<ServiceConfigGranularity>;
    id: Scalars["String"]["output"];
    maximum?: Maybe<Scalars["Int"]["output"]>;
    maximumBefore?: Maybe<Scalars["String"]["output"]>;
    minimumBefore?: Maybe<Scalars["String"]["output"]>;
    payAtCheckout?: Maybe<Scalars["Boolean"]["output"]>;
    reservationStatuses?: Maybe<Array<ReservationStatus>>;
    service: ServiceIdentifier;
    serviceConfigHash: Scalars["Int"]["output"];
    task?: Maybe<TaskConfig>;
    type: ServiceConfigType;
    unitGroups?: Maybe<Array<UnitGroupIdentifier>>;
    visibilities?: Maybe<Array<Visibility>>;
    whitelistedDateRanges?: Maybe<Array<DateRange>>;
  };

export type BaseServiceConfig = {
  blacklistedDateRanges?: Maybe<Array<DateRange>>;
  bookableDays?: Maybe<Array<BookableDay>>;
  bookingChannels?: Maybe<Array<Scalars["String"]["output"]>>;
  conflicts?: Maybe<Array<ServiceConfigConflict>>;
  id: Scalars["String"]["output"];
  maximum?: Maybe<Scalars["Int"]["output"]>;
  maximumBefore?: Maybe<Scalars["String"]["output"]>;
  minimumBefore?: Maybe<Scalars["String"]["output"]>;
  payAtCheckout?: Maybe<Scalars["Boolean"]["output"]>;
  reservationStatuses?: Maybe<Array<ReservationStatus>>;
  service: ServiceIdentifier;
  serviceConfigHash: Scalars["Int"]["output"];
  task?: Maybe<TaskConfig>;
  type: ServiceConfigType;
  unitGroups?: Maybe<Array<UnitGroupIdentifier>>;
  visibilities?: Maybe<Array<Visibility>>;
  whitelistedDateRanges?: Maybe<Array<DateRange>>;
};

export enum BookableDay {
  Friday = "FRIDAY",
  Monday = "MONDAY",
  Saturday = "SATURDAY",
  Sunday = "SUNDAY",
  Thursday = "THURSDAY",
  Tuesday = "TUESDAY",
  Wednesday = "WEDNESDAY"
}

export enum ChargeMode {
  EndOfReservation = "END_OF_RESERVATION",
  Once = "ONCE",
  PerPerson = "PER_PERSON",
  PerPersonPerTimeUnit = "PER_PERSON_PER_TIME_UNIT",
  StartOfReservation = "START_OF_RESERVATION",
  TimeUnit = "TIME_UNIT"
}

export enum ChargeUnit {
  Adult = "ADULT",
  Booking = "BOOKING",
  Child = "CHILD",
  Person = "PERSON",
  Reservation = "RESERVATION"
}

export enum ConflictType {
  DuplicatedConfigForUnitGroup = "DUPLICATED_CONFIG_FOR_UNIT_GROUP",
  DuplicatedDefaultServiceId = "DUPLICATED_DEFAULT_SERVICE_ID",
  DuplicatedDependencyGroupKey = "DUPLICATED_DEPENDENCY_GROUP_KEY",
  InvalidFreeCleaningServiceId = "INVALID_FREE_CLEANING_SERVICE_ID",
  MissingConfigurationProperties = "MISSING_CONFIGURATION_PROPERTIES",
  PmsServiceNotFound = "PMS_SERVICE_NOT_FOUND",
  PmsUnitGroupNotEligibleForProperty = "PMS_UNIT_GROUP_NOT_ELIGIBLE_FOR_PROPERTY",
  PmsUnitGroupNotFound = "PMS_UNIT_GROUP_NOT_FOUND"
}

export type DateRange = {
  __typename?: "DateRange";
  endInclusive: Scalars["Date"]["output"];
  startInclusive: Scalars["Date"]["output"];
};

export type DateRangeInput = {
  endInclusive: Scalars["Date"]["input"];
  startInclusive: Scalars["Date"]["input"];
};

export enum DefaultDatePickerSelection {
  All = "ALL",
  None = "NONE"
}

export type DefaultServiceConfig = {
  __typename?: "DefaultServiceConfig";
  conflicts?: Maybe<Array<ServiceConfigConflict>>;
  id: Scalars["String"]["output"];
  service: ServiceIdentifier;
  unitGroups?: Maybe<Array<UnitGroupIdentifier>>;
};

export type DefaultServiceConfigInput = {
  service: ServiceIdentifierInput;
  unitGroups?: InputMaybe<Array<UnitGroupIdentifierInput>>;
};

export enum DefaultTargetAudience {
  Adult = "ADULT",
  All = "ALL",
  Child = "CHILD",
  Five = "FIVE",
  Four = "FOUR",
  One = "ONE",
  Three = "THREE",
  Two = "TWO"
}

export enum DistributionRule {
  ArrivalWeekDay = "ARRIVAL_WEEK_DAY",
  EvenlySpread = "EVENLY_SPREAD",
  ExactInterval = "EXACT_INTERVAL"
}

export enum DueDurationType {
  Arrival = "ARRIVAL",
  Midnight = "MIDNIGHT",
  Now = "NOW"
}

export type EarlyCheckinServiceConfig = BaseServiceConfig &
  ServiceConfig & {
    __typename?: "EarlyCheckinServiceConfig";
    blacklistedDateRanges?: Maybe<Array<DateRange>>;
    bookableDays?: Maybe<Array<BookableDay>>;
    bookingChannels?: Maybe<Array<Scalars["String"]["output"]>>;
    conflicts?: Maybe<Array<ServiceConfigConflict>>;
    id: Scalars["String"]["output"];
    maximum?: Maybe<Scalars["Int"]["output"]>;
    maximumBefore?: Maybe<Scalars["String"]["output"]>;
    minimumBefore?: Maybe<Scalars["String"]["output"]>;
    minimumCheckinTime?: Maybe<Scalars["LocalTime"]["output"]>;
    payAtCheckout?: Maybe<Scalars["Boolean"]["output"]>;
    reservationStatuses?: Maybe<Array<ReservationStatus>>;
    service: ServiceIdentifier;
    serviceConfigHash: Scalars["Int"]["output"];
    task?: Maybe<TaskConfig>;
    type: ServiceConfigType;
    unitGroups?: Maybe<Array<UnitGroupIdentifier>>;
    visibilities?: Maybe<Array<Visibility>>;
    whitelistedDateRanges?: Maybe<Array<DateRange>>;
  };

export type FreeCleaningServiceConfig = ServiceConfig & {
  __typename?: "FreeCleaningServiceConfig";
  conflicts?: Maybe<Array<ServiceConfigConflict>>;
  distributionRule?: Maybe<DistributionRule>;
  id: Scalars["String"]["output"];
  interval?: Maybe<Scalars["BigDecimal"]["output"]>;
  minimumNights?: Maybe<Scalars["Int"]["output"]>;
  service: ServiceIdentifier;
  serviceConfigHash: Scalars["Int"]["output"];
  task?: Maybe<TaskConfig>;
  type: ServiceConfigType;
  unitGroups?: Maybe<Array<UnitGroupIdentifier>>;
  weekendRule?: Maybe<WeekendRule>;
};

export type LateCheckoutServiceConfig = BaseServiceConfig &
  ServiceConfig & {
    __typename?: "LateCheckoutServiceConfig";
    blacklistedDateRanges?: Maybe<Array<DateRange>>;
    bookableDays?: Maybe<Array<BookableDay>>;
    bookingChannels?: Maybe<Array<Scalars["String"]["output"]>>;
    conflicts?: Maybe<Array<ServiceConfigConflict>>;
    id: Scalars["String"]["output"];
    maximum?: Maybe<Scalars["Int"]["output"]>;
    maximumBefore?: Maybe<Scalars["String"]["output"]>;
    maximumCheckoutTime?: Maybe<Scalars["LocalTime"]["output"]>;
    minimumBefore?: Maybe<Scalars["String"]["output"]>;
    payAtCheckout?: Maybe<Scalars["Boolean"]["output"]>;
    reservationStatuses?: Maybe<Array<ReservationStatus>>;
    service: ServiceIdentifier;
    serviceConfigHash: Scalars["Int"]["output"];
    task?: Maybe<TaskConfig>;
    type: ServiceConfigType;
    unitGroups?: Maybe<Array<UnitGroupIdentifier>>;
    visibilities?: Maybe<Array<Visibility>>;
    whitelistedDateRanges?: Maybe<Array<DateRange>>;
  };

export type Mutation = {
  __typename?: "Mutation";
  MergeServiceConfiguration: ServiceConfigurationContainer;
  ValidateServiceConfiguration: ServiceConfigurationContainer;
};

export type MutationMergeServiceConfigurationArgs = {
  pmsPropertyId: Scalars["String"]["input"];
  serviceConfiguration: ServiceConfigurationInputContainer;
};

export type MutationValidateServiceConfigurationArgs = {
  pmsPropertyId: Scalars["String"]["input"];
  serviceConfiguration: ServiceConfigurationInputContainer;
};

export type Price = {
  __typename?: "Price";
  currency?: Maybe<Scalars["String"]["output"]>;
  grossPrice?: Maybe<Scalars["BigDecimal"]["output"]>;
  netPrice?: Maybe<Scalars["BigDecimal"]["output"]>;
  taxes?: Maybe<Array<Tax>>;
};

export enum Priority {
  High = "HIGH",
  Low = "LOW",
  Medium = "MEDIUM",
  Urgent = "URGENT"
}

export type Query = {
  __typename?: "Query";
  GetServiceConfiguration: ServiceConfigurationContainer;
};

export type QueryGetServiceConfigurationArgs = {
  pmsPropertyId: Scalars["String"]["input"];
};

export enum ReservationStatus {
  CheckedOut = "CHECKED_OUT",
  Confirmed = "CONFIRMED",
  InHouse = "IN_HOUSE"
}

export type ServiceConfig = {
  conflicts?: Maybe<Array<ServiceConfigConflict>>;
  id: Scalars["String"]["output"];
  service: ServiceIdentifier;
  serviceConfigHash: Scalars["Int"]["output"];
  task?: Maybe<TaskConfig>;
  type: ServiceConfigType;
  unitGroups?: Maybe<Array<UnitGroupIdentifier>>;
};

export type ServiceConfigConflict = {
  __typename?: "ServiceConfigConflict";
  relatedFieldName?: Maybe<Scalars["String"]["output"]>;
  relatedServiceId?: Maybe<Scalars["String"]["output"]>;
  relatedUnitGroupId?: Maybe<Scalars["String"]["output"]>;
  type: ConflictType;
};

export enum ServiceConfigGranularity {
  AnyDay = "ANY_DAY",
  AnyDayExceptArrivalDay = "ANY_DAY_EXCEPT_ARRIVAL_DAY",
  ArrivalDay = "ARRIVAL_DAY",
  DepartureDay = "DEPARTURE_DAY",
  Never = "NEVER",
  WholeStay = "WHOLE_STAY",
  WholeStayPayOnce = "WHOLE_STAY_PAY_ONCE"
}

export type ServiceConfigInput = {
  /**  add on */
  addOnType?: InputMaybe<AddOnType>;
  availableAfterMidnight?: InputMaybe<Scalars["String"]["input"]>;
  blacklistedDateRanges?: InputMaybe<Array<DateRangeInput>>;
  bookOnExtendStay?: InputMaybe<Scalars["Boolean"]["input"]>;
  bookableDays?: InputMaybe<Array<BookableDay>>;
  bookableOnDepartureDay?: InputMaybe<Scalars["Boolean"]["input"]>;
  bookingChannels?: InputMaybe<Array<Scalars["String"]["input"]>>;
  canBookMultiple?: InputMaybe<Scalars["Boolean"]["input"]>;
  defaultDatePickerSelection?: InputMaybe<DefaultDatePickerSelection>;
  defaultTargetAudience?: InputMaybe<DefaultTargetAudience>;
  distributionRule?: InputMaybe<DistributionRule>;
  /**  additional cleaning & add on */
  granularity?: InputMaybe<ServiceConfigGranularity>;
  interval?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  maximum?: InputMaybe<Scalars["Int"]["input"]>;
  maximumBefore?: InputMaybe<Scalars["String"]["input"]>;
  /**  late checkout */
  maximumCheckoutTime?: InputMaybe<Scalars["LocalTime"]["input"]>;
  /**  base */
  minimumBefore?: InputMaybe<Scalars["String"]["input"]>;
  /**  early checkin */
  minimumCheckinTime?: InputMaybe<Scalars["LocalTime"]["input"]>;
  /**  free cleaning */
  minimumNights?: InputMaybe<Scalars["Int"]["input"]>;
  payAtCheckout?: InputMaybe<Scalars["Boolean"]["input"]>;
  postOnNextDay?: InputMaybe<Scalars["Boolean"]["input"]>;
  reservationStatuses?: InputMaybe<Array<ReservationStatus>>;
  service: ServiceIdentifierInput;
  tags?: InputMaybe<Array<ServiceTag>>;
  task?: InputMaybe<TaskConfigInput>;
  type: ServiceConfigType;
  unitGroups?: InputMaybe<Array<UnitGroupIdentifierInput>>;
  visibilities?: InputMaybe<Array<Visibility>>;
  weekendRule?: InputMaybe<WeekendRule>;
  whitelistedDateRanges?: InputMaybe<Array<DateRangeInput>>;
};

/**  enums */
export enum ServiceConfigType {
  AdditionalCleaning = "ADDITIONAL_CLEANING",
  AddOn = "ADD_ON",
  EarlyCheckin = "EARLY_CHECKIN",
  FreeCleaning = "FREE_CLEANING",
  LateCheckout = "LATE_CHECKOUT"
}

/**  interfaces and types */
export type ServiceConfigurationContainer = {
  __typename?: "ServiceConfigurationContainer";
  conflicts?: Maybe<Array<ServiceConfigConflict>>;
  defaultServices?: Maybe<Array<DefaultServiceConfig>>;
  dependencyGroups?: Maybe<Array<ServiceDependencyGroup>>;
  fixedFreeCleaningServiceId?: Maybe<Scalars["String"]["output"]>;
  pmsServices?: Maybe<Array<ServiceContract>>;
  pmsUnitGroups?: Maybe<Array<UnitGroupContract>>;
  serviceConfigs?: Maybe<Array<ServiceConfig>>;
  supportsDefaultServices: Scalars["Boolean"]["output"];
};

/**  input */
export type ServiceConfigurationInputContainer = {
  defaultServices?: InputMaybe<Array<DefaultServiceConfigInput>>;
  dependencyGroups?: InputMaybe<Array<ServiceDependencyGroupInput>>;
  serviceConfigs?: InputMaybe<Array<InputMaybe<ServiceConfigInput>>>;
};

export type ServiceContract = {
  __typename?: "ServiceContract";
  active?: Maybe<Scalars["Boolean"]["output"]>;
  chargeMode?: Maybe<ChargeMode>;
  chargeUnit?: Maybe<ChargeUnit>;
  description?: Maybe<Scalars["String"]["output"]>;
  displayName?: Maybe<Scalars["String"]["output"]>;
  id?: Maybe<Scalars["ID"]["output"]>;
  included?: Maybe<Scalars["Boolean"]["output"]>;
  name?: Maybe<Scalars["String"]["output"]>;
  pmsCategoryId?: Maybe<Scalars["String"]["output"]>;
  pmsId: Scalars["String"]["output"];
  pmsUpdatedAt?: Maybe<Scalars["Instant"]["output"]>;
  price?: Maybe<Price>;
};

export type ServiceDependencyGroup = {
  __typename?: "ServiceDependencyGroup";
  conflicts?: Maybe<Array<ServiceConfigConflict>>;
  id: Scalars["String"]["output"];
  key: Scalars["String"]["output"];
  services?: Maybe<Array<ServiceIdentifier>>;
};

export type ServiceDependencyGroupInput = {
  key: Scalars["String"]["input"];
  services?: InputMaybe<Array<ServiceIdentifierInput>>;
};

export type ServiceIdentifier = {
  __typename?: "ServiceIdentifier";
  details?: Maybe<ServiceContract>;
  serviceId: Scalars["String"]["output"];
};

export type ServiceIdentifierInput = {
  serviceId: Scalars["String"]["input"];
};

export enum ServiceTag {
  Included = "INCLUDED",
  Key = "KEY",
  Mandatory = "MANDATORY",
  NotOpened = "NOT_OPENED",
  Opened = "OPENED",
  Purchased = "PURCHASED"
}

export type Subscription = {
  __typename?: "Subscription";
  StreamServiceConfiguration: ServiceConfigurationContainer;
};

export type SubscriptionStreamServiceConfigurationArgs = {
  pmsPropertyId: Scalars["String"]["input"];
};

export type TaskConfig = {
  __typename?: "TaskConfig";
  assigneeGroup?: Maybe<Scalars["String"]["output"]>;
  createMultipleTasks?: Maybe<Scalars["Boolean"]["output"]>;
  dueDuration?: Maybe<Scalars["String"]["output"]>;
  dueDurationType?: Maybe<DueDurationType>;
  granularity?: Maybe<TaskGranularity>;
  housekeepingMainTask?: Maybe<Scalars["Boolean"]["output"]>;
  priority?: Maybe<Priority>;
};

export type TaskConfigInput = {
  assigneeGroup?: InputMaybe<Scalars["String"]["input"]>;
  createMultipleTasks?: InputMaybe<Scalars["Boolean"]["input"]>;
  dueDuration?: InputMaybe<Scalars["String"]["input"]>;
  dueDurationType?: InputMaybe<DueDurationType>;
  granularity?: InputMaybe<TaskGranularity>;
  housekeepingMainTask?: InputMaybe<Scalars["Boolean"]["input"]>;
  priority?: InputMaybe<Priority>;
};

export enum TaskGranularity {
  EveryDay = "EVERY_DAY",
  FirstDay = "FIRST_DAY"
}

export type Tax = {
  __typename?: "Tax";
  amount?: Maybe<Scalars["BigDecimal"]["output"]>;
  code?: Maybe<Scalars["String"]["output"]>;
};

export type UnitGroupContract = {
  __typename?: "UnitGroupContract";
  active?: Maybe<Scalars["Boolean"]["output"]>;
  capacity?: Maybe<Scalars["Int"]["output"]>;
  extraCapacity?: Maybe<Scalars["Int"]["output"]>;
  id?: Maybe<Scalars["ID"]["output"]>;
  name?: Maybe<Scalars["String"]["output"]>;
  pmsId: Scalars["String"]["output"];
  pmsPropertyId?: Maybe<Scalars["String"]["output"]>;
  pmsUpdatedAt?: Maybe<Scalars["Instant"]["output"]>;
  type?: Maybe<Scalars["String"]["output"]>;
};

export type UnitGroupIdentifier = {
  __typename?: "UnitGroupIdentifier";
  details?: Maybe<UnitGroupContract>;
  unitGroupId: Scalars["String"]["output"];
};

export type UnitGroupIdentifierInput = {
  unitGroupId: Scalars["String"]["input"];
};

export enum Visibility {
  Backoffice = "BACKOFFICE",
  GuestFlow = "GUEST_FLOW",
  MyStay = "MY_STAY"
}

export enum WeekendRule {
  Closest = "CLOSEST",
  ExactDay = "EXACT_DAY",
  Friday = "FRIDAY",
  Monday = "MONDAY"
}

export type DateRangeFragment = {
  __typename?: "DateRange";
  startInclusive: any;
  endInclusive: any;
};

export type DefaultServiceConfigFragment = {
  __typename?: "DefaultServiceConfig";
  id: string;
  service: {
    __typename?: "ServiceIdentifier";
    serviceId: string;
    details?: {
      __typename?: "ServiceContract";
      active?: boolean | null;
      chargeMode?: ChargeMode | null;
      chargeUnit?: ChargeUnit | null;
      description?: string | null;
      displayName?: string | null;
      id?: string | null;
      included?: boolean | null;
      name?: string | null;
      pmsCategoryId?: string | null;
      pmsId: string;
      pmsUpdatedAt?: any | null;
      price?: {
        __typename?: "Price";
        currency?: string | null;
        grossPrice?: any | null;
        netPrice?: any | null;
        taxes?: Array<{ __typename?: "Tax"; amount?: any | null; code?: string | null }> | null;
      } | null;
    } | null;
  };
  unitGroups?: Array<{
    __typename?: "UnitGroupIdentifier";
    unitGroupId: string;
    details?: {
      __typename?: "UnitGroupContract";
      active?: boolean | null;
      capacity?: number | null;
      extraCapacity?: number | null;
      id?: string | null;
      name?: string | null;
      pmsId: string;
      pmsPropertyId?: string | null;
      pmsUpdatedAt?: any | null;
      type?: string | null;
    } | null;
  }> | null;
  conflicts?: Array<{
    __typename?: "ServiceConfigConflict";
    type: ConflictType;
    relatedServiceId?: string | null;
    relatedUnitGroupId?: string | null;
    relatedFieldName?: string | null;
  }> | null;
};

type ServiceConfig_AddOnServiceConfig_Fragment = {
  __typename?: "AddOnServiceConfig";
  minimumBefore?: string | null;
  maximumBefore?: string | null;
  maximum?: number | null;
  bookableDays?: Array<BookableDay> | null;
  bookingChannels?: Array<string> | null;
  reservationStatuses?: Array<ReservationStatus> | null;
  visibilities?: Array<Visibility> | null;
  payAtCheckout?: boolean | null;
  addOnType: AddOnType;
  canBookMultiple?: boolean | null;
  bookableOnDepartureDay?: boolean | null;
  bookOnExtendStay?: boolean | null;
  availableAfterMidnight?: string | null;
  granularity?: ServiceConfigGranularity | null;
  defaultTargetAudience?: DefaultTargetAudience | null;
  tags?: Array<ServiceTag> | null;
  defaultDatePickerSelection?: DefaultDatePickerSelection | null;
  postOnNextDay?: boolean | null;
  id: string;
  serviceConfigHash: number;
  type: ServiceConfigType;
  whitelistedDateRanges?: Array<{
    __typename?: "DateRange";
    startInclusive: any;
    endInclusive: any;
  }> | null;
  blacklistedDateRanges?: Array<{
    __typename?: "DateRange";
    startInclusive: any;
    endInclusive: any;
  }> | null;
  service: {
    __typename?: "ServiceIdentifier";
    serviceId: string;
    details?: {
      __typename?: "ServiceContract";
      active?: boolean | null;
      chargeMode?: ChargeMode | null;
      chargeUnit?: ChargeUnit | null;
      description?: string | null;
      displayName?: string | null;
      id?: string | null;
      included?: boolean | null;
      name?: string | null;
      pmsCategoryId?: string | null;
      pmsId: string;
      pmsUpdatedAt?: any | null;
      price?: {
        __typename?: "Price";
        currency?: string | null;
        grossPrice?: any | null;
        netPrice?: any | null;
        taxes?: Array<{ __typename?: "Tax"; amount?: any | null; code?: string | null }> | null;
      } | null;
    } | null;
  };
  unitGroups?: Array<{
    __typename?: "UnitGroupIdentifier";
    unitGroupId: string;
    details?: {
      __typename?: "UnitGroupContract";
      active?: boolean | null;
      capacity?: number | null;
      extraCapacity?: number | null;
      id?: string | null;
      name?: string | null;
      pmsId: string;
      pmsPropertyId?: string | null;
      pmsUpdatedAt?: any | null;
      type?: string | null;
    } | null;
  }> | null;
  task?: {
    __typename?: "TaskConfig";
    housekeepingMainTask?: boolean | null;
    assigneeGroup?: string | null;
    createMultipleTasks?: boolean | null;
    dueDuration?: string | null;
    dueDurationType?: DueDurationType | null;
    granularity?: TaskGranularity | null;
    priority?: Priority | null;
  } | null;
  conflicts?: Array<{
    __typename?: "ServiceConfigConflict";
    type: ConflictType;
    relatedServiceId?: string | null;
    relatedUnitGroupId?: string | null;
    relatedFieldName?: string | null;
  }> | null;
};

type ServiceConfig_AdditionalCleaningServiceConfig_Fragment = {
  __typename?: "AdditionalCleaningServiceConfig";
  minimumBefore?: string | null;
  maximumBefore?: string | null;
  maximum?: number | null;
  bookableDays?: Array<BookableDay> | null;
  bookingChannels?: Array<string> | null;
  reservationStatuses?: Array<ReservationStatus> | null;
  visibilities?: Array<Visibility> | null;
  payAtCheckout?: boolean | null;
  granularity?: ServiceConfigGranularity | null;
  defaultDatePickerSelection?: DefaultDatePickerSelection | null;
  id: string;
  serviceConfigHash: number;
  type: ServiceConfigType;
  whitelistedDateRanges?: Array<{
    __typename?: "DateRange";
    startInclusive: any;
    endInclusive: any;
  }> | null;
  blacklistedDateRanges?: Array<{
    __typename?: "DateRange";
    startInclusive: any;
    endInclusive: any;
  }> | null;
  service: {
    __typename?: "ServiceIdentifier";
    serviceId: string;
    details?: {
      __typename?: "ServiceContract";
      active?: boolean | null;
      chargeMode?: ChargeMode | null;
      chargeUnit?: ChargeUnit | null;
      description?: string | null;
      displayName?: string | null;
      id?: string | null;
      included?: boolean | null;
      name?: string | null;
      pmsCategoryId?: string | null;
      pmsId: string;
      pmsUpdatedAt?: any | null;
      price?: {
        __typename?: "Price";
        currency?: string | null;
        grossPrice?: any | null;
        netPrice?: any | null;
        taxes?: Array<{ __typename?: "Tax"; amount?: any | null; code?: string | null }> | null;
      } | null;
    } | null;
  };
  unitGroups?: Array<{
    __typename?: "UnitGroupIdentifier";
    unitGroupId: string;
    details?: {
      __typename?: "UnitGroupContract";
      active?: boolean | null;
      capacity?: number | null;
      extraCapacity?: number | null;
      id?: string | null;
      name?: string | null;
      pmsId: string;
      pmsPropertyId?: string | null;
      pmsUpdatedAt?: any | null;
      type?: string | null;
    } | null;
  }> | null;
  task?: {
    __typename?: "TaskConfig";
    housekeepingMainTask?: boolean | null;
    assigneeGroup?: string | null;
    createMultipleTasks?: boolean | null;
    dueDuration?: string | null;
    dueDurationType?: DueDurationType | null;
    granularity?: TaskGranularity | null;
    priority?: Priority | null;
  } | null;
  conflicts?: Array<{
    __typename?: "ServiceConfigConflict";
    type: ConflictType;
    relatedServiceId?: string | null;
    relatedUnitGroupId?: string | null;
    relatedFieldName?: string | null;
  }> | null;
};

type ServiceConfig_EarlyCheckinServiceConfig_Fragment = {
  __typename?: "EarlyCheckinServiceConfig";
  minimumBefore?: string | null;
  maximumBefore?: string | null;
  maximum?: number | null;
  bookableDays?: Array<BookableDay> | null;
  bookingChannels?: Array<string> | null;
  reservationStatuses?: Array<ReservationStatus> | null;
  visibilities?: Array<Visibility> | null;
  payAtCheckout?: boolean | null;
  minimumCheckinTime?: any | null;
  id: string;
  serviceConfigHash: number;
  type: ServiceConfigType;
  whitelistedDateRanges?: Array<{
    __typename?: "DateRange";
    startInclusive: any;
    endInclusive: any;
  }> | null;
  blacklistedDateRanges?: Array<{
    __typename?: "DateRange";
    startInclusive: any;
    endInclusive: any;
  }> | null;
  service: {
    __typename?: "ServiceIdentifier";
    serviceId: string;
    details?: {
      __typename?: "ServiceContract";
      active?: boolean | null;
      chargeMode?: ChargeMode | null;
      chargeUnit?: ChargeUnit | null;
      description?: string | null;
      displayName?: string | null;
      id?: string | null;
      included?: boolean | null;
      name?: string | null;
      pmsCategoryId?: string | null;
      pmsId: string;
      pmsUpdatedAt?: any | null;
      price?: {
        __typename?: "Price";
        currency?: string | null;
        grossPrice?: any | null;
        netPrice?: any | null;
        taxes?: Array<{ __typename?: "Tax"; amount?: any | null; code?: string | null }> | null;
      } | null;
    } | null;
  };
  unitGroups?: Array<{
    __typename?: "UnitGroupIdentifier";
    unitGroupId: string;
    details?: {
      __typename?: "UnitGroupContract";
      active?: boolean | null;
      capacity?: number | null;
      extraCapacity?: number | null;
      id?: string | null;
      name?: string | null;
      pmsId: string;
      pmsPropertyId?: string | null;
      pmsUpdatedAt?: any | null;
      type?: string | null;
    } | null;
  }> | null;
  task?: {
    __typename?: "TaskConfig";
    housekeepingMainTask?: boolean | null;
    assigneeGroup?: string | null;
    createMultipleTasks?: boolean | null;
    dueDuration?: string | null;
    dueDurationType?: DueDurationType | null;
    granularity?: TaskGranularity | null;
    priority?: Priority | null;
  } | null;
  conflicts?: Array<{
    __typename?: "ServiceConfigConflict";
    type: ConflictType;
    relatedServiceId?: string | null;
    relatedUnitGroupId?: string | null;
    relatedFieldName?: string | null;
  }> | null;
};

type ServiceConfig_FreeCleaningServiceConfig_Fragment = {
  __typename?: "FreeCleaningServiceConfig";
  minimumNights?: number | null;
  interval?: any | null;
  weekendRule?: WeekendRule | null;
  distributionRule?: DistributionRule | null;
  id: string;
  serviceConfigHash: number;
  type: ServiceConfigType;
  service: {
    __typename?: "ServiceIdentifier";
    serviceId: string;
    details?: {
      __typename?: "ServiceContract";
      active?: boolean | null;
      chargeMode?: ChargeMode | null;
      chargeUnit?: ChargeUnit | null;
      description?: string | null;
      displayName?: string | null;
      id?: string | null;
      included?: boolean | null;
      name?: string | null;
      pmsCategoryId?: string | null;
      pmsId: string;
      pmsUpdatedAt?: any | null;
      price?: {
        __typename?: "Price";
        currency?: string | null;
        grossPrice?: any | null;
        netPrice?: any | null;
        taxes?: Array<{ __typename?: "Tax"; amount?: any | null; code?: string | null }> | null;
      } | null;
    } | null;
  };
  unitGroups?: Array<{
    __typename?: "UnitGroupIdentifier";
    unitGroupId: string;
    details?: {
      __typename?: "UnitGroupContract";
      active?: boolean | null;
      capacity?: number | null;
      extraCapacity?: number | null;
      id?: string | null;
      name?: string | null;
      pmsId: string;
      pmsPropertyId?: string | null;
      pmsUpdatedAt?: any | null;
      type?: string | null;
    } | null;
  }> | null;
  task?: {
    __typename?: "TaskConfig";
    housekeepingMainTask?: boolean | null;
    assigneeGroup?: string | null;
    createMultipleTasks?: boolean | null;
    dueDuration?: string | null;
    dueDurationType?: DueDurationType | null;
    granularity?: TaskGranularity | null;
    priority?: Priority | null;
  } | null;
  conflicts?: Array<{
    __typename?: "ServiceConfigConflict";
    type: ConflictType;
    relatedServiceId?: string | null;
    relatedUnitGroupId?: string | null;
    relatedFieldName?: string | null;
  }> | null;
};

type ServiceConfig_LateCheckoutServiceConfig_Fragment = {
  __typename?: "LateCheckoutServiceConfig";
  minimumBefore?: string | null;
  maximumBefore?: string | null;
  maximum?: number | null;
  bookableDays?: Array<BookableDay> | null;
  bookingChannels?: Array<string> | null;
  reservationStatuses?: Array<ReservationStatus> | null;
  visibilities?: Array<Visibility> | null;
  payAtCheckout?: boolean | null;
  maximumCheckoutTime?: any | null;
  id: string;
  serviceConfigHash: number;
  type: ServiceConfigType;
  whitelistedDateRanges?: Array<{
    __typename?: "DateRange";
    startInclusive: any;
    endInclusive: any;
  }> | null;
  blacklistedDateRanges?: Array<{
    __typename?: "DateRange";
    startInclusive: any;
    endInclusive: any;
  }> | null;
  service: {
    __typename?: "ServiceIdentifier";
    serviceId: string;
    details?: {
      __typename?: "ServiceContract";
      active?: boolean | null;
      chargeMode?: ChargeMode | null;
      chargeUnit?: ChargeUnit | null;
      description?: string | null;
      displayName?: string | null;
      id?: string | null;
      included?: boolean | null;
      name?: string | null;
      pmsCategoryId?: string | null;
      pmsId: string;
      pmsUpdatedAt?: any | null;
      price?: {
        __typename?: "Price";
        currency?: string | null;
        grossPrice?: any | null;
        netPrice?: any | null;
        taxes?: Array<{ __typename?: "Tax"; amount?: any | null; code?: string | null }> | null;
      } | null;
    } | null;
  };
  unitGroups?: Array<{
    __typename?: "UnitGroupIdentifier";
    unitGroupId: string;
    details?: {
      __typename?: "UnitGroupContract";
      active?: boolean | null;
      capacity?: number | null;
      extraCapacity?: number | null;
      id?: string | null;
      name?: string | null;
      pmsId: string;
      pmsPropertyId?: string | null;
      pmsUpdatedAt?: any | null;
      type?: string | null;
    } | null;
  }> | null;
  task?: {
    __typename?: "TaskConfig";
    housekeepingMainTask?: boolean | null;
    assigneeGroup?: string | null;
    createMultipleTasks?: boolean | null;
    dueDuration?: string | null;
    dueDurationType?: DueDurationType | null;
    granularity?: TaskGranularity | null;
    priority?: Priority | null;
  } | null;
  conflicts?: Array<{
    __typename?: "ServiceConfigConflict";
    type: ConflictType;
    relatedServiceId?: string | null;
    relatedUnitGroupId?: string | null;
    relatedFieldName?: string | null;
  }> | null;
};

export type ServiceConfigFragment =
  | ServiceConfig_AddOnServiceConfig_Fragment
  | ServiceConfig_AdditionalCleaningServiceConfig_Fragment
  | ServiceConfig_EarlyCheckinServiceConfig_Fragment
  | ServiceConfig_FreeCleaningServiceConfig_Fragment
  | ServiceConfig_LateCheckoutServiceConfig_Fragment;

export type ServiceConfigConflictFragment = {
  __typename?: "ServiceConfigConflict";
  type: ConflictType;
  relatedServiceId?: string | null;
  relatedUnitGroupId?: string | null;
  relatedFieldName?: string | null;
};

export type ServiceContractFragment = {
  __typename?: "ServiceContract";
  active?: boolean | null;
  chargeMode?: ChargeMode | null;
  chargeUnit?: ChargeUnit | null;
  description?: string | null;
  displayName?: string | null;
  id?: string | null;
  included?: boolean | null;
  name?: string | null;
  pmsCategoryId?: string | null;
  pmsId: string;
  pmsUpdatedAt?: any | null;
  price?: {
    __typename?: "Price";
    currency?: string | null;
    grossPrice?: any | null;
    netPrice?: any | null;
    taxes?: Array<{ __typename?: "Tax"; amount?: any | null; code?: string | null }> | null;
  } | null;
};

export type ServiceDependencyGroupFragment = {
  __typename?: "ServiceDependencyGroup";
  id: string;
  key: string;
  services?: Array<{
    __typename?: "ServiceIdentifier";
    serviceId: string;
    details?: {
      __typename?: "ServiceContract";
      active?: boolean | null;
      chargeMode?: ChargeMode | null;
      chargeUnit?: ChargeUnit | null;
      description?: string | null;
      displayName?: string | null;
      id?: string | null;
      included?: boolean | null;
      name?: string | null;
      pmsCategoryId?: string | null;
      pmsId: string;
      pmsUpdatedAt?: any | null;
      price?: {
        __typename?: "Price";
        currency?: string | null;
        grossPrice?: any | null;
        netPrice?: any | null;
        taxes?: Array<{ __typename?: "Tax"; amount?: any | null; code?: string | null }> | null;
      } | null;
    } | null;
  }> | null;
  conflicts?: Array<{
    __typename?: "ServiceConfigConflict";
    type: ConflictType;
    relatedServiceId?: string | null;
    relatedUnitGroupId?: string | null;
    relatedFieldName?: string | null;
  }> | null;
};

export type ServiceIdentifierFragment = {
  __typename?: "ServiceIdentifier";
  serviceId: string;
  details?: {
    __typename?: "ServiceContract";
    active?: boolean | null;
    chargeMode?: ChargeMode | null;
    chargeUnit?: ChargeUnit | null;
    description?: string | null;
    displayName?: string | null;
    id?: string | null;
    included?: boolean | null;
    name?: string | null;
    pmsCategoryId?: string | null;
    pmsId: string;
    pmsUpdatedAt?: any | null;
    price?: {
      __typename?: "Price";
      currency?: string | null;
      grossPrice?: any | null;
      netPrice?: any | null;
      taxes?: Array<{ __typename?: "Tax"; amount?: any | null; code?: string | null }> | null;
    } | null;
  } | null;
};

export type TaskConfigFragment = {
  __typename?: "TaskConfig";
  housekeepingMainTask?: boolean | null;
  assigneeGroup?: string | null;
  createMultipleTasks?: boolean | null;
  dueDuration?: string | null;
  dueDurationType?: DueDurationType | null;
  granularity?: TaskGranularity | null;
  priority?: Priority | null;
};

export type UnitGroupContractFragment = {
  __typename?: "UnitGroupContract";
  active?: boolean | null;
  capacity?: number | null;
  extraCapacity?: number | null;
  id?: string | null;
  name?: string | null;
  pmsId: string;
  pmsPropertyId?: string | null;
  pmsUpdatedAt?: any | null;
  type?: string | null;
};

export type UnitGroupIdentifierFragment = {
  __typename?: "UnitGroupIdentifier";
  unitGroupId: string;
  details?: {
    __typename?: "UnitGroupContract";
    active?: boolean | null;
    capacity?: number | null;
    extraCapacity?: number | null;
    id?: string | null;
    name?: string | null;
    pmsId: string;
    pmsPropertyId?: string | null;
    pmsUpdatedAt?: any | null;
    type?: string | null;
  } | null;
};

export type MergeServiceConfigurationMutationVariables = Exact<{
  pmsPropertyId: Scalars["String"]["input"];
  serviceConfiguration: ServiceConfigurationInputContainer;
}>;

export type MergeServiceConfigurationMutation = {
  __typename?: "Mutation";
  MergeServiceConfiguration: {
    __typename?: "ServiceConfigurationContainer";
    supportsDefaultServices: boolean;
    fixedFreeCleaningServiceId?: string | null;
    serviceConfigs?: Array<
      | {
          __typename?: "AddOnServiceConfig";
          minimumBefore?: string | null;
          maximumBefore?: string | null;
          maximum?: number | null;
          bookableDays?: Array<BookableDay> | null;
          bookingChannels?: Array<string> | null;
          reservationStatuses?: Array<ReservationStatus> | null;
          visibilities?: Array<Visibility> | null;
          payAtCheckout?: boolean | null;
          addOnType: AddOnType;
          canBookMultiple?: boolean | null;
          bookableOnDepartureDay?: boolean | null;
          bookOnExtendStay?: boolean | null;
          availableAfterMidnight?: string | null;
          granularity?: ServiceConfigGranularity | null;
          defaultTargetAudience?: DefaultTargetAudience | null;
          tags?: Array<ServiceTag> | null;
          defaultDatePickerSelection?: DefaultDatePickerSelection | null;
          postOnNextDay?: boolean | null;
          id: string;
          serviceConfigHash: number;
          type: ServiceConfigType;
          whitelistedDateRanges?: Array<{
            __typename?: "DateRange";
            startInclusive: any;
            endInclusive: any;
          }> | null;
          blacklistedDateRanges?: Array<{
            __typename?: "DateRange";
            startInclusive: any;
            endInclusive: any;
          }> | null;
          service: {
            __typename?: "ServiceIdentifier";
            serviceId: string;
            details?: {
              __typename?: "ServiceContract";
              active?: boolean | null;
              chargeMode?: ChargeMode | null;
              chargeUnit?: ChargeUnit | null;
              description?: string | null;
              displayName?: string | null;
              id?: string | null;
              included?: boolean | null;
              name?: string | null;
              pmsCategoryId?: string | null;
              pmsId: string;
              pmsUpdatedAt?: any | null;
              price?: {
                __typename?: "Price";
                currency?: string | null;
                grossPrice?: any | null;
                netPrice?: any | null;
                taxes?: Array<{
                  __typename?: "Tax";
                  amount?: any | null;
                  code?: string | null;
                }> | null;
              } | null;
            } | null;
          };
          unitGroups?: Array<{
            __typename?: "UnitGroupIdentifier";
            unitGroupId: string;
            details?: {
              __typename?: "UnitGroupContract";
              active?: boolean | null;
              capacity?: number | null;
              extraCapacity?: number | null;
              id?: string | null;
              name?: string | null;
              pmsId: string;
              pmsPropertyId?: string | null;
              pmsUpdatedAt?: any | null;
              type?: string | null;
            } | null;
          }> | null;
          task?: {
            __typename?: "TaskConfig";
            housekeepingMainTask?: boolean | null;
            assigneeGroup?: string | null;
            createMultipleTasks?: boolean | null;
            dueDuration?: string | null;
            dueDurationType?: DueDurationType | null;
            granularity?: TaskGranularity | null;
            priority?: Priority | null;
          } | null;
          conflicts?: Array<{
            __typename?: "ServiceConfigConflict";
            type: ConflictType;
            relatedServiceId?: string | null;
            relatedUnitGroupId?: string | null;
            relatedFieldName?: string | null;
          }> | null;
        }
      | {
          __typename?: "AdditionalCleaningServiceConfig";
          minimumBefore?: string | null;
          maximumBefore?: string | null;
          maximum?: number | null;
          bookableDays?: Array<BookableDay> | null;
          bookingChannels?: Array<string> | null;
          reservationStatuses?: Array<ReservationStatus> | null;
          visibilities?: Array<Visibility> | null;
          payAtCheckout?: boolean | null;
          granularity?: ServiceConfigGranularity | null;
          defaultDatePickerSelection?: DefaultDatePickerSelection | null;
          id: string;
          serviceConfigHash: number;
          type: ServiceConfigType;
          whitelistedDateRanges?: Array<{
            __typename?: "DateRange";
            startInclusive: any;
            endInclusive: any;
          }> | null;
          blacklistedDateRanges?: Array<{
            __typename?: "DateRange";
            startInclusive: any;
            endInclusive: any;
          }> | null;
          service: {
            __typename?: "ServiceIdentifier";
            serviceId: string;
            details?: {
              __typename?: "ServiceContract";
              active?: boolean | null;
              chargeMode?: ChargeMode | null;
              chargeUnit?: ChargeUnit | null;
              description?: string | null;
              displayName?: string | null;
              id?: string | null;
              included?: boolean | null;
              name?: string | null;
              pmsCategoryId?: string | null;
              pmsId: string;
              pmsUpdatedAt?: any | null;
              price?: {
                __typename?: "Price";
                currency?: string | null;
                grossPrice?: any | null;
                netPrice?: any | null;
                taxes?: Array<{
                  __typename?: "Tax";
                  amount?: any | null;
                  code?: string | null;
                }> | null;
              } | null;
            } | null;
          };
          unitGroups?: Array<{
            __typename?: "UnitGroupIdentifier";
            unitGroupId: string;
            details?: {
              __typename?: "UnitGroupContract";
              active?: boolean | null;
              capacity?: number | null;
              extraCapacity?: number | null;
              id?: string | null;
              name?: string | null;
              pmsId: string;
              pmsPropertyId?: string | null;
              pmsUpdatedAt?: any | null;
              type?: string | null;
            } | null;
          }> | null;
          task?: {
            __typename?: "TaskConfig";
            housekeepingMainTask?: boolean | null;
            assigneeGroup?: string | null;
            createMultipleTasks?: boolean | null;
            dueDuration?: string | null;
            dueDurationType?: DueDurationType | null;
            granularity?: TaskGranularity | null;
            priority?: Priority | null;
          } | null;
          conflicts?: Array<{
            __typename?: "ServiceConfigConflict";
            type: ConflictType;
            relatedServiceId?: string | null;
            relatedUnitGroupId?: string | null;
            relatedFieldName?: string | null;
          }> | null;
        }
      | {
          __typename?: "EarlyCheckinServiceConfig";
          minimumBefore?: string | null;
          maximumBefore?: string | null;
          maximum?: number | null;
          bookableDays?: Array<BookableDay> | null;
          bookingChannels?: Array<string> | null;
          reservationStatuses?: Array<ReservationStatus> | null;
          visibilities?: Array<Visibility> | null;
          payAtCheckout?: boolean | null;
          minimumCheckinTime?: any | null;
          id: string;
          serviceConfigHash: number;
          type: ServiceConfigType;
          whitelistedDateRanges?: Array<{
            __typename?: "DateRange";
            startInclusive: any;
            endInclusive: any;
          }> | null;
          blacklistedDateRanges?: Array<{
            __typename?: "DateRange";
            startInclusive: any;
            endInclusive: any;
          }> | null;
          service: {
            __typename?: "ServiceIdentifier";
            serviceId: string;
            details?: {
              __typename?: "ServiceContract";
              active?: boolean | null;
              chargeMode?: ChargeMode | null;
              chargeUnit?: ChargeUnit | null;
              description?: string | null;
              displayName?: string | null;
              id?: string | null;
              included?: boolean | null;
              name?: string | null;
              pmsCategoryId?: string | null;
              pmsId: string;
              pmsUpdatedAt?: any | null;
              price?: {
                __typename?: "Price";
                currency?: string | null;
                grossPrice?: any | null;
                netPrice?: any | null;
                taxes?: Array<{
                  __typename?: "Tax";
                  amount?: any | null;
                  code?: string | null;
                }> | null;
              } | null;
            } | null;
          };
          unitGroups?: Array<{
            __typename?: "UnitGroupIdentifier";
            unitGroupId: string;
            details?: {
              __typename?: "UnitGroupContract";
              active?: boolean | null;
              capacity?: number | null;
              extraCapacity?: number | null;
              id?: string | null;
              name?: string | null;
              pmsId: string;
              pmsPropertyId?: string | null;
              pmsUpdatedAt?: any | null;
              type?: string | null;
            } | null;
          }> | null;
          task?: {
            __typename?: "TaskConfig";
            housekeepingMainTask?: boolean | null;
            assigneeGroup?: string | null;
            createMultipleTasks?: boolean | null;
            dueDuration?: string | null;
            dueDurationType?: DueDurationType | null;
            granularity?: TaskGranularity | null;
            priority?: Priority | null;
          } | null;
          conflicts?: Array<{
            __typename?: "ServiceConfigConflict";
            type: ConflictType;
            relatedServiceId?: string | null;
            relatedUnitGroupId?: string | null;
            relatedFieldName?: string | null;
          }> | null;
        }
      | {
          __typename?: "FreeCleaningServiceConfig";
          minimumNights?: number | null;
          interval?: any | null;
          weekendRule?: WeekendRule | null;
          distributionRule?: DistributionRule | null;
          id: string;
          serviceConfigHash: number;
          type: ServiceConfigType;
          service: {
            __typename?: "ServiceIdentifier";
            serviceId: string;
            details?: {
              __typename?: "ServiceContract";
              active?: boolean | null;
              chargeMode?: ChargeMode | null;
              chargeUnit?: ChargeUnit | null;
              description?: string | null;
              displayName?: string | null;
              id?: string | null;
              included?: boolean | null;
              name?: string | null;
              pmsCategoryId?: string | null;
              pmsId: string;
              pmsUpdatedAt?: any | null;
              price?: {
                __typename?: "Price";
                currency?: string | null;
                grossPrice?: any | null;
                netPrice?: any | null;
                taxes?: Array<{
                  __typename?: "Tax";
                  amount?: any | null;
                  code?: string | null;
                }> | null;
              } | null;
            } | null;
          };
          unitGroups?: Array<{
            __typename?: "UnitGroupIdentifier";
            unitGroupId: string;
            details?: {
              __typename?: "UnitGroupContract";
              active?: boolean | null;
              capacity?: number | null;
              extraCapacity?: number | null;
              id?: string | null;
              name?: string | null;
              pmsId: string;
              pmsPropertyId?: string | null;
              pmsUpdatedAt?: any | null;
              type?: string | null;
            } | null;
          }> | null;
          task?: {
            __typename?: "TaskConfig";
            housekeepingMainTask?: boolean | null;
            assigneeGroup?: string | null;
            createMultipleTasks?: boolean | null;
            dueDuration?: string | null;
            dueDurationType?: DueDurationType | null;
            granularity?: TaskGranularity | null;
            priority?: Priority | null;
          } | null;
          conflicts?: Array<{
            __typename?: "ServiceConfigConflict";
            type: ConflictType;
            relatedServiceId?: string | null;
            relatedUnitGroupId?: string | null;
            relatedFieldName?: string | null;
          }> | null;
        }
      | {
          __typename?: "LateCheckoutServiceConfig";
          minimumBefore?: string | null;
          maximumBefore?: string | null;
          maximum?: number | null;
          bookableDays?: Array<BookableDay> | null;
          bookingChannels?: Array<string> | null;
          reservationStatuses?: Array<ReservationStatus> | null;
          visibilities?: Array<Visibility> | null;
          payAtCheckout?: boolean | null;
          maximumCheckoutTime?: any | null;
          id: string;
          serviceConfigHash: number;
          type: ServiceConfigType;
          whitelistedDateRanges?: Array<{
            __typename?: "DateRange";
            startInclusive: any;
            endInclusive: any;
          }> | null;
          blacklistedDateRanges?: Array<{
            __typename?: "DateRange";
            startInclusive: any;
            endInclusive: any;
          }> | null;
          service: {
            __typename?: "ServiceIdentifier";
            serviceId: string;
            details?: {
              __typename?: "ServiceContract";
              active?: boolean | null;
              chargeMode?: ChargeMode | null;
              chargeUnit?: ChargeUnit | null;
              description?: string | null;
              displayName?: string | null;
              id?: string | null;
              included?: boolean | null;
              name?: string | null;
              pmsCategoryId?: string | null;
              pmsId: string;
              pmsUpdatedAt?: any | null;
              price?: {
                __typename?: "Price";
                currency?: string | null;
                grossPrice?: any | null;
                netPrice?: any | null;
                taxes?: Array<{
                  __typename?: "Tax";
                  amount?: any | null;
                  code?: string | null;
                }> | null;
              } | null;
            } | null;
          };
          unitGroups?: Array<{
            __typename?: "UnitGroupIdentifier";
            unitGroupId: string;
            details?: {
              __typename?: "UnitGroupContract";
              active?: boolean | null;
              capacity?: number | null;
              extraCapacity?: number | null;
              id?: string | null;
              name?: string | null;
              pmsId: string;
              pmsPropertyId?: string | null;
              pmsUpdatedAt?: any | null;
              type?: string | null;
            } | null;
          }> | null;
          task?: {
            __typename?: "TaskConfig";
            housekeepingMainTask?: boolean | null;
            assigneeGroup?: string | null;
            createMultipleTasks?: boolean | null;
            dueDuration?: string | null;
            dueDurationType?: DueDurationType | null;
            granularity?: TaskGranularity | null;
            priority?: Priority | null;
          } | null;
          conflicts?: Array<{
            __typename?: "ServiceConfigConflict";
            type: ConflictType;
            relatedServiceId?: string | null;
            relatedUnitGroupId?: string | null;
            relatedFieldName?: string | null;
          }> | null;
        }
    > | null;
    defaultServices?: Array<{
      __typename?: "DefaultServiceConfig";
      id: string;
      service: {
        __typename?: "ServiceIdentifier";
        serviceId: string;
        details?: {
          __typename?: "ServiceContract";
          active?: boolean | null;
          chargeMode?: ChargeMode | null;
          chargeUnit?: ChargeUnit | null;
          description?: string | null;
          displayName?: string | null;
          id?: string | null;
          included?: boolean | null;
          name?: string | null;
          pmsCategoryId?: string | null;
          pmsId: string;
          pmsUpdatedAt?: any | null;
          price?: {
            __typename?: "Price";
            currency?: string | null;
            grossPrice?: any | null;
            netPrice?: any | null;
            taxes?: Array<{ __typename?: "Tax"; amount?: any | null; code?: string | null }> | null;
          } | null;
        } | null;
      };
      unitGroups?: Array<{
        __typename?: "UnitGroupIdentifier";
        unitGroupId: string;
        details?: {
          __typename?: "UnitGroupContract";
          active?: boolean | null;
          capacity?: number | null;
          extraCapacity?: number | null;
          id?: string | null;
          name?: string | null;
          pmsId: string;
          pmsPropertyId?: string | null;
          pmsUpdatedAt?: any | null;
          type?: string | null;
        } | null;
      }> | null;
      conflicts?: Array<{
        __typename?: "ServiceConfigConflict";
        type: ConflictType;
        relatedServiceId?: string | null;
        relatedUnitGroupId?: string | null;
        relatedFieldName?: string | null;
      }> | null;
    }> | null;
    dependencyGroups?: Array<{
      __typename?: "ServiceDependencyGroup";
      id: string;
      key: string;
      services?: Array<{
        __typename?: "ServiceIdentifier";
        serviceId: string;
        details?: {
          __typename?: "ServiceContract";
          active?: boolean | null;
          chargeMode?: ChargeMode | null;
          chargeUnit?: ChargeUnit | null;
          description?: string | null;
          displayName?: string | null;
          id?: string | null;
          included?: boolean | null;
          name?: string | null;
          pmsCategoryId?: string | null;
          pmsId: string;
          pmsUpdatedAt?: any | null;
          price?: {
            __typename?: "Price";
            currency?: string | null;
            grossPrice?: any | null;
            netPrice?: any | null;
            taxes?: Array<{ __typename?: "Tax"; amount?: any | null; code?: string | null }> | null;
          } | null;
        } | null;
      }> | null;
      conflicts?: Array<{
        __typename?: "ServiceConfigConflict";
        type: ConflictType;
        relatedServiceId?: string | null;
        relatedUnitGroupId?: string | null;
        relatedFieldName?: string | null;
      }> | null;
    }> | null;
    conflicts?: Array<{
      __typename?: "ServiceConfigConflict";
      type: ConflictType;
      relatedServiceId?: string | null;
      relatedUnitGroupId?: string | null;
      relatedFieldName?: string | null;
    }> | null;
    pmsServices?: Array<{
      __typename?: "ServiceContract";
      active?: boolean | null;
      chargeMode?: ChargeMode | null;
      chargeUnit?: ChargeUnit | null;
      description?: string | null;
      displayName?: string | null;
      id?: string | null;
      included?: boolean | null;
      name?: string | null;
      pmsCategoryId?: string | null;
      pmsId: string;
      pmsUpdatedAt?: any | null;
      price?: {
        __typename?: "Price";
        currency?: string | null;
        grossPrice?: any | null;
        netPrice?: any | null;
        taxes?: Array<{ __typename?: "Tax"; amount?: any | null; code?: string | null }> | null;
      } | null;
    }> | null;
    pmsUnitGroups?: Array<{
      __typename?: "UnitGroupContract";
      active?: boolean | null;
      capacity?: number | null;
      extraCapacity?: number | null;
      id?: string | null;
      name?: string | null;
      pmsId: string;
      pmsPropertyId?: string | null;
      pmsUpdatedAt?: any | null;
      type?: string | null;
    }> | null;
  };
};

export type ValidateServiceConfigurationMutationVariables = Exact<{
  pmsPropertyId: Scalars["String"]["input"];
  serviceConfiguration: ServiceConfigurationInputContainer;
}>;

export type ValidateServiceConfigurationMutation = {
  __typename?: "Mutation";
  ValidateServiceConfiguration: {
    __typename?: "ServiceConfigurationContainer";
    supportsDefaultServices: boolean;
    fixedFreeCleaningServiceId?: string | null;
    serviceConfigs?: Array<
      | {
          __typename?: "AddOnServiceConfig";
          minimumBefore?: string | null;
          maximumBefore?: string | null;
          maximum?: number | null;
          bookableDays?: Array<BookableDay> | null;
          bookingChannels?: Array<string> | null;
          reservationStatuses?: Array<ReservationStatus> | null;
          visibilities?: Array<Visibility> | null;
          payAtCheckout?: boolean | null;
          addOnType: AddOnType;
          canBookMultiple?: boolean | null;
          bookableOnDepartureDay?: boolean | null;
          bookOnExtendStay?: boolean | null;
          availableAfterMidnight?: string | null;
          granularity?: ServiceConfigGranularity | null;
          defaultTargetAudience?: DefaultTargetAudience | null;
          tags?: Array<ServiceTag> | null;
          defaultDatePickerSelection?: DefaultDatePickerSelection | null;
          postOnNextDay?: boolean | null;
          id: string;
          serviceConfigHash: number;
          type: ServiceConfigType;
          whitelistedDateRanges?: Array<{
            __typename?: "DateRange";
            startInclusive: any;
            endInclusive: any;
          }> | null;
          blacklistedDateRanges?: Array<{
            __typename?: "DateRange";
            startInclusive: any;
            endInclusive: any;
          }> | null;
          service: {
            __typename?: "ServiceIdentifier";
            serviceId: string;
            details?: {
              __typename?: "ServiceContract";
              active?: boolean | null;
              chargeMode?: ChargeMode | null;
              chargeUnit?: ChargeUnit | null;
              description?: string | null;
              displayName?: string | null;
              id?: string | null;
              included?: boolean | null;
              name?: string | null;
              pmsCategoryId?: string | null;
              pmsId: string;
              pmsUpdatedAt?: any | null;
              price?: {
                __typename?: "Price";
                currency?: string | null;
                grossPrice?: any | null;
                netPrice?: any | null;
                taxes?: Array<{
                  __typename?: "Tax";
                  amount?: any | null;
                  code?: string | null;
                }> | null;
              } | null;
            } | null;
          };
          unitGroups?: Array<{
            __typename?: "UnitGroupIdentifier";
            unitGroupId: string;
            details?: {
              __typename?: "UnitGroupContract";
              active?: boolean | null;
              capacity?: number | null;
              extraCapacity?: number | null;
              id?: string | null;
              name?: string | null;
              pmsId: string;
              pmsPropertyId?: string | null;
              pmsUpdatedAt?: any | null;
              type?: string | null;
            } | null;
          }> | null;
          task?: {
            __typename?: "TaskConfig";
            housekeepingMainTask?: boolean | null;
            assigneeGroup?: string | null;
            createMultipleTasks?: boolean | null;
            dueDuration?: string | null;
            dueDurationType?: DueDurationType | null;
            granularity?: TaskGranularity | null;
            priority?: Priority | null;
          } | null;
          conflicts?: Array<{
            __typename?: "ServiceConfigConflict";
            type: ConflictType;
            relatedServiceId?: string | null;
            relatedUnitGroupId?: string | null;
            relatedFieldName?: string | null;
          }> | null;
        }
      | {
          __typename?: "AdditionalCleaningServiceConfig";
          minimumBefore?: string | null;
          maximumBefore?: string | null;
          maximum?: number | null;
          bookableDays?: Array<BookableDay> | null;
          bookingChannels?: Array<string> | null;
          reservationStatuses?: Array<ReservationStatus> | null;
          visibilities?: Array<Visibility> | null;
          payAtCheckout?: boolean | null;
          granularity?: ServiceConfigGranularity | null;
          defaultDatePickerSelection?: DefaultDatePickerSelection | null;
          id: string;
          serviceConfigHash: number;
          type: ServiceConfigType;
          whitelistedDateRanges?: Array<{
            __typename?: "DateRange";
            startInclusive: any;
            endInclusive: any;
          }> | null;
          blacklistedDateRanges?: Array<{
            __typename?: "DateRange";
            startInclusive: any;
            endInclusive: any;
          }> | null;
          service: {
            __typename?: "ServiceIdentifier";
            serviceId: string;
            details?: {
              __typename?: "ServiceContract";
              active?: boolean | null;
              chargeMode?: ChargeMode | null;
              chargeUnit?: ChargeUnit | null;
              description?: string | null;
              displayName?: string | null;
              id?: string | null;
              included?: boolean | null;
              name?: string | null;
              pmsCategoryId?: string | null;
              pmsId: string;
              pmsUpdatedAt?: any | null;
              price?: {
                __typename?: "Price";
                currency?: string | null;
                grossPrice?: any | null;
                netPrice?: any | null;
                taxes?: Array<{
                  __typename?: "Tax";
                  amount?: any | null;
                  code?: string | null;
                }> | null;
              } | null;
            } | null;
          };
          unitGroups?: Array<{
            __typename?: "UnitGroupIdentifier";
            unitGroupId: string;
            details?: {
              __typename?: "UnitGroupContract";
              active?: boolean | null;
              capacity?: number | null;
              extraCapacity?: number | null;
              id?: string | null;
              name?: string | null;
              pmsId: string;
              pmsPropertyId?: string | null;
              pmsUpdatedAt?: any | null;
              type?: string | null;
            } | null;
          }> | null;
          task?: {
            __typename?: "TaskConfig";
            housekeepingMainTask?: boolean | null;
            assigneeGroup?: string | null;
            createMultipleTasks?: boolean | null;
            dueDuration?: string | null;
            dueDurationType?: DueDurationType | null;
            granularity?: TaskGranularity | null;
            priority?: Priority | null;
          } | null;
          conflicts?: Array<{
            __typename?: "ServiceConfigConflict";
            type: ConflictType;
            relatedServiceId?: string | null;
            relatedUnitGroupId?: string | null;
            relatedFieldName?: string | null;
          }> | null;
        }
      | {
          __typename?: "EarlyCheckinServiceConfig";
          minimumBefore?: string | null;
          maximumBefore?: string | null;
          maximum?: number | null;
          bookableDays?: Array<BookableDay> | null;
          bookingChannels?: Array<string> | null;
          reservationStatuses?: Array<ReservationStatus> | null;
          visibilities?: Array<Visibility> | null;
          payAtCheckout?: boolean | null;
          minimumCheckinTime?: any | null;
          id: string;
          serviceConfigHash: number;
          type: ServiceConfigType;
          whitelistedDateRanges?: Array<{
            __typename?: "DateRange";
            startInclusive: any;
            endInclusive: any;
          }> | null;
          blacklistedDateRanges?: Array<{
            __typename?: "DateRange";
            startInclusive: any;
            endInclusive: any;
          }> | null;
          service: {
            __typename?: "ServiceIdentifier";
            serviceId: string;
            details?: {
              __typename?: "ServiceContract";
              active?: boolean | null;
              chargeMode?: ChargeMode | null;
              chargeUnit?: ChargeUnit | null;
              description?: string | null;
              displayName?: string | null;
              id?: string | null;
              included?: boolean | null;
              name?: string | null;
              pmsCategoryId?: string | null;
              pmsId: string;
              pmsUpdatedAt?: any | null;
              price?: {
                __typename?: "Price";
                currency?: string | null;
                grossPrice?: any | null;
                netPrice?: any | null;
                taxes?: Array<{
                  __typename?: "Tax";
                  amount?: any | null;
                  code?: string | null;
                }> | null;
              } | null;
            } | null;
          };
          unitGroups?: Array<{
            __typename?: "UnitGroupIdentifier";
            unitGroupId: string;
            details?: {
              __typename?: "UnitGroupContract";
              active?: boolean | null;
              capacity?: number | null;
              extraCapacity?: number | null;
              id?: string | null;
              name?: string | null;
              pmsId: string;
              pmsPropertyId?: string | null;
              pmsUpdatedAt?: any | null;
              type?: string | null;
            } | null;
          }> | null;
          task?: {
            __typename?: "TaskConfig";
            housekeepingMainTask?: boolean | null;
            assigneeGroup?: string | null;
            createMultipleTasks?: boolean | null;
            dueDuration?: string | null;
            dueDurationType?: DueDurationType | null;
            granularity?: TaskGranularity | null;
            priority?: Priority | null;
          } | null;
          conflicts?: Array<{
            __typename?: "ServiceConfigConflict";
            type: ConflictType;
            relatedServiceId?: string | null;
            relatedUnitGroupId?: string | null;
            relatedFieldName?: string | null;
          }> | null;
        }
      | {
          __typename?: "FreeCleaningServiceConfig";
          minimumNights?: number | null;
          interval?: any | null;
          weekendRule?: WeekendRule | null;
          distributionRule?: DistributionRule | null;
          id: string;
          serviceConfigHash: number;
          type: ServiceConfigType;
          service: {
            __typename?: "ServiceIdentifier";
            serviceId: string;
            details?: {
              __typename?: "ServiceContract";
              active?: boolean | null;
              chargeMode?: ChargeMode | null;
              chargeUnit?: ChargeUnit | null;
              description?: string | null;
              displayName?: string | null;
              id?: string | null;
              included?: boolean | null;
              name?: string | null;
              pmsCategoryId?: string | null;
              pmsId: string;
              pmsUpdatedAt?: any | null;
              price?: {
                __typename?: "Price";
                currency?: string | null;
                grossPrice?: any | null;
                netPrice?: any | null;
                taxes?: Array<{
                  __typename?: "Tax";
                  amount?: any | null;
                  code?: string | null;
                }> | null;
              } | null;
            } | null;
          };
          unitGroups?: Array<{
            __typename?: "UnitGroupIdentifier";
            unitGroupId: string;
            details?: {
              __typename?: "UnitGroupContract";
              active?: boolean | null;
              capacity?: number | null;
              extraCapacity?: number | null;
              id?: string | null;
              name?: string | null;
              pmsId: string;
              pmsPropertyId?: string | null;
              pmsUpdatedAt?: any | null;
              type?: string | null;
            } | null;
          }> | null;
          task?: {
            __typename?: "TaskConfig";
            housekeepingMainTask?: boolean | null;
            assigneeGroup?: string | null;
            createMultipleTasks?: boolean | null;
            dueDuration?: string | null;
            dueDurationType?: DueDurationType | null;
            granularity?: TaskGranularity | null;
            priority?: Priority | null;
          } | null;
          conflicts?: Array<{
            __typename?: "ServiceConfigConflict";
            type: ConflictType;
            relatedServiceId?: string | null;
            relatedUnitGroupId?: string | null;
            relatedFieldName?: string | null;
          }> | null;
        }
      | {
          __typename?: "LateCheckoutServiceConfig";
          minimumBefore?: string | null;
          maximumBefore?: string | null;
          maximum?: number | null;
          bookableDays?: Array<BookableDay> | null;
          bookingChannels?: Array<string> | null;
          reservationStatuses?: Array<ReservationStatus> | null;
          visibilities?: Array<Visibility> | null;
          payAtCheckout?: boolean | null;
          maximumCheckoutTime?: any | null;
          id: string;
          serviceConfigHash: number;
          type: ServiceConfigType;
          whitelistedDateRanges?: Array<{
            __typename?: "DateRange";
            startInclusive: any;
            endInclusive: any;
          }> | null;
          blacklistedDateRanges?: Array<{
            __typename?: "DateRange";
            startInclusive: any;
            endInclusive: any;
          }> | null;
          service: {
            __typename?: "ServiceIdentifier";
            serviceId: string;
            details?: {
              __typename?: "ServiceContract";
              active?: boolean | null;
              chargeMode?: ChargeMode | null;
              chargeUnit?: ChargeUnit | null;
              description?: string | null;
              displayName?: string | null;
              id?: string | null;
              included?: boolean | null;
              name?: string | null;
              pmsCategoryId?: string | null;
              pmsId: string;
              pmsUpdatedAt?: any | null;
              price?: {
                __typename?: "Price";
                currency?: string | null;
                grossPrice?: any | null;
                netPrice?: any | null;
                taxes?: Array<{
                  __typename?: "Tax";
                  amount?: any | null;
                  code?: string | null;
                }> | null;
              } | null;
            } | null;
          };
          unitGroups?: Array<{
            __typename?: "UnitGroupIdentifier";
            unitGroupId: string;
            details?: {
              __typename?: "UnitGroupContract";
              active?: boolean | null;
              capacity?: number | null;
              extraCapacity?: number | null;
              id?: string | null;
              name?: string | null;
              pmsId: string;
              pmsPropertyId?: string | null;
              pmsUpdatedAt?: any | null;
              type?: string | null;
            } | null;
          }> | null;
          task?: {
            __typename?: "TaskConfig";
            housekeepingMainTask?: boolean | null;
            assigneeGroup?: string | null;
            createMultipleTasks?: boolean | null;
            dueDuration?: string | null;
            dueDurationType?: DueDurationType | null;
            granularity?: TaskGranularity | null;
            priority?: Priority | null;
          } | null;
          conflicts?: Array<{
            __typename?: "ServiceConfigConflict";
            type: ConflictType;
            relatedServiceId?: string | null;
            relatedUnitGroupId?: string | null;
            relatedFieldName?: string | null;
          }> | null;
        }
    > | null;
    defaultServices?: Array<{
      __typename?: "DefaultServiceConfig";
      id: string;
      service: {
        __typename?: "ServiceIdentifier";
        serviceId: string;
        details?: {
          __typename?: "ServiceContract";
          active?: boolean | null;
          chargeMode?: ChargeMode | null;
          chargeUnit?: ChargeUnit | null;
          description?: string | null;
          displayName?: string | null;
          id?: string | null;
          included?: boolean | null;
          name?: string | null;
          pmsCategoryId?: string | null;
          pmsId: string;
          pmsUpdatedAt?: any | null;
          price?: {
            __typename?: "Price";
            currency?: string | null;
            grossPrice?: any | null;
            netPrice?: any | null;
            taxes?: Array<{ __typename?: "Tax"; amount?: any | null; code?: string | null }> | null;
          } | null;
        } | null;
      };
      unitGroups?: Array<{
        __typename?: "UnitGroupIdentifier";
        unitGroupId: string;
        details?: {
          __typename?: "UnitGroupContract";
          active?: boolean | null;
          capacity?: number | null;
          extraCapacity?: number | null;
          id?: string | null;
          name?: string | null;
          pmsId: string;
          pmsPropertyId?: string | null;
          pmsUpdatedAt?: any | null;
          type?: string | null;
        } | null;
      }> | null;
      conflicts?: Array<{
        __typename?: "ServiceConfigConflict";
        type: ConflictType;
        relatedServiceId?: string | null;
        relatedUnitGroupId?: string | null;
        relatedFieldName?: string | null;
      }> | null;
    }> | null;
    dependencyGroups?: Array<{
      __typename?: "ServiceDependencyGroup";
      id: string;
      key: string;
      services?: Array<{
        __typename?: "ServiceIdentifier";
        serviceId: string;
        details?: {
          __typename?: "ServiceContract";
          active?: boolean | null;
          chargeMode?: ChargeMode | null;
          chargeUnit?: ChargeUnit | null;
          description?: string | null;
          displayName?: string | null;
          id?: string | null;
          included?: boolean | null;
          name?: string | null;
          pmsCategoryId?: string | null;
          pmsId: string;
          pmsUpdatedAt?: any | null;
          price?: {
            __typename?: "Price";
            currency?: string | null;
            grossPrice?: any | null;
            netPrice?: any | null;
            taxes?: Array<{ __typename?: "Tax"; amount?: any | null; code?: string | null }> | null;
          } | null;
        } | null;
      }> | null;
      conflicts?: Array<{
        __typename?: "ServiceConfigConflict";
        type: ConflictType;
        relatedServiceId?: string | null;
        relatedUnitGroupId?: string | null;
        relatedFieldName?: string | null;
      }> | null;
    }> | null;
    conflicts?: Array<{
      __typename?: "ServiceConfigConflict";
      type: ConflictType;
      relatedServiceId?: string | null;
      relatedUnitGroupId?: string | null;
      relatedFieldName?: string | null;
    }> | null;
    pmsServices?: Array<{
      __typename?: "ServiceContract";
      active?: boolean | null;
      chargeMode?: ChargeMode | null;
      chargeUnit?: ChargeUnit | null;
      description?: string | null;
      displayName?: string | null;
      id?: string | null;
      included?: boolean | null;
      name?: string | null;
      pmsCategoryId?: string | null;
      pmsId: string;
      pmsUpdatedAt?: any | null;
      price?: {
        __typename?: "Price";
        currency?: string | null;
        grossPrice?: any | null;
        netPrice?: any | null;
        taxes?: Array<{ __typename?: "Tax"; amount?: any | null; code?: string | null }> | null;
      } | null;
    }> | null;
    pmsUnitGroups?: Array<{
      __typename?: "UnitGroupContract";
      active?: boolean | null;
      capacity?: number | null;
      extraCapacity?: number | null;
      id?: string | null;
      name?: string | null;
      pmsId: string;
      pmsPropertyId?: string | null;
      pmsUpdatedAt?: any | null;
      type?: string | null;
    }> | null;
  };
};

export type GetServiceConfigurationQueryVariables = Exact<{
  pmsPropertyId: Scalars["String"]["input"];
}>;

export type GetServiceConfigurationQuery = {
  __typename?: "Query";
  GetServiceConfiguration: {
    __typename?: "ServiceConfigurationContainer";
    supportsDefaultServices: boolean;
    fixedFreeCleaningServiceId?: string | null;
    serviceConfigs?: Array<
      | {
          __typename?: "AddOnServiceConfig";
          minimumBefore?: string | null;
          maximumBefore?: string | null;
          maximum?: number | null;
          bookableDays?: Array<BookableDay> | null;
          bookingChannels?: Array<string> | null;
          reservationStatuses?: Array<ReservationStatus> | null;
          visibilities?: Array<Visibility> | null;
          payAtCheckout?: boolean | null;
          addOnType: AddOnType;
          canBookMultiple?: boolean | null;
          bookableOnDepartureDay?: boolean | null;
          bookOnExtendStay?: boolean | null;
          availableAfterMidnight?: string | null;
          granularity?: ServiceConfigGranularity | null;
          defaultTargetAudience?: DefaultTargetAudience | null;
          tags?: Array<ServiceTag> | null;
          defaultDatePickerSelection?: DefaultDatePickerSelection | null;
          postOnNextDay?: boolean | null;
          id: string;
          serviceConfigHash: number;
          type: ServiceConfigType;
          whitelistedDateRanges?: Array<{
            __typename?: "DateRange";
            startInclusive: any;
            endInclusive: any;
          }> | null;
          blacklistedDateRanges?: Array<{
            __typename?: "DateRange";
            startInclusive: any;
            endInclusive: any;
          }> | null;
          service: {
            __typename?: "ServiceIdentifier";
            serviceId: string;
            details?: {
              __typename?: "ServiceContract";
              active?: boolean | null;
              chargeMode?: ChargeMode | null;
              chargeUnit?: ChargeUnit | null;
              description?: string | null;
              displayName?: string | null;
              id?: string | null;
              included?: boolean | null;
              name?: string | null;
              pmsCategoryId?: string | null;
              pmsId: string;
              pmsUpdatedAt?: any | null;
              price?: {
                __typename?: "Price";
                currency?: string | null;
                grossPrice?: any | null;
                netPrice?: any | null;
                taxes?: Array<{
                  __typename?: "Tax";
                  amount?: any | null;
                  code?: string | null;
                }> | null;
              } | null;
            } | null;
          };
          unitGroups?: Array<{
            __typename?: "UnitGroupIdentifier";
            unitGroupId: string;
            details?: {
              __typename?: "UnitGroupContract";
              active?: boolean | null;
              capacity?: number | null;
              extraCapacity?: number | null;
              id?: string | null;
              name?: string | null;
              pmsId: string;
              pmsPropertyId?: string | null;
              pmsUpdatedAt?: any | null;
              type?: string | null;
            } | null;
          }> | null;
          task?: {
            __typename?: "TaskConfig";
            housekeepingMainTask?: boolean | null;
            assigneeGroup?: string | null;
            createMultipleTasks?: boolean | null;
            dueDuration?: string | null;
            dueDurationType?: DueDurationType | null;
            granularity?: TaskGranularity | null;
            priority?: Priority | null;
          } | null;
          conflicts?: Array<{
            __typename?: "ServiceConfigConflict";
            type: ConflictType;
            relatedServiceId?: string | null;
            relatedUnitGroupId?: string | null;
            relatedFieldName?: string | null;
          }> | null;
        }
      | {
          __typename?: "AdditionalCleaningServiceConfig";
          minimumBefore?: string | null;
          maximumBefore?: string | null;
          maximum?: number | null;
          bookableDays?: Array<BookableDay> | null;
          bookingChannels?: Array<string> | null;
          reservationStatuses?: Array<ReservationStatus> | null;
          visibilities?: Array<Visibility> | null;
          payAtCheckout?: boolean | null;
          granularity?: ServiceConfigGranularity | null;
          defaultDatePickerSelection?: DefaultDatePickerSelection | null;
          id: string;
          serviceConfigHash: number;
          type: ServiceConfigType;
          whitelistedDateRanges?: Array<{
            __typename?: "DateRange";
            startInclusive: any;
            endInclusive: any;
          }> | null;
          blacklistedDateRanges?: Array<{
            __typename?: "DateRange";
            startInclusive: any;
            endInclusive: any;
          }> | null;
          service: {
            __typename?: "ServiceIdentifier";
            serviceId: string;
            details?: {
              __typename?: "ServiceContract";
              active?: boolean | null;
              chargeMode?: ChargeMode | null;
              chargeUnit?: ChargeUnit | null;
              description?: string | null;
              displayName?: string | null;
              id?: string | null;
              included?: boolean | null;
              name?: string | null;
              pmsCategoryId?: string | null;
              pmsId: string;
              pmsUpdatedAt?: any | null;
              price?: {
                __typename?: "Price";
                currency?: string | null;
                grossPrice?: any | null;
                netPrice?: any | null;
                taxes?: Array<{
                  __typename?: "Tax";
                  amount?: any | null;
                  code?: string | null;
                }> | null;
              } | null;
            } | null;
          };
          unitGroups?: Array<{
            __typename?: "UnitGroupIdentifier";
            unitGroupId: string;
            details?: {
              __typename?: "UnitGroupContract";
              active?: boolean | null;
              capacity?: number | null;
              extraCapacity?: number | null;
              id?: string | null;
              name?: string | null;
              pmsId: string;
              pmsPropertyId?: string | null;
              pmsUpdatedAt?: any | null;
              type?: string | null;
            } | null;
          }> | null;
          task?: {
            __typename?: "TaskConfig";
            housekeepingMainTask?: boolean | null;
            assigneeGroup?: string | null;
            createMultipleTasks?: boolean | null;
            dueDuration?: string | null;
            dueDurationType?: DueDurationType | null;
            granularity?: TaskGranularity | null;
            priority?: Priority | null;
          } | null;
          conflicts?: Array<{
            __typename?: "ServiceConfigConflict";
            type: ConflictType;
            relatedServiceId?: string | null;
            relatedUnitGroupId?: string | null;
            relatedFieldName?: string | null;
          }> | null;
        }
      | {
          __typename?: "EarlyCheckinServiceConfig";
          minimumBefore?: string | null;
          maximumBefore?: string | null;
          maximum?: number | null;
          bookableDays?: Array<BookableDay> | null;
          bookingChannels?: Array<string> | null;
          reservationStatuses?: Array<ReservationStatus> | null;
          visibilities?: Array<Visibility> | null;
          payAtCheckout?: boolean | null;
          minimumCheckinTime?: any | null;
          id: string;
          serviceConfigHash: number;
          type: ServiceConfigType;
          whitelistedDateRanges?: Array<{
            __typename?: "DateRange";
            startInclusive: any;
            endInclusive: any;
          }> | null;
          blacklistedDateRanges?: Array<{
            __typename?: "DateRange";
            startInclusive: any;
            endInclusive: any;
          }> | null;
          service: {
            __typename?: "ServiceIdentifier";
            serviceId: string;
            details?: {
              __typename?: "ServiceContract";
              active?: boolean | null;
              chargeMode?: ChargeMode | null;
              chargeUnit?: ChargeUnit | null;
              description?: string | null;
              displayName?: string | null;
              id?: string | null;
              included?: boolean | null;
              name?: string | null;
              pmsCategoryId?: string | null;
              pmsId: string;
              pmsUpdatedAt?: any | null;
              price?: {
                __typename?: "Price";
                currency?: string | null;
                grossPrice?: any | null;
                netPrice?: any | null;
                taxes?: Array<{
                  __typename?: "Tax";
                  amount?: any | null;
                  code?: string | null;
                }> | null;
              } | null;
            } | null;
          };
          unitGroups?: Array<{
            __typename?: "UnitGroupIdentifier";
            unitGroupId: string;
            details?: {
              __typename?: "UnitGroupContract";
              active?: boolean | null;
              capacity?: number | null;
              extraCapacity?: number | null;
              id?: string | null;
              name?: string | null;
              pmsId: string;
              pmsPropertyId?: string | null;
              pmsUpdatedAt?: any | null;
              type?: string | null;
            } | null;
          }> | null;
          task?: {
            __typename?: "TaskConfig";
            housekeepingMainTask?: boolean | null;
            assigneeGroup?: string | null;
            createMultipleTasks?: boolean | null;
            dueDuration?: string | null;
            dueDurationType?: DueDurationType | null;
            granularity?: TaskGranularity | null;
            priority?: Priority | null;
          } | null;
          conflicts?: Array<{
            __typename?: "ServiceConfigConflict";
            type: ConflictType;
            relatedServiceId?: string | null;
            relatedUnitGroupId?: string | null;
            relatedFieldName?: string | null;
          }> | null;
        }
      | {
          __typename?: "FreeCleaningServiceConfig";
          minimumNights?: number | null;
          interval?: any | null;
          weekendRule?: WeekendRule | null;
          distributionRule?: DistributionRule | null;
          id: string;
          serviceConfigHash: number;
          type: ServiceConfigType;
          service: {
            __typename?: "ServiceIdentifier";
            serviceId: string;
            details?: {
              __typename?: "ServiceContract";
              active?: boolean | null;
              chargeMode?: ChargeMode | null;
              chargeUnit?: ChargeUnit | null;
              description?: string | null;
              displayName?: string | null;
              id?: string | null;
              included?: boolean | null;
              name?: string | null;
              pmsCategoryId?: string | null;
              pmsId: string;
              pmsUpdatedAt?: any | null;
              price?: {
                __typename?: "Price";
                currency?: string | null;
                grossPrice?: any | null;
                netPrice?: any | null;
                taxes?: Array<{
                  __typename?: "Tax";
                  amount?: any | null;
                  code?: string | null;
                }> | null;
              } | null;
            } | null;
          };
          unitGroups?: Array<{
            __typename?: "UnitGroupIdentifier";
            unitGroupId: string;
            details?: {
              __typename?: "UnitGroupContract";
              active?: boolean | null;
              capacity?: number | null;
              extraCapacity?: number | null;
              id?: string | null;
              name?: string | null;
              pmsId: string;
              pmsPropertyId?: string | null;
              pmsUpdatedAt?: any | null;
              type?: string | null;
            } | null;
          }> | null;
          task?: {
            __typename?: "TaskConfig";
            housekeepingMainTask?: boolean | null;
            assigneeGroup?: string | null;
            createMultipleTasks?: boolean | null;
            dueDuration?: string | null;
            dueDurationType?: DueDurationType | null;
            granularity?: TaskGranularity | null;
            priority?: Priority | null;
          } | null;
          conflicts?: Array<{
            __typename?: "ServiceConfigConflict";
            type: ConflictType;
            relatedServiceId?: string | null;
            relatedUnitGroupId?: string | null;
            relatedFieldName?: string | null;
          }> | null;
        }
      | {
          __typename?: "LateCheckoutServiceConfig";
          minimumBefore?: string | null;
          maximumBefore?: string | null;
          maximum?: number | null;
          bookableDays?: Array<BookableDay> | null;
          bookingChannels?: Array<string> | null;
          reservationStatuses?: Array<ReservationStatus> | null;
          visibilities?: Array<Visibility> | null;
          payAtCheckout?: boolean | null;
          maximumCheckoutTime?: any | null;
          id: string;
          serviceConfigHash: number;
          type: ServiceConfigType;
          whitelistedDateRanges?: Array<{
            __typename?: "DateRange";
            startInclusive: any;
            endInclusive: any;
          }> | null;
          blacklistedDateRanges?: Array<{
            __typename?: "DateRange";
            startInclusive: any;
            endInclusive: any;
          }> | null;
          service: {
            __typename?: "ServiceIdentifier";
            serviceId: string;
            details?: {
              __typename?: "ServiceContract";
              active?: boolean | null;
              chargeMode?: ChargeMode | null;
              chargeUnit?: ChargeUnit | null;
              description?: string | null;
              displayName?: string | null;
              id?: string | null;
              included?: boolean | null;
              name?: string | null;
              pmsCategoryId?: string | null;
              pmsId: string;
              pmsUpdatedAt?: any | null;
              price?: {
                __typename?: "Price";
                currency?: string | null;
                grossPrice?: any | null;
                netPrice?: any | null;
                taxes?: Array<{
                  __typename?: "Tax";
                  amount?: any | null;
                  code?: string | null;
                }> | null;
              } | null;
            } | null;
          };
          unitGroups?: Array<{
            __typename?: "UnitGroupIdentifier";
            unitGroupId: string;
            details?: {
              __typename?: "UnitGroupContract";
              active?: boolean | null;
              capacity?: number | null;
              extraCapacity?: number | null;
              id?: string | null;
              name?: string | null;
              pmsId: string;
              pmsPropertyId?: string | null;
              pmsUpdatedAt?: any | null;
              type?: string | null;
            } | null;
          }> | null;
          task?: {
            __typename?: "TaskConfig";
            housekeepingMainTask?: boolean | null;
            assigneeGroup?: string | null;
            createMultipleTasks?: boolean | null;
            dueDuration?: string | null;
            dueDurationType?: DueDurationType | null;
            granularity?: TaskGranularity | null;
            priority?: Priority | null;
          } | null;
          conflicts?: Array<{
            __typename?: "ServiceConfigConflict";
            type: ConflictType;
            relatedServiceId?: string | null;
            relatedUnitGroupId?: string | null;
            relatedFieldName?: string | null;
          }> | null;
        }
    > | null;
    defaultServices?: Array<{
      __typename?: "DefaultServiceConfig";
      id: string;
      service: {
        __typename?: "ServiceIdentifier";
        serviceId: string;
        details?: {
          __typename?: "ServiceContract";
          active?: boolean | null;
          chargeMode?: ChargeMode | null;
          chargeUnit?: ChargeUnit | null;
          description?: string | null;
          displayName?: string | null;
          id?: string | null;
          included?: boolean | null;
          name?: string | null;
          pmsCategoryId?: string | null;
          pmsId: string;
          pmsUpdatedAt?: any | null;
          price?: {
            __typename?: "Price";
            currency?: string | null;
            grossPrice?: any | null;
            netPrice?: any | null;
            taxes?: Array<{ __typename?: "Tax"; amount?: any | null; code?: string | null }> | null;
          } | null;
        } | null;
      };
      unitGroups?: Array<{
        __typename?: "UnitGroupIdentifier";
        unitGroupId: string;
        details?: {
          __typename?: "UnitGroupContract";
          active?: boolean | null;
          capacity?: number | null;
          extraCapacity?: number | null;
          id?: string | null;
          name?: string | null;
          pmsId: string;
          pmsPropertyId?: string | null;
          pmsUpdatedAt?: any | null;
          type?: string | null;
        } | null;
      }> | null;
      conflicts?: Array<{
        __typename?: "ServiceConfigConflict";
        type: ConflictType;
        relatedServiceId?: string | null;
        relatedUnitGroupId?: string | null;
        relatedFieldName?: string | null;
      }> | null;
    }> | null;
    dependencyGroups?: Array<{
      __typename?: "ServiceDependencyGroup";
      id: string;
      key: string;
      services?: Array<{
        __typename?: "ServiceIdentifier";
        serviceId: string;
        details?: {
          __typename?: "ServiceContract";
          active?: boolean | null;
          chargeMode?: ChargeMode | null;
          chargeUnit?: ChargeUnit | null;
          description?: string | null;
          displayName?: string | null;
          id?: string | null;
          included?: boolean | null;
          name?: string | null;
          pmsCategoryId?: string | null;
          pmsId: string;
          pmsUpdatedAt?: any | null;
          price?: {
            __typename?: "Price";
            currency?: string | null;
            grossPrice?: any | null;
            netPrice?: any | null;
            taxes?: Array<{ __typename?: "Tax"; amount?: any | null; code?: string | null }> | null;
          } | null;
        } | null;
      }> | null;
      conflicts?: Array<{
        __typename?: "ServiceConfigConflict";
        type: ConflictType;
        relatedServiceId?: string | null;
        relatedUnitGroupId?: string | null;
        relatedFieldName?: string | null;
      }> | null;
    }> | null;
    conflicts?: Array<{
      __typename?: "ServiceConfigConflict";
      type: ConflictType;
      relatedServiceId?: string | null;
      relatedUnitGroupId?: string | null;
      relatedFieldName?: string | null;
    }> | null;
    pmsServices?: Array<{
      __typename?: "ServiceContract";
      active?: boolean | null;
      chargeMode?: ChargeMode | null;
      chargeUnit?: ChargeUnit | null;
      description?: string | null;
      displayName?: string | null;
      id?: string | null;
      included?: boolean | null;
      name?: string | null;
      pmsCategoryId?: string | null;
      pmsId: string;
      pmsUpdatedAt?: any | null;
      price?: {
        __typename?: "Price";
        currency?: string | null;
        grossPrice?: any | null;
        netPrice?: any | null;
        taxes?: Array<{ __typename?: "Tax"; amount?: any | null; code?: string | null }> | null;
      } | null;
    }> | null;
    pmsUnitGroups?: Array<{
      __typename?: "UnitGroupContract";
      active?: boolean | null;
      capacity?: number | null;
      extraCapacity?: number | null;
      id?: string | null;
      name?: string | null;
      pmsId: string;
      pmsPropertyId?: string | null;
      pmsUpdatedAt?: any | null;
      type?: string | null;
    }> | null;
  };
};
