import { usePropertyConfig } from "@likemagic-tech/sv-magic-library";

const THIS_DEVICE_OPTION_NAME = "This device";

interface UseSecondScreenProps {
  propertyId: string;
}

export const useSecondScreen = ({ propertyId }: UseSecondScreenProps) => {
  const { secondScreenDevices } = usePropertyConfig({
    propertyId
  });

  const devices = secondScreenDevices
    ? Object.keys(secondScreenDevices)
        .filter((key) => secondScreenDevices[key].propertyId === propertyId)
        .filter((key) => secondScreenDevices[key].name !== THIS_DEVICE_OPTION_NAME)
        .map((key) => ({
          id: key,
          label: secondScreenDevices[key].name
        }))
    : undefined;

  return { secondScreenDevicesPerProperty: devices };
};
