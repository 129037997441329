import { Box, Grid2, Paper, Tooltip, useTheme } from "@mui/material";
import {
  Caption,
  formatDate,
  Paragraph,
  ParagraphSmall,
  ParagraphSmallBold,
  Select
} from "@likemagic-tech/sv-magic-library";
import { Service } from "../../../../../domain/service";
import { useTranslationWrapper } from "src/hooks/use-translation-wrapper";
import { formatPriceToString } from "src/utils/price";
import { FC, PropsWithChildren, useEffect, useMemo } from "react";
import { DisplayServiceIcon } from "./display-service-icon";
import RemoveCircle from "../../../../../icons/RemoveCircle";
import { AdditionalServicesCodes } from "src/domain/additional-services-codes";
import { useSetPreferredCleaningDays } from "src/hooks/use-set-preferred-cleaning-days";
import { Params, useSelectId } from "src/hooks/use-select-id";
import { useDispatch } from "src/store";
import { openBanner } from "src/slices/banner.slice";
import { useIsMobile } from "src/hooks/use-is-mobile";

/**
 * Returns a card for each service booked on the reservation.
 */

interface ServicesInterface {
  services: Service[];
  hiddenCells?: boolean;
  serviceOnRemove?: (service: Service) => void;
  preferredCleaningDays: string[] | null;
  addOns?: boolean;
}

export enum DaysOfTheWeek {
  MONDAY = "MONDAY",
  TUESDAY = "TUESDAY",
  WEDNESDAY = "WEDNESDAY",
  THURSDAY = "THURSDAY",
  FRIDAY = "FRIDAY",
  SATURDAY = "SATURDAY",
  SUNDAY = "SUNDAY"
}

export const Services: FC<PropsWithChildren<ServicesInterface>> = ({
  services,
  serviceOnRemove,
  preferredCleaningDays,
  hiddenCells,
  addOns
}) => {
  const { t } = useTranslationWrapper();
  const [setPreferredCleaningDays, resultSetPreferredCleaningDays] = useSetPreferredCleaningDays();
  const { selectedId } = useSelectId({
    fieldName: Params.SELECTED_RESERVATION_ID
  });
  const dispatch = useDispatch();
  const isMobile = useIsMobile();
  const theme = useTheme();

  const rhythmOptions = useMemo(() => {
    return Object.keys(DaysOfTheWeek).map((day) => ({
      value: day,
      label: t(`labels__days_of_the_week_${day}`)
    }));
  }, [t]);

  useEffect(() => {
    if (resultSetPreferredCleaningDays.isSuccess) {
      dispatch(
        openBanner({
          type: "success",
          title: t("labels__action_successfully_performed")
        })
      );
      resultSetPreferredCleaningDays.reset();
    }
  }, [resultSetPreferredCleaningDays, dispatch, t]);

  const displayDates = (dates: string[]) => {
    const dateCounts: { [key: string]: number } = dates.reduce(
      (acc: { [key: string]: number }, date: string) => {
        acc[date] = (acc[date] || 0) + 1;
        return acc;
      },
      {}
    );
    return Object.entries(dateCounts)
      .map(([date, count]) => (count === 1 ? formatDate(date) : `${formatDate(date)} x ${count}`))
      .join(", ");
  };

  const handleChangeRythm = (value: string) => {
    setPreferredCleaningDays({
      reservationId: selectedId ?? "",
      payload: [value]
    });
  };

  const displayAttributeNames = useMemo(() => {
    return preferredCleaningDays?.reduce<string[]>((acc, cur) => {
      const label = rhythmOptions.find((item) => item.value === cur)?.label;
      if (label) {
        acc.push(label);
      }
      return acc;
    }, []);
  }, [preferredCleaningDays, rhythmOptions]);

  const showcaseRhythmColumn =
    !hiddenCells &&
    preferredCleaningDays &&
    services.some((service: Service) => service.code === AdditionalServicesCodes.FREE_CLEANING);

  return (
    <Box sx={{ overflowX: "auto", width: "100%" }}>
      <Box sx={{ minWidth: isMobile ? "220%" : "unset", width: addOns ? "99%" : "100%" }}>
        <Grid2
          container
          sx={{
            alignItems: "center",
            pb: 0.5
          }}
        >
          <Grid2
            size={{ xs: showcaseRhythmColumn ? 3 : 4 }}
            sx={{
              pl: 1
            }}
          >
            <ParagraphSmall sx={{ color: theme.palette.text.primary }}>
              {t("labels__reservation__details__overview__services__service")}
            </ParagraphSmall>
          </Grid2>
          <Grid2
            size={{ xs: showcaseRhythmColumn ? 3 : 4 }}
            sx={{
              pl: 1
            }}
          >
            <ParagraphSmall>{t("labels__dates")}</ParagraphSmall>
          </Grid2>
          {showcaseRhythmColumn && (
            <Grid2
              size={{ xs: 2 }}
              sx={{
                textAlign: "center"
              }}
            >
              <ParagraphSmall>{t("labels__rhythm")}</ParagraphSmall>
            </Grid2>
          )}
          <Grid2
            size={{ xs: 2 }}
            sx={{
              textAlign: "center"
            }}
          >
            <ParagraphSmall sx={{ color: theme.palette.text.primary }}>
              {t("labels__reservation__details__overview__services__quantity")}
            </ParagraphSmall>
          </Grid2>
          <Grid2
            size={{ xs: 2 }}
            sx={{
              textAlign: "right",
              pr: 1
            }}
          >
            <ParagraphSmall sx={{ color: theme.palette.text.primary }}>
              {t("labels__reservation__details__overview__services__price")}
            </ParagraphSmall>
          </Grid2>
        </Grid2>
        <Grid2 container>
          {services.map((service: Service) => (
            <Grid2
              size={{ xs: 12 }}
              key={service.name}
              sx={{
                paddingBottom: 1.5
              }}
            >
              <Paper
                elevation={0}
                sx={{
                  background: theme.palette.grey[100],
                  borderColor: theme.palette.grey[100],
                  borderRadius: 0.5
                }}
                variant="outlined"
              >
                <Grid2
                  container
                  direction="row"
                  sx={{
                    p: 1,
                    alignItems: "center",
                    position: "relative"
                  }}
                >
                  <Grid2
                    size={{ xs: showcaseRhythmColumn ? 3 : 4 }}
                    sx={{
                      textAlign: "center",
                      flexDirection: "column"
                    }}
                  >
                    <Tooltip title={service?.name} placement="top-start">
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "left",
                          alignItems: "center"
                        }}
                      >
                        <DisplayServiceIcon code={service?.code} fontSize={"medium"} />
                        <ParagraphSmall
                          sx={{ color: theme.palette.text.primary }}
                          textAlign="left"
                          pl={1}
                          overflow="hidden"
                          whiteSpace="nowrap"
                          textOverflow="ellipsis"
                        >
                          {service?.name}
                        </ParagraphSmall>
                      </Box>
                    </Tooltip>
                  </Grid2>
                  <Tooltip title={displayDates(service.dates)} placement="top-start">
                    <Grid2
                      size={{ xs: showcaseRhythmColumn ? 3 : 4 }}
                      sx={{
                        overflow: "hidden",
                        whiteSpace: "nowrap",
                        textOverflow: "ellipsis",
                        pl: 1
                      }}
                    >
                      <Caption textAlign="left">{displayDates(service.dates)}</Caption>
                    </Grid2>
                  </Tooltip>
                  {showcaseRhythmColumn && (
                    <Grid2 size={{ xs: 2 }}>
                      {service.code === AdditionalServicesCodes.FREE_CLEANING && (
                        <Select
                          size="small"
                          options={rhythmOptions}
                          // @ts-ignore
                          value={preferredCleaningDays ?? []}
                          renderValue={() => (
                            <Paragraph sx={{ textAlign: "center" }}>
                              {displayAttributeNames?.join(", ")}
                            </Paragraph>
                          )}
                          onChange={(e) => handleChangeRythm(e.target.value as string)}
                        />
                      )}
                    </Grid2>
                  )}
                  <Grid2
                    size={{ xs: 2 }}
                    sx={{
                      textAlign: "center"
                    }}
                  >
                    <ParagraphSmall sx={{ color: theme.palette.text.primary }}>
                      {service?.quantity}
                    </ParagraphSmall>
                  </Grid2>

                  <Grid2 size={{ xs: 2 }}>
                    <Grid2
                      container
                      sx={{
                        justifyContent: "flex-end",
                        alignItems: "center"
                      }}
                    >
                      <ParagraphSmallBold
                        sx={{ color: theme.palette.text.primary }}
                        textAlign="right"
                      >
                        {formatPriceToString(service?.price)}
                      </ParagraphSmallBold>
                      {serviceOnRemove && (
                        <RemoveCircle
                          onClick={() => serviceOnRemove(service)}
                          sx={{
                            position: "absolute",
                            cursor: "pointer",
                            right: -8,
                            top: -8,
                            width: 16,
                            height: 16
                          }}
                        />
                      )}
                    </Grid2>
                  </Grid2>
                </Grid2>
              </Paper>
            </Grid2>
          ))}
        </Grid2>
      </Box>
    </Box>
  );
};
