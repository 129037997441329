import { FilterObjectType, serializeIdOfFilter } from "../components/table/filters/table-filters";
import { FilterOperator } from "../types/filters/filters";

const reservationBookingId = {
  name: "bookingId",
  type: FilterObjectType.ADDITIONAL,
  operator: FilterOperator.Equality
};
export const getBookingIdFilter = (selectedBookingId: string) => ({
  [serializeIdOfFilter(reservationBookingId)]: {
    ...reservationBookingId,
    value: selectedBookingId
  }
});
