import { Grid2 } from "@mui/material";
import * as React from "react";
import { FC, useCallback } from "react";
import { Chip } from "@likemagic-tech/sv-magic-library";
import { ReservationDetailsDTO } from "../../../../../store/endpoints/reservation-table.endpoints";
import { useTranslationWrapper } from "../../../../../hooks/use-translation-wrapper";
import { useUnitGroupById } from "../../../../../hooks/use-unit-group-by-id";

const MOBILE_KEY = "MOBILE_KEY";

interface DoorKeyLabel {
  exists: boolean;
  label: string;
}

interface DoorAvailabilityInfoProps {
  reservation: ReservationDetailsDTO;
}
export const DoorAvailabilityInfo: FC<DoorAvailabilityInfoProps> = ({ reservation }) => {
  const { t } = useTranslationWrapper();
  const assignedUnitGroup = useUnitGroupById(reservation.propertyId, reservation.unit?.unitGroupId);
  const bookedUnitGroup = useUnitGroupById(reservation.propertyId, reservation.bookedUnitGroupId);

  const publicDoorsLabel = useCallback(() => {
    const publicDoorExists = !!reservation?.accessibleDoors?.find(
      (door) => door.is_general || door.title === MOBILE_KEY
    );
    return publicDoorExists
      ? {
          exists: publicDoorExists,
          label: t("labels__card_public_door_available")
        }
      : {
          exists: publicDoorExists,
          label: t("labels__card_public_door_not_available")
        };
  }, [t, reservation?.accessibleDoors]);

  const privateDoorsLabel = useCallback(() => {
    const privateDoorExists = !!reservation?.accessibleDoors?.find((door) => !door.is_general);
    return privateDoorExists
      ? {
          exists: privateDoorExists,
          label: `${assignedUnitGroup?.name || bookedUnitGroup?.name} ${t(
            "labels__card_unit_door_available",
            { unitNumber: reservation?.unit?.name ?? "" }
          )}`
        }
      : {
          exists: privateDoorExists,
          label: `${assignedUnitGroup?.name || bookedUnitGroup?.name} ${t(
            "labels__card_unit_door_not_available",
            { unitNumber: reservation?.unit?.name ?? "" }
          )}`
        };
  }, [
    reservation?.accessibleDoors,
    assignedUnitGroup?.name,
    bookedUnitGroup?.name,
    reservation?.unit?.name,
    t
  ]);

  const doorsAvailabilityInfo = useCallback(() => {
    const labelsArray = [privateDoorsLabel(), publicDoorsLabel()];
    return (
      <Grid2
        container
        sx={{
          gap: 1
        }}
      >
        {labelsArray.map((type: DoorKeyLabel) => (
          <Chip
            size="small"
            color={type.exists ? "success" : "error"}
            key={type.label}
            label={type.label}
          />
        ))}
      </Grid2>
    );
  }, [privateDoorsLabel, publicDoorsLabel]);

  return (
    <Grid2 container>
      <Grid2
        sx={{
          paddingTop: 1
        }}
      >
        {doorsAvailabilityInfo()}
      </Grid2>
    </Grid2>
  );
};
