import { formatDate, Heading3, Paragraph, ParagraphSmall } from "@likemagic-tech/sv-magic-library";
import { Accordion, AccordionDetails, AccordionSummary, Box, Grid2, useTheme } from "@mui/material";
import React, { FC, useCallback } from "react";
import { useTranslationWrapper } from "src/hooks/use-translation-wrapper";
import { useProperty } from "src/hooks/use-property";
import { ReservationFromConversations } from "../domain/conversation";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { DisplayServiceIcon } from "../../features/reservations/reservation-details/reservation-details-tabs/services/display-service-icon";
import { TitleHeader } from "src/features/reservations/reservation-details/reservation-details-tabs/overview/card-header/title-header";
import { getI18nSelectedLanguage } from "src/utils/language";
import { makeStyles } from "tss-react/mui";
import { ReservationStatusIcon } from "../../components/reservation-status-icon/reservation-status-icon";
import { mapReservationStateV2ToCommonReservationState } from "../../graphql/transform/transform-reservation-details";
import { ButtonWithChevron } from "../../components/button/button-with-chevron";

interface ChatPreviewReservationsProps {
  reservations: ReservationFromConversations;
  onSelect: (reservationId: string) => void;
}

const useStyles = makeStyles()(() => ({
  summaryContent: {
    margin: 0
  },
  summaryRoot: {
    minHeight: "auto"
  },
  root: {
    padding: 0
  }
}));

export const ChatPreviewReservations: FC<ChatPreviewReservationsProps> = ({
  reservations,
  onSelect
}) => {
  const { palette } = useTheme();
  const { classes } = useStyles();
  const { t } = useTranslationWrapper();

  const { getProperty, selectedProperty } = useProperty();
  const language = getI18nSelectedLanguage();

  const getPropertyName = useCallback(
    (propertyId: string) => {
      const property = getProperty(propertyId);
      return property?.details?.name;
    },
    [getProperty]
  );

  return (
    <Accordion sx={{ boxShadow: "none" }} disableGutters defaultExpanded>
      <AccordionSummary
        sx={{ px: 0 }}
        classes={{ content: classes.summaryContent, root: classes.summaryRoot }}
        expandIcon={<ExpandMoreIcon />}
        aria-controls="last-reservations"
        id="last-reservations"
      >
        <Heading3>
          <TitleHeader title={t("title__reservations")} length={reservations?.length} />
        </Heading3>
      </AccordionSummary>
      <AccordionDetails sx={{ px: 0 }} classes={{ root: classes.root }}>
        <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
          {reservations?.map((reservationInfo) => (
            <ButtonWithChevron
              key={reservationInfo?.pmsId}
              onClick={() => {
                if (reservationInfo?.pmsId) {
                  onSelect(reservationInfo?.pmsId);
                }
              }}
            >
              <Grid2 container direction="column" spacing={0.5}>
                <Grid2>
                  <Paragraph>
                    {reservationInfo?.confirmationNumber ?? reservationInfo?.pmsId}
                  </Paragraph>
                  <ParagraphSmall sx={{ color: palette.text.secondary }}>
                    {getPropertyName(reservationInfo?.pmsPropertyId ?? "")}
                  </ParagraphSmall>
                  <Paragraph>{`${formatDate(
                    reservationInfo?.arrival,
                    language,
                    selectedProperty?.details?.timeZone
                  )} - ${formatDate(
                    reservationInfo?.departure,
                    language,
                    selectedProperty?.details?.timeZone
                  )}`}</Paragraph>

                  <ParagraphSmall
                    sx={{
                      display: "flex",
                      color: palette.text.secondary,
                      verticalAlign: "text-bottom"
                    }}
                  >
                    {reservationInfo?.status && (
                      <ReservationStatusIcon
                        status={mapReservationStateV2ToCommonReservationState(
                          reservationInfo?.status
                        )}
                        size="small"
                      />
                    )}
                    <Box component="span" ml={0.5} />
                    {t(`labels__conversation__status__${reservationInfo?.status}`)}
                  </ParagraphSmall>
                </Grid2>

                {reservationInfo?.services && reservationInfo?.services.length > 0 && (
                  <Grid2>
                    {reservationInfo?.services.map((service, index) => (
                      <Grid2
                        container
                        direction="row"
                        key={`chat-preview-service-${service?.magicServiceCodeEnum}`}
                        sx={{
                          alignItems: "center",
                          paddingTop: index === 0 ? 0 : 1
                        }}
                        spacing={1}
                        wrap="nowrap"
                      >
                        <DisplayServiceIcon
                          code={service?.magicServiceCodeEnum!}
                          fontSize="small"
                        />
                        <Grid2 width="calc(256px - 20px)">
                          <ParagraphSmall
                            sx={{
                              textOverflow: "ellipsis",
                              whiteSpace: "nowrap",
                              overflow: "hidden",
                              display: "block"
                            }}
                          >
                            {service?.name}
                          </ParagraphSmall>
                        </Grid2>
                      </Grid2>
                    ))}
                  </Grid2>
                )}
              </Grid2>
            </ButtonWithChevron>
          ))}
        </Box>
      </AccordionDetails>
    </Accordion>
  );
};
