import { OverviewTableQuery as GetOverviewQuery } from "../queries/GetReservationOverview.generated";
import {
  Gender,
  IdCheckStatus,
  ReservationAction,
  ReservationCondition,
  ReservationStatus,
  UnitState
} from "../generated/graphql";
import {
  transformV2AllowedActionsToCommonAllowedActions,
  transformV2FilesToCommonFiles
} from "./transform-reservation-details";
import { ReservationActionPayload } from "../../hooks/use-reservation-actions";
import {
  AllowedActionEnum,
  ReservationConditionEnum as ReservationConditionEnumCommon,
  ReservationTableDto
} from "../../domain/reservation-table-dto";
import { PageableResponse } from "../../types/pageable";
import { ReservationStatus as ReservationStatusCommon } from "../../domain/reservation-status";
import { PersonGender } from "../../domain/person";
import { UnitCondition as UnitConditionCommon } from "../../domain/Unit";
import { IdCheckStatus as IdCheckStatusCommon } from "../../domain/reservation";

export const transformReservationTableOverviewV2ToCommonReservationOverview = (
  arg: GetOverviewQuery["OverviewTable"]
): PageableResponse<ReservationTableDto> => {
  return {
    content:
      arg?.content?.map(
        (item) =>
          ({
            id: item?.pmsReservationId ?? "",
            magicToken: item?.magicToken,
            magicId: item?.magicId,
            magicLink: item?.magicLink,
            bookingMagicLink: item?.bookingMagicLink,
            createdAt: item?.createdAt,
            checkoutOnAr: item?.checkoutOnAr,
            files: item?.files?.map(transformV2FilesToCommonFiles) ?? [],

            booker: {
              address: {
                addressLine1: item?.booker?.address?.addressLine1,
                addressLine2: item?.booker?.address?.addressLine2,
                postalCode: item?.booker?.address?.postalCode,
                city: item?.booker?.address?.city,
                countryCode: item?.booker?.address?.countryCode
              },
              birthdate: item?.booker?.birthdate,
              email: item?.booker?.email,
              firstName: item?.booker?.firstName,
              gender: mapGuestGenderV2ToCommonGuestGender(item?.booker?.gender),
              guestPreferences: {},
              guestPreferencesText: "",
              identificationNumber: item?.booker?.identificationDocumentNumber,
              isRecurring: item?.booker?.userProfile?.isRecurring,
              lastName: item?.booker?.lastName,
              nationalityCountryCode: item?.booker?.nationality,
              phone: item?.booker?.phone,
              preferredCommunicationChannel: item?.booker?.preferredCommunicationChannel,
              preferredLanguage: item?.booker?.preferredLanguage,
              userProfileId: item?.booker?.userProfileId,
              hasAccount: !!item?.booker?.userProfile?.userAccountUuid
            },

            primaryGuest: {
              address: {
                addressLine1: item?.primaryGuest?.address?.addressLine1,
                addressLine2: item?.primaryGuest?.address?.addressLine2,
                postalCode: item?.primaryGuest?.address?.postalCode,
                city: item?.primaryGuest?.address?.city,
                countryCode: item?.primaryGuest?.address?.countryCode
              },
              birthdate: item?.primaryGuest?.birthdate,
              email: item?.primaryGuest?.email,
              firstName: item?.primaryGuest?.firstName,
              gender: mapGuestGenderV2ToCommonGuestGender(item?.primaryGuest?.gender),
              guestPreferences: {},
              guestPreferencesText: "",
              identificationNumber: item?.primaryGuest?.identificationDocumentNumber,
              isRecurring: item?.primaryGuest?.userProfile?.isRecurring,
              lastName: item?.primaryGuest?.lastName,
              nationalityCountryCode: item?.primaryGuest?.nationality,
              phone: item?.primaryGuest?.phone,
              preferredCommunicationChannel: item?.primaryGuest?.preferredCommunicationChannel,
              preferredLanguage: item?.primaryGuest?.preferredLanguage,
              userProfileId: item?.primaryGuest?.userProfileId,
              hasAccount: !!item?.primaryGuest?.userProfile?.userAccountUuid,
              vipCode: item?.primaryGuest?.vipCode,
              enrolledInLoyaltyProgram: item?.primaryGuest?.enrolledInLoyaltyProgram,
              emergencyEvacuationHelpNeeded: item.primaryGuest?.emergencyEvacuationHelpNeeded,
              emergencyEvacuationHelpNotes: item.primaryGuest?.emergencyEvacuationHelpNotes
            },

            adultsCount: item?.adultsCount,

            allowedActions:
              item?.allowedActions?.map(transformV2AllowedActionsToCommonAllowedActions) ?? [],

            childrenCount: item?.childrenCount,
            childrenAges: item?.childrenAges ?? [],

            reservationStatus: mapReservationStateV2ToCommonReservationState(
              item?.reservationStatus
            ),

            notes: {
              previousVisits: item?.notes?.previousVisits,
              guestComment: item?.notes?.guestComment ?? [],
              bookerComment: item?.notes?.bookerComment ?? [],
              comment: item?.notes?.comment ?? [],
              bookingComment: item?.notes?.bookingComment ?? [],
              rateBreakdownComment: item?.notes?.rateBreakdownComment ?? [],
              beddingComment: item?.notes?.beddingComment ?? []
            },

            arrival: item?.arrival,
            departure: item?.departure,
            unitCleanOnCheckin: item?.unitCleanOnCheckin,
            idCheckStatus: mapIdCheckStatusV2ToCommonIdCheckStatus(item.idCheckStatus),

            unit: {
              unitId: item?.unit?.pmsUnitId,
              unitGroupId: item?.unit?.pmsUnitGroupId,
              name: item?.unit?.name,
              condition: mapUnitConditionV2ToCommonUnitCondition(item?.unit?.condition),
              privacyMode: item?.unit?.privacyMode,
              privacyModeLastChangeTimestamp: item?.unit?.privacyModeLastChangeTimestamp
            },

            bookedUnitGroupId: item?.bookedPmsUnitGroupId,

            reservationCondition: mapReservationConditionV2ToCommonReservationCondition(
              item?.reservationCondition
            ),

            reservationConditionReasons: item?.reservationConditionReasons,
            reservationIsPaid: item?.reservationIsPaid,
            bookingChannel: item?.bookingChannel,
            propertyId: item?.pmsPropertyId,
            lastConfirmedPage: item?.lastConfirmedPage,
            secondScreenCheckpoint: item?.secondScreenCheckpoint,
            completed: item?.completed,
            checkInTime: item?.checkinTime,
            checkOutTime: item?.checkoutTime,
            estimatedArrivalTime: item?.estimatedArrivalTime,
            estimatedDepartureTime: item?.estimatedDepartureTime,
            guestPreferences: {},
            primaryGuestSameAsBooker: item?.primaryGuestSameAsBooker,
            firstName: item?.primaryGuest?.firstName,
            lastName: item?.primaryGuest?.lastName,
            bookingId: item?.booking?.pmsId,
            bookingName: item?.booking?.name,
            isGroupBooking:
              item.booking?.expectedNumberOfReservations &&
              item?.booking?.expectedNumberOfReservations > 1
          }) as ReservationTableDto
      ) ?? [],
    number: 0,
    pageable: {
      offset: 0,
      pageNumber: 0,
      pageSize: 25,
      paged: true,
      sort: { empty: false, sorted: true, unsorted: false },
      unpaged: false
    },
    size: 25,
    sort: { empty: false, sorted: true, unsorted: false },
    totalElements: arg?.totalElements ?? 0,
    totalPages: 0
  };
};

export const transformCommonPayloadToV2 = (
  action: AllowedActionEnum,
  payload?: ReservationActionPayload
) => {
  switch (action) {
    case AllowedActionEnum?.ASSIGN_UNIT:
      return { pmsUnitId: payload?.unitId, pmsUnitGroupId: payload?.unitGroupId };
    default:
      return payload;
  }
};

export const transformV2AllowedActionsToV2AllowedActions = (
  allowedActionsV2: AllowedActionEnum
): ReservationAction => {
  switch (allowedActionsV2) {
    case AllowedActionEnum.CHANGE_ID_CHECK_STATUS:
      return ReservationAction.ChangeIdCheckStatus;
    case AllowedActionEnum.ASSIGN_UNIT:
      return ReservationAction.AssignUnit;
    case AllowedActionEnum.CHANGE_STATUS_TO_CANCELED:
      return ReservationAction.ChangeStatusToCanceled;
    case AllowedActionEnum.CHANGE_STATUS_TO_CHECKED_OUT:
      return ReservationAction.ChangeStatusToCheckedOut;
    case AllowedActionEnum.CHANGE_STATUS_TO_IN_HOUSE:
      return ReservationAction.ChangeStatusToInHouse;
    case AllowedActionEnum.CHANGE_UNIT_CONDITION_TO_CLEAN:
      return ReservationAction.ChangeUnitConditionToClean;
    case AllowedActionEnum.CHANGE_UNIT_CONDITION_TO_CLEAN_TO_BE_INSPECTED:
      return ReservationAction.ChangeUnitConditionToCleanToBeInspected;
    case AllowedActionEnum.CHANGE_UNIT_CONDITION_TO_DIRTY:
      return ReservationAction.ChangeUnitConditionToDirty;
    case AllowedActionEnum.VALIDATE_RESERVATION:
      return ReservationAction.ValidateReservation;
    case AllowedActionEnum.JUMP_TO_BOOKING:
      return ReservationAction.JumpToBooking;
    case AllowedActionEnum.JUMP_TO_RESERVATION:
      return ReservationAction.JumpToReservation;
    case AllowedActionEnum.ENCODE_KEY:
      return ReservationAction.EncodeKey;
    case AllowedActionEnum.RESEND_NOTIFICATION:
      return ReservationAction.ResendNotification;
    case AllowedActionEnum.RESEND_GUESTFLOW_LINK:
      return ReservationAction.ResendGuestflowLink;
    case AllowedActionEnum.COPY_GUESTFLOW_LINK:
      return ReservationAction.CopyGuestflowLink;
    case AllowedActionEnum.MANUAL_CHECKIN:
      return ReservationAction.ManualCheckin;
    default:
      console.warn(`ExtractAllowedAction with code ${allowedActionsV2} not supported`);
      return "Unknown" as ReservationAction;
  }
};

const mapReservationStateV2ToCommonReservationState = (
  status: ReservationStatus | null | undefined
): ReservationStatusCommon => {
  switch (status) {
    case ReservationStatus?.CheckedOut:
      return ReservationStatusCommon?.CHECKED_OUT;
    case ReservationStatus?.Confirmed:
      return ReservationStatusCommon?.CONFIRMED;
    case ReservationStatus?.InHouse:
      return ReservationStatusCommon?.IN_HOUSE;
    case ReservationStatus?.NoShow:
      return ReservationStatusCommon?.NO_SHOW;
    default:
      return ReservationStatusCommon?.DELETED;
  }
};

export const mapIdCheckStatusV2ToCommonIdCheckStatus = (
  idCheckStatus: IdCheckStatus
): IdCheckStatusCommon => {
  switch (idCheckStatus) {
    case IdCheckStatus?.Confirmed:
      return IdCheckStatusCommon?.CONFIRMED;
    case IdCheckStatus?.Declined:
      return IdCheckStatusCommon?.DECLINED;
    case IdCheckStatus?.Unconfirmed:
      return IdCheckStatusCommon?.UNCONFIRMED;
    case IdCheckStatus?.Reuploaded:
      return IdCheckStatusCommon?.REUPLOADED;
  }
};

const mapUnitConditionV2ToCommonUnitCondition = (
  condition: UnitState | null | undefined
): UnitConditionCommon => {
  switch (condition) {
    case UnitState?.Dirty:
      return UnitConditionCommon?.DIRTY;
    case UnitState?.CleanToBeInspected:
      return UnitConditionCommon?.CLEAN_TO_BE_INSPECTED;
    case UnitState?.Clean:
      return UnitConditionCommon?.CLEAN;
    default:
      return UnitConditionCommon?.UNKNOWN;
  }
};

const mapReservationConditionV2ToCommonReservationCondition = (
  reservationCondition: ReservationCondition | null | undefined
): ReservationConditionEnumCommon | undefined => {
  switch (reservationCondition) {
    case ReservationCondition?.Critical:
      return ReservationConditionEnumCommon?.CRITICAL;
    case ReservationCondition?.Minor:
      return ReservationConditionEnumCommon?.MINOR;
    case ReservationCondition?.Ok:
      return ReservationConditionEnumCommon?.OK;
    default:
      return ReservationConditionEnumCommon.UNKNOWN;
  }
};

const mapGuestGenderV2ToCommonGuestGender = (gender: Gender | null | undefined): PersonGender => {
  switch (gender) {
    case Gender.Male:
      return PersonGender.MALE;
    case Gender.Female:
      return PersonGender.FEMALE;
    default:
      return PersonGender.OTHER;
  }
};
