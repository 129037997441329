import React, { FC, PropsWithChildren, useContext, useEffect, useMemo, useState } from "react";
import { initI18n } from "../i18n-config";
import enJSON from "../languages/en.json";
import deJSON from "../languages/de.json";
import esJSON from "../languages/es.json";
import itJSON from "../languages/it.json";
import ptJSON from "../languages/pt.json";
import rsJSON from "../languages/rs.json";
import trJSON from "../languages/tr.json";
import uaJSON from "../languages/ua.json";
import enMewsJSON from "../pms-speficic/enMews.json";
import enTFEJSON from "../pms-speficic/enTFE.json";
import deTFEJSON from "../pms-speficic/deTFE.json";
import deMewsJSON from "../pms-speficic/deMews.json";
import enOHIPJSON from "../pms-speficic/enOHIP.json";
import deOHIPJSON from "../pms-speficic/deOHIP.json";
import enErrorsJSON from "../errors/enErrors.json";
import deErrorsJSON from "../errors/deErrors.json";
import { PmsType, TenantContext } from "@likemagic-tech/sv-magic-library";
import { validateTranslation } from "./translation.utils";
import { TranslationType } from "./translation.types";

type ExactTranslationType<T, U = T> = {
  [K in keyof T]: K extends keyof U ? U[K] : never;
} & Record<Exclude<keyof U, keyof T>, never>;

export const InitI18n: FC<PropsWithChildren> = ({ children }) => {
  const [initialized, setInitialized] = useState(false);

  const { pms, tenantId } = useContext(TenantContext);

  const enFile = useMemo(() => {
    const validatedEnJSON: ExactTranslationType<typeof enJSON, TranslationType> = enJSON;

    switch (pms) {
      case PmsType.APALEO:
        return validatedEnJSON;
      case PmsType.MEWS:
        return { ...validatedEnJSON, ...enMewsJSON };
      // Might need to be adapted when this pms is integrated
      case PmsType.OHIP:
        // TODO - this should be fixed at some point :) - this is a temporary solution
        return {
          ...validatedEnJSON,
          ...enOHIPJSON,
          ...(tenantId === "tfe" || tenantId === "tfe-poc" ? enTFEJSON : {})
        };
      default:
        return validatedEnJSON;
    }
  }, [pms, tenantId]);

  const deFile = useMemo(() => {
    const validatedDeJSON = validateTranslation<TranslationType>(deJSON);
    switch (pms) {
      case PmsType.APALEO:
        return validatedDeJSON;
      case PmsType.MEWS:
        return { ...validatedDeJSON, ...deMewsJSON };
      // Might need to be adapted when this pms is integrated
      case PmsType.OHIP:
        return {
          ...validatedDeJSON,
          ...deOHIPJSON,
          ...(tenantId === "tfe" || tenantId === "tfe-poc" ? deTFEJSON : {})
        };
      default:
        return validatedDeJSON;
    }
  }, [pms, tenantId]);

  const esFile = useMemo(() => {
    const validatedEsJSON = validateTranslation<TranslationType>(esJSON);
    switch (pms) {
      case PmsType.APALEO:
        return validatedEsJSON;
      case PmsType.MEWS:
        return { ...validatedEsJSON, ...enMewsJSON };
      // Might need to be adapted when this pms is integrated
      case PmsType.OHIP:
        return { ...validatedEsJSON, ...enOHIPJSON };
      default:
        return validatedEsJSON;
    }
  }, [pms]);

  const itFile = useMemo(() => {
    const validatedItJSON = validateTranslation<TranslationType>(itJSON);
    switch (pms) {
      case PmsType.APALEO:
        return validatedItJSON;
      case PmsType.MEWS:
        return { ...validatedItJSON, ...enMewsJSON };
      // Might need to be adapted when this pms is integrated
      case PmsType.OHIP:
        return { ...validatedItJSON, ...enOHIPJSON };
      default:
        return validatedItJSON;
    }
  }, [pms]);

  const ptFile = useMemo(() => {
    const validatedPtJSON = validateTranslation<TranslationType>(ptJSON);

    switch (pms) {
      case PmsType.APALEO:
        return validatedPtJSON;
      case PmsType.MEWS:
        return { ...validatedPtJSON, ...enMewsJSON };
      // Might need to be adapted when this pms is integrated
      case PmsType.OHIP:
        return { ...validatedPtJSON, ...enOHIPJSON };
      default:
        return validatedPtJSON;
    }
  }, [pms]);

  const rsFile = useMemo(() => {
    const validatedRsJSON = validateTranslation<TranslationType>(rsJSON);
    switch (pms) {
      case PmsType.APALEO:
        return validatedRsJSON;
      case PmsType.MEWS:
        return { ...validatedRsJSON, ...enMewsJSON };
      // Might need to be adapted when this pms is integrated
      case PmsType.OHIP:
        return { ...validatedRsJSON, ...enOHIPJSON };
      default:
        return validatedRsJSON;
    }
  }, [pms]);

  const trFile = useMemo(() => {
    const validatedTRJSON = validateTranslation<TranslationType>(trJSON);
    switch (pms) {
      case PmsType.APALEO:
        return validatedTRJSON;
      case PmsType.MEWS:
        return { ...validatedTRJSON, ...enMewsJSON };
      // Might need to be adapted when this pms is integrated
      case PmsType.OHIP:
        return { ...validatedTRJSON, ...enOHIPJSON };
      default:
        return validatedTRJSON;
    }
  }, [pms]);

  const uaFile = useMemo(() => {
    const validatedUaJSON = validateTranslation<TranslationType>(uaJSON);
    switch (pms) {
      case PmsType.APALEO:
        return validatedUaJSON;
      case PmsType.MEWS:
        return { ...validatedUaJSON, ...enMewsJSON };
      // Might need to be adapted when this pms is integrated
      case PmsType.OHIP:
        return { ...validatedUaJSON, ...enOHIPJSON };
      default:
        return validatedUaJSON;
    }
  }, [pms]);

  useEffect(() => {
    initI18n({
      fallbackLanguage: "en",
      englishFile: { ...enFile, ...enErrorsJSON },
      germanFile: { ...deFile, ...deErrorsJSON },
      spanishFile: { ...esFile, ...enErrorsJSON },
      italianFile: { ...itFile, ...enErrorsJSON },
      portugueseFile: { ...ptFile, ...enErrorsJSON },
      serbianFile: { ...rsFile, ...enErrorsJSON },
      turkishFile: { ...trFile, ...enErrorsJSON },
      ukrainianFile: { ...uaFile, ...enErrorsJSON }
    }).then(() => setInitialized(true));
  }, [enFile, deFile, esFile, itFile, ptFile, rsFile, trFile, uaFile]);

  if (!initialized) {
    return null;
  }

  return <>{children}</>;
};
