import React, { FC } from "react";
import {
  FormControl,
  FormHelperText,
  Grid2,
  IconButton,
  InputAdornment,
  useTheme
} from "@mui/material";
import { useTranslationWrapper } from "../../../../hooks/use-translation-wrapper";
import { ParagraphBold, ParagraphSmall, Select } from "@likemagic-tech/sv-magic-library";
import {
  AdditionalCleaningServiceConfig,
  DefaultDatePickerSelection,
  ServiceConfigGranularity
} from "../../../../graphql-tenantconfig/generated/graphql";
import { useFormikContext } from "formik";
import { BaseServiceConfigFields } from "./base-service-config-fields";
import { useCanEditServiceConfig } from "../use-can-edit-service-config";
import ClearIcon from "@mui/icons-material/Clear";
import { useIsMobile } from "../../../../hooks/use-is-mobile";

interface AdditionalCleaningFieldsProps {}

export const AdditionalCleaningFields: FC<AdditionalCleaningFieldsProps> = () => {
  const theme = useTheme();
  const { t } = useTranslationWrapper();
  const isMobile = useIsMobile();
  const { canEditServiceConfig } = useCanEditServiceConfig();

  const { values, errors, handleChange, setFieldValue } =
    useFormikContext<AdditionalCleaningServiceConfig>();

  const granularityOptions = [
    ServiceConfigGranularity.AnyDayExceptArrivalDay,
    ServiceConfigGranularity.Never
  ].map((type) => ({
    label: t("labels__settings_service_config_granularity_" + type),
    value: type
  }));

  const defaultDatePickerSelectionOptions = Object.values(DefaultDatePickerSelection).map(
    (type) => ({
      label: t("labels__settings_service_config_defaultdatepickerselection_" + type),
      value: type
    })
  );

  return (
    <>
      <Grid2 container spacing={2}>
        <Grid2 size={isMobile ? 12 : 6}>
          <ParagraphBold>
            {t("labels__service_config_form_additionalcleaning_granularity")} *
          </ParagraphBold>
          <ParagraphSmall sx={{ mt: theme.spacing(0.5) }}>
            {t("labels__service_config_form_additionalcleaning_granularity_description")}
          </ParagraphSmall>
          <FormControl fullWidth={true} sx={{ mt: theme.spacing(0.75) }}>
            <Select
              id="granularity"
              name="granularity"
              variant="outlined"
              value={values.granularity ?? ""}
              options={granularityOptions}
              onChange={handleChange}
              error={!!errors.granularity}
              disabled={!canEditServiceConfig}
            />
            <FormHelperText error required>
              {errors.granularity?.toString()}
            </FormHelperText>
          </FormControl>
        </Grid2>

        <Grid2 size={isMobile ? 12 : 6}>
          <ParagraphBold>
            {t("labels__service_config_form_additionalcleaning_defaultdatepickerselection")}
          </ParagraphBold>
          <ParagraphSmall sx={{ mt: theme.spacing(0.5) }}>
            {t(
              "labels__service_config_form_additionalcleaning_defaultdatepickerselection_description"
            )}
          </ParagraphSmall>
          <FormControl fullWidth={true} sx={{ mt: theme.spacing(0.75) }}>
            <Select
              id="defaultDatePickerSelection"
              name="defaultDatePickerSelection"
              variant="outlined"
              value={values.defaultDatePickerSelection ?? ""}
              options={defaultDatePickerSelectionOptions}
              onChange={handleChange}
              error={!!errors.defaultDatePickerSelection}
              disabled={!canEditServiceConfig}
              endAdornment={
                canEditServiceConfig &&
                !!values.defaultDatePickerSelection && (
                  <InputAdornment sx={{ position: "absolute", right: 32 }} position="end">
                    <IconButton
                      sx={{ p: "4px" }}
                      onClick={() => {
                        setFieldValue("defaultDatePickerSelection", undefined);
                      }}
                    >
                      <ClearIcon sx={{ fontSize: 20 }}></ClearIcon>
                    </IconButton>
                  </InputAdornment>
                )
              }
            />
            <FormHelperText error required>
              {errors.defaultDatePickerSelection?.toString()}
            </FormHelperText>
          </FormControl>
        </Grid2>

        <BaseServiceConfigFields />
      </Grid2>
    </>
  );
};
