import { FilterObject } from "./filters/filters";

// TODO remove unneeded methods https://like-magic.slack.com/archives/C03RJPH56TC/p1688967544012789

export type Order = "asc" | "desc";

export enum OrderEnum {
  DESC = "desc",
  ASC = "asc"
}

export interface SortingRule<T extends any = any> {
  id: keyof T;
  order: Order;
}

export interface PageableRequestParams {
  pageNumber: number;
  pageSize: number;
}

export interface GetRequestParams<T extends any = any> {
  pageable?: PageableRequestParams;
  sortingRules: SortingRule<T>[];
  filterParams: Array<FilterObject>;
}

// TODO remove unneeded fields https://like-magic.slack.com/archives/C03RJPH56TC/p1688967544012789
export interface PageableResponse<T> {
  content: T[];
  number: number;
  pageable: {
    offset: number;
    pageNumber: number;
    pageSize: number;
    paged: boolean;
    sort: { sorted: boolean; unsorted: boolean; empty: boolean };
    unpaged: boolean;
  };
  size: number;
  sort: { sorted: boolean; unsorted: boolean; empty: boolean };
  totalElements: number;
  totalPages: number;
}

export function parseQueryParams(requestParams: GetRequestParams): string {
  if (!requestParams) {
    return "";
  }
  const result = [];
  if (Object.keys(requestParams.filterParams).length) {
    const parsedFilterParams = requestParams.filterParams
      .map((filterObject) => {
        return `${filterObject.name}${filterObject.operator}${filterObject.value}`;
      })
      .join("&");
    result.push(`search=${parsedFilterParams}`);
  }

  if (requestParams.pageable?.pageNumber !== undefined) {
    result.push(`page=${encodeURIComponent(requestParams.pageable.pageNumber)}`);
  }
  if (requestParams.pageable?.pageSize !== undefined) {
    result.push(`size=${encodeURIComponent(requestParams.pageable.pageSize)}`);
  }
  if (requestParams.sortingRules !== undefined) {
    result.push(
      ...requestParams.sortingRules.map((rule) => `sort=${String(rule.id)},${rule.order}`)
    );
  }
  return result.join("&");
}

export const GetRequestParamsToSearchParams = (
  params: GetRequestParams | undefined
): URLSearchParams => {
  const result = new URLSearchParams();
  if (!params) {
    return result;
  }
  result.set("pageNumber", "" + params?.pageable?.pageNumber);
  result.set("pageSize", "" + params?.pageable?.pageSize);
  result.set("sortingRules", JSON.stringify(params.sortingRules));

  result.set("filterParams", JSON.stringify(params.filterParams));
  return result;
};

export const GetRequestParamsFromSearchParams = (
  params: URLSearchParams
): GetRequestParams | undefined => {
  const pageNumber = params.get("pageNumber");
  const pageSize = params.get("pageSize");
  const sortingRules = params.get("sortingRules");
  const filterParams = params.get("filterParams");

  if (!(pageNumber && pageSize && sortingRules && filterParams)) {
    return undefined;
  }

  return {
    filterParams: JSON.parse(filterParams),
    sortingRules: JSON.parse(sortingRules),
    pageable: {
      pageNumber: +pageNumber,
      pageSize: +pageSize
    }
  };
};

export function PageableResponseToPageableAdapterPayload<T extends any>(
  response: PageableResponse<T>,
  filterParams: FilterObject[] = [],
  sortingRules: SortingRule[] = []
) {
  return {
    entities: response.content,
    page: response.number,
    pageSize: response.size,
    total: response.totalElements,
    filterParams,
    sortingRules
  };
}
