import React, { FC, useMemo, useState } from "react";
import { ServiceDependencyGroup } from "../../../graphql-tenantconfig/generated/graphql";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import { useTheme } from "@mui/material/styles";
import { Box, Collapse, IconButton, Tooltip } from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { useTranslationWrapper } from "../../../hooks/use-translation-wrapper";
import { useCanEditServiceConfig } from "./use-can-edit-service-config";
import { ActionMenu } from "../../../components/action-menu/action-menu";
import { useDependencyGroupModal } from "./dependency-group-modal/use-dependency-group-modal";
import { ParagraphBold, ParagraphSmall } from "@likemagic-tech/sv-magic-library";
import {
  containsConflictRelatedToServiceId,
  sortedServices,
  toDependencyGroupTranslationProps
} from "./service-config-utils";
import ErrorIcon from "@mui/icons-material/Error";

interface DependencyGroupsTableRowProps {
  data: ServiceDependencyGroup;
  onDelete: (dependencyGroup: ServiceDependencyGroup) => void;
}

const DependencyGroupsTableRow: FC<DependencyGroupsTableRowProps> = ({ data, onDelete }) => {
  const theme = useTheme();
  const { t } = useTranslationWrapper();
  const { canEditServiceConfig } = useCanEditServiceConfig();

  const [open, setOpen] = useState(false);

  const { openEditDependencyGroup } = useDependencyGroupModal();

  const menuItems = useMemo(() => {
    if (canEditServiceConfig) {
      return [
        {
          id: "edit_dependency_group",
          label: t("labels__edit")
        },
        {
          id: "delete_dependency_group",
          label: t("labels__delete")
        }
      ];
    } else {
      return [
        {
          id: "view_dependency_group",
          label: t("labels__view_details")
        }
      ];
    }
  }, [t, canEditServiceConfig]);

  const handleMenuItemClick = (action: string) => {
    switch (action) {
      case "edit_dependency_group":
      case "view_dependency_group":
        openEditDependencyGroup(data.id);
        break;
      case "delete_dependency_group":
        onDelete(data);
        break;
      default:
        break;
    }
  };

  return (
    <>
      <TableRow>
        <TableCell sx={{ paddingLeft: theme.spacing(1), borderBottomWidth: open ? 0 : 1 }}>
          <Box sx={{ display: "flex", alignItems: "center", justifyContent: "flex-start" }}>
            <IconButton
              aria-label="expand row"
              size="small"
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                setOpen(!open);
              }}
              sx={{ marginRight: theme.spacing(1) }}
            >
              {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
            <ParagraphBold
              sx={{
                color:
                  data.conflicts && data.conflicts.length > 0
                    ? theme.palette.error.main
                    : theme.palette.text.primary,
                fontSize: theme.typography.fontSize
              }}
            >
              {data.key}
            </ParagraphBold>
            {data.conflicts && data.conflicts.length > 0 && (
              <ErrorIcon color="error" sx={{ ml: theme.spacing(1) }} />
            )}
          </Box>
        </TableCell>
        <TableCell sx={{ borderBottomWidth: open ? 0 : 1 }}></TableCell>
        <TableCell sx={{ borderBottomWidth: open ? 0 : 1, textAlign: "right" }}>
          <Tooltip title={t("labels__action")}>
            <Box sx={{ marginRight: theme.spacing(2) }}>
              <ActionMenu
                items={menuItems}
                handleAction={(selection: string) => {
                  handleMenuItemClick(selection as string);
                }}
                menuPlacement="bottom-end"
              />
            </Box>
          </Tooltip>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell
          colSpan={3}
          sx={{ paddingLeft: theme.spacing(6.5), py: 0, borderBottomWidth: 0 }}
        >
          <Collapse in={open}>
            {data.conflicts &&
              data.conflicts.map((conflict, index) => (
                <Box
                  key={`service-config-conflict-${data.id}-${index}`}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "flex-start",
                    mb: theme.spacing(0.75)
                  }}
                >
                  <ParagraphSmall
                    sx={{
                      color: theme.palette.error.main,
                      fontSize: "0.75rem",
                      fontWeight: "bold",
                      pr: theme.spacing(2)
                    }}
                  >
                    {t("labels__settings_service_config_conflict_title_" + conflict.type)}
                  </ParagraphSmall>
                  <ParagraphSmall sx={{ fontSize: "0.75rem" }}>
                    {t(
                      "labels__settings_service_config_conflict_info_" + conflict.type,
                      toDependencyGroupTranslationProps(conflict, data)
                    )}
                  </ParagraphSmall>
                </Box>
              ))}
            <Box
              sx={{
                pb: theme.spacing(1.25)
              }}
            >
              <ParagraphSmall sx={{ color: theme.palette.text.secondary, fontSize: "0.75rem" }}>
                {t("labels__settings_dependency_group_includes_services")}
              </ParagraphSmall>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
      {data.services &&
        sortedServices(data.services).map((service, index) => (
          <TableRow key={`dependency-group-service-${data.id}-${service.serviceId}`}>
            <TableCell
              style={{
                paddingBottom: 0,
                paddingTop: 0,
                borderBottomWidth: open && index === data.services!.length - 1 ? 1 : 0
              }}
              sx={{ paddingLeft: theme.spacing(6.5) }}
            >
              <Collapse in={open} timeout="auto" unmountOnExit>
                <Box
                  sx={{
                    paddingTop: theme.spacing(index === 0 ? 0.75 : 1.25),
                    paddingBottom: theme.spacing(index === data.services!.length - 1 ? 2.25 : 1.25)
                  }}
                >
                  <span
                    style={{
                      color: containsConflictRelatedToServiceId(
                        data.conflicts ?? [],
                        service.serviceId
                      )
                        ? theme.palette.error.main
                        : theme.palette.text.primary
                    }}
                  >
                    {service.details?.displayName || service.serviceId}
                  </span>
                </Box>
              </Collapse>
            </TableCell>
            <TableCell
              style={{
                paddingBottom: 0,
                paddingTop: 0,
                borderBottomWidth: open && index === data.services!.length - 1 ? 1 : 0
              }}
              colSpan={2}
            >
              <Collapse in={open} timeout="auto" unmountOnExit>
                <Box
                  sx={{
                    paddingTop: theme.spacing(index === 0 ? 0.75 : 1.25),
                    paddingBottom: theme.spacing(index === data.services!.length - 1 ? 2.25 : 1.25)
                  }}
                >
                  {service.serviceId}
                </Box>
              </Collapse>
            </TableCell>
          </TableRow>
        ))}
    </>
  );
};

export default DependencyGroupsTableRow;
