import {
  Box,
  Dialog as MuiDialog,
  DialogActions,
  DialogContent,
  IconButton,
  useTheme
} from "@mui/material";
import { WithStyles } from "@mui/styles";
import createStyles from "@mui/styles/createStyles";
import { makeStyles } from "tss-react/mui";
import withStyles from "@mui/styles/withStyles";
import { Theme } from "@mui/material/styles";
import React, { FC, ReactNode } from "react";

import { Button, Heading4 } from "@likemagic-tech/sv-magic-library";
import CloseIcon from "@mui/icons-material/Close";

export interface DialogProps {
  open: boolean;
  onConfirm: () => void;
  onDismiss: () => void;
  content: ReactNode;
  buttonLabel: string;
  buttonDisabled?: boolean;
  title?: string;
  disableBackdropClick?: boolean;
  classNameActions?: string;
  classNameContent?: string;
  fullScreen?: boolean;
}

const useStyles = makeStyles()(({ spacing }: Theme) => ({
  content: {
    padding: spacing(2),
    fontSize: 17
  }
}));

const dialogTitleStyles = (theme: Theme) =>
  createStyles({
    root: {
      margin: 0,
      padding: theme.spacing(2)
    },
    closeButton: {
      color: theme.palette.grey[500]
    },
    heading: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center"
    }
  });

export interface DialogTitleProps extends WithStyles<typeof dialogTitleStyles> {
  children: ReactNode;
  onClose?: () => void;
}

const DialogTitle = withStyles(dialogTitleStyles)((props: DialogTitleProps) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <Box className={classes.heading}>
      <Heading4 className={classes.root} {...other}>
        {children}
      </Heading4>
      {onClose ? (
        <IconButton className={classes.closeButton} onClick={() => onClose()} size="large">
          <CloseIcon />
        </IconButton>
      ) : null}
    </Box>
  );
});

export const Dialog: FC<DialogProps> = ({
  onConfirm,
  onDismiss,
  open,
  content,
  buttonLabel,
  buttonDisabled,
  title,
  classNameContent,
  classNameActions,
  fullScreen
}) => {
  const { classes } = useStyles();
  const theme = useTheme();

  return (
    <MuiDialog
      onClose={onDismiss}
      open={open}
      maxWidth="md"
      fullScreen={fullScreen}
      PaperProps={{
        sx: {
          margin: fullScreen ? 0 : 2,
          borderRadius: fullScreen ? 0 : 2,
          paddingBottom: fullScreen ? 2 : 0,
          padding: theme.spacing(1)
        }
      }}
    >
      <DialogTitle onClose={onDismiss}>{title}</DialogTitle>
      <DialogContent className={`${classes.content} ${classNameContent}`}>{content}</DialogContent>
      <DialogActions className={classNameActions}>
        <Box
          sx={{
            display: "flex",
            flexGrow: 1,
            justifyContent: "center",
            alignItems: "center",
            py: 1
          }}
        >
          <Button variant="primary" onClick={onConfirm} fullWidth disabled={buttonDisabled}>
            {buttonLabel}
          </Button>
        </Box>
      </DialogActions>
    </MuiDialog>
  );
};
