import { FC, useCallback, useEffect, useMemo, useState } from "react";
import { Box, Grid2, useTheme } from "@mui/material";
import { useProperty } from "../../hooks/use-property";
import { UnitConditionFilter } from "./unit-condition-filter";
import { Unit, UnitCondition } from "../../domain/Unit";
import { UnitDashboardDTO } from "../../domain/UnitDashboardDTO";
import { UnitCard } from "./unit-card";
import PageTitle from "src/components/page-title";
import { useTranslationWrapper } from "src/hooks/use-translation-wrapper";
import { SearchField } from "../../components/search-field/search-field";
import { ReloadButton } from "../../components/reload-button";
import { useUnitsWithAvailableDoors } from "src/hooks/use-units-with-available-doors";
import { useUnits } from "src/hooks/use-unit-by-id";
import { UnitsActions } from "./units-actions";
import { UnitActionType } from "../dirty-units/dirty-units-actions";
import { MaintenanceModal } from "src/components/maintenance-modal/maintenance-modal";
import { useAddMaintenanceSlot } from "src/hooks/use-maintenance";
import { MaintenanceSlotArgs } from "src/store/endpoints/unit.endpoints";
import { useFetchUnitsToBeCleaned } from "src/hooks/use-fetch-units-to-be-cleaned";
import { useDispatch } from "src/store";
import { openBanner } from "src/slices/banner.slice";
import { useApiVersion } from "@likemagic-tech/sv-magic-library";
import { useIsMobile } from "../../hooks/use-is-mobile";

export const UnitsPage: FC = () => {
  const { t } = useTranslationWrapper();
  const theme = useTheme();
  const isMobile = useIsMobile();
  const [freeTextSearch, setFreeTextSearch] = useState<string>();
  const [maintenanceModalOpen, setMaintenanceModalOpen] = useState(false);
  const { selectedProperty, selectedPropertyPreview } = useProperty();
  const [addMaintenance, addResult] = useAddMaintenanceSlot();
  const { fetchAction } = useFetchUnitsToBeCleaned({
    propertyId: selectedProperty?.propertyId ?? ""
  });
  const { isRESTVersion } = useApiVersion();
  const dispatch = useDispatch();

  const [conditionFilter, setConditionFilter] = useState<UnitCondition | null>(null);
  const { fetchAction: reloadUnitsAction } = useUnits({
    propertyId: selectedProperty?.propertyId ?? "",
    refetchOnMountOrArgChange: true
  });

  useEffect(() => {
    if (addResult?.isSuccess) {
      dispatch(
        openBanner({
          type: "success",
          title: t("labels__action_successfully_performed")
        })
      );
      addResult.reset();
      fetchAction();
      setMaintenanceModalOpen(false);
    }
  }, [dispatch, t, addResult, fetchAction]);

  const reloadUnits = useCallback(async () => {
    if (!selectedProperty?.propertyId) {
      return {};
    }

    await reloadUnitsAction();
  }, [selectedProperty?.propertyId, reloadUnitsAction]);

  const units: UnitDashboardDTO[] | undefined = useUnitsWithAvailableDoors(
    selectedProperty?.propertyId ?? ""
  );

  const unitsWithFilter = useMemo(
    () =>
      (conditionFilter
        ? units?.filter((value) => value?.status?.condition === conditionFilter)
        : units
      )
        ?.filter((value) => !freeTextSearch || value.name.indexOf(freeTextSearch) > -1)
        .sort((a: Unit, b: Unit) => a.name.localeCompare(b.name)),
    [units, conditionFilter, freeTextSearch]
  );

  const countForUnitCondition = units?.reduce(
    (acc, unit) => {
      if (UnitCondition.CLEAN === unit.status.condition) {
        acc.Clean += 1;
      } else if (UnitCondition.CLEAN_TO_BE_INSPECTED === unit.status.condition) {
        acc.CleanToBeInspected += 1;
      } else if (UnitCondition.DIRTY === unit.status.condition) {
        acc.Dirty += 1;
      }
      return acc;
    },
    { Clean: 0, CleanToBeInspected: 0, Dirty: 0 }
  );

  const setConditionFilterWithDeselect = useCallback(
    (unitCondition: UnitCondition) => {
      if (unitCondition === conditionFilter) {
        setConditionFilter(null);
      } else {
        setConditionFilter(unitCondition);
      }
    },
    [conditionFilter]
  );

  const bulkAction = async (selectedItem: string) => {
    if (selectedItem === UnitActionType.MAINTENANCE) {
      setMaintenanceModalOpen(true);
    }
  };

  return (
    <>
      <Box
        sx={{
          backgroundColor: theme.palette.background.default,
          minHeight: "100%",
          padding: isMobile ? 2 : "unset"
        }}
      >
        <Grid2 container>
          <Grid2
            container
            direction="row"
            sx={{
              justifyContent: "space-between",
              alignItems: "end",
              width: "100%"
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "center",
                gap: 2,
                width: "100%"
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexWrap: "wrap",
                  flex: 1,
                  gap: 2,
                  justifyContent: "space-between",
                  alignItems: "center"
                }}
              >
                <PageTitle subTitle={selectedPropertyPreview} title={t("labels__units")} />
                {isRESTVersion && <UnitsActions onClick={bulkAction} />}
              </Box>
              {isRESTVersion && (
                <ReloadButton
                  onClick={() => {
                    reloadUnits();
                    reloadUnitsAction();
                  }}
                />
              )}
            </Box>
          </Grid2>
          <Grid2>
            <Grid2
              container
              spacing={3}
              sx={{
                alignItems: "center",
                pt: 1
              }}
            >
              <Grid2
                sx={{
                  minWidth: isMobile ? "unset" : "400px",
                  width: isMobile ? "100%" : "unset"
                }}
              >
                <SearchField
                  value={freeTextSearch ?? ""}
                  onChange={setFreeTextSearch}
                  placeholder={t("labels__units_search")}
                />
              </Grid2>
              <Grid2>
                <Grid2
                  container
                  sx={{
                    justifyContent: "space-between"
                  }}
                >
                  <Grid2>
                    <UnitConditionFilter
                      value={conditionFilter ?? undefined}
                      onConditionSelected={setConditionFilterWithDeselect}
                      countForUnitCondition={countForUnitCondition}
                    />
                  </Grid2>
                </Grid2>
              </Grid2>
            </Grid2>
          </Grid2>
          <Grid2
            container
            size={{ xs: 12 }}
            spacing={2}
            sx={{
              py: 3
            }}
          >
            {unitsWithFilter?.map((unit) => (
              <UnitCard
                key={`${unit.id}_${unit.name}`}
                unit={unit}
                propertyId={selectedProperty?.propertyId ?? ""}
                refreshUnitList={reloadUnits}
              />
            ))}
          </Grid2>
        </Grid2>
      </Box>
      {maintenanceModalOpen && (
        <MaintenanceModal
          onSubmit={(values: MaintenanceSlotArgs) => addMaintenance(values)}
          onClose={() => setMaintenanceModalOpen(false)}
        />
      )}
    </>
  );
};
