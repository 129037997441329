import React, { FC, useCallback, useMemo, useState } from "react";
import { Box, Dialog, DialogActions, DialogContent, DialogTitle, useTheme } from "@mui/material";
import { Button, Heading3, ParagraphSmall, useGlobalModal } from "@likemagic-tech/sv-magic-library";
import { useTranslationWrapper } from "../../../../hooks/use-translation-wrapper";
import { Form, Formik } from "formik";
import {
  ServiceConfigurationContainer,
  ServiceDependencyGroup
} from "../../../../graphql-tenantconfig/generated/graphql";
import { Params } from "../../../../hooks/use-select-id";
import { Notification } from "../../../../components/notification";
import { useCanEditServiceConfig } from "../use-can-edit-service-config";
import { useDependencyGroupModal } from "./use-dependency-group-modal";
import { useDependencyGroupFormValidations } from "./use-dependency-group-form-validations";
import { DependencyGroupForm } from "./dependency-group-form";
import { toDependencyGroupTranslationProps } from "../service-config-utils";

interface DependencyGroupModalProps {
  dependencyGroups: Array<ServiceDependencyGroup>;
  serviceConfiguration: ServiceConfigurationContainer;
  onSubmit: (dependencyGroup: ServiceDependencyGroup) => void;
  onDelete: (dependencyGroupId: string) => void;
}

export const DependencyGroupModal: FC<DependencyGroupModalProps> = ({
  dependencyGroups,
  serviceConfiguration,
  onSubmit,
  onDelete
}) => {
  const theme = useTheme();
  const { t } = useTranslationWrapper();
  const { canEditServiceConfig } = useCanEditServiceConfig();

  const { dependencyGroupFormValidation } = useDependencyGroupFormValidations();
  const { dependencyGroupId, onClose } = useDependencyGroupModal();

  const { open } = useGlobalModal();
  const [isConfirmDeleteModalOpen, setConfirmDeleteModalOpen] = useState(false);

  const isCreateNew = useMemo(() => {
    return dependencyGroupId === Params.CREATE_DEPENDENCY_GROUP_PARAM;
  }, [dependencyGroupId]);

  const dependencyGroup = useMemo(() => {
    return dependencyGroups.find((dependencyGroup) => dependencyGroup.id === dependencyGroupId);
  }, [dependencyGroups, dependencyGroupId]);

  const initalValues: ServiceDependencyGroup = useMemo(() => {
    return dependencyGroup
      ? { ...dependencyGroup }
      : ({ key: null, services: [] } as any as ServiceDependencyGroup);
  }, [dependencyGroup]);

  const onSubmitForm = useCallback(
    (values: ServiceDependencyGroup) => {
      onSubmit(values);
      onClose();
    },
    [onClose, onSubmit]
  );

  const handleWithConfirmDeleteModal = useCallback(() => {
    setConfirmDeleteModalOpen(true);
    return open({
      modalActions: [
        {
          variant: "primary",
          color: "error",
          result: true,
          label: t(
            "labels__settings_service_config_delete_dependency_group_confirmation_button_delete"
          )
        },
        {
          variant: "secondary",
          result: false,
          label: t("buttons__cancel")
        }
      ],
      modalProps: {
        title: t("labels__settings_service_config_delete_dependency_group_confirmation_title"),
        content: (
          <>
            <span>
              {t("labels__settings_service_config_delete_dependency_group_confirmation_msg1", {
                key: dependencyGroup?.key || ""
              })}
            </span>
            <span style={{ color: theme.palette.error.main }}>
              {t("labels__settings_service_config_delete_dependency_group_confirmation_msg2")}
            </span>
          </>
        )
      }
    });
  }, [t, open, dependencyGroup, theme.palette.error.main]);

  const onDeleteDependencyGroup = useCallback(async () => {
    const confirmDeleteResult = await handleWithConfirmDeleteModal();
    if (confirmDeleteResult) {
      onDelete(dependencyGroup!.id);
      onClose();
    }
    setConfirmDeleteModalOpen(false);
  }, [dependencyGroup, handleWithConfirmDeleteModal, onDelete, onClose]);

  const [showJson, setShowJson] = useState<boolean>(false);

  return (
    <Dialog
      open={!isConfirmDeleteModalOpen && !!dependencyGroupId && (!!dependencyGroup || isCreateNew)}
      onClose={onClose}
      maxWidth={"lg"}
      PaperProps={{
        sx: {
          display: "flex",
          width: "100%"
        }
      }}
    >
      <Formik
        initialValues={initalValues}
        onSubmit={onSubmitForm}
        validationSchema={dependencyGroupFormValidation}
        validateOnChange={!isCreateNew}
        validateOnMount={!isCreateNew}
      >
        {(formik) => {
          return (
            <>
              <DialogTitle sx={{ justifyContent: "space-between", display: "flex" }}>
                <Box sx={{ flexGrow: 1 }}>
                  <Box>
                    <Heading3>
                      {isCreateNew
                        ? t("labels__create_dependency_group")
                        : dependencyGroup?.key || ""}
                    </Heading3>
                  </Box>
                  <Box>
                    <ParagraphSmall>
                      {t("labels__dependency_group_modal_description")}
                    </ParagraphSmall>
                  </Box>
                </Box>
                <Box>
                  <Button
                    variant="ghost"
                    size="small"
                    color="info"
                    onClick={() => setShowJson(!showJson)}
                  >
                    {showJson
                      ? t("buttons__service_config_hide_json")
                      : t("buttons__service_config_show_json")}
                  </Button>
                </Box>
              </DialogTitle>
              <DialogContent>
                {showJson ? (
                  <pre style={{ fontSize: "0.75rem" }}>
                    {JSON.stringify(formik.values, null, 2)}
                  </pre>
                ) : (
                  <>
                    {dependencyGroup &&
                      dependencyGroup.conflicts &&
                      dependencyGroup.conflicts.map((conflict, index) => (
                        <Box
                          key={`dependency_group-modal-conflict-${dependencyGroup.id}-${index}`}
                          sx={{ mb: theme.spacing(2) }}
                        >
                          <Notification
                            title={t(
                              "labels__settings_service_config_conflict_info_" + conflict.type,
                              toDependencyGroupTranslationProps(conflict, dependencyGroup)
                            )}
                            type="error"
                          ></Notification>
                        </Box>
                      ))}
                    <Form noValidate id="ServiceConfigForm" style={{ width: "100%" }}>
                      <DependencyGroupForm
                        serviceConfiguration={serviceConfiguration}
                        isCreateNew={isCreateNew}
                        conflicts={dependencyGroup?.conflicts || []}
                      />
                    </Form>
                  </>
                )}
              </DialogContent>
              {!showJson && (
                <>
                  {canEditServiceConfig ? (
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        width: "100%",
                        px: 3,
                        py: 2
                      }}
                    >
                      <DialogActions sx={{ p: 0 }}>
                        {dependencyGroup && dependencyGroup.id && (
                          <Button
                            size="large"
                            variant="secondary"
                            color="error"
                            onClick={(event) => onDeleteDependencyGroup()}
                            fullWidth
                            sx={{ mt: theme.spacing(2), mr: 1 }}
                          >
                            {t("labels__delete")}
                          </Button>
                        )}
                      </DialogActions>
                      <DialogActions sx={{ p: 0 }}>
                        <Button
                          size="large"
                          variant="ghost"
                          onClick={(event) => onClose()}
                          fullWidth
                          sx={{ mt: theme.spacing(2), mr: 1 }}
                        >
                          {t("buttons__cancel")}
                        </Button>
                        <Button
                          type="submit"
                          variant="primary"
                          size="large"
                          fullWidth
                          sx={{ marginTop: theme.spacing(2) }}
                          onClick={() => formik.handleSubmit()}
                        >
                          {t("buttons__save")}
                        </Button>
                      </DialogActions>
                    </Box>
                  ) : (
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "flex-end",
                        width: "100%",
                        px: 3,
                        py: 2
                      }}
                    >
                      <DialogActions sx={{ p: 0 }}>
                        <Button
                          size="large"
                          variant="primary"
                          onClick={(event) => onClose()}
                          fullWidth
                          sx={{ mt: theme.spacing(2), mr: 1 }}
                        >
                          {t("buttons__close")}
                        </Button>
                      </DialogActions>
                    </Box>
                  )}
                </>
              )}
            </>
          );
        }}
      </Formik>
    </Dialog>
  );
};
