import { useTranslationWrapper } from "../../../../hooks/use-translation-wrapper";
import { useMemo } from "react";
import yup from "../../../../config/yup.config";
import { useGetServiceConfigurationQueryEnhanced } from "../../../../graphql-tenantconfig/queries/enhanced-queries/get-service-configuration-enhanced";
import { useProperty } from "../../../../hooks/use-property";

export const useDefaultServiceFormValidations = () => {
  const { t } = useTranslationWrapper();
  const { selectedProperty } = useProperty();
  const { data } = useGetServiceConfigurationQueryEnhanced(
    { pmsPropertyId: selectedProperty.propertyId },
    {}
  );

  const defaultServiceFormValidation = useMemo(() => {
    const validServiceIds = data?.GetServiceConfiguration?.pmsServices?.map((s) => s.pmsId) ?? [];
    const validUnitGroupIds =
      data?.GetServiceConfiguration?.pmsUnitGroups?.map((u) => u.pmsId) ?? [];

    return yup.object().shape({
      service: yup
        .object()
        .nullable()
        .required(t("labels__service_config_service_not_provided"))
        .shape({
          serviceId: yup
            .string()
            .required(t("labels__service_config_service_not_provided"))
            .oneOf(validServiceIds, t("labels__service_config_pms_service_unknown"))
        }),
      unitGroups: yup
        .array(
          yup.object().shape({
            unitGroupId: yup
              .string()
              .required(t("labels__service_config_unitgroups_not_provided"))
              .oneOf(validUnitGroupIds, t("labels__service_config_pms_unitgroup_unknown"))
          })
        )
        .required(t("labels__service_config_unitgroups_not_provided"))
        .min(1, t("labels__service_config_unitgroups_not_provided"))
    });
  }, [t, data]);

  return {
    defaultServiceFormValidation
  };
};
