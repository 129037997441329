import React, { FC, useCallback, useMemo, useState } from "react";
import { Formik } from "formik";
import { ManualCheckinStepRegistrationCardForm } from "./components";
import { RegistrationCardFormDataType } from "../../../../../domain/registration-card";
import { ManualCheckinStepProps } from "../manual-checkin-step-domain";
import { Grid2, IconButton, useTheme } from "@mui/material";
import { Heading4 } from "@likemagic-tech/sv-magic-library";
import {
  ShareToDevice,
  ShareToDeviceType
} from "../../../../../components/share-to-device/share-to-device";
import { openBase64InNewTab } from "../../../../../utils/file-utils";
import Download from "../../../../../icons/Download";
import { useTranslationWrapper } from "../../../../../hooks/use-translation-wrapper";
import { useLazyGetFileQuery } from "../../../../../store/guest-service-files.api";
import { useProperty } from "../../../../../hooks/use-property";
import { emptyFrequentFlyerProgram } from "./components/manual-checkin-frequent-flyer-form";
import { transformCommunicationChannel } from "src/graphql/transform/transform-registration-card";
import { useCheckinWizardFormValidations } from "../../../../../hooks/use-checkin-wizard-form-validation";
import { getShareHereSecondScreenId } from "../../../../../utils/second-screen";
import { useSecondScreen } from "../../../../../hooks/use-second-screen";

export const ManualCheckinStepRegistrationCard: FC<ManualCheckinStepProps> = ({
  reservationDetails
}) => {
  const { t } = useTranslationWrapper();
  const [getFile] = useLazyGetFileQuery();
  const { selectedProperty } = useProperty();
  const { manualCheckinValidation } = useCheckinWizardFormValidations();
  const [isConfirmedIndicatorShown, setIsConfirmedIndicatorShown] = useState(false);
  const [isFormDisabled, setIsFormDisabled] = useState(false);
  const theme = useTheme();
  const { secondScreenDevicesPerProperty } = useSecondScreen({
    propertyId: selectedProperty?.propertyId
  });

  const initData: RegistrationCardFormDataType = useMemo(
    () => ({
      mainGuest: {
        gender: reservationDetails?.primaryGuest.gender || null,
        firstName: reservationDetails?.primaryGuest.firstName || "",
        lastName: reservationDetails?.primaryGuest.lastName || "",
        phone: reservationDetails?.primaryGuest.phone || "",
        email: reservationDetails?.primaryGuest.email || "",
        addressLine1: reservationDetails?.primaryGuest.address.addressLine1 || "",
        addressLine2: reservationDetails?.primaryGuest.address.addressLine2 || "",
        city: reservationDetails?.primaryGuest.address.city || "",
        region: reservationDetails?.primaryGuest.address.region || "",
        postcode: reservationDetails?.primaryGuest.address.postalCode || "",
        country: reservationDetails?.primaryGuest.address.countryCode || "",
        licensePlate: reservationDetails?.primaryGuest.licensePlate || "",
        isSameAddress: true,
        frequentFlyerPrograms: reservationDetails?.primaryGuest.frequentFlyerPrograms?.length
          ? reservationDetails?.primaryGuest.frequentFlyerPrograms.map((program: any) => ({
              frequentFlyerProgram: program.membershipTypeCode,
              frequentFlyerNumber: program.membershipId,
              existing: true
            }))
          : [emptyFrequentFlyerProgram()],
        eClubMembershipNumber: reservationDetails?.primaryGuest.eClubMembershipNumber || "",
        id: reservationDetails?.primaryGuest?.id ?? "",
        emergencyEvacuationHelpNeeded:
          reservationDetails?.primaryGuest.emergencyEvacuationHelpNeeded || false,
        emergencyEvacuationHelpNotes:
          reservationDetails?.primaryGuest.emergencyEvacuationHelpNotes || "",
        enrolledInLoyaltyProgram:
          reservationDetails?.primaryGuest.enrolledInLoyaltyProgram || false,
        pmsProfileId: reservationDetails?.primaryGuest.id || "",
        preferredCommunicationChannel: transformCommunicationChannel(
          reservationDetails?.primaryGuest.preferredCommunicationChannel || ""
        ),
        identificationNumber: reservationDetails?.primaryGuest.identificationNumber || "",
        identificationType: reservationDetails?.primaryGuest.identificationType || "",
        nationalityCountryCode: reservationDetails?.primaryGuest.nationalityCountryCode || "",
        birthdate: reservationDetails?.primaryGuest.birthdate || ""
      },
      travelBuddies:
        reservationDetails?.travelBuddies.map((buddy) => ({
          gender: buddy.gender || null,
          firstName: buddy.firstName || "",
          lastName: buddy.lastName || "",
          phone: buddy.phone || "",
          email: buddy.email || "",
          id: buddy.id || "",
          birthdate: buddy.birthdate || "",
          nationalityCountryCode: buddy.nationalityCountryCode || "",
          addressLine1: buddy.address?.addressLine1 || "",
          postalCode: buddy.address?.postalCode || "",
          city: buddy.address?.city || "",
          countryCode: buddy.address?.countryCode || "",
          identificationNumber: buddy.identificationNumber || "",
          identificationType: buddy.identificationType || ""
        })) || [],
      maxCompanions: reservationDetails?.maxCompanions,
      reservationId: reservationDetails?.id ?? ""
    }),
    [
      reservationDetails?.primaryGuest.gender,
      reservationDetails?.primaryGuest.firstName,
      reservationDetails?.primaryGuest.lastName,
      reservationDetails?.primaryGuest.phone,
      reservationDetails?.primaryGuest.email,
      reservationDetails?.primaryGuest.address.addressLine1,
      reservationDetails?.primaryGuest.address.addressLine2,
      reservationDetails?.primaryGuest.address.city,
      reservationDetails?.primaryGuest.address.region,
      reservationDetails?.primaryGuest.address.postalCode,
      reservationDetails?.primaryGuest.address.countryCode,
      reservationDetails?.primaryGuest.licensePlate,
      reservationDetails?.primaryGuest.frequentFlyerPrograms,
      reservationDetails?.primaryGuest.eClubMembershipNumber,
      reservationDetails?.primaryGuest.id,
      reservationDetails?.primaryGuest.emergencyEvacuationHelpNeeded,
      reservationDetails?.primaryGuest.emergencyEvacuationHelpNotes,
      reservationDetails?.primaryGuest.enrolledInLoyaltyProgram,
      reservationDetails?.primaryGuest.preferredCommunicationChannel,
      reservationDetails?.primaryGuest.identificationNumber,
      reservationDetails?.primaryGuest.identificationType,
      reservationDetails?.primaryGuest.nationalityCountryCode,
      reservationDetails?.primaryGuest.birthdate,
      reservationDetails?.travelBuddies,
      reservationDetails?.maxCompanions,
      reservationDetails?.id
    ]
  );

  const onSelectDevice = useCallback(() => {
    setIsFormDisabled(true);
  }, []);

  const onStopSharing = useCallback(() => {
    setIsFormDisabled(false);
  }, []);

  const onConfirm = useCallback(() => {
    setIsFormDisabled(false);
    setIsConfirmedIndicatorShown(true);
  }, []);

  const resId = useMemo(() => reservationDetails?.id ?? "", [reservationDetails?.id]);

  if (!initData) {
    return <></>;
  }

  return (
    <>
      <Grid2
        container
        sx={{
          alignItems: "center"
        }}
      >
        <Heading4 m={2}>{t("labels__wizard_guests")}</Heading4>
        {(!!secondScreenDevicesPerProperty?.length || getShareHereSecondScreenId()) && (
          <ShareToDevice
            reservationId={resId}
            type={ShareToDeviceType.REGISTRATION_CARD}
            onSelectDevice={onSelectDevice}
            onStopSharing={onStopSharing}
            onConfirm={onConfirm}
            guestJourneyBaseURL={reservationDetails?.magicLink.split("/magic/")[0] ?? ""}
          />
        )}
        <IconButton
          disableRipple
          sx={{ px: 0, width: 24, height: 24, ml: "auto", mr: 2 }}
          onClick={async (e) => {
            e.stopPropagation();
            const data = await getFile({
              propertyId: selectedProperty?.propertyId ?? "",
              fileName: "REGISTRATION_CARD"
            }).unwrap();
            openBase64InNewTab(data?.data ?? "");
          }}
        >
          <Download sx={{ color: theme.palette.accent.main }} />
        </IconButton>
      </Grid2>
      <Formik
        initialValues={initData}
        onSubmit={() => {}}
        validationSchema={manualCheckinValidation}
        validateOnMount
        enableReinitialize
      >
        <ManualCheckinStepRegistrationCardForm
          disabled={isFormDisabled}
          isConfirmedIndicatorShown={isConfirmedIndicatorShown}
          setIsConfirmedIndicatorShown={setIsConfirmedIndicatorShown}
          reservation={reservationDetails}
        />
      </Formik>
    </>
  );
};
