import { Box, Grid2, Paper } from "@mui/material";
import { FC, useState } from "react";
import { useTheme } from "@mui/material/styles";
import AddIcon from "@mui/icons-material/Add";
import {
  Button,
  EmptyState,
  EmptyStateSize,
  ParagraphBold,
  ParagraphSmall
} from "@likemagic-tech/sv-magic-library";
import { useTranslationWrapper } from "src/hooks/use-translation-wrapper";
import { EditReservationCommentModal } from "./edit-reservation-comment/edit-reservation-comment-modal";
import { Comments } from "../../../components/card-comments/card-comments";
import { numberOfComments } from "../overview/reservation-details-overview";
import { ReservationDetailsDTO } from "../../../../../store/endpoints/reservation-table.endpoints";

interface ReservationDetailsCommentsProps {
  reservation: ReservationDetailsDTO;
  isChangeCommentAllowed: boolean;
}

const ReservationDetailsComments: FC<ReservationDetailsCommentsProps> = ({
  reservation,
  isChangeCommentAllowed
}) => {
  const theme = useTheme();
  const comments = reservation.notes;

  const { t } = useTranslationWrapper();
  const [openModal, setOpenModal] = useState(false);
  const numberComments = numberOfComments(comments);

  const commentsToShowObject = [
    {
      comment: comments["guestComment"].join("\r\n"),
      label: "labels__notes__guest__comments"
    },
    {
      comment: comments["bookerComment"].join("\r\n"),
      label: "labels__notes__booker__comment"
    },
    {
      comment: comments["comment"].join("\r\n"),
      label: "labels__notes__reservation__comment"
    },
    {
      comment: comments["bookingComment"].join("\r\n"),
      label: "labels__notes__booking__comment"
    }
  ];

  const handleOpen = () => {
    setOpenModal(true);
    window.scrollTo(0, document.body.scrollHeight);
  };

  const handleClose = () => {
    setOpenModal(false);
  };

  return (
    <>
      <Grid2
        container
        rowSpacing={theme.spacing(2)}
        direction="column"
        sx={{
          py: isChangeCommentAllowed ? 2 : 1.5
        }}
      >
        {isChangeCommentAllowed && (
          <Grid2
            size={{ xs: 12 }}
            sx={{
              display: "flex",
              justifyContent: "end"
            }}
          >
            <Button variant="primary" onClick={handleOpen} size="medium">
              <Grid2
                direction="row"
                sx={{
                  display: "flex",
                  alignItems: "center"
                }}
              >
                <AddIcon
                  fontSize="small"
                  sx={{
                    color: theme.palette.background.paper,
                    marginRight: theme.spacing(2)
                  }}
                />

                {t("labels__tabs__comments__add")}
              </Grid2>
            </Button>
          </Grid2>
        )}
        <EditReservationCommentModal
          reservation={reservation}
          isOpen={openModal}
          onClose={handleClose}
        />
        {numberComments > 0 && (
          <Grid2>
            {commentsToShowObject.map(
              (comment: Comments) =>
                comment.comment && (
                  <Grid2
                    size={{ xs: 12 }}
                    key={comment.label}
                    sx={{
                      paddingBottom: 1
                    }}
                  >
                    <Paper
                      sx={{
                        background: theme.palette.background.paper,
                        borderColor: theme.palette.background.paper
                      }}
                      variant="outlined"
                      elevation={0}
                    >
                      <Grid2
                        container
                        direction="column"
                        sx={{
                          p: 2
                        }}
                      >
                        <Grid2>
                          <ParagraphBold>{t(comment.label)}</ParagraphBold>
                        </Grid2>
                        <Grid2
                          sx={{
                            marginTop: 1
                          }}
                        >
                          <ParagraphSmall sx={{ whiteSpace: "pre-wrap" }}>
                            {comment.comment}
                          </ParagraphSmall>
                        </Grid2>
                      </Grid2>
                    </Paper>
                  </Grid2>
                )
            )}
          </Grid2>
        )}
      </Grid2>
      {numberComments === 0 && (
        <Box sx={{ mt: isChangeCommentAllowed ? -10 : 0 }}>
          <EmptyState
            title={t("labels__no__comments__in__reservation")}
            size={EmptyStateSize.LARGE}
          />
        </Box>
      )}
    </>
  );
};

export default ReservationDetailsComments;
