import * as Types from "../generated/graphql";

import { ServiceConfigFragmentDoc } from "../fragments/ServiceConfig.generated";
import { ServiceIdentifierFragmentDoc } from "../fragments/ServiceIdentifier.generated";
import { ServiceContractFragmentDoc } from "../fragments/ServiceContract.generated";
import { UnitGroupIdentifierFragmentDoc } from "../fragments/UnitGroupIdentifier.generated";
import { UnitGroupContractFragmentDoc } from "../fragments/UnitGroupContract.generated";
import { TaskConfigFragmentDoc } from "../fragments/TaskConfig.generated";
import { ServiceConfigConflictFragmentDoc } from "../fragments/ServiceConfigConflict.generated";
import { DateRangeFragmentDoc } from "../fragments/DateRange.generated";
import { DefaultServiceConfigFragmentDoc } from "../fragments/DefaultServiceConfig.generated";
import { ServiceDependencyGroupFragmentDoc } from "../fragments/ServiceDependencyGroup.generated";
import { tenantconfigApi } from "../tenantconfig.api";
export type MergeServiceConfigurationMutationVariables = Types.Exact<{
  pmsPropertyId: Types.Scalars["String"]["input"];
  serviceConfiguration: Types.ServiceConfigurationInputContainer;
}>;

export type MergeServiceConfigurationMutation = {
  __typename?: "Mutation";
  MergeServiceConfiguration: {
    __typename?: "ServiceConfigurationContainer";
    supportsDefaultServices: boolean;
    fixedFreeCleaningServiceId?: string | null;
    serviceConfigs?: Array<
      | {
          __typename?: "AddOnServiceConfig";
          minimumBefore?: string | null;
          maximumBefore?: string | null;
          maximum?: number | null;
          bookableDays?: Array<Types.BookableDay> | null;
          bookingChannels?: Array<string> | null;
          reservationStatuses?: Array<Types.ReservationStatus> | null;
          visibilities?: Array<Types.Visibility> | null;
          payAtCheckout?: boolean | null;
          addOnType: Types.AddOnType;
          canBookMultiple?: boolean | null;
          bookableOnDepartureDay?: boolean | null;
          bookOnExtendStay?: boolean | null;
          availableAfterMidnight?: string | null;
          granularity?: Types.ServiceConfigGranularity | null;
          defaultTargetAudience?: Types.DefaultTargetAudience | null;
          tags?: Array<Types.ServiceTag> | null;
          defaultDatePickerSelection?: Types.DefaultDatePickerSelection | null;
          postOnNextDay?: boolean | null;
          id: string;
          serviceConfigHash: number;
          type: Types.ServiceConfigType;
          whitelistedDateRanges?: Array<{
            __typename?: "DateRange";
            startInclusive: any;
            endInclusive: any;
          }> | null;
          blacklistedDateRanges?: Array<{
            __typename?: "DateRange";
            startInclusive: any;
            endInclusive: any;
          }> | null;
          service: {
            __typename?: "ServiceIdentifier";
            serviceId: string;
            details?: {
              __typename?: "ServiceContract";
              active?: boolean | null;
              chargeMode?: Types.ChargeMode | null;
              chargeUnit?: Types.ChargeUnit | null;
              description?: string | null;
              displayName?: string | null;
              id?: string | null;
              included?: boolean | null;
              name?: string | null;
              pmsCategoryId?: string | null;
              pmsId: string;
              pmsUpdatedAt?: any | null;
              price?: {
                __typename?: "Price";
                currency?: string | null;
                grossPrice?: any | null;
                netPrice?: any | null;
                taxes?: Array<{
                  __typename?: "Tax";
                  amount?: any | null;
                  code?: string | null;
                }> | null;
              } | null;
            } | null;
          };
          unitGroups?: Array<{
            __typename?: "UnitGroupIdentifier";
            unitGroupId: string;
            details?: {
              __typename?: "UnitGroupContract";
              active?: boolean | null;
              capacity?: number | null;
              extraCapacity?: number | null;
              id?: string | null;
              name?: string | null;
              pmsId: string;
              pmsPropertyId?: string | null;
              pmsUpdatedAt?: any | null;
              type?: string | null;
            } | null;
          }> | null;
          task?: {
            __typename?: "TaskConfig";
            housekeepingMainTask?: boolean | null;
            assigneeGroup?: string | null;
            createMultipleTasks?: boolean | null;
            dueDuration?: string | null;
            dueDurationType?: Types.DueDurationType | null;
            granularity?: Types.TaskGranularity | null;
            priority?: Types.Priority | null;
          } | null;
          conflicts?: Array<{
            __typename?: "ServiceConfigConflict";
            type: Types.ConflictType;
            relatedServiceId?: string | null;
            relatedUnitGroupId?: string | null;
            relatedFieldName?: string | null;
          }> | null;
        }
      | {
          __typename?: "AdditionalCleaningServiceConfig";
          minimumBefore?: string | null;
          maximumBefore?: string | null;
          maximum?: number | null;
          bookableDays?: Array<Types.BookableDay> | null;
          bookingChannels?: Array<string> | null;
          reservationStatuses?: Array<Types.ReservationStatus> | null;
          visibilities?: Array<Types.Visibility> | null;
          payAtCheckout?: boolean | null;
          granularity?: Types.ServiceConfigGranularity | null;
          defaultDatePickerSelection?: Types.DefaultDatePickerSelection | null;
          id: string;
          serviceConfigHash: number;
          type: Types.ServiceConfigType;
          whitelistedDateRanges?: Array<{
            __typename?: "DateRange";
            startInclusive: any;
            endInclusive: any;
          }> | null;
          blacklistedDateRanges?: Array<{
            __typename?: "DateRange";
            startInclusive: any;
            endInclusive: any;
          }> | null;
          service: {
            __typename?: "ServiceIdentifier";
            serviceId: string;
            details?: {
              __typename?: "ServiceContract";
              active?: boolean | null;
              chargeMode?: Types.ChargeMode | null;
              chargeUnit?: Types.ChargeUnit | null;
              description?: string | null;
              displayName?: string | null;
              id?: string | null;
              included?: boolean | null;
              name?: string | null;
              pmsCategoryId?: string | null;
              pmsId: string;
              pmsUpdatedAt?: any | null;
              price?: {
                __typename?: "Price";
                currency?: string | null;
                grossPrice?: any | null;
                netPrice?: any | null;
                taxes?: Array<{
                  __typename?: "Tax";
                  amount?: any | null;
                  code?: string | null;
                }> | null;
              } | null;
            } | null;
          };
          unitGroups?: Array<{
            __typename?: "UnitGroupIdentifier";
            unitGroupId: string;
            details?: {
              __typename?: "UnitGroupContract";
              active?: boolean | null;
              capacity?: number | null;
              extraCapacity?: number | null;
              id?: string | null;
              name?: string | null;
              pmsId: string;
              pmsPropertyId?: string | null;
              pmsUpdatedAt?: any | null;
              type?: string | null;
            } | null;
          }> | null;
          task?: {
            __typename?: "TaskConfig";
            housekeepingMainTask?: boolean | null;
            assigneeGroup?: string | null;
            createMultipleTasks?: boolean | null;
            dueDuration?: string | null;
            dueDurationType?: Types.DueDurationType | null;
            granularity?: Types.TaskGranularity | null;
            priority?: Types.Priority | null;
          } | null;
          conflicts?: Array<{
            __typename?: "ServiceConfigConflict";
            type: Types.ConflictType;
            relatedServiceId?: string | null;
            relatedUnitGroupId?: string | null;
            relatedFieldName?: string | null;
          }> | null;
        }
      | {
          __typename?: "EarlyCheckinServiceConfig";
          minimumBefore?: string | null;
          maximumBefore?: string | null;
          maximum?: number | null;
          bookableDays?: Array<Types.BookableDay> | null;
          bookingChannels?: Array<string> | null;
          reservationStatuses?: Array<Types.ReservationStatus> | null;
          visibilities?: Array<Types.Visibility> | null;
          payAtCheckout?: boolean | null;
          minimumCheckinTime?: any | null;
          id: string;
          serviceConfigHash: number;
          type: Types.ServiceConfigType;
          whitelistedDateRanges?: Array<{
            __typename?: "DateRange";
            startInclusive: any;
            endInclusive: any;
          }> | null;
          blacklistedDateRanges?: Array<{
            __typename?: "DateRange";
            startInclusive: any;
            endInclusive: any;
          }> | null;
          service: {
            __typename?: "ServiceIdentifier";
            serviceId: string;
            details?: {
              __typename?: "ServiceContract";
              active?: boolean | null;
              chargeMode?: Types.ChargeMode | null;
              chargeUnit?: Types.ChargeUnit | null;
              description?: string | null;
              displayName?: string | null;
              id?: string | null;
              included?: boolean | null;
              name?: string | null;
              pmsCategoryId?: string | null;
              pmsId: string;
              pmsUpdatedAt?: any | null;
              price?: {
                __typename?: "Price";
                currency?: string | null;
                grossPrice?: any | null;
                netPrice?: any | null;
                taxes?: Array<{
                  __typename?: "Tax";
                  amount?: any | null;
                  code?: string | null;
                }> | null;
              } | null;
            } | null;
          };
          unitGroups?: Array<{
            __typename?: "UnitGroupIdentifier";
            unitGroupId: string;
            details?: {
              __typename?: "UnitGroupContract";
              active?: boolean | null;
              capacity?: number | null;
              extraCapacity?: number | null;
              id?: string | null;
              name?: string | null;
              pmsId: string;
              pmsPropertyId?: string | null;
              pmsUpdatedAt?: any | null;
              type?: string | null;
            } | null;
          }> | null;
          task?: {
            __typename?: "TaskConfig";
            housekeepingMainTask?: boolean | null;
            assigneeGroup?: string | null;
            createMultipleTasks?: boolean | null;
            dueDuration?: string | null;
            dueDurationType?: Types.DueDurationType | null;
            granularity?: Types.TaskGranularity | null;
            priority?: Types.Priority | null;
          } | null;
          conflicts?: Array<{
            __typename?: "ServiceConfigConflict";
            type: Types.ConflictType;
            relatedServiceId?: string | null;
            relatedUnitGroupId?: string | null;
            relatedFieldName?: string | null;
          }> | null;
        }
      | {
          __typename?: "FreeCleaningServiceConfig";
          minimumNights?: number | null;
          interval?: any | null;
          weekendRule?: Types.WeekendRule | null;
          distributionRule?: Types.DistributionRule | null;
          id: string;
          serviceConfigHash: number;
          type: Types.ServiceConfigType;
          service: {
            __typename?: "ServiceIdentifier";
            serviceId: string;
            details?: {
              __typename?: "ServiceContract";
              active?: boolean | null;
              chargeMode?: Types.ChargeMode | null;
              chargeUnit?: Types.ChargeUnit | null;
              description?: string | null;
              displayName?: string | null;
              id?: string | null;
              included?: boolean | null;
              name?: string | null;
              pmsCategoryId?: string | null;
              pmsId: string;
              pmsUpdatedAt?: any | null;
              price?: {
                __typename?: "Price";
                currency?: string | null;
                grossPrice?: any | null;
                netPrice?: any | null;
                taxes?: Array<{
                  __typename?: "Tax";
                  amount?: any | null;
                  code?: string | null;
                }> | null;
              } | null;
            } | null;
          };
          unitGroups?: Array<{
            __typename?: "UnitGroupIdentifier";
            unitGroupId: string;
            details?: {
              __typename?: "UnitGroupContract";
              active?: boolean | null;
              capacity?: number | null;
              extraCapacity?: number | null;
              id?: string | null;
              name?: string | null;
              pmsId: string;
              pmsPropertyId?: string | null;
              pmsUpdatedAt?: any | null;
              type?: string | null;
            } | null;
          }> | null;
          task?: {
            __typename?: "TaskConfig";
            housekeepingMainTask?: boolean | null;
            assigneeGroup?: string | null;
            createMultipleTasks?: boolean | null;
            dueDuration?: string | null;
            dueDurationType?: Types.DueDurationType | null;
            granularity?: Types.TaskGranularity | null;
            priority?: Types.Priority | null;
          } | null;
          conflicts?: Array<{
            __typename?: "ServiceConfigConflict";
            type: Types.ConflictType;
            relatedServiceId?: string | null;
            relatedUnitGroupId?: string | null;
            relatedFieldName?: string | null;
          }> | null;
        }
      | {
          __typename?: "LateCheckoutServiceConfig";
          minimumBefore?: string | null;
          maximumBefore?: string | null;
          maximum?: number | null;
          bookableDays?: Array<Types.BookableDay> | null;
          bookingChannels?: Array<string> | null;
          reservationStatuses?: Array<Types.ReservationStatus> | null;
          visibilities?: Array<Types.Visibility> | null;
          payAtCheckout?: boolean | null;
          maximumCheckoutTime?: any | null;
          id: string;
          serviceConfigHash: number;
          type: Types.ServiceConfigType;
          whitelistedDateRanges?: Array<{
            __typename?: "DateRange";
            startInclusive: any;
            endInclusive: any;
          }> | null;
          blacklistedDateRanges?: Array<{
            __typename?: "DateRange";
            startInclusive: any;
            endInclusive: any;
          }> | null;
          service: {
            __typename?: "ServiceIdentifier";
            serviceId: string;
            details?: {
              __typename?: "ServiceContract";
              active?: boolean | null;
              chargeMode?: Types.ChargeMode | null;
              chargeUnit?: Types.ChargeUnit | null;
              description?: string | null;
              displayName?: string | null;
              id?: string | null;
              included?: boolean | null;
              name?: string | null;
              pmsCategoryId?: string | null;
              pmsId: string;
              pmsUpdatedAt?: any | null;
              price?: {
                __typename?: "Price";
                currency?: string | null;
                grossPrice?: any | null;
                netPrice?: any | null;
                taxes?: Array<{
                  __typename?: "Tax";
                  amount?: any | null;
                  code?: string | null;
                }> | null;
              } | null;
            } | null;
          };
          unitGroups?: Array<{
            __typename?: "UnitGroupIdentifier";
            unitGroupId: string;
            details?: {
              __typename?: "UnitGroupContract";
              active?: boolean | null;
              capacity?: number | null;
              extraCapacity?: number | null;
              id?: string | null;
              name?: string | null;
              pmsId: string;
              pmsPropertyId?: string | null;
              pmsUpdatedAt?: any | null;
              type?: string | null;
            } | null;
          }> | null;
          task?: {
            __typename?: "TaskConfig";
            housekeepingMainTask?: boolean | null;
            assigneeGroup?: string | null;
            createMultipleTasks?: boolean | null;
            dueDuration?: string | null;
            dueDurationType?: Types.DueDurationType | null;
            granularity?: Types.TaskGranularity | null;
            priority?: Types.Priority | null;
          } | null;
          conflicts?: Array<{
            __typename?: "ServiceConfigConflict";
            type: Types.ConflictType;
            relatedServiceId?: string | null;
            relatedUnitGroupId?: string | null;
            relatedFieldName?: string | null;
          }> | null;
        }
    > | null;
    defaultServices?: Array<{
      __typename?: "DefaultServiceConfig";
      id: string;
      service: {
        __typename?: "ServiceIdentifier";
        serviceId: string;
        details?: {
          __typename?: "ServiceContract";
          active?: boolean | null;
          chargeMode?: Types.ChargeMode | null;
          chargeUnit?: Types.ChargeUnit | null;
          description?: string | null;
          displayName?: string | null;
          id?: string | null;
          included?: boolean | null;
          name?: string | null;
          pmsCategoryId?: string | null;
          pmsId: string;
          pmsUpdatedAt?: any | null;
          price?: {
            __typename?: "Price";
            currency?: string | null;
            grossPrice?: any | null;
            netPrice?: any | null;
            taxes?: Array<{ __typename?: "Tax"; amount?: any | null; code?: string | null }> | null;
          } | null;
        } | null;
      };
      unitGroups?: Array<{
        __typename?: "UnitGroupIdentifier";
        unitGroupId: string;
        details?: {
          __typename?: "UnitGroupContract";
          active?: boolean | null;
          capacity?: number | null;
          extraCapacity?: number | null;
          id?: string | null;
          name?: string | null;
          pmsId: string;
          pmsPropertyId?: string | null;
          pmsUpdatedAt?: any | null;
          type?: string | null;
        } | null;
      }> | null;
      conflicts?: Array<{
        __typename?: "ServiceConfigConflict";
        type: Types.ConflictType;
        relatedServiceId?: string | null;
        relatedUnitGroupId?: string | null;
        relatedFieldName?: string | null;
      }> | null;
    }> | null;
    dependencyGroups?: Array<{
      __typename?: "ServiceDependencyGroup";
      id: string;
      key: string;
      services?: Array<{
        __typename?: "ServiceIdentifier";
        serviceId: string;
        details?: {
          __typename?: "ServiceContract";
          active?: boolean | null;
          chargeMode?: Types.ChargeMode | null;
          chargeUnit?: Types.ChargeUnit | null;
          description?: string | null;
          displayName?: string | null;
          id?: string | null;
          included?: boolean | null;
          name?: string | null;
          pmsCategoryId?: string | null;
          pmsId: string;
          pmsUpdatedAt?: any | null;
          price?: {
            __typename?: "Price";
            currency?: string | null;
            grossPrice?: any | null;
            netPrice?: any | null;
            taxes?: Array<{ __typename?: "Tax"; amount?: any | null; code?: string | null }> | null;
          } | null;
        } | null;
      }> | null;
      conflicts?: Array<{
        __typename?: "ServiceConfigConflict";
        type: Types.ConflictType;
        relatedServiceId?: string | null;
        relatedUnitGroupId?: string | null;
        relatedFieldName?: string | null;
      }> | null;
    }> | null;
    conflicts?: Array<{
      __typename?: "ServiceConfigConflict";
      type: Types.ConflictType;
      relatedServiceId?: string | null;
      relatedUnitGroupId?: string | null;
      relatedFieldName?: string | null;
    }> | null;
    pmsServices?: Array<{
      __typename?: "ServiceContract";
      active?: boolean | null;
      chargeMode?: Types.ChargeMode | null;
      chargeUnit?: Types.ChargeUnit | null;
      description?: string | null;
      displayName?: string | null;
      id?: string | null;
      included?: boolean | null;
      name?: string | null;
      pmsCategoryId?: string | null;
      pmsId: string;
      pmsUpdatedAt?: any | null;
      price?: {
        __typename?: "Price";
        currency?: string | null;
        grossPrice?: any | null;
        netPrice?: any | null;
        taxes?: Array<{ __typename?: "Tax"; amount?: any | null; code?: string | null }> | null;
      } | null;
    }> | null;
    pmsUnitGroups?: Array<{
      __typename?: "UnitGroupContract";
      active?: boolean | null;
      capacity?: number | null;
      extraCapacity?: number | null;
      id?: string | null;
      name?: string | null;
      pmsId: string;
      pmsPropertyId?: string | null;
      pmsUpdatedAt?: any | null;
      type?: string | null;
    }> | null;
  };
};

export const MergeServiceConfigurationDocument = `
    mutation MergeServiceConfiguration($pmsPropertyId: String!, $serviceConfiguration: ServiceConfigurationInputContainer!) {
  MergeServiceConfiguration(
    pmsPropertyId: $pmsPropertyId
    serviceConfiguration: $serviceConfiguration
  ) {
    serviceConfigs {
      ...ServiceConfig
    }
    defaultServices {
      ...DefaultServiceConfig
    }
    dependencyGroups {
      ...ServiceDependencyGroup
    }
    conflicts {
      ...ServiceConfigConflict
    }
    pmsServices {
      ...ServiceContract
    }
    pmsUnitGroups {
      ...UnitGroupContract
    }
    supportsDefaultServices
    fixedFreeCleaningServiceId
  }
}
    ${ServiceConfigFragmentDoc}
${ServiceIdentifierFragmentDoc}
${ServiceContractFragmentDoc}
${UnitGroupIdentifierFragmentDoc}
${UnitGroupContractFragmentDoc}
${TaskConfigFragmentDoc}
${ServiceConfigConflictFragmentDoc}
${DateRangeFragmentDoc}
${DefaultServiceConfigFragmentDoc}
${ServiceDependencyGroupFragmentDoc}`;

const injectedRtkApi = tenantconfigApi.injectEndpoints({
  endpoints: (build) => ({
    MergeServiceConfiguration: build.mutation<
      MergeServiceConfigurationMutation,
      MergeServiceConfigurationMutationVariables
    >({
      query: (variables) => ({ document: MergeServiceConfigurationDocument, variables })
    })
  })
});

export { injectedRtkApi as api };
export const { useMergeServiceConfigurationMutation } = injectedRtkApi;
