import React, { FC, useMemo, useState } from "react";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import { useTheme } from "@mui/material/styles";
import { Box, Collapse, IconButton, Tooltip } from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { useTranslationWrapper } from "../../../hooks/use-translation-wrapper";
import {
  containsConflictRelatedToUnitGroupId,
  LIKE_MAGIC_FREE_CLEANING_ID,
  price,
  sortedUnitGroups,
  toServiceConfigTranslationProps,
  vat
} from "./service-config-utils";
import { useServiceConfigModal } from "./service-config-modal/use-service-config-modal";
import { ActionMenu } from "../../../components/action-menu/action-menu";
import { ServiceConfig } from "../../../graphql-tenantconfig/generated/graphql";
import ErrorIcon from "@mui/icons-material/Error";
import { ParagraphBold, ParagraphSmall } from "@likemagic-tech/sv-magic-library";
import { useCanEditServiceConfig } from "./use-can-edit-service-config";

interface AdditionalServicesTableRowProps {
  data: ServiceConfig;
  onDelete: (serviceConfig: ServiceConfig) => void;
}

const AdditionalServicesTableRow: FC<AdditionalServicesTableRowProps> = ({ data, onDelete }) => {
  const theme = useTheme();
  const { t } = useTranslationWrapper();
  const { canEditServiceConfig } = useCanEditServiceConfig();

  const [open, setOpen] = useState(false);

  const { openEditServiceConfig } = useServiceConfigModal();

  const menuItems = useMemo(() => {
    if (canEditServiceConfig) {
      return [
        {
          id: "edit_service_config",
          label: t("labels__edit")
        },
        {
          id: "delete_service_config",
          label: t("labels__delete")
        }
      ];
    } else {
      return [
        {
          id: "view_service_config",
          label: t("labels__view_details")
        }
      ];
    }
  }, [t, canEditServiceConfig]);

  const handleMenuItemClick = (action: string) => {
    switch (action) {
      case "edit_service_config":
      case "view_service_config":
        openEditServiceConfig(data.id);
        break;
      case "delete_service_config":
        onDelete(data);
        break;
      default:
        break;
    }
  };

  return (
    <>
      <TableRow>
        <TableCell sx={{ paddingLeft: theme.spacing(1), borderBottomWidth: open ? 0 : 1 }}>
          <Box sx={{ display: "flex", alignItems: "center", justifyContent: "flex-start" }}>
            <IconButton
              aria-label="expand row"
              size="small"
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                setOpen(!open);
              }}
              sx={{ marginRight: theme.spacing(1) }}
            >
              {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
            <ParagraphBold
              sx={{
                color:
                  data.conflicts && data.conflicts.length > 0
                    ? theme.palette.error.main
                    : theme.palette.text.primary,
                fontSize: theme.typography.fontSize
              }}
            >
              {LIKE_MAGIC_FREE_CLEANING_ID === data.service.serviceId
                ? t("labels__service_config_likemagic_free_cleaning")
                : data.service.details?.displayName || data.service.serviceId}
            </ParagraphBold>
            {data.conflicts && data.conflicts.length > 0 && (
              <ErrorIcon color="error" sx={{ ml: theme.spacing(1) }} />
            )}
          </Box>
        </TableCell>
        <TableCell sx={{ borderBottomWidth: open ? 0 : 1 }}>
          {data.service.details?.pmsId || data.service.serviceId}
        </TableCell>
        <TableCell sx={{ borderBottomWidth: open ? 0 : 1 }}>
          {data.service.details?.price && price(data.service.details?.price)}
        </TableCell>
        <TableCell sx={{ borderBottomWidth: open ? 0 : 1 }}>
          {data.service.details?.price && vat(data.service.details?.price)}
        </TableCell>
        <TableCell sx={{ borderBottomWidth: open ? 0 : 1 }}>
          {data.service.details?.chargeMode && (
            <>
              {t("labels__settings_service_config_charge_mode_" + data.service.details?.chargeMode)}
            </>
          )}
        </TableCell>
        <TableCell sx={{ borderBottomWidth: open ? 0 : 1 }}>
          {data.service.details?.chargeUnit && (
            <>
              {t("labels__settings_service_config_charge_unit_" + data.service.details?.chargeUnit)}
            </>
          )}
        </TableCell>
        <TableCell sx={{ borderBottomWidth: open ? 0 : 1 }}>
          {data.type && <>{t("labels__settings_service_config_type_" + data.type)}</>}
        </TableCell>
        <TableCell sx={{ borderBottomWidth: open ? 0 : 1, textAlign: "right" }}>
          <Tooltip title={t("labels__action")}>
            <Box sx={{ marginRight: theme.spacing(2) }}>
              <ActionMenu
                items={menuItems}
                handleAction={(selection: string) => {
                  handleMenuItemClick(selection as string);
                }}
                menuPlacement="bottom-end"
              />
            </Box>
          </Tooltip>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell
          colSpan={8}
          sx={{ paddingLeft: theme.spacing(6.5), py: 0, borderBottomWidth: 0 }}
        >
          <Collapse in={open}>
            {data.conflicts &&
              data.conflicts.map((conflict, index) => (
                <Box
                  key={`service-config-conflict-${data.id}-${index}`}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "flex-start",
                    mb: theme.spacing(0.75)
                  }}
                >
                  <ParagraphSmall
                    sx={{
                      color: theme.palette.error.main,
                      fontSize: "0.75rem",
                      fontWeight: "bold",
                      pr: theme.spacing(2)
                    }}
                  >
                    {t("labels__settings_service_config_conflict_title_" + conflict.type)}
                  </ParagraphSmall>
                  <ParagraphSmall sx={{ fontSize: "0.75rem" }}>
                    {t(
                      "labels__settings_service_config_conflict_info_" + conflict.type,
                      toServiceConfigTranslationProps(conflict, data)
                    )}
                  </ParagraphSmall>
                </Box>
              ))}
            <Box
              sx={{
                pb: theme.spacing(1.25)
              }}
            >
              <ParagraphSmall sx={{ color: theme.palette.text.secondary, fontSize: "0.75rem" }}>
                {t("labels__settings_service_config_assigned_to_unitgroups")}
              </ParagraphSmall>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
      {data.unitGroups &&
        sortedUnitGroups(data.unitGroups).map((unitGroup, index) => (
          <TableRow key={`service-config-unitgroup-${data.id}-${unitGroup.unitGroupId}`}>
            <TableCell
              sx={{
                paddingLeft: theme.spacing(6.5),
                py: 0,
                borderBottomWidth: open && index === data.unitGroups!.length - 1 ? 1 : 0
              }}
            >
              <Collapse in={open}>
                <Box
                  sx={{
                    paddingTop: theme.spacing(index === 0 ? 0.75 : 1.25),
                    paddingBottom: theme.spacing(
                      index === data.unitGroups!.length - 1 ? 2.25 : 1.25
                    )
                  }}
                >
                  <span
                    style={{
                      color: containsConflictRelatedToUnitGroupId(
                        data.conflicts ?? [],
                        unitGroup.unitGroupId
                      )
                        ? theme.palette.error.main
                        : theme.palette.text.primary
                    }}
                  >
                    {unitGroup.details?.name || unitGroup.unitGroupId}
                  </span>
                </Box>
              </Collapse>
            </TableCell>
            <TableCell
              sx={{
                py: 0,
                borderBottomWidth: open && index === data.unitGroups!.length - 1 ? 1 : 0
              }}
              colSpan={7}
            >
              <Collapse in={open}>
                <Box
                  sx={{
                    paddingTop: theme.spacing(index === 0 ? 0.75 : 1.25),
                    paddingBottom: theme.spacing(
                      index === data.unitGroups!.length - 1 ? 2.25 : 1.25
                    )
                  }}
                >
                  {unitGroup.unitGroupId}
                </Box>
              </Collapse>
            </TableCell>
          </TableRow>
        ))}
    </>
  );
};

export default AdditionalServicesTableRow;
