import { createAsyncThunk, createEntityAdapter, createSlice } from "@reduxjs/toolkit";
import { RootState, ThunkExtraArguments } from "../store";
import { DoorDelegateApi } from "../api/door-delegate";
import { handleSliceError } from "../utils/error-handling";
import { EntityStateStatus } from "../domain/EntityStateStatus";
import { DoorAccessLogDto } from "../domain/door-access-log-dto";
import { getTenantHeaders } from "../utils/api-utils";

export const doorAccessLogsAdapter = createEntityAdapter<DoorAccessLogDto>({
  selectId: (model) => `${model.doorId}-${model.timestamp}`,
  sortComparer: (a, b) => a.timestamp.localeCompare(b.timestamp)
});

const initialState = doorAccessLogsAdapter.getInitialState<{
  status: EntityStateStatus;
  reservationId: string;
}>({
  status: EntityStateStatus.IDLE,
  reservationId: ""
});

export const fetchDoorAccessLogs = createAsyncThunk<
  DoorAccessLogDto[],
  { reservationId: string; isV1: boolean },
  { state: RootState; extra: ThunkExtraArguments }
>(
  "door-access-logs/fetchAll",
  async (arg, thunkAPI) => {
    try {
      return DoorDelegateApi.fetchDoorAccessLogs(arg.reservationId, arg.isV1, {
        signal: thunkAPI.signal,
        ...(await getTenantHeaders(thunkAPI.extra))
      });
    } catch (e) {
      return handleSliceError(e, thunkAPI.rejectWithValue);
    }
  },
  {
    condition(arg, thunkAPI) {
      const status = thunkAPI.getState().availableDoor.status;
      const lastReservationId = thunkAPI.getState().doorAccessLogs.reservationId;
      // don't load already loaded reservation's door access logs
      if (
        (status === EntityStateStatus.LOADING || status === EntityStateStatus.SUCCEEDED) &&
        arg.reservationId === lastReservationId
      ) {
        return false;
      }
    }
  }
);

const slice = createSlice({
  name: "doorAccessLogs",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchDoorAccessLogs.pending, (state, action) => {
        state.status = EntityStateStatus.LOADING;
        state.reservationId = action.meta.arg.reservationId;
      })
      .addCase(fetchDoorAccessLogs.fulfilled, (state, action) => {
        state.reservationId = action.meta.arg.reservationId;
        doorAccessLogsAdapter.setAll(
          state,
          action.payload.map((item) => ({
            ...item
          }))
        );
        state.status = EntityStateStatus.SUCCEEDED;
      })
      .addCase(fetchDoorAccessLogs.rejected, (state, action) => {
        state.reservationId = "";
        if (action.error.name === "AbortError") {
          if (state.status === EntityStateStatus.LOADING) {
            state.status = EntityStateStatus.IDLE;
          }
          return;
        }
        state.status = EntityStateStatus.FAILED;
      });
  }
});

export const { reducer } = slice;

export const { selectAll: selectDoorAccessLogs } = doorAccessLogsAdapter.getSelectors<RootState>(
  (state) => state.doorAccessLogs
);
