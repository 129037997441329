import TableContainer from "@mui/material/TableContainer";
import { Grid2, Table, TableBody } from "@mui/material";
import * as React from "react";
import { FC } from "react";
import { useDoorAccessLogs } from "../../../../../../hooks/use-door-access-logs";
import { EmptyState } from "@likemagic-tech/sv-magic-library";
import TablePagination from "@mui/material/TablePagination";
import { DoorAccessLogsTableRow } from "./door-access-logs-table-row";
import { DoorAccessLogsTableHead } from "./door-access-logs-table-head";
import { useTranslationWrapper } from "../../../../../../hooks/use-translation-wrapper";
import { Loader } from "../../../../../../components/loaders/loader";

interface DoorAccessLogsTableProps {
  reservationId: string;
}

export const DoorAccessLogsTable: FC<DoorAccessLogsTableProps> = ({ reservationId }) => {
  const {
    doorAccessLogs,
    totalCount,
    onRowsPerPageChange,
    onPageChange,
    rowsPerPage,
    page,
    isLoading
  } = useDoorAccessLogs(reservationId);
  const { t } = useTranslationWrapper();

  if (isLoading) {
    return <Loader visible={isLoading} />;
  }

  return doorAccessLogs.length ? (
    <Grid2 container sx={{ width: "100%" }}>
      <TableContainer>
        <Table>
          <DoorAccessLogsTableHead />
          <TableBody>
            {doorAccessLogs?.map((doorAccessLog, index) => (
              <DoorAccessLogsTableRow
                key={`${doorAccessLog.doorId}-${index}`}
                doorAccessLog={doorAccessLog}
              />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        sx={{ width: "100%", justifyContent: "flex-end" }}
        component="div"
        count={totalCount}
        page={page}
        onPageChange={onPageChange}
        rowsPerPage={rowsPerPage}
        onRowsPerPageChange={onRowsPerPageChange}
        rowsPerPageOptions={[5, 10, 25]}
      />
    </Grid2>
  ) : (
    <EmptyState title={t("labels__empty_door_access_logs")} />
  );
};
