import * as React from "react";
import { FC, useMemo } from "react";
import { Box, LinearProgress, Tooltip } from "@mui/material";
import {
  getLastStuckGuestJourneyPage,
  getPercentageOfGJProgress
} from "../../../../utils/reservation-utils";
import { useTranslationWrapper } from "../../../../hooks/use-translation-wrapper";
import { GuestFlowCheckpoint, useGuestPreCheckinFlow } from "@likemagic-tech/sv-magic-library";
import { useFeatureFlags } from "../../../../hooks/use-configuration";

interface PreCheckinProgressProps {
  lastConfirmedPage?: GuestFlowCheckpoint;
  secondScreenCheckpoint?: GuestFlowCheckpoint;
  completed: boolean;
  propertyId: string;
}

export const PreCheckinProgress: FC<PreCheckinProgressProps> = ({
  lastConfirmedPage,
  secondScreenCheckpoint,
  completed,
  propertyId
}) => {
  const { t } = useTranslationWrapper();
  const features = useFeatureFlags();

  const { guestFlowPages } = useGuestPreCheckinFlow({
    upsellUnitGroupEnabled: !!features?.upsellUnitGroupEnabled,
    propertyId: propertyId
  });

  const isSecondScreen = useMemo(() => {
    return lastConfirmedPage === secondScreenCheckpoint;
  }, [lastConfirmedPage, secondScreenCheckpoint]);

  return (
    <Tooltip
      title={
        isSecondScreen
          ? t("labels__confirmed_on_second_screen")
          : t(
              `labels__step_data_${
                getLastStuckGuestJourneyPage(
                  guestFlowPages,
                  lastConfirmedPage ?? guestFlowPages[0]
                ) || "NOT_STARTED"
              }`
            )
      }
    >
      <Box pr={1}>
        <LinearProgress
          color={isSecondScreen ? "warning" : "success"}
          variant="buffer"
          valueBuffer={100}
          value={getPercentageOfGJProgress(
            lastConfirmedPage ?? guestFlowPages[0],
            completed,
            guestFlowPages
          )}
        />
      </Box>
    </Tooltip>
  );
};
