import { api } from "../ValidateServiceConfiguration.generated";

const validateServiceConfigurationEnhanced = api.enhanceEndpoints({
  endpoints: {
    ValidateServiceConfiguration: {}
  }
});

export const {
  useValidateServiceConfigurationMutation: useValidateServiceConfigurationMutationEnhanced
} = validateServiceConfigurationEnhanced;
