import * as React from "react";
import { FC, useEffect, useState } from "react";
import { useSelector } from "../../store";
import { selectAllUnreadMessageCount } from "../../slices/unread-message-count.slice";
import { BACKOFFICE_ROUTES } from "../../routes";
import { useTranslationWrapper } from "../../hooks/use-translation-wrapper";

interface ExtendedNavigator extends Navigator {
  setAppBadge?: (count: number) => Promise<void>;
  clearAppBadge?: () => Promise<void>;
  standalone?: boolean;
}

export const NotificationsProvider: FC = () => {
  const noOfAllUnreadMessages = useSelector(selectAllUnreadMessageCount);
  const { t } = useTranslationWrapper();

  const [prevCount, setPrevCount] = useState(noOfAllUnreadMessages);

  useEffect(() => {
    const navigatorExt = navigator as ExtendedNavigator;

    const isPWA =
      window.matchMedia("(display-mode: standalone)").matches ||
      (navigator as ExtendedNavigator).standalone;

    if (
      "setAppBadge" in navigatorExt &&
      navigatorExt.setAppBadge &&
      navigatorExt.clearAppBadge &&
      typeof noOfAllUnreadMessages === "number" &&
      isPWA
    ) {
      if (noOfAllUnreadMessages > 0) {
        navigatorExt.setAppBadge(noOfAllUnreadMessages).catch(console.error);
      } else if (noOfAllUnreadMessages === 0) {
        navigatorExt.clearAppBadge().catch(console.error);
      }
    }

    if (
      "Notification" in window &&
      noOfAllUnreadMessages !== undefined &&
      prevCount !== undefined &&
      noOfAllUnreadMessages > prevCount &&
      Notification.permission === "granted"
    ) {
      const notification = new Notification(t("labels__new_message_received"), {
        body: t("labels__unread_message", {
          numberOfUnreadMessages: noOfAllUnreadMessages.toString()
        }),
        icon: `${window.location.origin}/likemagic/logo192.png`,
        badge: `${window.location.origin}/likemagic/logo192.png`,
        vibrate: [200, 100, 200]
      });

      const targetUrl = `${window.location.origin}/${BACKOFFICE_ROUTES.MESSAGING}`;

      notification.onclick = (event) => {
        event.preventDefault();
        window.open(targetUrl, "_blank");
      };
    }

    setPrevCount(noOfAllUnreadMessages);
  }, [noOfAllUnreadMessages, prevCount, t]);

  return <></>;
};
