import { BaseApiClient, JSONApiResponse, VoidApiResponse } from "@likemagic-tech/sv-magic-library";
import { AvailableDoor } from "../domain/available-door";
import { DoorAccessLogDto } from "../domain/door-access-log-dto";

const BASE_URL_V1 = "/api/guest-journey-service";
const BASE_URL_V2 = "/api/digital-access-service";

class DoorDelegateApiClient extends BaseApiClient {
  async postDoorAccess(reservationId: string, doorType: DoorType, init?: RequestInit) {
    const response = await this.fetchApi(
      `${BASE_URL_V1}/door-access-delegate/${reservationId}/door-access?status=${doorType}`,
      {
        ...init,
        method: "POST"
      }
    );
    return response.ok;
  }

  async availableDoors(propertyId: String, init?: RequestInit) {
    const response = await this.fetchApi(
      `${BASE_URL_V1}/door-access-delegate/admin/${propertyId}/available-doors`,
      init
    );
    return new JSONApiResponse<AvailableDoor[]>(response).value();
  }
  async supportedSyncStates(propertyId: String, init?: RequestInit) {
    const response = await this.fetchApi(
      `${BASE_URL_V1}/door-access-delegate/admin/${propertyId}/supported-sync-states`,
      init
    );
    return new JSONApiResponse<DoorType[]>(response).value();
  }

  async postOpenDoor(propertyId: String, doorId: string) {
    const response = await this.fetchApi(
      `${BASE_URL_V1}/door-access-delegate/admin/${propertyId}/open/${doorId}`,
      {
        method: "POST"
      }
    );
    return new VoidApiResponse(response).value();
  }

  async encodeKey(reservationId: String, encoderId: string, init?: RequestInit) {
    const response = await this.fetch(
      `${BASE_URL_V1}/door-access-delegate/${reservationId}/encode-key/${encoderId}`,
      {
        ...init,
        method: "POST"
      }
    );
    return new VoidApiResponse(response).value();
  }

  async fetchDoorAccessLogs(
    reservationId: string,
    isV1: boolean,
    init?: RequestInit
  ): Promise<DoorAccessLogDto[]> {
    const baseUrl = isV1 ? BASE_URL_V1 : BASE_URL_V2;
    const response = await this.fetchApi(
      `${baseUrl}/door-access-delegate/${reservationId}/logs`,
      init
    );
    return new JSONApiResponse<DoorAccessLogDto[]>(response).value();
  }
}

export enum DoorType {
  NONE = "NONE",
  PUBLIC = "PUBLIC",
  PUBLIC_INHOUSE = "PUBLIC_INHOUSE",
  PUBLIC_AND_PRIVATE = "PUBLIC_AND_PRIVATE"
}

export const DoorDelegateApi = new DoorDelegateApiClient();
