const useRequestNotificationPermission = () => {
  return () => {
    if (typeof window !== "undefined" && "Notification" in window) {
      if (Notification.permission === "default") {
        Notification.requestPermission().catch(console.error);
      }
    } else {
      console.warn("Notifications are not supported on this browser.");
    }
  };
};

export default useRequestNotificationPermission;
