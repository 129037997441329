import { lazy, Suspense } from "react";
import { Outlet, RouteObject } from "react-router";
import { DashboardLayout } from "./components/dashboard/dashboard-layout";
import { UnitsPage } from "./pages/unit/units.page";
import { DirtyUnitsPage } from "./pages/dirty-units/dirty-units.page";
import { BreakfastPage } from "./pages/breakfast/breakfast.page";
import Home from "./icons/Home";
import RoleBasedGuard from "./components/role-based-guard";
import {
  ADMIN_ROLE,
  BREAKFAST_OPERATOR_ROLE,
  HOST_ROLE,
  HOUSEKEEPER_ROLE,
  PROPERTY_ADMIN_ROLE,
  PROPERTY_VIEWER_ROLE,
  SUPERUSER_ROLE
} from "@likemagic-tech/sv-magic-library";
import ReleaseNotesPage from "./pages/release-notes.page";
import { BoxesPage } from "./pages/boxes.page";
import Arrival from "./pages/arrival/arrival";
import { HousekeepingPage } from "./pages/housekeeping/housekeeping.page";
import InHotel from "./pages/in-hotel/in-hotel";
import Departure from "./pages/departure/departure";
import SettingsAdditionalServicesPage from "./pages/settings/settings-additional-services/settings-additional-services.page";
import { Loader } from "./components/loaders/loader";

const Loadable = (Component: any) => (props: any) => (
  <Suspense fallback={<Loader visible={true} />}>
    <Component {...props} />
  </Suspense>
);

export const BACKOFFICE_ROUTES = {
  DASHBOARD: "/",
  BREAKFAST: "breakfast",
  UNITS: "units",
  DIRTY_UNITS: "dirty-units",
  HOUSEKEEPING: "housekeeping",
  RESERVATIONS: "reservations",
  ARRIVAL: "arrival",
  DEPARTURE: "departure",
  IN_HOTEL: "in-hotel",
  RELEASE_NOTES: "release-notes",
  MESSAGING: "messaging",
  PROFILES: "profiles",
  SETTINGS: "settings",
  SETTINGS_ADDITIONAL_SERVICES: "settings-additional-services",
  BOXES: "boxes",
  IDENTITIES: "identities",
  TASKS: "tasks",
  BOOKINGS: "bookings"
} as const;

export type BackofficeRoute = (typeof BACKOFFICE_ROUTES)[keyof typeof BACKOFFICE_ROUTES];

export const BACKOFFICE_ROUTES_MULTI_PROPERTY: BackofficeRoute[] = [
  BACKOFFICE_ROUTES.MESSAGING,
  BACKOFFICE_ROUTES.RELEASE_NOTES
];

const Reservations = Loadable(lazy(() => import("./pages/reservations.page")));
const Messaging = Loadable(lazy(() => import("./pages/messaging.page")));
const Tasks = Loadable(lazy(() => import("./pages/tasks.page")));
const Profiles = Loadable(lazy(() => import("./pages/profiles/profiles.page")));
const Settings = Loadable(lazy(() => import("./pages/settings/settings.page")));
const IdentityList = Loadable(lazy(() => import("./pages/identity/identity-list")));
const Overview = Loadable(lazy(() => import("./pages/dashboard/overview")));
const Bookings = Loadable(lazy(() => import("./pages/bookings.page")));

// Error pages
const AuthorizationRequired = Loadable(lazy(() => import("./pages/authorization-required")));
const NotFound = Loadable(lazy(() => import("./pages/not-found")));
const ServerError = Loadable(lazy(() => import("./pages/server-error")));

const commonHomeAndErrors = {
  path: "*",
  element: <Outlet />,
  children: [
    {
      path: "",
      element: <Home />
    },
    {
      path: "401",
      element: <AuthorizationRequired />
    },
    {
      path: "404",
      element: <NotFound />
    },
    {
      path: "500",
      element: <ServerError />
    },
    {
      path: "*",
      element: <NotFound />
    }
  ]
};

const commonPages = [
  {
    path: BACKOFFICE_ROUTES.BREAKFAST,
    element: (
      <RoleBasedGuard roles={[HOST_ROLE, BREAKFAST_OPERATOR_ROLE]}>
        <BreakfastPage />
      </RoleBasedGuard>
    )
  },
  {
    path: BACKOFFICE_ROUTES.UNITS,
    element: (
      <RoleBasedGuard roles={[HOST_ROLE, HOUSEKEEPER_ROLE]}>
        <UnitsPage />
      </RoleBasedGuard>
    )
  },
  {
    path: BACKOFFICE_ROUTES.DIRTY_UNITS,
    element: (
      <RoleBasedGuard roles={[HOUSEKEEPER_ROLE]}>
        <DirtyUnitsPage />
      </RoleBasedGuard>
    )
  },
  {
    path: BACKOFFICE_ROUTES.HOUSEKEEPING,
    element: (
      <RoleBasedGuard roles={[HOUSEKEEPER_ROLE]}>
        <HousekeepingPage />
      </RoleBasedGuard>
    )
  },
  {
    path: BACKOFFICE_ROUTES.RESERVATIONS,
    element: (
      <RoleBasedGuard roles={[HOST_ROLE]}>
        <Reservations />
      </RoleBasedGuard>
    )
  },
  {
    path: BACKOFFICE_ROUTES.BOOKINGS,
    element: (
      <RoleBasedGuard roles={[HOST_ROLE]}>
        <Bookings />
      </RoleBasedGuard>
    )
  },
  {
    path: BACKOFFICE_ROUTES.ARRIVAL,
    element: (
      <RoleBasedGuard roles={[HOST_ROLE]}>
        <Arrival />
      </RoleBasedGuard>
    )
  },
  {
    path: BACKOFFICE_ROUTES.DEPARTURE,
    element: (
      <RoleBasedGuard roles={[HOST_ROLE]}>
        <Departure />
      </RoleBasedGuard>
    )
  },
  {
    path: BACKOFFICE_ROUTES.IN_HOTEL,
    element: (
      <RoleBasedGuard roles={[HOST_ROLE]}>
        <InHotel />
      </RoleBasedGuard>
    )
  },
  {
    path: BACKOFFICE_ROUTES.RELEASE_NOTES,
    element: <ReleaseNotesPage />
  },
  {
    path: BACKOFFICE_ROUTES.MESSAGING,
    element: <Messaging />
  },
  {
    path: BACKOFFICE_ROUTES.PROFILES,
    element: <Profiles />
  },
  {
    path: BACKOFFICE_ROUTES.SETTINGS,
    element: (
      <RoleBasedGuard roles={[SUPERUSER_ROLE, PROPERTY_ADMIN_ROLE, PROPERTY_VIEWER_ROLE]}>
        <Settings />
      </RoleBasedGuard>
    )
  },
  {
    path: BACKOFFICE_ROUTES.SETTINGS_ADDITIONAL_SERVICES,
    element: (
      <RoleBasedGuard roles={[ADMIN_ROLE, PROPERTY_ADMIN_ROLE, PROPERTY_VIEWER_ROLE]}>
        <SettingsAdditionalServicesPage />
      </RoleBasedGuard>
    )
  }
];

export const routesV1: Partial<RouteObject>[] = [
  {
    path: BACKOFFICE_ROUTES.DASHBOARD,
    element: <DashboardLayout />,
    children: [
      {
        path: BACKOFFICE_ROUTES.DASHBOARD,
        element: <Overview />
      },
      {
        path: BACKOFFICE_ROUTES.BOXES,
        element: (
          <RoleBasedGuard roles={[HOST_ROLE, HOUSEKEEPER_ROLE]}>
            <BoxesPage />
          </RoleBasedGuard>
        )
      },
      {
        path: BACKOFFICE_ROUTES.IDENTITIES,
        element: (
          <RoleBasedGuard roles={[HOST_ROLE]}>
            <IdentityList />
          </RoleBasedGuard>
        )
      },
      {
        path: BACKOFFICE_ROUTES.TASKS,
        element: (
          <RoleBasedGuard roles={[HOST_ROLE, HOUSEKEEPER_ROLE]}>
            <Tasks />
          </RoleBasedGuard>
        )
      },
      ...commonPages
    ]
  },
  commonHomeAndErrors
];

export const routesV2: Partial<RouteObject>[] = [
  {
    path: BACKOFFICE_ROUTES.DASHBOARD,
    element: <DashboardLayout />,
    children: [
      {
        path: BACKOFFICE_ROUTES.DASHBOARD,
        element: <Overview />
      },
      {
        path: BACKOFFICE_ROUTES.TASKS,
        element: (
          <RoleBasedGuard roles={[HOST_ROLE, HOUSEKEEPER_ROLE]}>
            <Tasks />
          </RoleBasedGuard>
        )
      },
      ...commonPages
    ]
  },
  commonHomeAndErrors
];
