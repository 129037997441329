import { api } from "../GetServiceConfiguration.generated";

export const getServiceConfigurationEnhanced = api.enhanceEndpoints({
  endpoints: {
    GetServiceConfiguration: {
      providesTags: ["ServiceConfiguration"]
    }
  }
});

export const { useGetServiceConfigurationQuery: useGetServiceConfigurationQueryEnhanced } =
  getServiceConfigurationEnhanced;
