import React, { FC, useMemo } from "react";
import { FormControl, FormHelperText, Grid2, useTheme } from "@mui/material";
import { useTranslationWrapper } from "../../../../hooks/use-translation-wrapper";
import { ParagraphBold, ParagraphSmall } from "@likemagic-tech/sv-magic-library";
import {
  DefaultServiceConfig,
  ServiceConfigConflict,
  ServiceConfigurationContainer
} from "../../../../graphql-tenantconfig/generated/graphql";
import { useFormikContext } from "formik";
import { useCanEditServiceConfig } from "../use-can-edit-service-config";
import { SingleServiceInput } from "../service-config-modal/single-service-input";
import { MultipleUnitGroupInput } from "../service-config-modal/multiple-unitgroup-input";
import { extractValidationError } from "../service-config-utils";

interface DependencyGroupFormProps {
  serviceConfiguration: ServiceConfigurationContainer;
  isCreateNew: boolean;
  conflicts: ServiceConfigConflict[];
}

export const DefaultServiceForm: FC<DependencyGroupFormProps> = ({
  serviceConfiguration,
  isCreateNew,
  conflicts
}) => {
  const theme = useTheme();
  const { t } = useTranslationWrapper();
  const { canEditServiceConfig } = useCanEditServiceConfig();

  const { values, errors, setFieldValue } = useFormikContext<DefaultServiceConfig>();

  const selectablePmsServices = useMemo(() => {
    return (serviceConfiguration.pmsServices ?? []).filter(
      (e) => e.pmsId !== serviceConfiguration.fixedFreeCleaningServiceId
    );
  }, [serviceConfiguration.fixedFreeCleaningServiceId, serviceConfiguration.pmsServices]);

  return (
    <Grid2 container spacing={2}>
      <Grid2 size={12}>
        <ParagraphBold>{t("labels__default_service_form_service")} *</ParagraphBold>
        <ParagraphSmall sx={{ mt: theme.spacing(0.5) }}>
          {t("labels__default_service_form_service_description")}
        </ParagraphSmall>
        <FormControl fullWidth={true} sx={{ mt: theme.spacing(0.75) }}>
          <SingleServiceInput
            value={values.service}
            options={selectablePmsServices}
            setValue={(newValue) => {
              setFieldValue("service", newValue);
            }}
            error={!!errors.service}
            disabled={!canEditServiceConfig}
          />
          <FormHelperText error required>
            {errors.service?.serviceId?.toString() || errors.service?.toString()}
          </FormHelperText>
        </FormControl>
      </Grid2>

      <Grid2 size={12}>
        <ParagraphBold>{t("labels__default_service_form_unitgroups")} *</ParagraphBold>
        <ParagraphSmall sx={{ mt: theme.spacing(0.5) }}>
          {t("labels__default_service_form_unitgroups_description")}
        </ParagraphSmall>
        <FormControl fullWidth={true} sx={{ mt: theme.spacing(0.75) }}>
          <MultipleUnitGroupInput
            value={values.unitGroups ?? []}
            options={serviceConfiguration?.pmsUnitGroups ?? []}
            setValue={(newValue) => {
              setFieldValue("unitGroups", newValue);
            }}
            error={!!errors.unitGroups}
            conflicts={conflicts}
            disabled={!canEditServiceConfig}
          />
          <FormHelperText error required>
            {extractValidationError(errors.unitGroups, "unitGroupId")}
          </FormHelperText>
        </FormControl>
      </Grid2>
    </Grid2>
  );
};
