import { Box, Divider, Tooltip, tooltipClasses, useTheme } from "@mui/material";
import { Button, ParagraphSmall } from "@likemagic-tech/sv-magic-library";
import { Groups } from "@mui/icons-material";
import * as React from "react";
import { FC, MouseEvent } from "react";
import { ReservationTableDto } from "../../domain/reservation-table-dto";
import {
  FilterMapInterface,
  FilterObjectType,
  serializeIdOfFilter
} from "../table/filters/table-filters";
import { FilterOperator } from "../../types/filters/filters";
import { useTranslationWrapper } from "../../hooks/use-translation-wrapper";

interface GroupBookingFilterTooltipProps {
  reservation: ReservationTableDto;
  onFiltersChange: (filters: FilterMapInterface) => void;
}

export const GroupBookingFilterTooltip: FC<GroupBookingFilterTooltipProps> = ({
  reservation,
  onFiltersChange
}) => {
  const { palette, spacing } = useTheme();
  const { t } = useTranslationWrapper();

  const onAdditionalFilterChange = (key: string, value: string) => {
    const filterUniqueData = {
      type: FilterObjectType.ADDITIONAL,
      operator: FilterOperator.Equality,
      name: key
    };
    const id = serializeIdOfFilter({ ...filterUniqueData });
    onFiltersChange({
      [id]: {
        value: value,
        ...filterUniqueData
      }
    });
  };

  const handleFilterGroupBookings = (e: MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    onAdditionalFilterChange("bookingId", reservation.bookingId ?? "");
  };

  return (
    <Tooltip
      slotProps={{
        tooltip: {
          sx: {
            backgroundColor: palette.grey[800]
          }
        },
        popper: {
          sx: {
            [`&.${tooltipClasses.popper}[data-popper-placement*="top"] .${tooltipClasses.tooltip}`]:
              {
                marginBottom: 0
              }
          }
        }
      }}
      leaveDelay={100}
      title={
        <Box
          sx={{
            width: 240,
            display: "flex",
            flexDirection: "column",
            gap: 1,
            px: 1.5,
            py: 1
          }}
        >
          {reservation.bookingName && (
            <ParagraphSmall color={palette?.common?.white} fontSize={12}>
              {`${t("labels__group_name")}: ${reservation.bookingName}`}
            </ParagraphSmall>
          )}
          {reservation.bookingId && (
            <ParagraphSmall color={palette?.common?.white} fontSize={12}>
              {`${t("labels__booking_id")}: ${reservation.bookingId}`}
            </ParagraphSmall>
          )}
          <Divider />
          <Box
            sx={{
              display: "flex",
              flexWrap: "nowrap",
              alignItems: "center",
              justifyContent: "space-between"
            }}
          >
            <ParagraphSmall color={palette?.common?.white} fontSize={12}>
              {t("labels__filter_by_booking_id")}
            </ParagraphSmall>
            <Button
              onClick={(e) => handleFilterGroupBookings(e)}
              color="info"
              size="small"
              children="Filter"
              sx={{ borderRadius: 0.5 }}
            />
          </Box>
        </Box>
      }
      arrow
      placement="top"
    >
      <Box component="div">
        <Groups
          fontSize="small"
          sx={{ position: "relative", top: spacing(0.2), left: spacing(0) }}
        />
      </Box>
    </Tooltip>
  );
};
