import { handleAuthHeader } from "@likemagic-tech/sv-magic-library";
import { ThunkExtraArguments } from "../store";

export const getTenantHeaders = async (args: ThunkExtraArguments) => {
  const tenantHeaders = {
    "tenant-id": args?.tenantId,
    "sk-magic-api-key": args?.apiKey
  };

  if (args?.keycloak?.authenticated) {
    const authHeader = await handleAuthHeader(args.keycloak);

    return {
      headers: {
        ...tenantHeaders,
        ...authHeader
      }
    };
  } else {
    return Promise.resolve({ headers: tenantHeaders });
  }
};
