import { Alert, Box, SvgIconProps, Theme } from "@mui/material";
import { Button, ParagraphSmall } from "@likemagic-tech/sv-magic-library";
import WarningRoundedIcon from "@mui/icons-material/Warning";
import { makeStyles } from "tss-react/mui";
import { useTheme } from "@mui/material/styles";
import { Done, ErrorOutline } from "@mui/icons-material";
import { FC, useMemo } from "react";

export enum NotificationType {
  ERROR = "error",
  SUCCESS = "success",
  INFO = "info",
  WARNING = "warning"
}

export interface NotificationProps {
  type?: "error" | "success" | "info" | "warning";
  title: string;
  subtitle?: string;
  onClick?: () => void;
  onClose?: () => void;
  showIcon?: boolean;
  Icon?: FC<SvgIconProps>;
  fullWidth?: boolean;
  buttonText?: string;
  buttonOnClick?: () => void;
}

const iconStyle = (theme: Theme) => ({
  width: 32,
  height: 32,
  padding: 4,
  borderRadius: theme.spacing(8)
});

const useStyles = makeStyles()((theme: Theme) => ({
  errorIcon: {
    ...iconStyle(theme),
    color: theme.palette.error.dark
  },
  warningIcon: {
    ...iconStyle(theme),
    color: theme.palette.warning.dark
  },
  infoIcon: {
    ...iconStyle(theme),
    color: theme.palette.info.dark
  },
  successIcon: {
    ...iconStyle(theme),
    color: theme.palette.success.dark
  },
  message: {
    flex: 1
  }
}));

export const Notification: FC<NotificationProps> = ({
  type,
  title,
  subtitle,
  onClick,
  onClose,
  Icon,
  showIcon,
  fullWidth,
  buttonOnClick,
  buttonText
}) => {
  const { classes } = useStyles();
  const theme = useTheme();

  const iconMapping = {
    [NotificationType.ERROR]: <WarningRoundedIcon className={classes.errorIcon} />,
    [NotificationType.WARNING]: <WarningRoundedIcon className={classes.warningIcon} />,
    [NotificationType.SUCCESS]: <Done className={classes.successIcon} />,
    [NotificationType.INFO]: <ErrorOutline className={classes.infoIcon} />
  };

  const hasButton = useMemo(() => buttonOnClick && buttonText, [buttonOnClick, buttonText]);

  return (
    <Alert
      variant="filled"
      icon={showIcon}
      severity={type}
      iconMapping={iconMapping}
      sx={{
        cursor: onClick ? "pointer" : "default",
        backgroundColor: type && theme.palette[type].light,
        alignItems: "center",
        borderRadius: theme.spacing(0.75),
        px: 1.25,
        py: 0.5,
        width: fullWidth ? "100%" : "auto"
      }}
      classes={{
        message: classes.message
      }}
      onClick={onClick}
      onClose={onClose}
    >
      <Box
        sx={{
          display: "flex",
          gap: 1,
          flexWrap: "nowrap",
          alignItems: "center",
          justifyContent: "space-between"
        }}
      >
        {Icon ? (
          <Icon
            sx={{
              color: type && theme.palette[type].dark
            }}
          />
        ) : (
          <></>
        )}
        <Box sx={{ display: "flex", flexDirection: "column" }}>
          <ParagraphSmall
            sx={{
              color: type && theme.palette[type].dark
            }}
          >
            {title}
          </ParagraphSmall>
          {subtitle && (
            <ParagraphSmall
              mt={1}
              sx={{
                color: type && theme.palette[type].dark
              }}
            >
              {subtitle}
            </ParagraphSmall>
          )}
        </Box>
        {hasButton ? (
          <Box sx={{ alignItems: "flex-end", display: "flex" }}>
            <Button onClick={buttonOnClick} size="small" sx={{ ml: "auto" }}>
              {buttonText}
            </Button>
          </Box>
        ) : (
          <></>
        )}
      </Box>
    </Alert>
  );
};
