import { CommunicationChannel, Select, TenantContext } from "@likemagic-tech/sv-magic-library";
import { DialogActions, DialogContent, Grid2, SelectChangeEvent, TextField } from "@mui/material";
import { Field, FieldProps, Form, Formik, FormikProps } from "formik";
import { FC, useCallback, useContext, useEffect, useMemo } from "react";
import { DialogModal } from "src/components/submit-modal/dialog";
import { DialogActionModal } from "src/components/submit-modal/dialog-actions";
import { DialogTitleModal } from "src/components/submit-modal/dialog-title";
import { useTranslationWrapper } from "src/hooks/use-translation-wrapper";
import { ReservationDetailsDTO } from "src/store/endpoints/reservation-table.endpoints";
import { getDefaultActor, getDefaultLangugage, getNotificationInfo } from "./notification-utils";
import { useFormValidations } from "src/hooks/use-form-validation";
import { NotificationLabelForAutocomplete } from "./notification-label-for-autocomplete";
import { useSendCustomNotification } from "src/hooks/use-send-custom-notification";
import { useDispatch } from "src/store";
import { openBanner } from "src/slices/banner.slice";
import {
  AutocompleteAsyncComponent,
  AutocompleteOption
} from "../../../../../../../components/autocomplete-sync-component";
import { useFetchProfilesLazy } from "src/hooks/use-fetch-profiles-lazy";
import { useProperty } from "src/hooks/use-property";
import { UserProfile } from "src/domain/user-profile";
import { NotificationSendModalAutocompleteItem } from "./notification-send-modal-autocomplete-item";
import {
  getI18nSelectedLanguage,
  getTranslatedLanguageName
} from "../../../../../../../utils/language";
import { useGetNotificationTemplate } from "./use-get-notification-template";
import { useEnabledCommunicationChannels } from "src/hooks/use-configuration";

interface NotificationSendModalProps {
  onClose: () => void;
  reservation: ReservationDetailsDTO;
}

export interface InitialValuesForForm {
  template: {
    value: string;
    label: string;
  };
  channel: {
    value: string;
    label: string;
  };
  language: {
    value: string;
    label: string;
  };
  autocompleteField: {
    id: string;
    label: string;
  };
  email: string;
  phone: string;
}

const initialValuesForFormik = {
  template: { value: "", label: "" },
  channel: {
    value: "",
    label: ""
  },
  language: {
    value: "",
    label: ""
  },
  autocompleteField: {
    id: "",
    label: ""
  },
  email: "",
  phone: ""
};

export const NotificationSendModal: FC<NotificationSendModalProps> = ({ onClose, reservation }) => {
  const { t } = useTranslationWrapper();
  const dispatch = useDispatch();
  const { allowedLanguages } = useContext(TenantContext);
  const { selectedProperty } = useProperty();
  const enabledCommunicationChannels = useEnabledCommunicationChannels(
    selectedProperty?.propertyId
  );
  const { notificationPersonalFieldsValidations } = useFormValidations();
  const [sendCustomNotification, resultSendCustomNotification] = useSendCustomNotification();
  const language = getI18nSelectedLanguage();

  const { functionCall, result } = useFetchProfilesLazy();
  const templatesList = useGetNotificationTemplate({ reservationId: reservation.id });

  const templateOptions = useMemo(() => {
    return templatesList.map((item) => ({
      label: t(`labels__notification__template__${item}`),
      value: item
    }));
  }, [t, templatesList]);

  const profileOptions = useMemo(
    () =>
      result?.map((profile: UserProfile) => ({
        id: `${profile.id}`,
        label: `${profile.person.firstName} ${profile.person.lastName}`
      })) ?? [],
    [result]
  );

  const getProfileOptionItem = useCallback(
    (id: string) =>
      profileOptions?.find((profile: { id: string; label: string }) => profile?.id === id),
    [profileOptions]
  );

  const languageOptions = useMemo(
    () =>
      allowedLanguages?.map((value) => ({
        value: value?.toUpperCase(),
        label: getTranslatedLanguageName({
          code: value,
          locale: language
        })
      })),
    [allowedLanguages, language]
  );
  const channelOptions = useMemo(
    () =>
      enabledCommunicationChannels.map((value) => ({
        value: value,
        label: t(`labels__guest__communication__channel__${value}`)
      })),
    [t, enabledCommunicationChannels]
  );

  const handleClose = useCallback(() => {
    onClose();
  }, [onClose]);

  const handleSubmit = useCallback(
    (values: typeof initialValuesForFormik) => {
      sendCustomNotification({
        reservationId: reservation.id,
        payload: {
          sendTos:
            values.channel.value === CommunicationChannel.EMAIL ? [values.email] : [values.phone],
          template: values.template.value,
          channel: values.channel.value,
          language: values.language.value
        }
      });
    },
    [reservation.id, sendCustomNotification]
  );

  useEffect(() => {
    if (resultSendCustomNotification?.isSuccess) {
      dispatch(
        openBanner({
          type: "success",
          title: t("labels__action_successfully_performed")
        })
      );
      resultSendCustomNotification?.reset();
      handleClose();
    }
  }, [resultSendCustomNotification, dispatch, handleClose, t]);

  const loadProfiles = useCallback(
    (text: string) =>
      functionCall({
        propertyId: selectedProperty?.propertyId ?? "",
        pageable: {
          pageNumber: 0,
          pageSize: 25
        },
        text
      }).then((items) =>
        items.map((item) => ({
          id: `${item.id}`,
          label: `${item.person.firstName} ${item.person.lastName}`
        }))
      ) as Promise<Array<AutocompleteOption>>,
    [functionCall, selectedProperty?.propertyId]
  );

  const setModalFieldsBaseOnTemplate = (
    e: SelectChangeEvent<string>,
    formik: FormikProps<InitialValuesForForm>
  ) => {
    formik.handleChange(e);
    formik.setFieldValue("autocompleteField", getDefaultActor(reservation, e.target.value));
    formik.setFieldValue(
      "channel.value",
      getNotificationInfo(reservation, e.target.value, "preferredCommunicationChannel")
    );
    formik.setFieldValue("language.value", getDefaultLangugage(reservation, e.target.value));
    formik.setFieldValue("phone", getNotificationInfo(reservation, e.target.value, "phone"));
    formik.setFieldValue("email", getNotificationInfo(reservation, e.target.value, "email"));
  };

  return (
    <Formik
      initialValues={initialValuesForFormik}
      onSubmit={handleSubmit}
      validationSchema={notificationPersonalFieldsValidations}
    >
      {(formik) => (
        <DialogModal handleClose={handleClose} isOpen>
          <Grid2
            container
            sx={{
              p: 3,
              flexDirection: "column",
              width: "100%"
            }}
          >
            <Form>
              <DialogTitleModal title={t("labels__send_new_notification")} onClose={onClose} />
              <DialogContent sx={{ px: 0, py: 2 }}>
                <Grid2 container direction="column" spacing={2}>
                  <Grid2>
                    <Select
                      required
                      variant="outlined"
                      id="template.value"
                      name="template.value"
                      options={templateOptions ?? []}
                      label={t("labels__notification_modal_template")}
                      value={formik.values.template.value}
                      onChange={(e) => {
                        setModalFieldsBaseOnTemplate(e as SelectChangeEvent<string>, formik);
                      }}
                    />
                  </Grid2>
                  {formik.values.template.value && (
                    <Grid2>
                      <Field name="autocompleteField">
                        {({ field }: FieldProps) => (
                          <AutocompleteAsyncComponent
                            loadItems={loadProfiles}
                            label={t("labels__notification_recipient")}
                            value={field.value}
                            displayItem={(_, option) => {
                              const profileOptionItem = getProfileOptionItem(option.id);
                              return (
                                <NotificationSendModalAutocompleteItem
                                  result={result}
                                  option={option}
                                  profileOptionItem={profileOptionItem}
                                  formik={formik}
                                />
                              );
                            }}
                            inputEndEndorsement={
                              field.value ? (
                                <NotificationLabelForAutocomplete
                                  profile={
                                    result?.filter(
                                      (profile: UserProfile) =>
                                        profile.id === Number(field.value.id)
                                    ) ?? []
                                  }
                                  channel={formik.values.channel.value}
                                />
                              ) : undefined
                            }
                            onSelected={(value) => {
                              formik.setFieldValue("autocompleteField", value);
                              formik.setFieldValue(
                                "email",
                                result?.filter(
                                  (profile: UserProfile) => profile.id === Number(value.id)
                                )[0]?.person.email ?? ""
                              );
                              formik.setFieldValue(
                                "phone",
                                result?.filter(
                                  (profile: UserProfile) => profile.id === Number(value.id)
                                )[0]?.person.phone ?? ""
                              );
                            }}
                          />
                        )}
                      </Field>
                    </Grid2>
                  )}
                  {formik.values.autocompleteField.label && (
                    <Grid2>
                      <Select
                        options={channelOptions}
                        onChange={formik.handleChange}
                        value={formik.values.channel.value}
                        label={t("labels__notification__modal__channel")}
                        id="channel.value"
                        name="channel.value"
                        variant="outlined"
                      />
                    </Grid2>
                  )}
                  {formik.values.autocompleteField.label && (
                    <Grid2>
                      <Select
                        options={languageOptions ?? []}
                        onChange={formik.handleChange}
                        value={formik.values.language.value}
                        label={t("labels__notification__modal__language")}
                        id="language.value"
                        name="language.value"
                        variant="outlined"
                      />
                    </Grid2>
                  )}
                  {CommunicationChannel.EMAIL === formik.values.channel.value && (
                    <Grid2>
                      <TextField
                        required
                        label={t("labels__email")}
                        fullWidth
                        value={formik.values.email}
                        onChange={formik.handleChange}
                        error={Boolean(formik.errors?.email)}
                        id="email"
                        name="email"
                      />
                    </Grid2>
                  )}
                  {(CommunicationChannel.WHATSAPP === formik.values.channel.value ||
                    CommunicationChannel.SMS === formik.values.channel.value) && (
                    <Grid2>
                      <TextField
                        required
                        label={t("labels__phone_number")}
                        fullWidth
                        value={formik.values.phone}
                        onChange={formik.handleChange}
                        error={Boolean(formik.errors?.phone)}
                        id="phone"
                        name="phone"
                      />
                    </Grid2>
                  )}
                </Grid2>
              </DialogContent>
              {formik.values.autocompleteField.label && formik.values.template.value && (
                <DialogActions>
                  <DialogActionModal
                    title={t("labels__send")}
                    disabled={!formik.values.channel.value || !formik.values.language.value}
                  />
                </DialogActions>
              )}
            </Form>
          </Grid2>
        </DialogModal>
      )}
    </Formik>
  );
};
