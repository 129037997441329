import { ADMIN_ROLE, PROPERTY_ADMIN_ROLE, useAuth } from "@likemagic-tech/sv-magic-library";
import { useMemo } from "react";

export const useCanEditServiceConfig = () => {
  const { hasRole, hasSuperuserRole } = useAuth();
  const canEditServiceConfig = useMemo(() => {
    return hasRole(PROPERTY_ADMIN_ROLE) || hasRole(ADMIN_ROLE) || hasSuperuserRole();
  }, [hasRole, hasSuperuserRole]);

  return {
    canEditServiceConfig: canEditServiceConfig
  };
};
