import React from "react";
import { Avatar, useTheme } from "@mui/material";
import PersonIcon from "@mui/icons-material/Person";
import { OverridableComponent } from "@mui/material/OverridableComponent";
import { AvatarProps, AvatarTypeMap } from "@mui/material/Avatar/Avatar";
import { Paragraph } from "@likemagic-tech/sv-magic-library";

type UserAvatarTypeMap = AvatarTypeMap<{ lettersToDisplay?: string }>;

export const UserAvatar: OverridableComponent<UserAvatarTypeMap> = (
  props: AvatarProps & { lettersToDisplay?: string }
) => {
  const { lettersToDisplay, ...avatarProps } = props;
  const { palette } = useTheme();

  if (lettersToDisplay) {
    return (
      <Avatar
        {...avatarProps}
        sx={{
          ...avatarProps.sx,
          border: `1px solid ${palette.grey["400"]}`,
          background: palette.grey["200"],
          color: palette.grey["600"]
        }}
      >
        <Paragraph sx={{ color: palette.grey["600"] }}>{lettersToDisplay}</Paragraph>
      </Avatar>
    );
  }
  return (
    <Avatar
      {...avatarProps}
      sx={{
        ...avatarProps.sx,
        border: `1px solid ${palette.grey["400"]}`,
        background: palette.grey["200"],
        color: palette.grey["600"]
      }}
    >
      <PersonIcon />
    </Avatar>
  );
};
