import * as Types from "../generated/graphql";

export type DateRangeFragment = {
  __typename?: "DateRange";
  startInclusive: any;
  endInclusive: any;
};

export const DateRangeFragmentDoc = `
    fragment DateRange on DateRange {
  startInclusive
  endInclusive
}
    `;
