import * as React from "react";
import { FC, useCallback, useMemo } from "react";
import { HousekeepingTableType, HousekeepingTaskTable } from "./housekeeping-task-table";
import { HousekeepingTaskType } from "../../features/tasks/domain/task";
import { useGetAdditionalTasksQueryEnhanced } from "../../graphql-tasks/query/enhanced-queries/get-additional-tasks-enhanced";
import { useUpdateAdditionalTaskMutationEnhanced } from "../../graphql-tasks/mutations/enhanced-mutations/update-additional-tasks-enhanced";
import { useIsMobile } from "../../hooks/use-is-mobile";
import { HousekeepingTaskCard } from "./housekeeping-task-card";
import { Card, Grid2 } from "@mui/material";
import { useTranslationWrapper } from "../../hooks/use-translation-wrapper";
import {
  generateUpdateTaskRequest,
  housekeepingFilterParamSelector
} from "../../utils/housekeeping";
import {
  FilterObjectType,
  serializeIdOfFilter
} from "../../components/table/filters/table-filters";
import { FilterOperator } from "../../types/filters/filters";
import { useCurrentActor } from "../../hooks/use-current-actor";
import { useSearchFilterParams } from "../../components/table/hooks/use-search-filter-params";
import { HousekeepingFilters } from "./housekeeping-filters";
import { HousekeepingTaskOwnershipFilters } from "../../domain/housekeeping-task-ownership-filters";
import { EmptyState } from "@likemagic-tech/sv-magic-library";

interface AdditionalTasksProps {
  propertyId?: string;
}

const assignedToMeFilter = serializeIdOfFilter({
  type: FilterObjectType.AUTOCOMPLETE,
  operator: FilterOperator.Equality,
  name: housekeepingFilterParamSelector(HousekeepingTaskOwnershipFilters.ASSIGNED_TO_ME)
});

const assignedToTeamsOfFilter = serializeIdOfFilter({
  type: FilterObjectType.AUTOCOMPLETE,
  operator: FilterOperator.Equality,
  name: housekeepingFilterParamSelector(HousekeepingTaskOwnershipFilters.ASSIGNED_TO_TEAM)
});

export const HousekeepingAdditionalTasks: FC<AdditionalTasksProps> = ({ propertyId }) => {
  const { data, isLoading } = useGetAdditionalTasksQueryEnhanced({
    pmsPropertyId: propertyId ?? ""
  });
  const [updateAction] = useUpdateAdditionalTaskMutationEnhanced();
  const isMobile = useIsMobile();
  const { t } = useTranslationWrapper();
  const { currentActorId } = useCurrentActor();
  const { filterMap } = useSearchFilterParams();

  const tasks = useMemo(() => {
    return data?.GetAdditionalTasks ?? [];
  }, [data]);

  const handleUpdateAdditionalTask = useCallback(
    async (values: HousekeepingTaskType) => {
      await updateAction({
        pmsPropertyId: propertyId ?? "",
        request: generateUpdateTaskRequest(values),
        taskId: values.id
      });
    },
    [propertyId, updateAction]
  );

  const currentActorTasks = useMemo(() => {
    return tasks?.filter((task) => task.assignee?.actorId === currentActorId);
  }, [tasks, currentActorId]);

  const teamTasks = useMemo(() => {
    return tasks?.filter((task) => task.assignee === null);
  }, [tasks]);

  const filteredTasks = useMemo(() => {
    const isActorTasksFilterActive = !!filterMap[assignedToMeFilter]?.value;
    const isTeamTasksFilterActive = !!filterMap[assignedToTeamsOfFilter]?.value;

    switch (true) {
      case isActorTasksFilterActive && isTeamTasksFilterActive:
        return tasks;
      case isActorTasksFilterActive:
        return currentActorTasks;
      case isTeamTasksFilterActive:
        return teamTasks;
      default:
        return tasks;
    }
  }, [filterMap, currentActorTasks, teamTasks, tasks]);

  if (filteredTasks.length === 0 && !isLoading) {
    return (
      <Grid2 container>
        {(Object.values(currentActorTasks).length > 0 || Object.values(teamTasks).length > 0) && (
          <HousekeepingFilters
            currentActorFilterCount={Object.values(currentActorTasks).length}
            teamFilterCount={Object.values(teamTasks).length}
          />
        )}
        <EmptyState title={t("labels__all_additional_tasks_resolved")} />
      </Grid2>
    );
  }
  return isMobile ? (
    <Grid2
      container
      sx={{
        gap: 4
      }}
    >
      <HousekeepingFilters
        currentActorFilterCount={Object.values(currentActorTasks).length}
        teamFilterCount={Object.values(teamTasks).length}
      />
      <Card sx={{ width: "100%" }}>
        {filteredTasks?.map((task) => (
          <HousekeepingTaskCard
            housekeepingTableType={HousekeepingTableType.ADDITIONAL}
            task={task}
            handleUpdate={handleUpdateAdditionalTask}
          />
        ))}
      </Card>
    </Grid2>
  ) : (
    <Grid2
      container
      sx={{
        gap: 4
      }}
    >
      <HousekeepingFilters
        currentActorFilterCount={Object.values(currentActorTasks).flatMap((unit) => unit).length}
        teamFilterCount={Object.values(teamTasks).flatMap((unit) => unit).length}
      />
      <Grid2 size={{ xs: 12 }}>
        <HousekeepingTaskTable
          housekeepingTableType={HousekeepingTableType.ADDITIONAL}
          tasks={filteredTasks ?? []}
          handleUpdate={handleUpdateAdditionalTask}
        />
      </Grid2>
    </Grid2>
  );
};
