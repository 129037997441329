import React, { FC, useMemo } from "react";
import { FormControl, FormHelperText, Grid2, useTheme } from "@mui/material";
import { useTranslationWrapper } from "../../../../hooks/use-translation-wrapper";
import { Input, ParagraphBold, ParagraphSmall } from "@likemagic-tech/sv-magic-library";
import {
  ServiceConfigConflict,
  ServiceConfigurationContainer,
  ServiceDependencyGroup
} from "../../../../graphql-tenantconfig/generated/graphql";
import { useFormikContext } from "formik";
import { useCanEditServiceConfig } from "../use-can-edit-service-config";
import { MultipleServiceInput } from "./multiple-service-input";
import { extractValidationError } from "../service-config-utils";

interface DependencyGroupFormProps {
  serviceConfiguration: ServiceConfigurationContainer;
  isCreateNew: boolean;
  conflicts: ServiceConfigConflict[];
}

export const DependencyGroupForm: FC<DependencyGroupFormProps> = ({
  serviceConfiguration,
  isCreateNew,
  conflicts
}) => {
  const theme = useTheme();
  const { t } = useTranslationWrapper();
  const { canEditServiceConfig } = useCanEditServiceConfig();

  const { values, errors, handleChange, setFieldValue } =
    useFormikContext<ServiceDependencyGroup>();

  const selectablePmsServices = useMemo(() => {
    return (serviceConfiguration.pmsServices ?? []).filter(
      (e) => e.pmsId !== serviceConfiguration.fixedFreeCleaningServiceId
    );
  }, [serviceConfiguration.fixedFreeCleaningServiceId, serviceConfiguration.pmsServices]);

  return (
    <Grid2 container spacing={2}>
      <Grid2 size={12}>
        <ParagraphBold>{t("labels__dependency_group_form_key")} *</ParagraphBold>
        <ParagraphSmall sx={{ mt: theme.spacing(0.5) }}>
          {t("labels__dependency_group_form_key_description")}
        </ParagraphSmall>
        <FormControl fullWidth={true} sx={{ mt: theme.spacing(0.75) }}>
          <Input
            id="key"
            name="key"
            variant="outlined"
            value={values.key ?? ""}
            onChange={handleChange}
            error={errors.key?.toString()}
            disabled={!canEditServiceConfig}
            sx={{
              ".MuiInputBase-input": {
                paddingY: theme.spacing(2)
              }
            }}
          />
        </FormControl>
      </Grid2>

      <Grid2 size={12}>
        <ParagraphBold>{t("labels__dependency_group_form_services")} *</ParagraphBold>
        <ParagraphSmall sx={{ mt: theme.spacing(0.5) }}>
          {t("labels__dependency_group_form_services_description")}
        </ParagraphSmall>
        <FormControl fullWidth={true} sx={{ mt: theme.spacing(0.75) }}>
          <MultipleServiceInput
            value={values.services ?? []}
            options={selectablePmsServices}
            setValue={(newValue) => {
              setFieldValue("services", newValue);
            }}
            error={!!errors.services}
            conflicts={conflicts}
            disabled={!canEditServiceConfig}
          />
          <FormHelperText error required>
            {extractValidationError(errors.services, "serviceId")}
          </FormHelperText>
        </FormControl>
      </Grid2>
    </Grid2>
  );
};
