import { Tab, useTheme } from "@mui/material";
import { TabContext, TabList } from "@mui/lab";
import { ProfileTabs, ProfileTabsList } from "../profile-constants";
import * as React from "react";
import { FC, SyntheticEvent } from "react";
import { useTranslationWrapper } from "../../../hooks/use-translation-wrapper";
import { ParagraphBold } from "@likemagic-tech/sv-magic-library";

interface ProfileDrawerTabsProps {
  value: ProfileTabs;
  handleChange: (event: SyntheticEvent, newValue: string) => void;
}
export const ProfileDrawerTabs: FC<ProfileDrawerTabsProps> = ({ value, handleChange }) => {
  const { t } = useTranslationWrapper();
  const { palette } = useTheme();

  return (
    <TabContext value={value}>
      <TabList onChange={handleChange} variant="scrollable">
        {ProfileTabsList.map((item) => (
          <Tab
            label={
              <ParagraphBold sx={{ textTransform: "none" }} color={palette.grey[900]}>
                {t(`labels__profiles_${item?.toLowerCase()}`)}
              </ParagraphBold>
            }
            value={item}
            key={item}
          />
        ))}
      </TabList>
    </TabContext>
  );
};
