import { useTranslationWrapper } from "../../../../hooks/use-translation-wrapper";
import { useMemo } from "react";
import yup from "../../../../config/yup.config";
import {
  AddOnType,
  BookableDay,
  DefaultDatePickerSelection,
  DefaultTargetAudience,
  DistributionRule,
  DueDurationType,
  ReservationStatus,
  ServiceConfigGranularity,
  ServiceConfigType,
  ServiceTag,
  TaskGranularity,
  Visibility,
  WeekendRule
} from "../../../../graphql-tenantconfig/generated/graphql";
import { useGetActors } from "../../../../features/tasks/use-get-actors";
import { useGetServiceConfigurationQueryEnhanced } from "../../../../graphql-tenantconfig/queries/enhanced-queries/get-service-configuration-enhanced";
import { useProperty } from "../../../../hooks/use-property";

export const useServiceConfigFormValidations = () => {
  const { t } = useTranslationWrapper();
  const { selectedProperty } = useProperty();
  const { actorGroupOptions } = useGetActors();
  const { data } = useGetServiceConfigurationQueryEnhanced(
    { pmsPropertyId: selectedProperty.propertyId },
    {}
  );

  const serviceConfigFormValidation = useMemo(() => {
    const validUnitGroupIds =
      data?.GetServiceConfiguration?.pmsUnitGroups?.map((u) => u.pmsId) ?? [];
    const validActorGroups = actorGroupOptions?.map((a) => a.label) ?? [];
    const validServiceIds = data?.GetServiceConfiguration?.pmsServices?.map((s) => s.pmsId) ?? [];
    const validFreeCleaningServiceIds = !!data?.GetServiceConfiguration?.fixedFreeCleaningServiceId
      ? [data.GetServiceConfiguration?.fixedFreeCleaningServiceId]
      : validServiceIds;

    return yup.object().shape({
      type: yup
        .mixed<ServiceConfigType>()
        .oneOf(Object.values(ServiceConfigType), t("labels__service_config_type_unkown"))
        .required(t("labels__service_config_type_not_provided")),
      service: yup
        .object()
        .nullable()
        .when("type", {
          is: (type: ServiceConfigType) => ServiceConfigType.FreeCleaning === type,
          then: (schema) =>
            yup
              .object()
              .nullable()
              .required(t("labels__service_config_service_not_provided"))
              .shape({
                serviceId: yup
                  .string()
                  .required(t("labels__service_config_service_not_provided"))
                  .oneOf(
                    validFreeCleaningServiceIds as any,
                    t("labels__service_config_pms_service_unsupported")
                  )
              }),
          otherwise: (schema) =>
            yup
              .object()
              .nullable()
              .required(t("labels__service_config_service_not_provided"))
              .shape({
                serviceId: yup
                  .string()
                  .required(t("labels__service_config_service_not_provided"))
                  .oneOf(validServiceIds, t("labels__service_config_pms_service_unknown"))
              })
        }),
      unitGroups: yup
        .array(
          yup.object().shape({
            unitGroupId: yup
              .string()
              .required(t("labels__service_config_unitgroups_not_provided"))
              .oneOf(validUnitGroupIds, t("labels__service_config_pms_unitgroup_unknown"))
          })
        )
        .required(t("labels__service_config_unitgroups_not_provided"))
        .min(1, t("labels__service_config_unitgroups_not_provided")),
      task: yup
        .object()
        .nullable()
        .default(null)
        .shape({
          housekeepingMainTask: yup.boolean().required(),
          assigneeGroup: yup
            .string()
            .oneOf(validActorGroups, t("labels__service_config_task_assigneegroup_unknown"))
            .required(t("labels__service_config_task_assigneegroup_not_provided")),
          createMultipleTasks: yup.boolean().required(),
          dueDuration: yup.string().nullable(),
          dueDurationType: yup
            .mixed<DueDurationType>()
            .oneOf(
              Object.values(DueDurationType),
              t("labels__service_config_task_duedurationtype_unknown")
            )
            .nullable(),
          granularity: yup
            .mixed<TaskGranularity>()
            .oneOf(
              Object.values(TaskGranularity),
              t("labels__service_config_task_granularity_unknown")
            )
            .required(t("labels__service_config_task_granularity_not_provided"))
        }),

      // freecleaning
      distributionRule: yup
        .mixed<DistributionRule>()
        .oneOf(
          Object.values(DistributionRule),
          t("labels__service_config_distribution_rule_unknown")
        )
        .when("type", {
          is: (type: ServiceConfigType) => ServiceConfigType.FreeCleaning === type,
          then: (schema) =>
            schema.required(t("labels__service_config_distributionrule_not_provided"))
        }),
      minimumNights: yup
        .number()
        .integer()
        .positive()
        .when(["type", "distributionRule"], {
          is: (type: ServiceConfigType, distributionRule: DistributionRule) =>
            ServiceConfigType.FreeCleaning === type &&
            (DistributionRule.EvenlySpread === distributionRule ||
              DistributionRule.ExactInterval === distributionRule),
          then: (schema) => schema.required(t("labels__service_config_minimumnights_not_provided"))
        }),
      interval: yup
        .number()
        .positive()
        .when(["type", "distributionRule"], {
          is: (type: ServiceConfigType, distributionRule: DistributionRule) =>
            ServiceConfigType.FreeCleaning === type &&
            (DistributionRule.EvenlySpread === distributionRule ||
              DistributionRule.ExactInterval === distributionRule),
          then: (schema) => schema.required(t("labels__service_config_interval_not_provided"))
        }),
      weekendRule: yup
        .mixed<WeekendRule>()
        .oneOf(Object.values(WeekendRule))
        .when(["type", "distributionRule"], {
          is: (type: ServiceConfigType, distributionRule: DistributionRule) =>
            ServiceConfigType.FreeCleaning === type &&
            DistributionRule.ArrivalWeekDay === distributionRule,
          then: (schema) => schema.required(t("labels__service_config_weekendrule_not_provided"))
        }),

      // base config
      minimumBefore: yup.string().nullable(),
      maximumBefore: yup.string().nullable(),
      maximum: yup.number().integer().positive().nullable(),
      bookableDays: yup
        .array(
          yup
            .mixed<BookableDay>()
            .oneOf(Object.values(BookableDay), t("labels__service_config_bookableday_unknown"))
        )
        .nullable(),
      reservationStatuses: yup
        .array(
          yup
            .mixed<ReservationStatus>()
            .oneOf(
              Object.values(ReservationStatus),
              t("labels__service_config_reservationstatus_unknown")
            )
        )
        .nullable()
        .when("type", {
          is: (type: ServiceConfigType) => ServiceConfigType.LateCheckout === type,
          then: (schema) =>
            yup.array(
              yup
                .mixed<ReservationStatus>()
                .oneOf(
                  [ReservationStatus.Confirmed, ReservationStatus.InHouse],
                  t("labels__service_config_reservationstatus_not_supported")
                )
            )
        })
        .when("type", {
          is: (type: ServiceConfigType) => ServiceConfigType.EarlyCheckin === type,
          then: (schema) =>
            yup.array(
              yup
                .mixed<ReservationStatus>()
                .oneOf(
                  [ReservationStatus.Confirmed],
                  t("labels__service_config_reservationstatus_not_supported")
                )
            )
        })
        .when("type", {
          is: (type: ServiceConfigType) => ServiceConfigType.AdditionalCleaning === type,
          then: (schema) =>
            yup.array(
              yup
                .mixed<ReservationStatus>()
                .oneOf(
                  [ReservationStatus.Confirmed, ReservationStatus.InHouse],
                  t("labels__service_config_reservationstatus_not_supported")
                )
            )
        }),

      visibilities: yup
        .array(
          yup
            .mixed<Visibility>()
            .oneOf(Object.values(Visibility), t("labels__service_config_visibility_unknown"))
        )
        .nullable(),
      payAtCheckOut: yup.boolean().nullable(),
      whitelistedDateRanges: yup
        .array(
          yup
            .object()
            .nullable()
            .required(t("labels__service_config_daterange_not_provided"))
            .shape({
              startInclusive: yup.date(),
              endInclusive: yup.date()
            })
        )
        .nullable(),
      blacklistedDateRanges: yup
        .array(
          yup
            .object()
            .nullable()
            .required(t("labels__service_config_daterange_not_provided"))
            .shape({
              startInclusive: yup.date(),
              endInclusive: yup.date()
            })
        )
        .nullable(),

      // early checkin
      minimumCheckinTime: yup.string().when("type", {
        is: (type: ServiceConfigType) => ServiceConfigType.EarlyCheckin === type,
        then: (schema) =>
          schema.required(t("labels__service_config_minimumcheckintime_not_provided"))
      }),

      // late checkout
      maximumCheckoutTime: yup.string().when("type", {
        is: (type: ServiceConfigType) => ServiceConfigType.LateCheckout === type,
        then: (schema) =>
          schema.required(t("labels__service_config_maximumcheckouttime_not_provided"))
      }),

      // add on & addtional cleaning
      granularity: yup
        .mixed<ServiceConfigGranularity>()
        .oneOf(
          Object.values(ServiceConfigGranularity),
          t("labels__service_config_granularity_unkown")
        )
        .when("type", {
          is: (type: ServiceConfigType) => ServiceConfigType.AdditionalCleaning === type,
          then: (schema) =>
            schema
              .required(t("labels__service_config_granularity_not_provided"))
              .oneOf(
                [ServiceConfigGranularity.AnyDayExceptArrivalDay, ServiceConfigGranularity.Never],
                t("labels__service_config_granularity_unsupported")
              )
        })
        .when("type", {
          is: (type: ServiceConfigType) => ServiceConfigType.AddOn === type,
          then: (schema) => schema.required(t("labels__service_config_granularity_not_provided"))
        }),
      defaultDatePickerSelection: yup
        .mixed<DefaultDatePickerSelection>()
        .oneOf(
          Object.values(DefaultDatePickerSelection),
          t("labels__service_config_defaultdatepickerselection_unknown")
        ),

      // addon
      addOnType: yup
        .mixed<AddOnType>()
        .oneOf(Object.values(AddOnType), t("labels__service_config_addontype_unknown"))
        .when("type", {
          is: (type: ServiceConfigType) => ServiceConfigType.AddOn === type,
          then: (schema) => schema.required(t("labels__service_config_addontype_not_provided"))
        }),
      canBookMultiple: yup.boolean().nullable(),
      bookableOnDepartureDay: yup.boolean().nullable(),
      bookOnExtendStay: yup.boolean().nullable(),
      availableAfterMidnight: yup.string().nullable(),
      defaultTargetAudience: yup.mixed<DefaultTargetAudience>().nullable(),
      tags: yup
        .array(
          yup
            .mixed<ServiceTag>()
            .oneOf(Object.values(ServiceTag), t("labels__service_config_servicetag_unkown"))
        )
        .when(["type", "addOnType"], {
          is: (type: ServiceConfigType, addOnType: AddOnType) =>
            ServiceConfigType.AddOn === type && AddOnType.Parking === addOnType,
          then: (schema) =>
            yup.array(
              yup
                .mixed<ServiceTag>()
                .oneOf([ServiceTag.Key], t("labels__service_config_servicetag_unsupported"))
            )
        }),
      postOnNextDay: yup.boolean().nullable()
    });
  }, [t, data, actorGroupOptions]);

  return {
    serviceConfigFormValidation
  };
};
