import type { FC } from "react";
import { Grid2 } from "@mui/material";
import { useProperty } from "../../hooks/use-property";
import { useTranslationWrapper } from "src/hooks/use-translation-wrapper";
import PageTitle from "src/components/page-title";
import { ReservationTable } from "../../features/reservations/reservation-table/reservation-table";
import { useIsMobile } from "../../hooks/use-is-mobile";
import { DEPARTURE_RESERVATIONS_SEARCH_FILTERS } from "src/components/table/hooks/use-search-filter-params";

const Departure: FC = () => {
  const { t } = useTranslationWrapper();
  const { selectedPropertyPreview } = useProperty();
  const isMobile = useIsMobile();

  return (
    <>
      {!isMobile && (
        <Grid2
          container
          spacing={3}
          size={{ xs: 12 }}
          sx={{
            alignItems: "center",
            justifyContent: "space-between"
          }}
        >
          <Grid2
            sx={{
              pb: 2.5
            }}
          >
            <PageTitle subTitle={selectedPropertyPreview} title={t("title__departures")} />
          </Grid2>
        </Grid2>
      )}
      <ReservationTable
        defaultFilter={DEPARTURE_RESERVATIONS_SEARCH_FILTERS}
        mobileTitleLabel={t("title__reservations")}
      />
    </>
  );
};

export default Departure;
