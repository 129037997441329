import { FC, useCallback, useMemo, useState } from "react";
import { Badge, Box, Grid2, Tab, Theme, useTheme } from "@mui/material";
import { useProperty } from "../../hooks/use-property";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import PageTitle from "src/components/page-title";
import { useTranslationWrapper } from "src/hooks/use-translation-wrapper";
import { useSelector } from "../../store";
import { pickerDateSelector } from "../../slices/date-picker";
import { makeStyles } from "tss-react/mui";
import { HousekeepingUnitsToClean } from "./housekeeping-units-to-clean";
import { HousekeepingAdditionalTasks } from "./housekeeping-additional-tasks";
import { HousekeepingLeftoverTasks } from "./housekeeping-leftover-tasks";
import { useGetHousekeepingCardsQueryEnhanced } from "../../graphql-tasks/query/enhanced-queries/get-housekeeping-cards-enhanced";
import { useGetHousekeepingTabCountsQueryEnhanced } from "src/graphql-tasks/query/enhanced-queries/get-housekeeping-tab-counts-enhanced";
import { ReloadButton } from "../../components/reload-button";
import { MaintenanceModal } from "../../components/maintenance-modal/maintenance-modal";
import { MaintenanceSlotArgs } from "../../store/endpoints/unit.endpoints";
import { HousekeepingForecast } from "./housekeeping-forecast";
import { ExportActionType, HousekeepingForecastActions } from "./housekeeping-forecast-actions";
import { useLazyGetForecastFileQuery } from "../../store/task-management-files.api";
import { downloadFile } from "../../utils/file-utils";
import { TaskType } from "../../graphql-tasks/generated/graphql";
import { useHousekeepingActions } from "../../hooks/use-housekeeping-actions";
import { useInvalidateHousekeepingCards } from "../../hooks/use-invalidate-housekeeping-cards";
import { isAfter } from "date-fns";
import { HousekeepingUnitsToCleanActions } from "./housekeeping-units-to-clean-actions";
import { useSearchFilterParams } from "../../components/table/hooks/use-search-filter-params";
import { useIsMobile } from "../../hooks/use-is-mobile";

enum TAB_VALUES {
  UNITS_TO_CLEAN = "1",
  ADDITIONAL_TASKS = "2",
  LEFTOVER_TASKS = "3",
  FORECAST = "4"
}

const useStyles = makeStyles()((theme: Theme) => ({
  tab: {
    paddingRight: theme.spacing(4),
    textTransform: "none",
    color: theme.palette.grey[600],
    fontWeight: "bold"
  },
  badge: {
    "& .MuiBadge-badge": {
      top: 9,
      right: -15,
      minWidth: 23,
      height: 20,
      backgroundColor: theme.palette.primary.main
    }
  }
}));

export const HousekeepingPage: FC = () => {
  const { t } = useTranslationWrapper();
  const { spacing } = useTheme();
  const isMobile = useIsMobile();
  const { selectedProperty, selectedPropertyPreview } = useProperty();
  const { classes } = useStyles();
  const date = useSelector(pickerDateSelector);
  const { setFilterMap } = useSearchFilterParams();
  const { data, isLoading } = useGetHousekeepingCardsQueryEnhanced({
    pmsPropertyId: selectedProperty?.propertyId ?? "",
    date
  });
  const housekeepingCards = data?.GetHousekeepingCards ?? { units: [], reservations: [] };
  const { refetch: refetchHousekeepingCards } = useGetHousekeepingCardsQueryEnhanced({
    pmsPropertyId: selectedProperty?.propertyId ?? "",
    date
  });
  const { data: tabCounts, refetch: refetchTabCounts } = useGetHousekeepingTabCountsQueryEnhanced({
    pmsPropertyId: selectedProperty?.propertyId ?? ""
  });
  const [tabValue, setTabValue] = useState(TAB_VALUES.UNITS_TO_CLEAN);
  const [getForecastFile] = useLazyGetForecastFileQuery();
  const { invalidateHousekeepingCards } = useInvalidateHousekeepingCards();

  const additionalTasks: boolean = useMemo(() => {
    return !!housekeepingCards?.units?.flatMap(
      (unit) =>
        unit?.housekeepingTasks?.filter(
          (task) =>
            task.type !== TaskType.DepartureCleaning && task.type !== TaskType.StayoverCleaning
        )
    ).length;
  }, [housekeepingCards?.units]);

  const allTaskIds: number[] = useMemo(() => {
    return housekeepingCards?.units
      ?.flatMap(
        (unit) =>
          unit?.housekeepingTasks?.filter(
            (task) => !(unit.occupied && task.type === TaskType.DepartureCleaning)
          ) ?? []
      )
      .map((task) => task.id);
  }, [housekeepingCards?.units]);

  const { bulkAction, addMaintenance, maintenanceModalOpen, setMaintenanceModalOpen } =
    useHousekeepingActions({ allTaskIds: allTaskIds });

  const handleTabChange = (_: TAB_VALUES, newValue: TAB_VALUES) => {
    setTabValue(newValue);
    setFilterMap({});
  };

  const onHousekeepingForecastActionClick = async (actionType: ExportActionType) => {
    const response = await getForecastFile({
      propertyId: selectedProperty?.propertyId ?? "",
      exportType: actionType
    });
    downloadFile(response?.data?.data ?? "", response?.data?.fileName ?? "");
  };

  const handleReloadAction = useCallback(() => {
    refetchHousekeepingCards();
    refetchTabCounts();
  }, [refetchHousekeepingCards, refetchTabCounts]);

  const futureDate = useMemo(() => {
    return isAfter(new Date(date), new Date());
  }, [date]);

  return (
    <>
      <Grid2 container sx={{ padding: isMobile ? 2 : "unset" }}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
            gap: 2,
            width: "100%"
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexWrap: "wrap",
              flex: 1,
              justifyContent: "space-between",
              gap: 2
            }}
          >
            <PageTitle subTitle={selectedPropertyPreview} title={t("labels__housekeeping")} beta />
            {tabValue === TAB_VALUES.UNITS_TO_CLEAN && (
              <Grid2
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: spacing(1)
                }}
              >
                <HousekeepingUnitsToCleanActions
                  onClick={bulkAction}
                  additionalTasks={additionalTasks}
                  allTaskIds={allTaskIds}
                />
              </Grid2>
            )}
            {tabValue === TAB_VALUES.FORECAST && (
              <Grid2
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: spacing(1)
                }}
              >
                <HousekeepingForecastActions onClick={onHousekeepingForecastActionClick} />
              </Grid2>
            )}
          </Box>
          <ReloadButton onClick={handleReloadAction} />
        </Box>

        <TabContext value={tabValue}>
          <Grid2
            container
            sx={{
              justifyContent: "space-between"
            }}
          >
            <Grid2 sx={{ mb: spacing(3) }}>
              <TabList
                onChange={(event, newValue) =>
                  handleTabChange(event as unknown as TAB_VALUES, newValue)
                }
                variant="scrollable"
              >
                <Tab
                  className={classes.tab}
                  value={TAB_VALUES.UNITS_TO_CLEAN}
                  label={
                    futureDate ? (
                      t("labels__units_to_clean")
                    ) : (
                      <Badge
                        className={classes.badge}
                        badgeContent={tabCounts?.GetHousekeepingTabCounts?.HOUSEKEEPING_CARDS}
                      >
                        {t("labels__units_to_clean")}
                      </Badge>
                    )
                  }
                />
                <Tab
                  className={classes.tab}
                  value={TAB_VALUES.ADDITIONAL_TASKS}
                  label={
                    <Badge
                      className={classes.badge}
                      badgeContent={tabCounts?.GetHousekeepingTabCounts?.ADDITIONAL_TASKS}
                    >
                      {t("labels__additional_tasks")}
                    </Badge>
                  }
                />
                <Tab
                  className={classes.tab}
                  value={TAB_VALUES.LEFTOVER_TASKS}
                  label={
                    <Badge
                      className={classes.badge}
                      badgeContent={tabCounts?.GetHousekeepingTabCounts?.LEFTOVER_TASKS}
                    >
                      {t("labels__leftover_tasks")}
                    </Badge>
                  }
                />
                <Tab
                  className={classes.tab}
                  value={TAB_VALUES.FORECAST}
                  label={<Badge className={classes.badge}>{t("labels__forecast")}</Badge>}
                />
              </TabList>
            </Grid2>
          </Grid2>
          <TabPanel value={TAB_VALUES.UNITS_TO_CLEAN} sx={{ width: "100%", p: 0 }}>
            <HousekeepingUnitsToClean
              selectedProperty={selectedProperty}
              housekeepingCards={housekeepingCards}
              isLoading={isLoading}
            />
          </TabPanel>
          <TabPanel value={TAB_VALUES.ADDITIONAL_TASKS} sx={{ width: "100%", p: 0 }}>
            <HousekeepingAdditionalTasks propertyId={selectedProperty?.propertyId} />
          </TabPanel>
          <TabPanel value={TAB_VALUES.LEFTOVER_TASKS} sx={{ width: "100%", p: 0 }}>
            <HousekeepingLeftoverTasks propertyId={selectedProperty?.propertyId} />
          </TabPanel>
          <TabPanel value={TAB_VALUES.FORECAST} sx={{ width: "100%", p: 0 }}>
            <HousekeepingForecast />
          </TabPanel>
        </TabContext>
      </Grid2>
      {maintenanceModalOpen && (
        <MaintenanceModal
          onSubmit={(values: MaintenanceSlotArgs) => {
            addMaintenance(values).then(() => {
              invalidateHousekeepingCards();
            });
          }}
          onClose={() => setMaintenanceModalOpen(false)}
        />
      )}
    </>
  );
};
