import { useMemo } from "react";
import { ReservationDetailsDTO } from "../../../../store/endpoints/reservation-table.endpoints";
import { ReservationStatus } from "../../../../domain/reservation-status";
import { useTranslationWrapper } from "../../../../hooks/use-translation-wrapper";
import { useProperty } from "../../../../hooks/use-property";
import { isPreStepDone, MonitoringSteps, Step } from "./monitoring-step.util";
import {
  formatDateTime,
  useGuestPreCheckinFlow,
  useGuestPreCheckoutFlow,
  usePropertyConfig
} from "@likemagic-tech/sv-magic-library";
import { getI18nSelectedLanguage } from "src/utils/language";
import { ReservationCheckInOutAuthor } from "../../../../graphql/generated/graphql";

const PRE_CHECKIN_PREFIX = "PRECHECKIN_";
const PRE_CHECKOUT_PREFIX = "PRECHECKOUT_";

export const useMonitoringSteps = (reservation: ReservationDetailsDTO) => {
  const { t } = useTranslationWrapper();
  const { selectedProperty } = useProperty();
  const language = getI18nSelectedLanguage();

  const lastConfirmedPage = `PRECHECKIN_${reservation.lastConfirmedPage}`;
  const lastConfirmedCheckoutPage = `PRECHECKOUT_${reservation.lastConfirmedCheckoutPage}`;
  const { features } = usePropertyConfig({ propertyId: reservation.propertyId });
  const { guestFlowPages } = useGuestPreCheckinFlow({
    upsellUnitGroupEnabled: !!features?.upsellUnitGroupEnabled,
    propertyId: reservation.propertyId
  });
  const { checkoutFlowPages } = useGuestPreCheckoutFlow({ propertyId: reservation.propertyId });

  const preCheckinStepsWithPrefix = guestFlowPages.map((step) => `${PRE_CHECKIN_PREFIX}${step}`);
  const preCheckoutStepsWithPrefix = checkoutFlowPages.map(
    (step) => `${PRE_CHECKOUT_PREFIX}${step}`
  );

  const checkoutPreSteps = useMemo(
    () =>
      features?.checkoutFlowEnabled
        ? preCheckoutStepsWithPrefix.map((page) => {
            return {
              id: page,
              informationText: t(`labels__monitoring_tooltip_${page}`),
              isPreStepDone: isPreStepDone({
                steps: Object.values(checkoutFlowPages),
                stepId: page,
                lastConfirmedPage: lastConfirmedCheckoutPage
              })
            };
          })
        : [],
    [
      checkoutFlowPages,
      features?.checkoutFlowEnabled,
      lastConfirmedCheckoutPage,
      preCheckoutStepsWithPrefix,
      t
    ]
  );

  const indexOfSecondScreenCheckpoint = useMemo(() => {
    const index = preCheckinStepsWithPrefix.findIndex(
      (step) => step === `${PRE_CHECKIN_PREFIX}${reservation.secondScreenCheckpoint}`
    );

    if (index === -1) {
      return undefined;
    }

    return index;
  }, [preCheckinStepsWithPrefix, reservation.secondScreenCheckpoint]);

  const steps: Step[] = useMemo(() => {
    return [
      {
        id: MonitoringSteps.BOOKING,
        informationText: t(`labels__monitoring_tooltip_${MonitoringSteps.BOOKING}`),
        dateTime: reservation.createdAt
          ? formatDateTime(reservation.createdAt, language, selectedProperty?.details.timeZone)
          : ""
      },
      {
        id: MonitoringSteps.IN_HOUSE,
        informationText: t(`labels__monitoring_tooltip_${MonitoringSteps.IN_HOUSE}`, {
          author: t(`labels__monitoring_legend_${reservation.checkedInBy ?? "UNKNOWN"}`)
        }),
        hasEmptySpace: !features?.checkoutFlowEnabled,
        author: reservation.checkedInBy,
        dateTime: reservation.checkInTime
          ? formatDateTime(reservation.checkInTime, language, selectedProperty?.details.timeZone)
          : "",

        preSteps: preCheckinStepsWithPrefix.map((preCheckinStep, index) => {
          return {
            id: preCheckinStep,
            informationText: t(`labels__monitoring_tooltip_${preCheckinStep}`, {
              channel: t(
                `labels__guest__communication__channel__${reservation.primaryGuest.preferredCommunicationChannel}`
              )
            }),
            author:
              indexOfSecondScreenCheckpoint && indexOfSecondScreenCheckpoint >= index
                ? ReservationCheckInOutAuthor.Backoffice
                : undefined,
            isPreStepDone: isPreStepDone({
              steps: preCheckinStepsWithPrefix,
              stepId: preCheckinStep,
              lastConfirmedPage
            })
          };
        })
      },
      {
        id: MonitoringSteps.CHECK_OUT,
        informationText: t(`labels__monitoring_tooltip_${MonitoringSteps.CHECK_OUT}`, {
          author: t(`labels__monitoring_legend_${reservation.checkedOutBy ?? "UNKNOWN"}`)
        }),
        author: reservation.checkedOutBy,
        dateTime: reservation.checkOutTime
          ? formatDateTime(reservation.checkOutTime, language, selectedProperty?.details.timeZone)
          : "",
        preSteps: checkoutPreSteps
      }
    ];
  }, [
    t,
    reservation.createdAt,
    reservation.checkedInBy,
    reservation.checkInTime,
    reservation.checkedOutBy,
    reservation.checkOutTime,
    reservation.primaryGuest.preferredCommunicationChannel,
    language,
    selectedProperty?.details.timeZone,
    features?.checkoutFlowEnabled,
    preCheckinStepsWithPrefix,
    checkoutPreSteps,
    indexOfSecondScreenCheckpoint,
    lastConfirmedPage
  ]);

  const currentStepId = useMemo(() => {
    if (reservation.reservationStatus === ReservationStatus.CHECKED_OUT) {
      return MonitoringSteps.CHECK_OUT;
    }

    if (
      Object.values(checkoutFlowPages).find(
        (step) => step === `PRECHECKOUT_${reservation.lastConfirmedCheckoutPage}`
      )
    ) {
      return `PRECHECKOUT_${reservation.lastConfirmedCheckoutPage}`;
    }

    if (reservation.reservationStatus === ReservationStatus.IN_HOUSE) {
      return MonitoringSteps.IN_HOUSE;
    }

    if (
      preCheckinStepsWithPrefix.find(
        (step) => step === `PRECHECKIN_${reservation.lastConfirmedPage}`
      )
    ) {
      return `PRECHECKIN_${reservation.lastConfirmedPage}`;
    }

    return MonitoringSteps.BOOKING;
  }, [
    reservation.reservationStatus,
    reservation.lastConfirmedCheckoutPage,
    reservation.lastConfirmedPage,
    checkoutFlowPages,
    preCheckinStepsWithPrefix
  ]);

  const flatSteps = useMemo(() => {
    return steps.reduce((acc, step) => {
      if (step.preSteps) {
        acc.push(...step.preSteps);
      }
      acc.push(step);
      return acc;
    }, [] as Step[]);
  }, [steps]);

  return { steps, currentStepId, flatSteps };
};
