import {
  DefaultServiceConfig,
  ServiceConfig,
  ServiceConfigConflict,
  ServiceConfigurationContainer,
  ServiceDependencyGroup
} from "../graphql-tenantconfig/generated/graphql";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { api as GetServiceConfigurationApi } from "../graphql-tenantconfig/queries/GetServiceConfiguration.generated";
import { RootState } from "../store";
import { api as ValidateServiceConfigurationApi } from "../graphql-tenantconfig/mutations/ValidateServiceConfiguration.generated";
import { api as MergeServiceConfigurationApi } from "../graphql-tenantconfig/mutations/MergeServiceConfiguration.generated";

interface ServiceConfigurationSandboxState {
  containers: any;
}

export interface ServiceConfigurationSandboxContainer {
  dirty: boolean;
  validated: boolean;
  published: boolean;
  sandbox?: ServiceConfigurationSandbox;
}

export interface ServiceConfigurationSandbox {
  serviceConfigs: ServiceConfig[];
  defaultServices: DefaultServiceConfig[];
  dependencyGroups: ServiceDependencyGroup[];
  conflicts: ServiceConfigConflict[];
}

const toServiceConfigurationSandbox = (
  container: ServiceConfigurationContainer
): ServiceConfigurationSandbox => {
  return {
    serviceConfigs: container.serviceConfigs ? [...container.serviceConfigs] : [],
    defaultServices: container.defaultServices ? [...container.defaultServices] : [],
    dependencyGroups: container.dependencyGroups ? [...container.dependencyGroups] : [],
    conflicts: container.conflicts ? [...container.conflicts] : []
  };
};

const initialState: ServiceConfigurationSandboxState = {
  containers: {}
};

const getContainer = (state: ServiceConfigurationSandboxState, pmsPropertyId: string) => {
  return (state.containers as any)[pmsPropertyId] as
    | ServiceConfigurationSandboxContainer
    | undefined;
};

const getSandbox = (state: ServiceConfigurationSandboxState, pmsPropertyId: string) => {
  return getContainer(state, pmsPropertyId)?.sandbox;
};

export const serviceConfigurationSandboxSlice = createSlice({
  name: "serviceConfigurationSandbox",
  initialState,
  reducers: {
    deleteServiceConfig: (
      state,
      action: PayloadAction<{ pmsPropertyId: string; serviceConfigId: string }>
    ) => {
      const pmsPropertyId = action.payload.pmsPropertyId;
      const container = getContainer(state, pmsPropertyId);
      const sandbox = getSandbox(state, pmsPropertyId);
      if (!!container && !!sandbox) {
        const index = sandbox.serviceConfigs.findIndex(
          (s) => s.id === action.payload.serviceConfigId
        );
        if (index >= 0) {
          sandbox.serviceConfigs = [
            ...sandbox.serviceConfigs!.slice(0, index),
            ...sandbox.serviceConfigs!.slice(index + 1)
          ];
          container.dirty = true;
          container.validated = false;
        }
      }
    },
    addOrReplaceServiceConfig: (
      state,
      action: PayloadAction<{ pmsPropertyId: string; serviceConfig: ServiceConfig }>
    ) => {
      const pmsPropertyId = action.payload.pmsPropertyId;
      const serviceConfig = action.payload.serviceConfig;

      const container = getContainer(state, pmsPropertyId);
      const sandbox = getSandbox(state, pmsPropertyId);
      if (!!container && !!sandbox) {
        if (!!serviceConfig.id) {
          const index = sandbox.serviceConfigs.findIndex((s) => s.id === serviceConfig.id);
          if (index >= 0) {
            sandbox.serviceConfigs = [
              ...sandbox.serviceConfigs!.slice(0, index),
              serviceConfig,
              ...sandbox.serviceConfigs!.slice(index + 1)
            ];
            container.dirty = true;
            container.validated = false;
          }
        } else {
          sandbox.serviceConfigs = [...sandbox.serviceConfigs, serviceConfig];
          container.dirty = true;
          container.validated = false;
        }
      }
    },
    deleteDependencyGroup: (
      state,
      action: PayloadAction<{ pmsPropertyId: string; dependencyGroupId: string }>
    ) => {
      const pmsPropertyId = action.payload.pmsPropertyId;
      const container = getContainer(state, pmsPropertyId);
      const sandbox = getSandbox(state, pmsPropertyId);
      if (!!container && !!sandbox) {
        const index = sandbox.dependencyGroups.findIndex(
          (s) => s.id === action.payload.dependencyGroupId
        );
        if (index >= 0) {
          sandbox.dependencyGroups = [
            ...sandbox.dependencyGroups!.slice(0, index),
            ...sandbox.dependencyGroups!.slice(index + 1)
          ];
          container.dirty = true;
          container.validated = false;
        }
      }
    },
    addOrReplaceDependencyGroup: (
      state,
      action: PayloadAction<{ pmsPropertyId: string; dependencyGroup: ServiceDependencyGroup }>
    ) => {
      const pmsPropertyId = action.payload.pmsPropertyId;
      const dependencyGroup = action.payload.dependencyGroup;

      const container = getContainer(state, pmsPropertyId);
      const sandbox = getSandbox(state, pmsPropertyId);
      if (!!container && !!sandbox) {
        if (!!dependencyGroup.id) {
          const index = sandbox.dependencyGroups.findIndex((s) => s.id === dependencyGroup.id);
          if (index >= 0) {
            sandbox.dependencyGroups = [
              ...sandbox.dependencyGroups!.slice(0, index),
              dependencyGroup,
              ...sandbox.dependencyGroups!.slice(index + 1)
            ];
            container.dirty = true;
            container.validated = false;
          }
        } else {
          sandbox.dependencyGroups = [...sandbox.dependencyGroups, dependencyGroup];
          container.dirty = true;
          container.validated = false;
        }
      }
    },
    deleteDefaultService: (
      state,
      action: PayloadAction<{ pmsPropertyId: string; defaultServiceId: string }>
    ) => {
      const pmsPropertyId = action.payload.pmsPropertyId;
      const container = getContainer(state, pmsPropertyId);
      const sandbox = getSandbox(state, pmsPropertyId);
      if (!!container && !!sandbox) {
        const index = sandbox.defaultServices.findIndex(
          (s) => s.id === action.payload.defaultServiceId
        );
        if (index >= 0) {
          sandbox.defaultServices = [
            ...sandbox.defaultServices!.slice(0, index),
            ...sandbox.defaultServices!.slice(index + 1)
          ];
          container.dirty = true;
          container.validated = false;
        }
      }
    },
    addOrReplaceDefaultService: (
      state,
      action: PayloadAction<{ pmsPropertyId: string; defaultService: DefaultServiceConfig }>
    ) => {
      const pmsPropertyId = action.payload.pmsPropertyId;
      const defaultService = action.payload.defaultService;

      const container = getContainer(state, pmsPropertyId);
      const sandbox = getSandbox(state, pmsPropertyId);
      if (!!container && !!sandbox) {
        if (!!defaultService.id) {
          const index = sandbox.defaultServices.findIndex((s) => s.id === defaultService.id);
          if (index >= 0) {
            sandbox.defaultServices = [
              ...sandbox.defaultServices!.slice(0, index),
              defaultService,
              ...sandbox.defaultServices!.slice(index + 1)
            ];
            container.dirty = true;
            container.validated = false;
          }
        } else {
          sandbox.defaultServices = [...sandbox.defaultServices, defaultService];
          container.dirty = true;
          container.validated = false;
        }
      }
    }
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      GetServiceConfigurationApi.endpoints.GetServiceConfiguration.matchFulfilled,
      (state, action) => {
        const pmsPropertyId = action.meta.arg.originalArgs.pmsPropertyId;
        const containers = state.containers;
        if (containers[pmsPropertyId] && containers[pmsPropertyId].dirty) {
          // do not replace dirty sandboxes
          return;
        }
        containers[pmsPropertyId] = {
          ...containers[pmsPropertyId],
          sandbox: toServiceConfigurationSandbox(action.payload.GetServiceConfiguration),
          dirty: false,
          validated: true,
          published: containers[pmsPropertyId]?.published ?? false
        };
      }
    );

    builder.addMatcher(
      ValidateServiceConfigurationApi.endpoints.ValidateServiceConfiguration.matchFulfilled,
      (state, action) => {
        const pmsPropertyId = action.meta.arg.originalArgs.pmsPropertyId;
        const containers = state.containers;
        containers[pmsPropertyId] = {
          ...containers[pmsPropertyId],
          sandbox: toServiceConfigurationSandbox(action.payload.ValidateServiceConfiguration),
          validated: true,
          published: false
        };
      }
    );

    builder.addMatcher(
      MergeServiceConfigurationApi.endpoints.MergeServiceConfiguration.matchFulfilled,
      (state, action) => {
        const pmsPropertyId = action.meta.arg.originalArgs.pmsPropertyId;
        const containers = state.containers;
        containers[pmsPropertyId] = {
          ...containers[pmsPropertyId],
          sandbox: toServiceConfigurationSandbox(action.payload.MergeServiceConfiguration),
          dirty: false,
          validated: true,
          published: true
        };
      }
    );
  }
});

export const {
  deleteServiceConfig,
  addOrReplaceServiceConfig,
  deleteDependencyGroup,
  addOrReplaceDependencyGroup,
  deleteDefaultService,
  addOrReplaceDefaultService
} = serviceConfigurationSandboxSlice.actions;

export const selectServiceConfigurationSandboxSlice = (state: RootState) =>
  state.serviceConfigurationSandbox;

export const selectServiceConfigurationSandboxContainer = (
  state: RootState,
  pmsPropertyId: string
): ServiceConfigurationSandboxContainer =>
  ((selectServiceConfigurationSandboxSlice(state).containers as any)[
    pmsPropertyId
  ] as ServiceConfigurationSandboxContainer) ?? {};
