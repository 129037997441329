import { useTranslationWrapper } from "../../../../hooks/use-translation-wrapper";
import { useMemo } from "react";
import yup from "../../../../config/yup.config";
import { useGetServiceConfigurationQueryEnhanced } from "../../../../graphql-tenantconfig/queries/enhanced-queries/get-service-configuration-enhanced";
import { useProperty } from "../../../../hooks/use-property";

export const useDependencyGroupFormValidations = () => {
  const { t } = useTranslationWrapper();
  const { selectedProperty } = useProperty();
  const { data } = useGetServiceConfigurationQueryEnhanced(
    { pmsPropertyId: selectedProperty.propertyId },
    {}
  );

  const dependencyGroupFormValidation = useMemo(() => {
    const validServiceIds = data?.GetServiceConfiguration?.pmsServices?.map((s) => s.pmsId) ?? [];

    return yup.object().shape({
      key: yup
        .string()
        .nullable()
        .trim()
        .matches(/^[a-zAZ0-9_]+$/, t("labels__dependency_group_key_invalid_characters"))
        .required(t("labels__dependency_group_key_not_provided")),
      services: yup
        .array(
          yup.object().shape({
            serviceId: yup
              .string()
              .required(t("labels__dependency_group_services_not_provided"))
              .oneOf(validServiceIds, t("labels__service_config_pms_service_unknown"))
          })
        )
        .required(t("labels__dependency_group_services_not_provided"))
        .min(1, t("labels__dependency_group_services_not_provided"))
    });
  }, [t, data]);

  return {
    dependencyGroupFormValidation
  };
};
