import React, { FC, PropsWithChildren, useMemo } from "react";
import { Outlet, useLocation } from "react-router-dom";
import DashboardSidebar from "./sidebar/dashboard-sidebar";
import { useProperty } from "../../hooks/use-property";
import { KeysSpeedDial } from "../keys-speed-dial";
import { Box, Collapse as MuiCollapse, CSSObject, styled, Theme } from "@mui/material";
import { useBanner } from "../../slices/use-banner";
import MuiAppBar, { AppBarProps as MuiAppBarProps } from "@mui/material/AppBar";
import { useSelector } from "react-redux";
import { selectIsOpenSideNavigation } from "src/slices/dashboard-sidebar";
import { ActionNotification } from "../action-notification";
import { TaskModal } from "src/features/tasks/task-modal/task-modal";
import { ReservationDetails } from "src/features/reservations/reservation-details/reservation-details/reservation-details";
import { GlobalSubscribe } from "../../features/global-subscribe/global-subscribe";
import { ManualCheckin } from "../../features/reservations/manual-checkin/manual-checkin";
import { ConversationDetails } from "../../messaging/conversation-details";
import { ProfileDrawer } from "../../features/profiles/profile-drawer/profile-drawer";
import {
  HOST_ROLE,
  HOUSEKEEPER_ROLE,
  useAuth,
  usePropertyConfig
} from "@likemagic-tech/sv-magic-library";
import DashboardNavbar from "./dashboard-navbar";
import { useIsMobile } from "../../hooks/use-is-mobile";
import { BookingDetails } from "../../bookings/booking-details";

const Collapse = styled(MuiCollapse)(({ theme }) => ({
  zIndex: theme.zIndex.drawer + 1,
  maxWidth: "500px",
  position: "absolute",
  bottom: "16px",
  right: "16px"
}));

const DashboardLayoutWrapper = styled("div")(({ theme }) => ({
  display: "flex",
  flex: "1 1 auto",
  overflow: "hidden",
  paddingTop: useIsMobile() && theme.spacing(7)
}));

const DashboardLayoutContainer = styled("div")({
  display: "flex",
  flex: "1 1 auto",
  overflow: "hidden"
});

const DashboardLayoutContent = styled("div")(({ theme }) => ({
  flex: "1 1 auto",
  height: "100%",
  overflow: "auto",
  position: "relative",
  WebkitOverflowScrolling: "touch",
  paddingRight: !useIsMobile() && theme.spacing(2),
  paddingTop: !useIsMobile() && theme.spacing(2)
}));

const DashboardLayoutRoot = styled("div")(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  display: "flex",
  height: "100%",
  overflow: "hidden",
  width: "100%"
}));

export const openDrawerWidth = 220;
export const closedDrawerWidth = 64;

export const openedMixin = (theme: Theme): CSSObject => ({
  width: openDrawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen
  }),
  overflowX: "hidden"
});

export const closedMixin = (theme: Theme): CSSObject => ({
  width: closedDrawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen
  }),
  overflowX: "hidden"
});

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open"
})<AppBarProps>(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  backgroundColor: `${theme.palette.primary.main} !important`,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen
  }),
  ...(open && {
    marginLeft: openDrawerWidth,
    width: `calc(100% - ${openDrawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    })
  })
}));

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}
const disabledRoutesForSpeedDial = ["/messaging", "/tasks", "/profiles"];

export const DashboardLayoutTemplate: FC<PropsWithChildren> = ({ children }) => {
  const { selectedPropertyIds, selectedProperty } = useProperty();
  const openSideNavigation = useSelector(selectIsOpenSideNavigation);
  const { bannerState, onClose } = useBanner();
  const { pathname } = useLocation();
  const { hasRole, hasAdminOrOperatorRole } = useAuth();
  const isMobile = useIsMobile();

  const { backofficeSpeedDialButtonPages } = usePropertyConfig({
    propertyId: selectedProperty?.propertyId
  });

  const showSpeedDial = useMemo(() => {
    if (backofficeSpeedDialButtonPages?.length) {
      return backofficeSpeedDialButtonPages.includes(pathname);
    } else {
      return (
        !disabledRoutesForSpeedDial.includes(pathname) &&
        (hasAdminOrOperatorRole() || hasRole(HOST_ROLE) || hasRole(HOUSEKEEPER_ROLE))
      );
    }
  }, [backofficeSpeedDialButtonPages, hasAdminOrOperatorRole, hasRole, pathname]);

  return (
    <DashboardLayoutRoot>
      {isMobile && (
        <AppBar open={openSideNavigation}>
          <DashboardNavbar />
        </AppBar>
      )}
      <Collapse in={bannerState.isOpen} sx={{ zIndex: 1301 }}>
        <Box>
          <ActionNotification
            onClick={onClose}
            type={bannerState.type}
            title={bannerState.title}
            errorId={bannerState.errorId}
          />
        </Box>
      </Collapse>
      <DashboardSidebar />
      <TaskModal />
      <ReservationDetails />
      <ProfileDrawer />
      <ManualCheckin />
      <ConversationDetails />
      <BookingDetails />
      <GlobalSubscribe />
      {selectedPropertyIds.length === 1 && showSpeedDial && (
        <KeysSpeedDial propertyId={selectedPropertyIds[0] ?? ""} />
      )}
      <DashboardLayoutWrapper>
        <DashboardLayoutContainer>
          <DashboardLayoutContent>{children}</DashboardLayoutContent>
        </DashboardLayoutContainer>
      </DashboardLayoutWrapper>
    </DashboardLayoutRoot>
  );
};
export const DashboardLayout: FC = () => {
  return (
    <DashboardLayoutTemplate>
      <Outlet />
    </DashboardLayoutTemplate>
  );
};
