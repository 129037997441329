import { FC } from "react";
import { ChatItemContentBaseProps } from "./domain/chat-item-content-base-props";
import { Paragraph } from "@likemagic-tech/sv-magic-library";
import { useTheme } from "@mui/material/styles";
import { ImagePreview } from "../../../components/image-preview/image-preview";

interface ChatItemContentImageProps extends ChatItemContentBaseProps {
  caption?: string;
  zoomInLabel?: string;
}

export const ChatItemContentImage: FC<ChatItemContentImageProps> = ({
  content,
  caption,
  isLoggedUser,
  zoomInLabel
}) => {
  const { palette } = useTheme();

  return (
    <>
      <ImagePreview
        link={content}
        hoverText={zoomInLabel}
        style={{ cursor: "pointer" }}
        fileName={content.fileName}
      />
      <Paragraph
        sx={{
          color: !isLoggedUser ? palette.background.paper : palette.primary.main
        }}
      >
        {caption}
      </Paragraph>
    </>
  );
};
