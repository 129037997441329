import { PaymentType, RefundStatus } from "../generated/graphql";
import { PaymentTypeEnum, RefundStatusEnum } from "src/features/reservations/domain/enums";
import { PaymentAccountType } from "@likemagic-tech/sv-magic-library";

export const transformPaymentTypeV2ToCommon = (paymentTypeV2?: PaymentType): PaymentTypeEnum => {
  switch (paymentTypeV2) {
    case PaymentType.CreditCard:
      return PaymentTypeEnum.CC;
    case PaymentType.Cash:
      return PaymentTypeEnum.CASH;
    case PaymentType.Others:
      return PaymentTypeEnum.OTHERS;
    case PaymentType.Invoice:
      return PaymentTypeEnum.INVOICE;
    default:
      return PaymentTypeEnum.NONE;
  }
};

export const transformRefundStatusV2ToCommon = (
  refundStatus?: RefundStatus | null
): RefundStatusEnum | null => {
  switch (refundStatus) {
    case RefundStatus.Initiated:
      return RefundStatusEnum.INITIATED;
    case RefundStatus.Refunded:
      return RefundStatusEnum.REFUNDED;
    case RefundStatus.Failed:
      return RefundStatusEnum.FAILED;
    default:
      return null;
  }
};

export const transformTokenizedCardTypeToCommon = (brand?: string | null): PaymentAccountType => {
  switch (brand) {
    case "Visa":
      return PaymentAccountType.VISA;
    case "Mc":
      return PaymentAccountType.MC;
    case "Maestro":
      return PaymentAccountType.MAESTRO;
    case "Diners":
      return PaymentAccountType.DINERS;
    case "Discover":
      return PaymentAccountType.DISCOVER;
    case "Amex":
      return PaymentAccountType.AMEX;
    default:
      return PaymentAccountType.UNKNOWN;
  }
};
