import { Params, useSelectId } from "../../../../hooks/use-select-id";

export const useDefaultServiceModal = () => {
  const { selectedId, setSelectId } = useSelectId({
    fieldName: Params.DEFAULT_SERVICE_ID_SEARCH_PARAM_NAME
  });

  return {
    defaultServiceId: selectedId ?? undefined,
    openNewDefaultService: () => setSelectId(Params.CREATE_DEFAULT_SERVICE_PARAM),
    openEditDefaultService: (defaultServiceId: string) => setSelectId(defaultServiceId),
    onClose: (event?: object, reason?: string) => {
      if (reason === "backdropClick" || reason === "escapeKeyDown") {
        // do not close service config modal on backdrop click or escape key
        return;
      }
      setSelectId(
        undefined,
        new URLSearchParams({
          [Params.DEFAULT_SERVICE_ID_SEARCH_PARAM_NAME]: ""
        })
      );
    }
  };
};
