import React, { FC, useMemo, useState } from "react";
import { Box, Divider, Grid2, IconButton, useTheme } from "@mui/material";
import {
  Checkbox,
  formatDateTime,
  Paragraph,
  ParagraphBold,
  ParagraphSmall,
  usePropertyConfig
} from "@likemagic-tech/sv-magic-library";
import { ActionsType, AddActionsButtons } from "./add-actions-buttons";
import { isFolioClosed } from "./payment-utils";
import { ChecklistRtl } from "@mui/icons-material";
import { PricePreview } from "../../../components/price-preview/price-preview";
import { ChargeActionsMenu } from "./action-menus/charge-actions-menu";
import { FolioPreview } from "../../../store/endpoints/reservation-table.endpoints";
import { useIsMobile } from "../../../hooks/use-is-mobile";
import { AddChargeModal } from "./modals/add-charge-modal";
import { useOrderItemTransactionTypes } from "../../../hooks/use-order-item-transaction-types";
import { useTranslationWrapper } from "../../../hooks/use-translation-wrapper";
import { getI18nSelectedLanguage } from "src/utils/language";

interface FolioItemsDetailsChargesProps {
  folios: FolioPreview[];
  folio: FolioPreview;
  reservationId: string;
  propertyTimeZone: string;
  propertyId: string;
  setShowPaymentOption: (value: boolean) => void;
}

export const FolioItemsDetailsCharges: FC<FolioItemsDetailsChargesProps> = ({
  folio,
  folios,
  reservationId,
  propertyTimeZone,
  propertyId,
  setShowPaymentOption
}) => {
  const { t } = useTranslationWrapper();
  const [selectedCharges, setSelectedCharges] = useState<string[]>([]);
  const [chargesSelectionEnabled, setChargesSelectionEnabled] = useState(false);
  const [openAddChargesModal, setOpenAddChargesModal] = useState(false);
  const isMobile = useIsMobile();
  const language = getI18nSelectedLanguage();
  const { features } = usePropertyConfig({
    propertyId: propertyId
  });
  const { palette } = useTheme();

  const { data: orderItemTransactionTypes, isLoading } =
    useOrderItemTransactionTypes({
      pmsPropertyId: propertyId,
      skip: !features?.billingTabAddChargeEnabled
    }) ?? [];

  const handleAddChargeCloseModal = () => {
    setOpenAddChargesModal(false);
  };

  const sortedFolioCharges = useMemo(() => {
    return folio.charges.sort((a, b) => {
      return new Date(a.created).getTime() - new Date(b.created).getTime();
    });
  }, [folio.charges]);

  return (
    <>
      <Grid2
        size={{ xs: 12, md: 6 }}
        sx={{
          p: 2,
          [isMobile ? "borderBottom" : "borderRight"]: `1px solid ${palette.grey[300]}`
        }}
      >
        <Grid2
          container
          sx={{
            justifyContent: "space-between",
            alignItems: "center",
            minHeight: 40
          }}
        >
          <ParagraphBold>{t("labels__charges")}</ParagraphBold>
          {features?.billingTabAddChargeEnabled && (
            <AddActionsButtons
              type={ActionsType.Charge}
              addDisabled={isFolioClosed(folio)}
              showActions={chargesSelectionEnabled}
              folio={folio}
              folios={folios}
              reservationId={reservationId}
              propertyId={propertyId}
              selectedIds={selectedCharges}
              onAddClick={() => {
                setShowPaymentOption(false);
                setOpenAddChargesModal(true);
              }}
              onDoneClick={() => {
                setSelectedCharges([]);
                setChargesSelectionEnabled(false);
              }}
            />
          )}
        </Grid2>

        <Grid2
          container
          sx={{
            justifyContent: "space-between",
            alignItems: "center",
            minHeight: 24
          }}
        >
          <ParagraphSmall color={palette.grey[600]}>{t("labels__charge_date")}</ParagraphSmall>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              mr: features?.billingTabChargeOptionsEnabled ? -1 : -0.3
            }}
          >
            <ParagraphSmall color={palette.grey[600]} mr={0.25}>
              {t("labels__amount")}
            </ParagraphSmall>
            {features?.billingTabChargeOptionsEnabled && !chargesSelectionEnabled && (
              <IconButton
                sx={{ px: 0.75, py: 0 }}
                size="small"
                color="primary"
                onClick={() => setChargesSelectionEnabled(true)}
                disableRipple
                disabled={folio.charges.length < 2}
              >
                <ChecklistRtl />
              </IconButton>
            )}
            {features?.billingTabChargeOptionsEnabled && chargesSelectionEnabled && (
              <Checkbox
                sx={{ px: 0.75, py: 0, ml: 1.375 }}
                id="select-all-charges"
                checked={folio.charges.every((charge) => selectedCharges.includes(charge.pmsId))}
                onChange={(event, checked) => {
                  if (checked) {
                    setSelectedCharges(folio.charges.map((charge) => charge.pmsId));
                  } else {
                    setSelectedCharges([]);
                  }
                }}
                disableRipple
              />
            )}
          </Box>
        </Grid2>

        <Divider sx={{ my: 1 }} />

        {sortedFolioCharges.length ? (
          sortedFolioCharges.map((charge, index) => (
            <div key={`${charge.id}-${index}`}>
              <Grid2
                container
                sx={{
                  justifyContent: "space-between",
                  alignItems: "center"
                }}
              >
                <Grid2 size={{ xs: 5 }}>
                  <Paragraph>{charge.name}</Paragraph>
                  {charge.subName && (
                    <ParagraphSmall color={palette.grey[600]}>{charge.subName}</ParagraphSmall>
                  )}
                  {charge.note && (
                    <ParagraphSmall color={palette.grey[600]}>{charge.note}</ParagraphSmall>
                  )}
                  {charge.reference && (
                    <ParagraphSmall color={palette.grey[600]}>{charge.reference}</ParagraphSmall>
                  )}
                  <ParagraphSmall color={palette.grey[600]}>
                    {formatDateTime(charge.created, language, propertyTimeZone)}
                  </ParagraphSmall>
                </Grid2>
                <Grid2
                  size={{ xs: 6 }}
                  sx={{
                    textAlign: "right",
                    height: 20,
                    display: "flex",
                    justifyContent: "flex-end",
                    alignItems: "center"
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center"
                    }}
                  >
                    <PricePreview price={charge.price} />
                    {features?.billingTabChargeOptionsEnabled && !chargesSelectionEnabled && (
                      <ChargeActionsMenu
                        reservationId={reservationId}
                        orderItemPmsIds={[charge.pmsId]}
                        folioId={folio.id}
                        folioMetadata={folio.metadata}
                        isFolioClosed={isFolioClosed(folio)}
                        folios={folios}
                        hasSelect={folio.charges.length > 1}
                        onSelectCharge={() => {
                          setChargesSelectionEnabled(true);
                          setSelectedCharges([...selectedCharges, charge.pmsId]);
                        }}
                        propertyId={propertyId}
                      />
                    )}
                    {features?.billingTabChargeOptionsEnabled && chargesSelectionEnabled && (
                      <Checkbox
                        sx={{ px: 1, py: 0, pr: 0, ml: 1.375 }}
                        id={`select-${charge.pmsId}`}
                        checked={selectedCharges.includes(charge.pmsId)}
                        onChange={(_, checked) => {
                          if (checked) {
                            setSelectedCharges([...selectedCharges, charge.pmsId]);
                          } else {
                            setSelectedCharges(selectedCharges.filter((id) => id !== charge.pmsId));
                          }
                        }}
                        disableRipple
                      />
                    )}
                  </Box>
                </Grid2>
              </Grid2>
              <Divider sx={{ my: 1 }} />
            </div>
          ))
        ) : (
          <ParagraphSmall color={palette.grey[600]}>
            {t("labels__no_items_available")}{" "}
          </ParagraphSmall>
        )}
      </Grid2>
      {features?.billingTabAddChargeEnabled && openAddChargesModal && (
        <AddChargeModal
          onClose={handleAddChargeCloseModal}
          orderItemTransactionTypes={orderItemTransactionTypes}
          isLoading={isLoading}
          reservationId={reservationId}
          folio={folio}
        />
      )}
    </>
  );
};
