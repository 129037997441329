import { PersonGender } from "src/domain/person";
import { GetUserProfilesQuery } from "../queries/GetUserProfiles.generated";
import { CommunicationChannel as CommunicationChannelV2 } from "../generated/graphql";
import { CommunicationChannel } from "@likemagic-tech/sv-magic-library";
import { UserProfile } from "src/domain/user-profile";

type UserProfileV2 = GetUserProfilesQuery["GetUserProfiles"]["content"][number];
export const transformUserProfileV2ToCommon = (userProfileV2: UserProfileV2): UserProfile => {
  return {
    id: userProfileV2?.id,
    createdAt: "",
    updateAt: "",
    person: {
      address: {
        addressLine1: "",
        postalCode: "",
        city: "",
        countryCode: ""
      },
      birthdate: "",
      email: userProfileV2.email,
      firstName: userProfileV2.firstName,
      gender: PersonGender.UNKNOW,
      guestPreferences: userProfileV2.preferences,
      guestPreferencesText: "",
      identificationNumber: "",
      identificationType: "",
      isRecurring: userProfileV2.isRecurring,
      lastName: userProfileV2.lastName,
      nationalityCountryCode: userProfileV2.nationality,
      phone: userProfileV2.phone,
      preferredCommunicationChannel: mapPreferredCommunicationChannelV2ToV1(
        userProfileV2?.preferredCommunicationChannel
      ),
      isBlacklisted: userProfileV2.blacklisted,
      preferredLanguage: userProfileV2.preferredLanguage,
      hasAccount: false,
      id: userProfileV2?.id.toString()
    },
    tenant: {
      id: 0,
      name: "",
      description: "",
      createdAt: "",
      updateAt: "",
      version: 0
    },
    emailVerified: true,
    keycloakUUID: "",
    guestIdentityId: userProfileV2.guestIdentityId?.toString() ?? "",
    preferredCommunicationChannel: mapPreferredCommunicationChannelV2ToV1(
      userProfileV2?.preferredCommunicationChannel
    ),
    tcGeneral: false,
    tcOnline: false,
    tcMarketingConsent: false,
    tcMarketingConsentDoubleOptin: false,
    guestPreferencesText: "",
    tenantName: ""
  } as UserProfile;
};

const mapPreferredCommunicationChannelV2ToV1 = (
  preferredCommunicationChannel: CommunicationChannelV2 | null | undefined
): CommunicationChannel => {
  switch (preferredCommunicationChannel) {
    case CommunicationChannelV2.Email:
      return CommunicationChannel.EMAIL;
    case CommunicationChannelV2.Sms:
      return CommunicationChannel.SMS;
    case CommunicationChannelV2.Whatsapp:
      return CommunicationChannel.WHATSAPP;
    default:
      return CommunicationChannel.EMAIL;
  }
};
