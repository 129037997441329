import * as Types from "../generated/graphql";

import { ServiceIdentifierFragmentDoc } from "./ServiceIdentifier.generated";
import { ServiceContractFragmentDoc } from "./ServiceContract.generated";
import { UnitGroupIdentifierFragmentDoc } from "./UnitGroupIdentifier.generated";
import { UnitGroupContractFragmentDoc } from "./UnitGroupContract.generated";
import { TaskConfigFragmentDoc } from "./TaskConfig.generated";
import { ServiceConfigConflictFragmentDoc } from "./ServiceConfigConflict.generated";
import { DateRangeFragmentDoc } from "./DateRange.generated";
export type ServiceConfig_AddOnServiceConfig_Fragment = {
  __typename?: "AddOnServiceConfig";
  minimumBefore?: string | null;
  maximumBefore?: string | null;
  maximum?: number | null;
  bookableDays?: Array<Types.BookableDay> | null;
  bookingChannels?: Array<string> | null;
  reservationStatuses?: Array<Types.ReservationStatus> | null;
  visibilities?: Array<Types.Visibility> | null;
  payAtCheckout?: boolean | null;
  addOnType: Types.AddOnType;
  canBookMultiple?: boolean | null;
  bookableOnDepartureDay?: boolean | null;
  bookOnExtendStay?: boolean | null;
  availableAfterMidnight?: string | null;
  granularity?: Types.ServiceConfigGranularity | null;
  defaultTargetAudience?: Types.DefaultTargetAudience | null;
  tags?: Array<Types.ServiceTag> | null;
  defaultDatePickerSelection?: Types.DefaultDatePickerSelection | null;
  postOnNextDay?: boolean | null;
  id: string;
  serviceConfigHash: number;
  type: Types.ServiceConfigType;
  whitelistedDateRanges?: Array<{
    __typename?: "DateRange";
    startInclusive: any;
    endInclusive: any;
  }> | null;
  blacklistedDateRanges?: Array<{
    __typename?: "DateRange";
    startInclusive: any;
    endInclusive: any;
  }> | null;
  service: {
    __typename?: "ServiceIdentifier";
    serviceId: string;
    details?: {
      __typename?: "ServiceContract";
      active?: boolean | null;
      chargeMode?: Types.ChargeMode | null;
      chargeUnit?: Types.ChargeUnit | null;
      description?: string | null;
      displayName?: string | null;
      id?: string | null;
      included?: boolean | null;
      name?: string | null;
      pmsCategoryId?: string | null;
      pmsId: string;
      pmsUpdatedAt?: any | null;
      price?: {
        __typename?: "Price";
        currency?: string | null;
        grossPrice?: any | null;
        netPrice?: any | null;
        taxes?: Array<{ __typename?: "Tax"; amount?: any | null; code?: string | null }> | null;
      } | null;
    } | null;
  };
  unitGroups?: Array<{
    __typename?: "UnitGroupIdentifier";
    unitGroupId: string;
    details?: {
      __typename?: "UnitGroupContract";
      active?: boolean | null;
      capacity?: number | null;
      extraCapacity?: number | null;
      id?: string | null;
      name?: string | null;
      pmsId: string;
      pmsPropertyId?: string | null;
      pmsUpdatedAt?: any | null;
      type?: string | null;
    } | null;
  }> | null;
  task?: {
    __typename?: "TaskConfig";
    housekeepingMainTask?: boolean | null;
    assigneeGroup?: string | null;
    createMultipleTasks?: boolean | null;
    dueDuration?: string | null;
    dueDurationType?: Types.DueDurationType | null;
    granularity?: Types.TaskGranularity | null;
    priority?: Types.Priority | null;
  } | null;
  conflicts?: Array<{
    __typename?: "ServiceConfigConflict";
    type: Types.ConflictType;
    relatedServiceId?: string | null;
    relatedUnitGroupId?: string | null;
    relatedFieldName?: string | null;
  }> | null;
};

export type ServiceConfig_AdditionalCleaningServiceConfig_Fragment = {
  __typename?: "AdditionalCleaningServiceConfig";
  minimumBefore?: string | null;
  maximumBefore?: string | null;
  maximum?: number | null;
  bookableDays?: Array<Types.BookableDay> | null;
  bookingChannels?: Array<string> | null;
  reservationStatuses?: Array<Types.ReservationStatus> | null;
  visibilities?: Array<Types.Visibility> | null;
  payAtCheckout?: boolean | null;
  granularity?: Types.ServiceConfigGranularity | null;
  defaultDatePickerSelection?: Types.DefaultDatePickerSelection | null;
  id: string;
  serviceConfigHash: number;
  type: Types.ServiceConfigType;
  whitelistedDateRanges?: Array<{
    __typename?: "DateRange";
    startInclusive: any;
    endInclusive: any;
  }> | null;
  blacklistedDateRanges?: Array<{
    __typename?: "DateRange";
    startInclusive: any;
    endInclusive: any;
  }> | null;
  service: {
    __typename?: "ServiceIdentifier";
    serviceId: string;
    details?: {
      __typename?: "ServiceContract";
      active?: boolean | null;
      chargeMode?: Types.ChargeMode | null;
      chargeUnit?: Types.ChargeUnit | null;
      description?: string | null;
      displayName?: string | null;
      id?: string | null;
      included?: boolean | null;
      name?: string | null;
      pmsCategoryId?: string | null;
      pmsId: string;
      pmsUpdatedAt?: any | null;
      price?: {
        __typename?: "Price";
        currency?: string | null;
        grossPrice?: any | null;
        netPrice?: any | null;
        taxes?: Array<{ __typename?: "Tax"; amount?: any | null; code?: string | null }> | null;
      } | null;
    } | null;
  };
  unitGroups?: Array<{
    __typename?: "UnitGroupIdentifier";
    unitGroupId: string;
    details?: {
      __typename?: "UnitGroupContract";
      active?: boolean | null;
      capacity?: number | null;
      extraCapacity?: number | null;
      id?: string | null;
      name?: string | null;
      pmsId: string;
      pmsPropertyId?: string | null;
      pmsUpdatedAt?: any | null;
      type?: string | null;
    } | null;
  }> | null;
  task?: {
    __typename?: "TaskConfig";
    housekeepingMainTask?: boolean | null;
    assigneeGroup?: string | null;
    createMultipleTasks?: boolean | null;
    dueDuration?: string | null;
    dueDurationType?: Types.DueDurationType | null;
    granularity?: Types.TaskGranularity | null;
    priority?: Types.Priority | null;
  } | null;
  conflicts?: Array<{
    __typename?: "ServiceConfigConflict";
    type: Types.ConflictType;
    relatedServiceId?: string | null;
    relatedUnitGroupId?: string | null;
    relatedFieldName?: string | null;
  }> | null;
};

export type ServiceConfig_EarlyCheckinServiceConfig_Fragment = {
  __typename?: "EarlyCheckinServiceConfig";
  minimumBefore?: string | null;
  maximumBefore?: string | null;
  maximum?: number | null;
  bookableDays?: Array<Types.BookableDay> | null;
  bookingChannels?: Array<string> | null;
  reservationStatuses?: Array<Types.ReservationStatus> | null;
  visibilities?: Array<Types.Visibility> | null;
  payAtCheckout?: boolean | null;
  minimumCheckinTime?: any | null;
  id: string;
  serviceConfigHash: number;
  type: Types.ServiceConfigType;
  whitelistedDateRanges?: Array<{
    __typename?: "DateRange";
    startInclusive: any;
    endInclusive: any;
  }> | null;
  blacklistedDateRanges?: Array<{
    __typename?: "DateRange";
    startInclusive: any;
    endInclusive: any;
  }> | null;
  service: {
    __typename?: "ServiceIdentifier";
    serviceId: string;
    details?: {
      __typename?: "ServiceContract";
      active?: boolean | null;
      chargeMode?: Types.ChargeMode | null;
      chargeUnit?: Types.ChargeUnit | null;
      description?: string | null;
      displayName?: string | null;
      id?: string | null;
      included?: boolean | null;
      name?: string | null;
      pmsCategoryId?: string | null;
      pmsId: string;
      pmsUpdatedAt?: any | null;
      price?: {
        __typename?: "Price";
        currency?: string | null;
        grossPrice?: any | null;
        netPrice?: any | null;
        taxes?: Array<{ __typename?: "Tax"; amount?: any | null; code?: string | null }> | null;
      } | null;
    } | null;
  };
  unitGroups?: Array<{
    __typename?: "UnitGroupIdentifier";
    unitGroupId: string;
    details?: {
      __typename?: "UnitGroupContract";
      active?: boolean | null;
      capacity?: number | null;
      extraCapacity?: number | null;
      id?: string | null;
      name?: string | null;
      pmsId: string;
      pmsPropertyId?: string | null;
      pmsUpdatedAt?: any | null;
      type?: string | null;
    } | null;
  }> | null;
  task?: {
    __typename?: "TaskConfig";
    housekeepingMainTask?: boolean | null;
    assigneeGroup?: string | null;
    createMultipleTasks?: boolean | null;
    dueDuration?: string | null;
    dueDurationType?: Types.DueDurationType | null;
    granularity?: Types.TaskGranularity | null;
    priority?: Types.Priority | null;
  } | null;
  conflicts?: Array<{
    __typename?: "ServiceConfigConflict";
    type: Types.ConflictType;
    relatedServiceId?: string | null;
    relatedUnitGroupId?: string | null;
    relatedFieldName?: string | null;
  }> | null;
};

export type ServiceConfig_FreeCleaningServiceConfig_Fragment = {
  __typename?: "FreeCleaningServiceConfig";
  minimumNights?: number | null;
  interval?: any | null;
  weekendRule?: Types.WeekendRule | null;
  distributionRule?: Types.DistributionRule | null;
  id: string;
  serviceConfigHash: number;
  type: Types.ServiceConfigType;
  service: {
    __typename?: "ServiceIdentifier";
    serviceId: string;
    details?: {
      __typename?: "ServiceContract";
      active?: boolean | null;
      chargeMode?: Types.ChargeMode | null;
      chargeUnit?: Types.ChargeUnit | null;
      description?: string | null;
      displayName?: string | null;
      id?: string | null;
      included?: boolean | null;
      name?: string | null;
      pmsCategoryId?: string | null;
      pmsId: string;
      pmsUpdatedAt?: any | null;
      price?: {
        __typename?: "Price";
        currency?: string | null;
        grossPrice?: any | null;
        netPrice?: any | null;
        taxes?: Array<{ __typename?: "Tax"; amount?: any | null; code?: string | null }> | null;
      } | null;
    } | null;
  };
  unitGroups?: Array<{
    __typename?: "UnitGroupIdentifier";
    unitGroupId: string;
    details?: {
      __typename?: "UnitGroupContract";
      active?: boolean | null;
      capacity?: number | null;
      extraCapacity?: number | null;
      id?: string | null;
      name?: string | null;
      pmsId: string;
      pmsPropertyId?: string | null;
      pmsUpdatedAt?: any | null;
      type?: string | null;
    } | null;
  }> | null;
  task?: {
    __typename?: "TaskConfig";
    housekeepingMainTask?: boolean | null;
    assigneeGroup?: string | null;
    createMultipleTasks?: boolean | null;
    dueDuration?: string | null;
    dueDurationType?: Types.DueDurationType | null;
    granularity?: Types.TaskGranularity | null;
    priority?: Types.Priority | null;
  } | null;
  conflicts?: Array<{
    __typename?: "ServiceConfigConflict";
    type: Types.ConflictType;
    relatedServiceId?: string | null;
    relatedUnitGroupId?: string | null;
    relatedFieldName?: string | null;
  }> | null;
};

export type ServiceConfig_LateCheckoutServiceConfig_Fragment = {
  __typename?: "LateCheckoutServiceConfig";
  minimumBefore?: string | null;
  maximumBefore?: string | null;
  maximum?: number | null;
  bookableDays?: Array<Types.BookableDay> | null;
  bookingChannels?: Array<string> | null;
  reservationStatuses?: Array<Types.ReservationStatus> | null;
  visibilities?: Array<Types.Visibility> | null;
  payAtCheckout?: boolean | null;
  maximumCheckoutTime?: any | null;
  id: string;
  serviceConfigHash: number;
  type: Types.ServiceConfigType;
  whitelistedDateRanges?: Array<{
    __typename?: "DateRange";
    startInclusive: any;
    endInclusive: any;
  }> | null;
  blacklistedDateRanges?: Array<{
    __typename?: "DateRange";
    startInclusive: any;
    endInclusive: any;
  }> | null;
  service: {
    __typename?: "ServiceIdentifier";
    serviceId: string;
    details?: {
      __typename?: "ServiceContract";
      active?: boolean | null;
      chargeMode?: Types.ChargeMode | null;
      chargeUnit?: Types.ChargeUnit | null;
      description?: string | null;
      displayName?: string | null;
      id?: string | null;
      included?: boolean | null;
      name?: string | null;
      pmsCategoryId?: string | null;
      pmsId: string;
      pmsUpdatedAt?: any | null;
      price?: {
        __typename?: "Price";
        currency?: string | null;
        grossPrice?: any | null;
        netPrice?: any | null;
        taxes?: Array<{ __typename?: "Tax"; amount?: any | null; code?: string | null }> | null;
      } | null;
    } | null;
  };
  unitGroups?: Array<{
    __typename?: "UnitGroupIdentifier";
    unitGroupId: string;
    details?: {
      __typename?: "UnitGroupContract";
      active?: boolean | null;
      capacity?: number | null;
      extraCapacity?: number | null;
      id?: string | null;
      name?: string | null;
      pmsId: string;
      pmsPropertyId?: string | null;
      pmsUpdatedAt?: any | null;
      type?: string | null;
    } | null;
  }> | null;
  task?: {
    __typename?: "TaskConfig";
    housekeepingMainTask?: boolean | null;
    assigneeGroup?: string | null;
    createMultipleTasks?: boolean | null;
    dueDuration?: string | null;
    dueDurationType?: Types.DueDurationType | null;
    granularity?: Types.TaskGranularity | null;
    priority?: Types.Priority | null;
  } | null;
  conflicts?: Array<{
    __typename?: "ServiceConfigConflict";
    type: Types.ConflictType;
    relatedServiceId?: string | null;
    relatedUnitGroupId?: string | null;
    relatedFieldName?: string | null;
  }> | null;
};

export type ServiceConfigFragment =
  | ServiceConfig_AddOnServiceConfig_Fragment
  | ServiceConfig_AdditionalCleaningServiceConfig_Fragment
  | ServiceConfig_EarlyCheckinServiceConfig_Fragment
  | ServiceConfig_FreeCleaningServiceConfig_Fragment
  | ServiceConfig_LateCheckoutServiceConfig_Fragment;

export const ServiceConfigFragmentDoc = `
    fragment ServiceConfig on ServiceConfig {
  id
  serviceConfigHash
  type
  service {
    ...ServiceIdentifier
  }
  unitGroups {
    ...UnitGroupIdentifier
  }
  task {
    ...TaskConfig
  }
  conflicts {
    ...ServiceConfigConflict
  }
  ... on BaseServiceConfig {
    minimumBefore
    maximumBefore
    maximum
    bookableDays
    bookingChannels
    reservationStatuses
    visibilities
    payAtCheckout
    whitelistedDateRanges {
      ...DateRange
    }
    blacklistedDateRanges {
      ...DateRange
    }
  }
  ... on FreeCleaningServiceConfig {
    minimumNights
    interval
    weekendRule
    distributionRule
  }
  ... on AdditionalCleaningServiceConfig {
    granularity
    defaultDatePickerSelection
  }
  ... on AddOnServiceConfig {
    addOnType
    canBookMultiple
    bookableOnDepartureDay
    bookOnExtendStay
    availableAfterMidnight
    granularity
    defaultTargetAudience
    tags
    defaultDatePickerSelection
    postOnNextDay
  }
  ... on EarlyCheckinServiceConfig {
    minimumCheckinTime
  }
  ... on LateCheckoutServiceConfig {
    maximumCheckoutTime
  }
}
    `;
