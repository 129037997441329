import { DoorProvider, useDoorProviderConfig } from "@likemagic-tech/sv-magic-library";
import { AvailableDoor } from "../domain/available-door";
import { ReservationDetailsDTO } from "../store/endpoints/reservation-table.endpoints";
import { useFetchAvailableDoors } from "./use-fetch-available-doors";
import { useMemo } from "react";
import { DoorType } from "../api/door-delegate";
import { openBanner } from "../slices/banner.slice";
import { useSyncDoors } from "./use-sync-doors";
import { useDispatch } from "../store";
import { useTranslationWrapper } from "./use-translation-wrapper";

const DOOR_PROVIDERS_WITHOUT_KEY_DETAILS = [DoorProvider.OFFLINE_KEY, DoorProvider.DORMA_KABA];
const PIN_SUFFIX = "_PIN";

export const useReservationDoors = (reservation: ReservationDetailsDTO) => {
  const { propertyId, id: reservationId, accessibleDoors } = reservation;
  const doorConfig = useDoorProviderConfig(propertyId);
  const { filterKeysByIds } = useFetchAvailableDoors(propertyId);
  const { syncDoors } = useSyncDoors();
  const dispatch = useDispatch();
  const { t } = useTranslationWrapper();

  const reservationKeyIds = useMemo(
    () => accessibleDoors?.map((door) => door.id) || [],
    [accessibleDoors]
  );

  const allAvailableDoors = filterKeysByIds(reservationKeyIds);

  // Handle special cases for different door providers
  const specialPinDoors = useMemo(() => {
    if (!doorConfig || !accessibleDoors) return undefined;

    // GLUTZ or SALTO with PIN door
    if (
      doorConfig.doorProvider === DoorProvider.GLUTZ ||
      doorConfig.doorProvider === DoorProvider.SALTO
    ) {
      const accessiblePinDoor = accessibleDoors.find((door) => door.id.endsWith(PIN_SUFFIX));
      if (accessiblePinDoor) {
        return [accessiblePinDoor as unknown as AvailableDoor, ...allAvailableDoors];
      }
    }

    // BURGWACHTER handling
    if (
      doorConfig.doorProvider === DoorProvider.BURGWACHTER ||
      doorConfig.doorProvider === DoorProvider.BURGWACHTER_LEGACY
    ) {
      const accessiblePinDoor = accessibleDoors.find((door) => !door.is_general);
      if (accessiblePinDoor) {
        return [accessiblePinDoor as unknown as AvailableDoor];
      }
    }

    // NUKI and OFFLINE_PIN handling
    if (
      doorConfig.doorProvider === DoorProvider.NUKI ||
      doorConfig.doorProvider === DoorProvider.OFFLINE_PIN
    ) {
      return accessibleDoors.map((door) => door as unknown as AvailableDoor);
    }

    return undefined;
  }, [accessibleDoors, doorConfig, allAvailableDoors]);

  const handleSyncDoors = (doorType: DoorType) => async () => {
    const result = await syncDoors({ reservationId: reservation.id, doorType });
    if (result) {
      dispatch(
        openBanner({
          type: "success",
          title: t("labels__action_successfully_performed")
        })
      );
    }
  };

  return {
    computedAvailableDoors: specialPinDoors || allAvailableDoors,
    doorConfig,
    showKeyDetails: !DOOR_PROVIDERS_WITHOUT_KEY_DETAILS.includes(
      doorConfig?.doorProvider || DoorProvider.NONE
    ),
    reservationId,
    handleSyncDoors
  };
};
