import React, { FC, useCallback, useMemo } from "react";
import { FieldWithIcon } from "./field-with-icon";
import { Link as LinkIcon } from "@mui/icons-material";
import { Paragraph, ParagraphSmall } from "@likemagic-tech/sv-magic-library";
import { Box, Divider, Grid2, useTheme } from "@mui/material";
import {
  AutocompleteComponent,
  AutocompleteOption
} from "../../../../components/autocomplete-component";
import { useUnits } from "../../../../hooks/use-unit-by-id";
import { useProperty } from "../../../../hooks/use-property";
import {
  BoxLabelForMaintenanceAndStatus,
  mapLabelsToUnitsForAssignRoom
} from "../../../../utils/unit-utils";
import { useTranslationWrapper } from "../../../../hooks/use-translation-wrapper";
import { useIsMobile } from "../../../../hooks/use-is-mobile";
import { Params } from "../../../../hooks/use-select-id";
import { useReservationDetailsId } from "../../../reservations/reservation-details/reservation-details/use-reservation-details-id";

interface AssignProps {
  setFieldValue: (field: string, value: any) => void;
  linkedUnitId?: string;
  linkedPmsReservationId?: string;
  linkedDisplayablePmsReservationId?: string;
  linkedArrivingPmsReservationId?: string;
  linkedDisplayableArrivingReservationId?: string;
  linkedDepartingPmsReservationId?: string;
  linkedDisplayableDepartingReservationId?: string;
  disabledRoomSelection?: boolean;
}

export const Link: FC<AssignProps> = ({
  setFieldValue,
  linkedUnitId,
  linkedPmsReservationId,
  linkedDisplayablePmsReservationId,
  linkedArrivingPmsReservationId,
  linkedDisplayableArrivingReservationId,
  linkedDepartingPmsReservationId,
  linkedDisplayableDepartingReservationId,
  disabledRoomSelection
}) => {
  const theme = useTheme();
  const { selectedProperty } = useProperty();
  const propertyId = selectedProperty?.propertyId ?? "";
  const { data: units } = useUnits({
    propertyId
  });
  const { t } = useTranslationWrapper();
  const isMobile = useIsMobile();
  const unitOptions = useMemo(
    () => (units ? mapLabelsToUnitsForAssignRoom(units, t) : []),
    [units, t]
  );
  const { setSelectId } = useReservationDetailsId();

  const linkedRoom = useMemo(() => {
    return unitOptions.find((unit) => unit.id === linkedUnitId);
  }, [unitOptions, linkedUnitId]);

  // TODO - once we implement a todo below, this has to be used
  // const linkedReservationOption = useMemo(() => {
  //   return { id: linkedReservationId ?? "", label: linkedReservationId ?? "" };
  // }, [linkedReservationId]);

  const getUnitOptionItem = useCallback(
    (id: string) => unitOptions.find((item) => item.id === id),
    [unitOptions]
  );

  const handleOnReservationIdClick = (reservationId: string) => {
    setSelectId(
      reservationId,
      new URLSearchParams({
        [Params.TASK_LINK_TYPE_URL_PARAM]: "",
        [Params.TASK_LINK_ID_URL_PARAM]: "",
        [Params.TASK_ID_SEARCH_PARAM_NAME]: "",
        [Params.CREATE_TASK_PARAM]: "",
        [Params.MANUAL_CHECKIN_STEP]: "",
        [Params.PROFILE_ID_SEARCH_PARAM_NAME]: "",
        [Params.SELECTED_MANUAL_CHECKIN_RESERVATION_ID]: ""
      })
    );
  };

  return (
    <FieldWithIcon icon={<LinkIcon />}>
      <Box sx={{ flex: 1, ml: 2, minWidth: isMobile ? "calc(100% - 16px)" : "auto" }}>
        <AutocompleteComponent
          disabled={disabledRoomSelection}
          options={unitOptions as AutocompleteOption[]}
          onSelected={(value) => setFieldValue("linkedUnitId", value.id)}
          value={linkedRoom ?? null}
          inputVariant="standard"
          disableClearable={false}
          placeholder={t("labels__linked_unit")}
          label=""
          inputFontSize={14}
          displayItem={(_, option) => {
            const unitOptionItem = getUnitOptionItem(option.id);
            return (
              <Grid2
                container
                direction="row"
                sx={{
                  alignItems: "center",
                  justifyContent: "space-between",
                  width: "100%"
                }}
              >
                <Grid2>
                  <Paragraph>{unitOptionItem?.label}</Paragraph>
                </Grid2>
                <Grid2>
                  <BoxLabelForMaintenanceAndStatus
                    maintenanceType={unitOptionItem?.maintenance}
                    status={unitOptionItem?.status}
                    t={t}
                  />
                </Grid2>
              </Grid2>
            );
          }}
          inputStartAdornment={
            linkedRoom?.id ? (
              <ParagraphSmall sx={{ color: theme.palette.text.secondary, mr: 0.5 }}>
                {t("labels__linked_unit")}:
              </ParagraphSmall>
            ) : (
              <></>
            )
          }
        />
      </Box>
      {!isMobile && (
        <Divider
          orientation="vertical"
          sx={{ color: theme.palette.text.secondary, height: theme.spacing(3) }}
        />
      )}
      <Box
        sx={{
          display: "flex",
          gap: 0.5,
          flex: 1,
          ml: 2,
          minWidth: isMobile ? "calc(100% - 16px)" : "auto"
        }}
      >
        {/*TODO - make an api to get a list of reservations for autocomplete and make a proper component*/}
        {/*<AutocompleteComponent
          options={
            [{ id: linkedReservationId, label: linkedReservationId }] as AutocompleteOption[]
          }
          onSelected={(value) => setFieldValue("linkedReservationId", value.id)}
          value={linkedReservationOption}
          inputVariant="standard"
          disableClearable={false}
          placeholder={t("labels__linked_reservation")}
          inputStartAdornment={
            linkedReservationId ? (
              <ParagraphSmall sx={{ color: theme.palette.text.secondary, mr: 0.5 }}>
                {t("labels__linked_reservation")}:
              </ParagraphSmall>
            ) : (
              <></>
            )
          }
          label=""
          disabled
          inputFontSize={14}
        />*/}

        <ParagraphSmall sx={{ color: theme.palette.text.secondary, cursor: "auto" }}>
          {linkedPmsReservationId ||
          linkedArrivingPmsReservationId ||
          linkedDepartingPmsReservationId
            ? `${t("labels__linked_reservation")}:`
            : ""}
        </ParagraphSmall>
        {linkedPmsReservationId && (
          <>
            <ParagraphSmall
              sx={{
                color: theme.palette.text.secondary,
                "&:hover": { textDecoration: "underline", color: "info.main", cursor: "pointer" }
              }}
              onClick={(e) => {
                e.stopPropagation();
                handleOnReservationIdClick(linkedPmsReservationId);
              }}
            >
              {linkedDisplayablePmsReservationId}
            </ParagraphSmall>
            {linkedArrivingPmsReservationId || linkedDepartingPmsReservationId ? ", " : ""}
          </>
        )}
        {linkedDepartingPmsReservationId && (
          <>
            <ParagraphSmall
              onClick={(e) => {
                e.stopPropagation();
                handleOnReservationIdClick(linkedDepartingPmsReservationId);
              }}
              sx={{
                color: theme.palette.text.secondary,
                "&:hover": { textDecoration: "underline", color: "info.main", cursor: "pointer" }
              }}
            >
              {linkedDisplayableDepartingReservationId}
            </ParagraphSmall>
            {linkedDisplayableArrivingReservationId ? "," : ""}
          </>
        )}
        {linkedArrivingPmsReservationId && (
          <ParagraphSmall
            onClick={(e) => {
              e.stopPropagation();
              handleOnReservationIdClick(linkedArrivingPmsReservationId);
            }}
            sx={{
              color: theme.palette.text.secondary,
              "&:hover": { textDecoration: "underline", color: "info.main", cursor: "pointer" }
            }}
          >
            {linkedDisplayableArrivingReservationId}
          </ParagraphSmall>
        )}
      </Box>
    </FieldWithIcon>
  );
};
