import * as React from "react";
import { Grid2, useTheme } from "@mui/material";
import { Button, EmptyState, EmptyStateSize } from "@likemagic-tech/sv-magic-library";
import { NotesForProfile } from "../types/profile";
import { FC, useState } from "react";
import { useTranslationWrapper } from "src/hooks/use-translation-wrapper";
import AddIcon from "@mui/icons-material/Add";
import { ProfileAddGuestNoteModal } from "./modals/profile-add-guest-note-modal";
import { ProfileNoteItem } from "./profile-note-item";

interface ProfileDrawerNotesProps {
  notes: NotesForProfile;
}

export const ProfileDrawerNotes: FC<ProfileDrawerNotesProps> = ({ notes }) => {
  const { t } = useTranslationWrapper();
  const { palette, spacing } = useTheme();
  const [openModal, setOpenModal] = useState(false);

  const handleOpen = () => {
    setOpenModal(true);
  };

  const handleClose = () => {
    setOpenModal(false);
  };

  return (
    <Grid2
      container
      rowSpacing={spacing(2)}
      sx={{
        py: 3,
        overflow: "visible",
        width: "100%"
      }}
    >
      <Grid2
        size={{ xs: 12 }}
        sx={{
          display: "flex",
          justifyContent: "end"
        }}
      >
        <Button variant="primary" onClick={handleOpen} size={"medium"}>
          <Grid2
            container
            direction="row"
            sx={{
              display: "flex",
              alignItems: "center"
            }}
          >
            <AddIcon
              fontSize="small"
              sx={{
                color: palette.background.paper,
                marginRight: spacing(2)
              }}
            />

            {t("labels__profile_add_guest_note")}
          </Grid2>
        </Button>
      </Grid2>
      {notes?.length > 0 ? (
        notes.map((note) => <ProfileNoteItem note={note} key={note.pmsId} />)
      ) : (
        <EmptyState title={t("labels__profile_no_notes_information")} size={EmptyStateSize.LARGE} />
      )}
      {openModal && <ProfileAddGuestNoteModal isOpen onClose={handleClose} />}
    </Grid2>
  );
};
