import { Dialog } from "@mui/material";
import { FC, PropsWithChildren } from "react";

interface DialogModalProps {
  handleClose: () => void | null;
  isOpen: boolean;
  maxWidth?: "xs" | "sm" | "md" | "lg" | "xl" | false;
  overflow?: string;
  fullScreen?: boolean;
}

export const DialogModal: FC<PropsWithChildren<DialogModalProps>> = ({
  handleClose,
  isOpen,
  children,
  maxWidth,
  overflow,
  fullScreen
}) => {
  return (
    <Dialog
      open={isOpen}
      onClose={handleClose}
      maxWidth={maxWidth}
      fullScreen={fullScreen}
      PaperProps={{
        sx: {
          display: "flex",
          alignItems: "center",
          width: "100%",
          overflow,
          margin: fullScreen ? 0 : 2,
          borderRadius: fullScreen ? 0 : 2,
          paddingBottom: fullScreen ? 2 : 0
        }
      }}
    >
      {children}
    </Dialog>
  );
};
