import { CommunicationChannel, ParagraphSmall } from "@likemagic-tech/sv-magic-library";
import { FC, useCallback, useMemo } from "react";
import { UserProfile } from "src/domain/user-profile";
import { useTranslationWrapper } from "src/hooks/use-translation-wrapper";
import { useTheme } from "@mui/material";

interface NotificationLabelForAutocompleteProps {
  profile: UserProfile[];
  channel: string;
}

export const NotificationLabelForAutocomplete: FC<NotificationLabelForAutocompleteProps> = ({
  profile,
  channel
}) => {
  const { t } = useTranslationWrapper();
  const labelToDisplay = useCallback(
    (info: string) => (info ? info : t("labels__not_defined")),
    [t]
  );
  const { palette } = useTheme();
  const value = useMemo(() => {
    switch (channel) {
      case CommunicationChannel.EMAIL:
        return labelToDisplay(profile[0]?.person.email ?? "");
      case CommunicationChannel.SMS:
      case CommunicationChannel.WHATSAPP:
        return labelToDisplay(profile[0]?.person.phone ?? "");
      default:
        return t("labels__not_defined");
    }
  }, [channel, labelToDisplay, profile, t]);

  return <ParagraphSmall sx={{ color: palette.grey[500] }}>{value}</ParagraphSmall>;
};
