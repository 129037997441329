import React, { FC, Suspense, useCallback, useMemo, useState } from "react";
import { ReservationDetailsDTO } from "../../../../../store/endpoints/reservation-table.endpoints";
import { Box, Button, Grid2, Paper, useTheme } from "@mui/material";
import {
  Chip,
  ChipProps,
  EmptyState,
  EmptyStateSize,
  formatDate,
  formatTime,
  Heading3,
  ParagraphSmall
} from "@likemagic-tech/sv-magic-library";
import { useTranslationWrapper } from "../../../../../hooks/use-translation-wrapper";
import { RestaurantReservationStatus } from "../../../../../domain/reservation-table-dto";
import { ActionMenu } from "../../../../../components/action-menu/action-menu";
import { SevenroomsWidget } from "../../../../../components/sevenrooms-widget/sevenrooms-widget";
import { useSevenRoomsWidgetConfig } from "../../../../../hooks/use-configuration";
import { useIsMobile } from "../../../../../hooks/use-is-mobile";
import { SevenRoomsLoader } from "../../../../../components/sevenrooms-widget/seven-rooms-loader";
import AddIcon from "@mui/icons-material/Add";
import { useProperty } from "src/hooks/use-property";
import { getI18nSelectedLanguage } from "src/utils/language";

interface ReservationDetailsRestaurantsProps {
  reservation: ReservationDetailsDTO;
}

export const ReservationDetailsRestaurants: FC<ReservationDetailsRestaurantsProps> = ({
  reservation
}) => {
  const { t } = useTranslationWrapper();
  const [selectedReservationId, setSelectedReservationId] = useState<string | undefined>();
  const { selectedProperty } = useProperty();
  const sevenRoomsWidgetConfig = useSevenRoomsWidgetConfig(selectedProperty?.propertyId);
  const isMobile = useIsMobile();
  const theme = useTheme();
  const [showComponent, setShowComponent] = useState(false);
  const language = getI18nSelectedLanguage();

  const getChipColor = (status?: RestaurantReservationStatus): ChipProps["color"] => {
    switch (status) {
      case RestaurantReservationStatus.Arrived:
        return "info";
      case RestaurantReservationStatus.Booked:
        return "info";
      case RestaurantReservationStatus.Canceled:
        return "error";
      case RestaurantReservationStatus.NoShow:
        return "error";
      case RestaurantReservationStatus.Completed:
        return "success";
      case RestaurantReservationStatus.Confirmed:
        return "info";
      case RestaurantReservationStatus.Seated:
        return "success";
      default:
        return "default";
    }
  };

  const person = reservation.primaryGuest;

  const menuItems = useMemo(() => {
    return [
      {
        label: t("labels__edit"),
        id: "edit"
      }
    ];
  }, [t]);

  const handleAction = useCallback((selectedOptionId: string, restaurantReservationId?: string) => {
    switch (selectedOptionId) {
      case "edit":
        setSelectedReservationId(restaurantReservationId);
        console.log("edit");
        break;
      case "cancel":
        console.log("cancel");
        break;
    }
  }, []);

  return (
    <Box
      sx={{
        mt: 2
      }}
    >
      <Grid2
        sx={{
          pb: 2,
          display: "flex",
          justifyContent: "end"
        }}
      >
        <Button variant="primary" onClick={() => setShowComponent(!showComponent)}>
          <Grid2
            container
            direction="row"
            sx={{
              alignItems: "center"
            }}
          >
            <AddIcon
              fontSize="small"
              sx={{
                color: theme.palette.background.paper,
                marginRight: theme.spacing(2)
              }}
            />
            {t("buttons__new_reservation")}
          </Grid2>
        </Button>
      </Grid2>
      <Paper sx={{ p: 3 }} elevation={0}>
        <Heading3 sx={{ marginBottom: 1.5 }}>{t("labels__restaurant_reservations")}</Heading3>
        {!!reservation?.restaurantReservations?.length && (
          <Box sx={{ overflowX: "auto" }}>
            <Box sx={{ minWidth: isMobile ? "220%" : "unset", width: "100%" }}>
              <Grid2
                container
                sx={{
                  alignItems: "center",
                  pb: 0.5
                }}
              >
                <Grid2
                  size={{ xs: 2.5 }}
                  sx={{
                    pl: 1.5
                  }}
                >
                  <ParagraphSmall sx={{ color: theme.palette.text.primary }}>
                    {t("labels__reservation__details__restaurant_venue_name")}
                  </ParagraphSmall>
                </Grid2>
                <Grid2 size={{ xs: 1.5 }}>
                  <ParagraphSmall sx={{ color: theme.palette.text.primary }}>
                    {t("labels__reservation__details__restaurant_date")}
                  </ParagraphSmall>
                </Grid2>
                <Grid2 size={{ xs: 1.5 }}>
                  <ParagraphSmall sx={{ color: theme.palette.text.primary }}>
                    {t("labels__reservation__details__restaurant_guests")}
                  </ParagraphSmall>
                </Grid2>
                <Grid2 size={{ xs: 1.5 }}>
                  <ParagraphSmall sx={{ color: theme.palette.text.primary }}>
                    {t("labels__reservation__details__restaurant_table_number")}
                  </ParagraphSmall>
                </Grid2>
                <Grid2 size={{ xs: 1.5 }}>
                  <ParagraphSmall sx={{ color: theme.palette.text.primary }}>
                    {t("labels__reservation__details__restaurant_created_at")}
                  </ParagraphSmall>
                </Grid2>
                <Grid2 size={{ xs: 2 }}>
                  <ParagraphSmall sx={{ color: theme.palette.text.primary }}>
                    {t("labels__reservation__details__restaurant_note")}
                  </ParagraphSmall>
                </Grid2>
                <Grid2 size={{ xs: 1.5 }} />
                <Grid2 size={{ xs: 0.5 }} />
              </Grid2>
              <Grid2 container>
                {reservation.restaurantReservations?.map((restaurantReservation) => (
                  <Grid2
                    size={{ xs: 12 }}
                    key={`${restaurantReservation?.foodAndBeveragePropertyId}_${restaurantReservation?.reservationDateTime}`}
                    sx={{
                      paddingBottom: 1.5
                    }}
                  >
                    <Paper
                      elevation={0}
                      sx={{
                        background: theme.palette.grey[100],
                        borderColor: theme.palette.grey[100],
                        borderRadius: "5px",
                        pr: 1
                      }}
                      variant="outlined"
                    >
                      <Grid2
                        container
                        direction="row"
                        sx={{
                          py: 1.5,
                          alignItems: "center",
                          position: "relative"
                        }}
                      >
                        <Grid2
                          size={{ xs: 2.5 }}
                          sx={{
                            pl: 2
                          }}
                        >
                          <ParagraphSmall sx={{ color: theme.palette.text.primary }}>
                            {restaurantReservation?.foodAndBeveragePropertyName}
                          </ParagraphSmall>
                        </Grid2>
                        <Grid2 size={{ xs: 1.5 }}>
                          {restaurantReservation?.reservationDateTime && (
                            <>
                              {formatDate(
                                restaurantReservation?.reservationDateTime,
                                language,
                                selectedProperty?.details.timeZone
                              )}
                              {" | "}
                              {formatTime(
                                restaurantReservation?.reservationDateTime,
                                language,
                                selectedProperty?.details.timeZone
                              )}
                            </>
                          )}
                        </Grid2>
                        <Grid2
                          size={{ xs: 1.5 }}
                          sx={{
                            pl: 0.5
                          }}
                        >
                          <ParagraphSmall sx={{ color: theme.palette.text.primary }}>
                            {restaurantReservation?.actualNumberOfGuests ??
                              restaurantReservation?.expectedNumberOfGuests ??
                              ""}
                          </ParagraphSmall>
                        </Grid2>
                        <Grid2
                          size={{ xs: 1.5 }}
                          sx={{
                            pl: 0.5
                          }}
                        >
                          <ParagraphSmall sx={{ color: theme.palette.text.primary }}>
                            {restaurantReservation?.tableNumbers?.map((table, index) => {
                              return `${table}${
                                restaurantReservation?.tableNumbers?.length &&
                                index === restaurantReservation?.tableNumbers?.length - 1
                                  ? ""
                                  : ", "
                              }`;
                            })}
                          </ParagraphSmall>
                        </Grid2>
                        <Grid2
                          size={{ xs: 1.5 }}
                          sx={{
                            pl: 0.5
                          }}
                        >
                          <ParagraphSmall sx={{ color: theme.palette.text.primary }}>
                            <>
                              {formatDate(
                                restaurantReservation?.createdAt,
                                language,
                                selectedProperty?.details.timeZone
                              )}
                              {" | "}
                              {formatTime(
                                restaurantReservation?.createdAt,
                                language,
                                selectedProperty?.details.timeZone
                              )}
                            </>
                          </ParagraphSmall>
                        </Grid2>
                        <Grid2
                          size={{ xs: 2 }}
                          sx={{
                            pl: 0.5
                          }}
                        >
                          <ParagraphSmall sx={{ color: theme.palette.text.primary }}>
                            {restaurantReservation?.notes ?? ""}
                          </ParagraphSmall>
                        </Grid2>
                        <Grid2
                          size={{ xs: 1 }}
                          sx={{
                            textAlign: "center"
                          }}
                        >
                          <Chip
                            size="small"
                            variant="outlined"
                            color={getChipColor(restaurantReservation?.status)}
                            label={t(
                              `labels__restaurant_reservation_status_${restaurantReservation?.status}`
                            )}
                          />
                        </Grid2>
                        <Grid2 size={{ xs: 0.5 }}>
                          <ActionMenu
                            items={menuItems}
                            handleAction={(item) => {
                              handleAction(
                                item,
                                restaurantReservation?.foodAndBeverageReservationId
                              );
                              setShowComponent(!showComponent);
                            }}
                            disabled={!menuItems.length || !sevenRoomsWidgetConfig}
                          />
                        </Grid2>
                      </Grid2>
                    </Paper>
                  </Grid2>
                ))}
              </Grid2>
            </Box>
          </Box>
        )}
        {!reservation?.restaurantReservations?.length && (
          <Box>
            <EmptyState
              title={t("labels__reservation__details__restaurant_no_reservations")}
              size={EmptyStateSize.LARGE}
            />
          </Box>
        )}
      </Paper>
      {sevenRoomsWidgetConfig && (
        <Suspense fallback={<div> Loading </div>}>
          <SevenRoomsLoader url={sevenRoomsWidgetConfig.url}>
            <SevenroomsWidget
              guestIdentityId={person.guestIdentityId}
              firstName={person.firstName}
              lastName={person.lastName}
              email={person.email}
              phone={person.phone}
              locale={person.preferredLocale}
              hotelReservationArrivalDate={reservation.arrival ?? ""}
              hotelReservationDepartureDate={reservation.departure ?? ""}
              amountOfPeople={reservation.adultsCount}
              //TODO check if there is admin mode ?
              mode="GUEST"
              restaurantReservationId={selectedReservationId ?? ""}
              toggle={showComponent}
              reservationId={reservation.id}
              onClose={(toggle: boolean) => {
                setSelectedReservationId(undefined);
                setShowComponent(toggle);
              }}
              apiKey={sevenRoomsWidgetConfig?.apiKey}
              foodAndBeveragePropertyId={sevenRoomsWidgetConfig?.foodAndBeveragePropertyId}
            />
          </SevenRoomsLoader>
        </Suspense>
      )}
    </Box>
  );
};
