import { api } from "../MergeServiceConfiguration.generated";

const mergeServiceConfigurationEnhanced = api.enhanceEndpoints({
  endpoints: {
    MergeServiceConfiguration: {
      invalidatesTags: (result, error, arg) => [
        {
          pmsPropertyId: arg.pmsPropertyId,
          type: "ServiceConfiguration"
        }
      ]
    }
  }
});

export const {
  useMergeServiceConfigurationMutation: useMergeServiceConfigurationMutationEnhanced
} = mergeServiceConfigurationEnhanced;
