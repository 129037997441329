import { FC, useState } from "react";
import { Person } from "src/domain/person";
import { GuestType } from "../overview/rows-for-table-guest-overview/rows-for-table-guest-overview";
import { Divider, Grid2, useTheme } from "@mui/material";
import {
  Button,
  Chip,
  ParagraphSmall,
  useApiVersion,
  usePropertyConfig
} from "@likemagic-tech/sv-magic-library";
import { useTranslationWrapper } from "src/hooks/use-translation-wrapper";
import { DisplayPreference } from "../overview/rows-for-table-guest-overview/display-preference";
import { useUnits } from "src/hooks/use-unit-by-id";
import { PersonCardInfoTitle } from "./person-card-info-title";
import { PersonCardInfoPersonalInfo } from "./person-card-info-personal-info";
import { PersonCardInfoBillingInfo } from "./person-card-info-billing-info";
import { PersonCardInfoLegalInfo } from "./person-card-info-legal-info";
import { IdCheckStatus } from "src/domain/reservation";
import { PersonCardInfoGuestNotes } from "./person-card-info-guest-notes";
import { ReservationDetailsDTO } from "src/store/endpoints/reservation-table.endpoints";
import { PersonCardInfoOtherInfo } from "./person-card-info-other-info";
import AddIcon from "@mui/icons-material/Add";
import { GuestPreferencesModal } from "../../preferences/guest-preferences-modal";

interface PersonCardInfoProps {
  guest: Person;
  role: GuestType;
  propertyId: string;
  reservation: ReservationDetailsDTO;
}

const verifiedLabelStyling = (status?: IdCheckStatus) => {
  switch (status) {
    case "CONFIRMED":
      return "success";
    case "UNCONFIRMED":
      return "warning";
    case "DECLINED":
      return "error";
  }
};

export const PersonCardInfo: FC<PersonCardInfoProps> = ({
  guest,
  role,
  propertyId,
  reservation
}) => {
  const { t } = useTranslationWrapper();
  const { isRESTVersion } = useApiVersion();
  const theme = useTheme();
  const { features } = usePropertyConfig({
    propertyId
  });
  const { data: units } = useUnits({
    propertyId
  });

  const [addPreferencesModalOpened, setAddPreferencesModalOpened] = useState(false);

  return (
    <Grid2 container sx={{ width: "100%" }} direction="column" alignItems="start" spacing={1}>
      <PersonCardInfoTitle
        title={t("labels__person_card_personal_info")}
        content={
          role === GuestType.SAME_GUEST || role === GuestType.PRIMARY_GUEST ? (
            <Chip
              size="small"
              color={guest.hasAccount ? "info" : "default"}
              label={
                guest.hasAccount
                  ? t("labels__guest_has_account")
                  : t("labels__guest_has_no_account")
              }
            />
          ) : (
            <></>
          )
        }
      />
      <Divider sx={{ width: "100%" }} />
      <Grid2
        size={{ xs: 12 }}
        sx={{ paddingTop: theme.spacing(1), paddingBottom: theme.spacing(1.5) }}
      >
        <PersonCardInfoPersonalInfo
          showField={features?.tfeFeatureEnabled ?? false}
          guest={guest}
          role={role}
        />
      </Grid2>
      {isRESTVersion && role !== GuestType.TRAVEL_BUDDY && (
        <>
          <PersonCardInfoTitle title={t("labels__billing_information")} />
          <Divider sx={{ width: "100%" }} />
          <Grid2
            size={{ xs: 12 }}
            sx={{ paddingTop: theme.spacing(1), paddingBottom: theme.spacing(1.5) }}
          >
            <PersonCardInfoBillingInfo billingAddress={guest?.billingAddress} />
          </Grid2>
        </>
      )}
      {role !== GuestType.TRAVEL_BUDDY && (
        <>
          <PersonCardInfoTitle
            title={t("labels__guest__insight__LEGAL_DATA")}
            content={
              <Chip
                label={t("labels__guest__verified__" + reservation?.idCheckStatus)}
                color={verifiedLabelStyling(reservation?.idCheckStatus)}
                size="small"
              />
            }
          />
          <Divider sx={{ width: "100%" }} />
          <Grid2
            size={{ xs: 12 }}
            sx={{ paddingTop: theme.spacing(1), paddingBottom: theme.spacing(1.5) }}
          >
            <PersonCardInfoLegalInfo guest={guest} reservation={reservation} role={role} />
          </Grid2>
        </>
      )}
      {role !== GuestType.TRAVEL_BUDDY && (
        <Grid2 size={{ xs: 12 }}>
          <Grid2 container direction="row">
            <Grid2 size={{ xs: 12, md: 6 }}>
              <PersonCardInfoTitle title={t("labels__preference_category_GUEST_NOTE")} />
              <Divider sx={{ width: "100%", paddingTop: theme.spacing(1) }} />
              <Grid2
                size={{ xs: 12 }}
                sx={{ paddingTop: theme.spacing(1), paddingBottom: theme.spacing(1.5) }}
              >
                <Grid2 container direction="row" px={3}>
                  <PersonCardInfoGuestNotes guest={guest} />
                </Grid2>
              </Grid2>
            </Grid2>
            <Grid2 size={{ xs: 12, md: 6 }}>
              <PersonCardInfoTitle
                title={t("labels__reservation__details__overview__guests__preferences")}
              />
              <Divider sx={{ width: "100%", paddingTop: theme.spacing(1) }} />
              <Grid2
                size={{ xs: 12 }}
                sx={{ paddingTop: theme.spacing(1), paddingBottom: theme.spacing(1.5) }}
              >
                <Grid2 container direction="row" px={2} alignItems="center">
                  {guest?.guestPreferences && Object.keys(guest?.guestPreferences).length > 0 ? (
                    <>
                      {Object.keys(guest?.guestPreferences).map((preference) => (
                        <DisplayPreference
                          preference={preference}
                          propertyId={propertyId}
                          preferences={guest.guestPreferences}
                          key={preference}
                          units={units}
                          addPreferencesAllow
                        />
                      ))}
                      <Button
                        variant="secondary"
                        size="small"
                        sx={{
                          px: theme.spacing(2),
                          py: theme.spacing(0.5),
                          marginLeft: theme.spacing(1)
                        }}
                        onClick={() => setAddPreferencesModalOpened(true)}
                      >
                        <AddIcon fontSize="small" />
                        <ParagraphSmall>{t("labels__add")}</ParagraphSmall>
                      </Button>
                    </>
                  ) : (
                    <Button
                      variant="secondary"
                      size="small"
                      sx={{
                        px: theme.spacing(2),
                        py: theme.spacing(0.5),
                        marginLeft: theme.spacing(1)
                      }}
                      onClick={() => setAddPreferencesModalOpened(true)}
                    >
                      <AddIcon fontSize="small" />
                      <ParagraphSmall>{t("labels__add")}</ParagraphSmall>
                    </Button>
                  )}
                </Grid2>
              </Grid2>
            </Grid2>
          </Grid2>
        </Grid2>
      )}
      {role !== GuestType.TRAVEL_BUDDY && (
        <>
          <PersonCardInfoTitle title={t("labels__other")} />
          <Divider sx={{ width: "100%" }} />
          <Grid2
            size={{ xs: 12 }}
            sx={{ paddingTop: theme.spacing(1), paddingBottom: theme.spacing(3) }}
          >
            <PersonCardInfoOtherInfo
              guest={guest}
              showTfeFields={features?.tfeFeatureEnabled ?? false}
            />
          </Grid2>
        </>
      )}
      {addPreferencesModalOpened && (
        <GuestPreferencesModal
          isOpen={addPreferencesModalOpened}
          propertyId={propertyId ?? ""}
          onClose={() => setAddPreferencesModalOpened(false)}
          userFullName={`${guest.firstName} ${guest.lastName}`}
          profileId={guest.userProfileId?.toString()}
          reservation={isRESTVersion ? reservation : undefined}
        />
      )}
    </Grid2>
  );
};
