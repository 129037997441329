import { Grid2 } from "@mui/material";
import { Button, Heading2 } from "@likemagic-tech/sv-magic-library";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { useTranslationWrapper } from "src/hooks/use-translation-wrapper";
import { FC } from "react";
import { TitleHeader } from "./title-header";

/**
 * Returns the header of which card
 * @param title: string with the title of the card
 */

interface CardHeaderInterface {
  title: string;
  length?: number;
  viewAllInfo: () => void;
  extraAction?: JSX.Element;
}

export const CardHeader: FC<CardHeaderInterface> = ({
  title,
  viewAllInfo,
  extraAction,
  length
}) => {
  const { t } = useTranslationWrapper();

  return (
    <Grid2
      container
      direction="row"
      sx={{
        p: 3,
        alignItems: "center",
        justifyContent: "space-between"
      }}
    >
      <Grid2>
        <Heading2>
          <TitleHeader title={title} length={length} />
        </Heading2>
      </Grid2>
      <Grid2>{extraAction}</Grid2>
      <Grid2>
        <Button
          variant="ghost"
          size="small"
          onClick={viewAllInfo}
          endIcon={<ChevronRightIcon fontSize="small" />}
          disablePadding
        >
          {t("labels__reservation__details__overview__view_all")}
        </Button>
      </Grid2>
    </Grid2>
  );
};
