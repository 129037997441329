import * as Types from "../generated/graphql";

import { graphqlApi } from "../graphql.api";
export type GetPaymentMethodsQueryVariables = Types.Exact<{
  pmsPropertyId: Types.Scalars["String"]["input"];
  shopperReference: Types.Scalars["String"]["input"];
}>;

export type GetPaymentMethodsQuery = {
  __typename?: "Query";
  GetPaymentMethods: Array<
    | {
        __typename: "CardPaymentMethod";
        hashCode: string;
        type: Types.PaymentMethodType;
        paymentMethods?: Array<{
          __typename?: "PossiblePaymentMethod";
          brand?: string | null;
          brands?: Array<string | null> | null;
          configuration?: any | null;
          fundingSource?: string | null;
          group?: any | null;
          inputDetails?: string | null;
          issuers?: string | null;
          name?: string | null;
          type?: string | null;
        } | null> | null;
      }
    | { __typename: "CashPaymentMethod"; hashCode: string; type: Types.PaymentMethodType }
    | { __typename: "MewsPaymentMethod"; hashCode: string; type: Types.PaymentMethodType }
    | { __typename: "PayAtCheckinPaymentMethod"; hashCode: string; type: Types.PaymentMethodType }
    | { __typename: "PayAtCheckoutPaymentMethod"; hashCode: string; type: Types.PaymentMethodType }
    | { __typename: "PaymentLinkPaymentMethod"; hashCode: string; type: Types.PaymentMethodType }
    | {
        __typename: "PmsTokenizedCardPaymentMethod";
        hashCode: string;
        type: Types.PaymentMethodType;
      }
    | {
        __typename: "PreAuthorizationPaymentMethod";
        hashCode: string;
        type: Types.PaymentMethodType;
      }
    | {
        __typename: "StoredPaymentMethod";
        hashCode: string;
        pointOfSales: boolean;
        shopperReference?: string | null;
        type: Types.PaymentMethodType;
        config?: {
          __typename?: "StoredPaymentConfig";
          brand?: string | null;
          expiryMonth?: string | null;
          expiryYear?: string | null;
          holderName?: string | null;
          iban?: string | null;
          id?: string | null;
          lastFour?: string | null;
          name?: string | null;
          ownerName?: string | null;
          shopperEmail?: string | null;
          supportedShopperInteractions?: Array<string | null> | null;
          type?: string | null;
        } | null;
      }
    | {
        __typename: "TerminalPaymentMethod";
        hashCode: string;
        type: Types.PaymentMethodType;
        availableTerminals?: Array<{
          __typename?: "AvailableTerminal";
          id?: string | null;
          name?: string | null;
        } | null> | null;
      }
  >;
};

export const GetPaymentMethodsDocument = `
    query GetPaymentMethods($pmsPropertyId: String!, $shopperReference: String!) {
  GetPaymentMethods(
    pmsPropertyId: $pmsPropertyId
    shopperReference: $shopperReference
  ) {
    __typename
    ... on PaymentLinkPaymentMethod {
      hashCode
      type
    }
    ... on CardPaymentMethod {
      hashCode
      paymentMethods {
        brand
        brands
        configuration
        fundingSource
        group
        inputDetails
        issuers
        name
        type
      }
      type
    }
    ... on CashPaymentMethod {
      hashCode
      type
    }
    ... on MewsPaymentMethod {
      hashCode
      type
    }
    ... on PayAtCheckoutPaymentMethod {
      hashCode
      type
    }
    ... on PayAtCheckinPaymentMethod {
      hashCode
      type
    }
    ... on PreAuthorizationPaymentMethod {
      hashCode
      type
    }
    ... on StoredPaymentMethod {
      hashCode
      pointOfSales
      shopperReference
      config {
        brand
        expiryMonth
        expiryYear
        holderName
        iban
        id
        lastFour
        name
        ownerName
        shopperEmail
        supportedShopperInteractions
        type
      }
      type
    }
    ... on PaymentLinkPaymentMethod {
      hashCode
      type
    }
    ... on TerminalPaymentMethod {
      hashCode
      type
      availableTerminals {
        id
        name
      }
    }
    ... on PmsTokenizedCardPaymentMethod {
      hashCode
      type
    }
  }
}
    `;

const injectedRtkApi = graphqlApi.injectEndpoints({
  endpoints: (build) => ({
    GetPaymentMethods: build.query<GetPaymentMethodsQuery, GetPaymentMethodsQueryVariables>({
      query: (variables) => ({ document: GetPaymentMethodsDocument, variables })
    })
  })
});

export { injectedRtkApi as api };
export const { useGetPaymentMethodsQuery, useLazyGetPaymentMethodsQuery } = injectedRtkApi;
