import { FC, useCallback, useEffect, useMemo, useState } from "react";
import { Box, Button, Grid2, IconButton, useTheme } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { experimentalStyled } from "@mui/material/styles";
import { useDebounce } from "../../hooks/use-debounce";
import {
  formatDate,
  pickerDateSelector,
  setDate as setDateReducer
} from "../../slices/date-picker";
import { useDispatch, useSelector } from "../../store";
import { format, isSameDay } from "date-fns";
import {
  DatePicker,
  Input,
  InputProps,
  ParagraphSmallBold
} from "@likemagic-tech/sv-magic-library";
import { useTranslationWrapper } from "../../hooks/use-translation-wrapper";
import { getI18nSelectedLanguage } from "../../utils/language";
import { utcToZonedTime } from "date-fns-tz";
import useDynamicLocale from "../../hooks/use-dynamic-locale";

const DatePickerWrapper = experimentalStyled("div")(() => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center"
}));

interface DatePickerProps {
  timeZone: string;
  disablePast?: boolean;
}

export const DatePickerFilter: FC<DatePickerProps> = ({ timeZone, disablePast }) => {
  const { t } = useTranslationWrapper();
  const dispatch = useDispatch();
  const date = useSelector(pickerDateSelector);
  const theme = useTheme();
  const locale = useDynamicLocale();

  const setDate = useCallback(
    (newDate: Date) => {
      dispatch(setDateReducer(formatDate(newDate, timeZone)));
    },
    [dispatch, timeZone]
  );
  const [internalDate, setInternalDate] = useState<Date | null>(new Date(date));
  useEffect(() => {
    setInternalDate(new Date(date));
  }, [date]);

  const resetDate = useCallback(() => {
    setInternalDate(new Date());
  }, []);

  const decreaseDateByDay = useCallback(() => {
    const newDate = internalDate?.getTime() ? new Date(internalDate?.getTime()) : null;
    newDate?.setDate(newDate.getDate() - 1);
    setInternalDate(newDate);
  }, [internalDate]);

  const increaseDateByDay = useCallback(() => {
    const newDate = internalDate?.getTime() ? new Date(internalDate?.getTime()) : null;
    newDate?.setDate(newDate.getDate() + 1);
    setInternalDate(newDate);
  }, [internalDate]);

  const dateWithDebounce = useDebounce(internalDate, 500);
  useEffect(() => {
    if (!dateWithDebounce) {
      return;
    }
    setDate(dateWithDebounce);
  }, [dateWithDebounce, setDate]);

  const disablePastDayButton = useMemo(() => {
    return disablePast ? isSameDay(new Date(date), new Date()) : false;
  }, [disablePast, date]);

  const customInputComponent = (props: InputProps) => {
    return (
      <Input
        variant="outlined"
        size="small"
        {...props}
        sx={{
          ".MuiInputBase-root": {
            height: theme.spacing(3.75),
            width: theme.spacing(15),
            fontSize: theme.typography.body2.fontSize
          },
          ".MuiInputBase-input": {
            textAlign: "center",
            fontWeight: theme.typography.fontWeightBold,
            cursor: "pointer"
          },
          ".MuiOutlinedInput-notchedOutline": {
            border: "none"
          }
        }}
        endAdornment={undefined}
        value={format(utcToZonedTime(date, timeZone), "EEE, MMM dd", { locale })}
      />
    );
  };

  return (
    <DatePickerWrapper>
      <Button
        variant="contained"
        sx={{
          backgroundColor: theme.palette.text.primary,
          borderRadius: theme.spacing(0.5),
          p: theme.spacing(0.75)
        }}
        onClick={resetDate}
      >
        <ParagraphSmallBold sx={{ color: theme.palette.background.paper }}>
          {t("labels__today")}
        </ParagraphSmallBold>
      </Button>
      <Grid2
        container
        sx={{
          ml: 2,
          alignItems: "center"
        }}
      >
        <Grid2>
          <IconButton
            onClick={decreaseDateByDay}
            sx={{
              p: theme.spacing(0.625),
              borderRadius: theme.spacing(0.5),
              border: `1px solid ${theme.palette.text.primary}`,
              left: theme.spacing(0.5)
            }}
            size="large"
            disabled={disablePastDayButton}
          >
            <ArrowBackIcon fontSize="small" />
          </IconButton>
        </Grid2>
        <Grid2>
          <Box
            sx={{
              borderTop: `1px solid ${theme.palette.text.primary}`,
              borderBottom: `1px solid ${theme.palette.text.primary}`,
              "& .MuiInputBase-root": {
                minHeight: "unset !important"
              },
              "& .MuiInputBase-input": {
                padding: "5px 14px"
              }
            }}
          >
            <DatePicker
              selectedDate={internalDate?.toISOString() ?? null}
              selectedLanguage={getI18nSelectedLanguage()}
              onChange={(newDate: string | null) =>
                setInternalDate(newDate ? new Date(newDate) : null)
              }
              minDate={disablePast ? new Date() : undefined}
              CustomInputComponent={customInputComponent}
            />
          </Box>
        </Grid2>
        <Grid2>
          <IconButton
            onClick={increaseDateByDay}
            sx={{
              p: theme.spacing(0.625),
              border: `1px solid ${theme.palette.text.primary}`,
              borderRadius: theme.spacing(0.5),
              right: theme.spacing(0.5)
            }}
            size="large"
          >
            <ArrowForwardIcon fontSize="small" />
          </IconButton>
        </Grid2>
      </Grid2>
    </DatePickerWrapper>
  );
};
