import { FC, PropsWithChildren, useEffect, useState } from "react";
import { loadScriptOnce, unloadScript } from "../../utils/file-utils";

interface SevenRoomsLoaderProps {
  url: string;
}

export const SevenRoomsLoader: FC<PropsWithChildren<SevenRoomsLoaderProps>> = ({
  children,
  url
}) => {
  const [init, setInit] = useState(false);
  useEffect(() => {
    if (url) {
      loadScriptOnce(url, () => {
        setInit(true);
      });
    }

    return () => {
      unloadScript(url);
      setInit(false);
    };
  }, [url]);

  return init ? <>{children}</> : null;
};
