import { Button } from "@likemagic-tech/sv-magic-library";
import { Grid2 } from "@mui/material";
import { useTranslationWrapper } from "src/hooks/use-translation-wrapper";
import DeleteIcon from "@mui/icons-material/Delete";
import * as React from "react";
import { FC, Fragment } from "react";
import { DoorType } from "src/api/door-delegate";
import { ReservationDetailsDTO } from "src/store/endpoints/reservation-table.endpoints";
import { useFetchSupportedSyncStates } from "src/hooks/use-fetch-supported-sync-states";
import { DoorAccessLogsTable } from "./door-access-logs-table/door-access-logs-table";
import { ReservationDetailsWidget } from "../reservation-details-widget";
import { useReservationDoors } from "../../../../../hooks/use-reservation-doors";
import { DoorAvailabilityInfo } from "./door-availability-info";
import { ReservationKeys } from "./reservation-keys";
import { KeySynchronization } from "./key-synchronization";
import { Keycards } from "./keycards";

interface ReservationDetailsKeysProps {
  reservation: ReservationDetailsDTO;
}

export const ReservationDetailsKeys: FC<ReservationDetailsKeysProps> = ({ reservation }) => {
  const { t } = useTranslationWrapper();
  const reservationId = reservation.id;
  const { showKeyDetails, handleSyncDoors } = useReservationDoors(reservation);

  useFetchSupportedSyncStates(reservation.propertyId);

  return (
    <Grid2
      container
      rowSpacing={1}
      spacing={1}
      sx={{
        py: 2
      }}
    >
      {showKeyDetails && (
        <>
          <ReservationDetailsWidget
            size={{ xs: 12 }}
            title={t("labels__keys__availability")}
            body={<DoorAvailabilityInfo reservation={reservation} />}
            endButton={
              <Button
                variant="primary"
                onClick={handleSyncDoors(DoorType.NONE)}
                startIcon={<DeleteIcon fontSize="small" sx={{ color: "background.paper" }} />}
              >
                {t("labels__sync_doors_NONE")}
              </Button>
            }
          />

          <ReservationDetailsWidget
            size={{ xs: 12, md: 6 }}
            title={t("labels__keys__reservation__keys")}
            body={<ReservationKeys reservation={reservation} />}
          />
          <ReservationDetailsWidget
            size={{ xs: 12, md: 6 }}
            title={t("labels__keys__key__synchronization")}
            body={<KeySynchronization reservation={reservation} />}
          />
        </>
      )}

      {reservation.keyCards && (
        <ReservationDetailsWidget
          size={{ xs: 12 }}
          title={`${t("labels__keycards")} ${
            reservation.keyCards.length > 0 ? `(${reservation.keyCards.length})` : ""
          }`}
          body={<Keycards reservation={reservation} />}
        />
      )}

      <ReservationDetailsWidget
        size={{ xs: 12 }}
        title={t("labels__door_access_log")}
        body={<DoorAccessLogsTable reservationId={reservationId} />}
      />
    </Grid2>
  );
};
