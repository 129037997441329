import TableContainer from "@mui/material/TableContainer";
import { Grid2, Table, TableBody, TableCell, TableHead, TableRow, Tooltip } from "@mui/material";
import {
  EmptyState,
  formatDateTime,
  Paragraph,
  ParagraphBold,
  ParagraphSmall
} from "@likemagic-tech/sv-magic-library";
import { BackofficeKeyCard } from "../../../../../graphql/generated/graphql";
import * as React from "react";
import { FC } from "react";
import { ReservationDetailsDTO } from "../../../../../store/endpoints/reservation-table.endpoints";
import { useTranslationWrapper } from "../../../../../hooks/use-translation-wrapper";
import { useProperty } from "../../../../../hooks/use-property";
import { getI18nSelectedLanguage } from "../../../../../utils/language";

interface KeycardsProps {
  reservation: ReservationDetailsDTO;
}
export const Keycards: FC<KeycardsProps> = ({ reservation }) => {
  const { t } = useTranslationWrapper();
  const { selectedProperty } = useProperty();
  const language = getI18nSelectedLanguage();

  return (
    <Grid2 container>
      {reservation.keyCards.length > 0 ? (
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>
                  <Paragraph>{t("labels__keycards_column_keycard")}</Paragraph>
                </TableCell>
                <TableCell>
                  <Paragraph>{t("labels__keycards_column_copy")}</Paragraph>
                </TableCell>
                <TableCell>
                  <Paragraph>{t("labels__keycards_column_issuer")}</Paragraph>
                </TableCell>
                <TableCell>
                  <Paragraph>{t("labels__keycards_column_issued")}</Paragraph>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {reservation.keyCards?.map((card: BackofficeKeyCard, index) => (
                <TableRow key={`BackofficeKeyCard-${card.id}`}>
                  <TableCell>
                    <Tooltip title={card.id} arrow placement="bottom">
                      <div>
                        <ParagraphBold>
                          {t("labels__keycards_keycard_label", {
                            index: String(index + 1)
                          })}
                        </ParagraphBold>
                      </div>
                    </Tooltip>
                  </TableCell>
                  <TableCell>
                    <ParagraphSmall>
                      {card.isCopy
                        ? t("labels__keycards_duplicate_card")
                        : t("labels__keycards_main_card")}
                    </ParagraphSmall>
                  </TableCell>
                  <TableCell>
                    <ParagraphSmall>
                      {card.requestor?.role === "ADMIN"
                        ? t("labels__keycards_team_member")
                        : card.requestor?.principal}
                    </ParagraphSmall>
                  </TableCell>
                  <TableCell>
                    <ParagraphSmall>
                      {formatDateTime(card.createdAt, language, selectedProperty?.details.timeZone)}
                    </ParagraphSmall>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <EmptyState title={t("labels__keycards_no_keycards")} />
      )}
    </Grid2>
  );
};
