import * as Types from "../generated/graphql";

import { graphqlApi } from "../graphql.api";
export type CashPaymentMutationVariables = Types.Exact<{
  cashPaymentRequest: Types.PaymentRequest;
  magicId: Types.Scalars["String"]["input"];
}>;

export type CashPaymentMutation = { __typename?: "Mutation"; CashPayment?: string | null };

export const CashPaymentDocument = `
    mutation CashPayment($cashPaymentRequest: PaymentRequest!, $magicId: String!) {
  CashPayment(magicId: $magicId, cashPaymentRequest: $cashPaymentRequest)
}
    `;

const injectedRtkApi = graphqlApi.injectEndpoints({
  endpoints: (build) => ({
    CashPayment: build.mutation<CashPaymentMutation, CashPaymentMutationVariables>({
      query: (variables) => ({ document: CashPaymentDocument, variables })
    })
  })
});

export { injectedRtkApi as api };
export const { useCashPaymentMutation } = injectedRtkApi;
