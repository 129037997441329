import { createSelector, createSlice, PayloadAction, Selector } from "@reduxjs/toolkit";
import { RootState } from "../store";
import { ConversationTabsValues } from "../messaging/domain/conversation-constants";

export interface MessageCountSliceState {
  [ConversationTabsValues.ALL]?: number;
  [ConversationTabsValues.INBOX]: number;
  [ConversationTabsValues.ASSIGNED_TO_ME]: number;
  [ConversationTabsValues.RESOLVED]: number;
}

const initialState: MessageCountSliceState = {
  [ConversationTabsValues.ALL]: undefined,
  [ConversationTabsValues.INBOX]: 0,
  [ConversationTabsValues.ASSIGNED_TO_ME]: 0,
  [ConversationTabsValues.RESOLVED]: 0
};
export const unreadMessageCountSlice = createSlice({
  name: "unreadMessageCount",
  initialState,
  reducers: {
    setMessageCountSlice: (state, action: PayloadAction<MessageCountSliceState>) => {
      state[ConversationTabsValues.ALL] = action.payload[ConversationTabsValues.ALL];
      state[ConversationTabsValues.INBOX] = action.payload[ConversationTabsValues.INBOX];
      state[ConversationTabsValues.ASSIGNED_TO_ME] =
        action.payload[ConversationTabsValues.ASSIGNED_TO_ME];
      state[ConversationTabsValues.RESOLVED] = action.payload[ConversationTabsValues.RESOLVED];
    }
  }
});

export const { setMessageCountSlice } = unreadMessageCountSlice.actions;

const selectSelf: Selector<RootState, MessageCountSliceState> = (state: RootState) =>
  state[unreadMessageCountSlice.name];

export const selectAllUnreadMessageCount = createSelector(selectSelf, (res) => res.ALL);

export const selectTabsUnreadMessageCount = createSelector(selectSelf, (res) => {
  const { ALL, ...rest } = res;
  return rest;
});
