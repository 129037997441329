import * as React from "react";
import { FC, Fragment, useCallback, useRef } from "react";
import { Grid2 } from "@mui/material";
import { EmptyState, Paragraph } from "@likemagic-tech/sv-magic-library";
import { KeyComponent } from "../../../../../components/key-component";
import { useReservationDoors } from "../../../../../hooks/use-reservation-doors";
import { ReservationDetailsDTO } from "../../../../../store/endpoints/reservation-table.endpoints";
import { resetDoorStatus } from "../../../../../slices/available-doors-state.slice";
import { useOpenDoor } from "../../../../../hooks/use-open-door";
import { useDispatch } from "../../../../../store";
import { useTranslationWrapper } from "../../../../../hooks/use-translation-wrapper";

interface ReservationKeysProps {
  reservation: ReservationDetailsDTO;
}
export const ReservationKeys: FC<ReservationKeysProps> = ({ reservation }) => {
  const { t } = useTranslationWrapper();
  const dispatch = useDispatch();
  const { openDoor, openAdminDoor } = useOpenDoor();
  const { computedAvailableDoors, doorConfig } = useReservationDoors(reservation);
  const keySliderRefs = useRef<{ [doorId: string]: any }>({});

  const handleOpenDoor = useCallback(
    async (isGeneral: boolean, magicToken: string, magicId: string, doorId: string) => {
      if (isGeneral) {
        await openAdminDoor({
          propertyId: reservation.propertyId,
          doorId: doorId
        });
      } else {
        await openDoor({ magicToken, magicId, doorId });
      }
      setTimeout(() => {
        dispatch(resetDoorStatus(doorId));
        keySliderRefs.current?.[doorId]?.reset();
      }, 5000);
    },
    [dispatch, openDoor, openAdminDoor, reservation.propertyId]
  );
  return (
    <Grid2
      container
      direction="row"
      rowSpacing={1}
      sx={{
        px: 3,
        paddingBottom: 3,
        alignItems: "center"
      }}
    >
      {computedAvailableDoors?.length > 0 ? (
        computedAvailableDoors.map((door, index) => (
          <Fragment key={`${door.id}-${door.unitId}-${index}`}>
            <Grid2
              size={{ xs: 3 }}
              sx={{
                paddingRight: 3
              }}
            >
              <Paragraph>{door.title}</Paragraph>
            </Grid2>
            <Grid2 size={{ xs: 9 }}>
              <KeyComponent
                doorData={door}
                keySliderRefs={keySliderRefs}
                doorProvider={doorConfig?.doorProvider}
                onSuccess={() =>
                  handleOpenDoor(!door.unitId, reservation.magicToken, reservation.magicId, door.id)
                }
              />
            </Grid2>
          </Fragment>
        ))
      ) : (
        <EmptyState title={t("labels__no__reservation__keys")} />
      )}
    </Grid2>
  );
};
