import { Params, useSelectId } from "src/hooks/use-select-id";
import { generateURLSearchParams } from "../components/table/hooks/use-search-filter-params";
import { getBookingIdFilter } from "./bookings-filters.utils";

export const useBookingSelectId = () => {
  const { selectedId: selectedBookingId, setSelectId: setSelectedBookingId } = useSelectId({
    fieldName: Params.SELECTED_BOOKING_ID
  });

  return {
    selectedBookingId: selectedBookingId ?? undefined,
    openDrawer: (bookingId: string) => {
      setSelectedBookingId(bookingId, generateURLSearchParams(getBookingIdFilter(bookingId)));
    },
    closeDrawer: () => {
      setSelectedBookingId(
        undefined,
        new URLSearchParams({
          [Params.SELECTED_BOOKING_ID]: ""
        })
      );
    }
  };
};
