import * as React from "react";
import { FC, useCallback, useEffect } from "react";
import { Box, Fade, Grid2, Tooltip } from "@mui/material";
import { useDispatch } from "../store";
import { responsiveVariables, useIsMobile } from "../hooks/use-is-mobile";
import { setIsOpenSideNavigation } from "../slices/dashboard-sidebar";
import { DrawerDetails } from "../components/drawer-details/drawer-details";
import { Params } from "../hooks/use-select-id";
import { useBookingSelectId } from "./use-booking-select-id";
import { ReservationTable } from "../features/reservations/reservation-table/reservation-table";
import { Heading1, Heading2, ParagraphBold } from "@likemagic-tech/sv-magic-library";
import { grey } from "@mui/material/colors";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import { useTheme } from "@mui/material/styles";
import { useGetBooking } from "./use-get-bookings";
import { useTranslationWrapper } from "../hooks/use-translation-wrapper";
import { getBookingIdFilter } from "./bookings-filters.utils";

export const BookingDetails: FC = () => {
  const { selectedBookingId, closeDrawer } = useBookingSelectId();
  const dispatch = useDispatch();
  const isMobile = useIsMobile();
  const { t } = useTranslationWrapper();
  const setOpenSideNavigation = useCallback(
    (openSidebar: boolean) => {
      dispatch(setIsOpenSideNavigation({ isOpenSideNavigation: openSidebar }));
    },
    [dispatch]
  );

  useEffect(() => {
    if (selectedBookingId || isMobile) {
      setOpenSideNavigation(false);
    }
  }, [selectedBookingId, setOpenSideNavigation, isMobile]);
  const booking = useGetBooking(selectedBookingId);

  const { spacing, palette, breakpoints } = useTheme();

  return (
    <DrawerDetails
      isOpen={!!selectedBookingId}
      onClose={closeDrawer}
      drawerId={Params.SELECTED_BOOKING_ID}
    >
      {selectedBookingId && (
        <Box p={isMobile ? 0 : 3}>
          <Fade in={!!selectedBookingId}>
            <div>
              {!isMobile && (
                <Box pb={2}>
                  <Grid2
                    container
                    sx={{
                      alignItems: "center",

                      [breakpoints.down(responsiveVariables.firstDesktopSize)]: {
                        paddingRight: spacing(3)
                      }
                    }}
                  >
                    <Grid2>
                      <Heading1 mr={1}>{booking.name}</Heading1>
                    </Grid2>

                    <Grid2>
                      <Heading2 mr={1} color={grey[300]}>
                        |
                      </Heading2>
                    </Grid2>
                    <Grid2>
                      <Grid2
                        container
                        sx={{
                          alignItems: "center"
                        }}
                      >
                        <Grid2>
                          <Tooltip title={t("labels__tooltip__pms__link")} arrow placement="bottom">
                            <Box
                              onClick={() => alert("open booking " + booking.id)}
                              sx={{
                                display: "flex",
                                cursor: "pointer",
                                alignItems: "center",
                                gap: 1,
                                color: palette.grey[500]
                              }}
                            >
                              <OpenInNewIcon fontSize="small" sx={{ top: spacing(0.5) }} />
                              <ParagraphBold color={palette.grey[500]}>
                                {booking.displayId}
                              </ParagraphBold>
                            </Box>
                          </Tooltip>
                        </Grid2>
                      </Grid2>
                    </Grid2>
                  </Grid2>
                </Box>
              )}

              <Grid2 container direction="row" wrap="nowrap">
                <ReservationTable
                  defaultFilter={selectedBookingId ? getBookingIdFilter(selectedBookingId) : {}}
                  mobileTitleLabel={t("title__bookings")}
                />
              </Grid2>
            </div>
          </Fade>
        </Box>
      )}
    </DrawerDetails>
  );
};
