import { Drawer, Grid2, useTheme } from "@mui/material";
import { Heading4 } from "@likemagic-tech/sv-magic-library";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import { ReleaseNotesCard } from "./release-notes-card";
import React, { FC } from "react";
import { useNavigateWithPropertyId } from "../../hooks/use-navigate-with-propertyId";
import { useTranslationWrapper } from "../../hooks/use-translation-wrapper";
import { ReleaseNotes } from "./types/release-notes";

interface ReleaseNotesDrawerProps {
  openDrawer: boolean;
  setOpenDrawer: (open: boolean) => void;
  releaseNotes: ReleaseNotes[];
}

export const ReleaseNotesDrawer: FC<ReleaseNotesDrawerProps> = ({
  openDrawer,
  setOpenDrawer,
  releaseNotes
}) => {
  const theme = useTheme();
  const navigate = useNavigateWithPropertyId();
  const { t } = useTranslationWrapper();

  return (
    <Drawer
      anchor="right"
      SlideProps={{ appear: false }}
      open={openDrawer}
      onClose={() => {
        setOpenDrawer(false);
      }}
      sx={{ zIndex: 1301 }}
      PaperProps={{
        sx: {
          maxWidth: theme.spacing(45)
        }
      }}
    >
      <Grid2
        container
        sx={{
          alignItems: "center",
          justifyContent: "space-between",
          backgroundColor: theme.palette.primary.main,
          minHeight: theme.spacing(8)
        }}
      >
        <Grid2>
          <Heading4 sx={{ color: theme.palette.grey[200], margin: theme.spacing(2) }}>
            {t("release__what__new")}
          </Heading4>
        </Grid2>
        <Grid2>
          <OpenInNewIcon
            fontSize="medium"
            sx={{
              color: theme.palette.grey[200],
              margin: theme.spacing(2),
              cursor: "pointer"
            }}
            onClick={() => {
              navigate("/release-notes");
              setOpenDrawer(false);
            }}
          />
        </Grid2>
      </Grid2>
      <ReleaseNotesCard releaseNotes={releaseNotes} />
    </Drawer>
  );
};
