import { ReservationDetailsDTO } from "../../../../store/endpoints/reservation-table.endpoints";
import * as React from "react";
import { FC, useContext, useMemo, useRef } from "react";
import { Box, Grid2, Tooltip } from "@mui/material";
import {
  Chip,
  formatDate,
  formatTime,
  Heading1,
  Heading2,
  isEndOfDayPms,
  ParagraphBold,
  ParagraphSmall,
  TenantContext
} from "@likemagic-tech/sv-magic-library";
import {
  ConfirmationNumber,
  FlightLand,
  FlightTakeoff,
  Groups,
  MeetingRoom,
  PeopleAlt
} from "@mui/icons-material";
import EscalatorWarningIcon from "@mui/icons-material/EscalatorWarning";
import { ReservationActionsMenu } from "../../../../components/menu/reservation-actions-menu";
import { useProperty } from "../../../../hooks/use-property";
import { useTranslationWrapper } from "../../../../hooks/use-translation-wrapper";
import { formatPriceToString, sumPrices } from "../../../../utils/price";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import { useTheme } from "@mui/material/styles";
import { ReservationStatus } from "src/domain/reservation-status";
import { useUnitGroupById } from "../../../../hooks/use-unit-group-by-id";
import { responsiveVariables, useIsMobile } from "src/hooks/use-is-mobile";
import { useOpenReservationPms } from "../../../../hooks/use-open-reservation-pms";
import { emptyPrice } from "src/domain/price";
import { CrownSimpleIcon } from "src/icons/CrownSimpleIcon";
import { getI18nSelectedLanguage } from "src/utils/language";
import { ReservationStatusIcon } from "../../../../components/reservation-status-icon/reservation-status-icon";

enum BalanceStatus {
  PAID = "PAID",
  UNPAID = "UNPAID",
  OVERPAID = "OVERPAID",
  CHECKOUTONAR = "CHECKOUTONAR"
}

const getBalanceStatus = (amount: number, checkoutOnAr: boolean) => {
  if (checkoutOnAr) {
    return { status: BalanceStatus.CHECKOUTONAR, color: "info" };
  } else if (amount === 0) {
    return { status: BalanceStatus.PAID, color: "success" };
  } else if (amount > 0) {
    return { status: BalanceStatus.OVERPAID, color: "info" };
  } else {
    return { status: BalanceStatus.UNPAID, color: "error" };
  }
};

const getOHIPChargesBalance = (reservation: ReservationDetailsDTO, currencyCode: string) => {
  const charges = reservation?.rateBreakDown ?? [];
  const totalSelectedCharges =
    sumPrices(
      charges
        .flatMap((item) => item.breakDownItems)
        .flatMap((item) => item.items)
        .map((item) => item.price)
    ) ?? emptyPrice(currencyCode);

  const payments = reservation.folios ?? [];
  const totalSelectedPayments = () => {
    const sum = sumPrices(payments.flatMap((item) => item.payments).map((item) => item.price));
    return sum ?? emptyPrice(currencyCode);
  };

  return {
    amount: totalSelectedCharges.amount * -1 + totalSelectedPayments().amount,
    currency: totalSelectedCharges.currency
  };
};

interface ReservationDetailsHeaderProps {
  reservation: ReservationDetailsDTO;
}

export const ReservationDetailsHeader: FC<ReservationDetailsHeaderProps> = ({ reservation }) => {
  const { t } = useTranslationWrapper();
  const reservationActionsAnchorRef = useRef<SVGSVGElement>(null);
  const { selectedProperty } = useProperty();
  const language = getI18nSelectedLanguage();
  const openReservation = useOpenReservationPms();
  const assignedUnitGroup = useUnitGroupById(
    selectedProperty?.propertyId ?? "",
    reservation.unit?.unitGroupId
  );
  const bookedUnitGroup = useUnitGroupById(
    selectedProperty?.propertyId ?? "",
    reservation?.bookedUnitGroupId
  );
  const { spacing, palette, breakpoints } = useTheme();
  const isMobile = useIsMobile();
  const theme = useTheme();

  const { pms } = useContext(TenantContext);

  const balance = useMemo(
    () =>
      pms &&
      isEndOfDayPms(pms) &&
      reservation.folios?.length === 0 &&
      [ReservationStatus.CONFIRMED, ReservationStatus.IN_HOUSE].includes(
        reservation.reservationStatus
      )
        ? getOHIPChargesBalance(reservation, selectedProperty?.details?.currencyCode)
        : reservation?.balance,
    [pms, reservation, selectedProperty?.details?.currencyCode]
  );

  const balanceStatus = useMemo(
    () => getBalanceStatus(balance?.amount, reservation.checkoutOnAr),
    [balance?.amount, reservation.checkoutOnAr]
  );

  const reservationShorInfoList = useMemo(
    () =>
      [
        reservation.isGroupBooking
          ? {
              icon: <Groups />,
              id: "groups",
              showcase: true,
              label: (
                <Box>
                  <ParagraphSmall>
                    {reservation.bookingName || reservation.bookingId}
                  </ParagraphSmall>
                </Box>
              ),
              tooltipContent: (
                <ParagraphSmall
                  sx={{
                    color: theme.palette.common.white
                  }}
                >{`${
                  reservation.bookingName ? t("labels__group_name") : t("labels__booking_id")
                }: ${reservation.bookingName || reservation.bookingId}`}</ParagraphSmall>
              )
            }
          : {},

        {
          icon: <FlightLand />,
          id: "flight-land",
          showcase: true,
          label: (
            <Grid2 container direction="row">
              <ParagraphSmall>
                {formatDate(
                  reservation.checkInTime ||
                    reservation.estimatedArrivalTime ||
                    reservation.arrival,
                  language,
                  selectedProperty?.details?.timeZone
                )}
              </ParagraphSmall>

              <ParagraphSmall sx={{ color: palette.text.secondary, marginLeft: spacing(0.5) }}>
                {formatTime(
                  reservation.checkInTime ||
                    reservation.estimatedArrivalTime ||
                    reservation.arrival,
                  language,
                  selectedProperty?.details?.timeZone
                )}
              </ParagraphSmall>
            </Grid2>
          )
        },
        {
          icon: <FlightTakeoff />,
          showcase: true,
          id: "flight-takeoff",
          label: (
            <Grid2 container direction="row">
              <ParagraphSmall>
                {formatDate(
                  reservation.checkOutTime ||
                    reservation.estimatedDepartureTime ||
                    reservation.departure,
                  language,
                  selectedProperty?.details.timeZone
                )}
              </ParagraphSmall>
              <ParagraphSmall sx={{ color: palette.text.secondary, marginLeft: spacing(0.5) }}>
                {formatTime(
                  reservation.checkOutTime ||
                    reservation.estimatedDepartureTime ||
                    reservation.departure,
                  language,
                  selectedProperty?.details.timeZone
                )}
              </ParagraphSmall>
            </Grid2>
          )
        },
        {
          icon: <ReservationStatusIcon status={reservation.reservationStatus} />,
          showcase: true,
          id: "reservation-status",
          label: (
            <ParagraphSmall>
              {t(`labels__reservation_status_${reservation.reservationStatus}`)}
            </ParagraphSmall>
          )
        },
        {
          icon: <PeopleAlt />,
          id: "people-at",
          showcase: true,
          label: (
            <ParagraphSmall>
              {[
                reservation.adultsCount,
                /*@ts-ignore*/ t("labels__adult", {
                  count: reservation.adultsCount
                })
              ].join(" ")}
            </ParagraphSmall>
          )
        },
        {
          icon: <EscalatorWarningIcon />,
          id: "escalator-warning",
          showcase: reservation.childrenCount > 0,
          label: (
            <ParagraphSmall sx={{ fontVariantLigatures: "none" }}>
              {[
                reservation.childrenCount,
                t(
                  "labels__child",
                  /*@ts-ignore*/
                  { count: reservation.childrenCount }
                ),
                reservation.childrenAges
                  ?.map((ages) => {
                    return `(${ages})`;
                  })
                  .join(", ")
              ].join(" ")}
            </ParagraphSmall>
          )
        },
        {
          icon: <MeetingRoom />,
          id: "meeting-room",
          showcase: true,
          label: (
            <Box sx={{ position: "relative" }}>
              <ParagraphSmall>
                {[assignedUnitGroup?.name || bookedUnitGroup?.name, reservation?.unit?.name]
                  .filter((item) => !!item)
                  .join(", ")}
              </ParagraphSmall>
            </Box>
          ),
          tooltipContent: reservation?.unit?.name ? (
            <ParagraphSmall
              sx={{
                color: theme.palette.common.white
              }}
            >
              {`${[assignedUnitGroup?.name || bookedUnitGroup?.name, reservation?.unit?.name]
                .filter((item) => !!item)
                .join(", ")} ${t("labels__is")} ${t(
                `labels__unit_condition_${reservation?.unit?.condition}`
              )} & ${t(`labels__unit_${reservation?.unit?.occupied ? "occupied" : "free"}`)}`}
            </ParagraphSmall>
          ) : null
        },
        {
          icon: <ConfirmationNumber />,
          id: "confirmation-number",
          showcase: true,
          label: (
            <ParagraphSmall>
              {t(`labels__booking_channel_${reservation.bookingChannel}`)}
            </ParagraphSmall>
          )
        }
      ].filter((item) => !!item.label),
    [
      reservation.isGroupBooking,
      reservation.bookingName,
      reservation.bookingId,
      reservation.checkInTime,
      reservation.estimatedArrivalTime,
      reservation.arrival,
      reservation.checkOutTime,
      reservation.estimatedDepartureTime,
      reservation.departure,
      reservation.reservationStatus,
      reservation.adultsCount,
      reservation.childrenCount,
      reservation.childrenAges,
      reservation?.unit?.name,
      reservation?.unit?.condition,
      reservation?.unit?.occupied,
      reservation.bookingChannel,
      language,
      selectedProperty?.details.timeZone,
      palette.text.secondary,
      spacing,
      t,
      assignedUnitGroup?.name,
      bookedUnitGroup?.name,
      theme.palette.common.white
    ]
  );

  //checkin code for TFE
  const checkinCode = useMemo(() => {
    return reservation.notes?.rateBreakdownComment?.[0];
  }, [reservation.notes?.rateBreakdownComment]);

  //bedding note for TFE
  const beddingNote = useMemo(() => {
    return reservation.notes?.beddingComment?.[0];
  }, [reservation.notes?.beddingComment]);

  return (
    <Grid2
      container
      sx={{
        justifyContent: "space-between",
        flexDirection: isMobile ? "column" : "row"
      }}
    >
      <Box
        sx={{
          flex: 3
        }}
      >
        <Grid2
          container
          sx={{
            alignItems: "center",

            [breakpoints.down(responsiveVariables.firstDesktopSize)]: {
              paddingRight: spacing(3)
            }
          }}
        >
          <Grid2>
            <Heading1 mr={1}>{`${reservation.firstName} ${reservation.lastName}`}</Heading1>
          </Grid2>
          {reservation.primaryGuest.vipCode && (
            <Grid2>
              <Grid2
                container
                sx={{
                  alignItems: "center"
                }}
              >
                <Tooltip title={reservation.primaryGuest.vipCode} arrow placement="bottom">
                  <div>
                    <CrownSimpleIcon sx={{ position: "relative", top: spacing(0.8) }} />
                  </div>
                </Tooltip>
              </Grid2>
            </Grid2>
          )}
          <Grid2>
            <Heading2 mr={1} color={theme.palette.grey[300]}>
              |
            </Heading2>
          </Grid2>
          <Grid2>
            <Grid2
              container
              sx={{
                alignItems: "center"
              }}
            >
              <Grid2>
                <Tooltip title={t("labels__tooltip__pms__link")} arrow placement="bottom">
                  <Box
                    onClick={() => openReservation(reservation)}
                    sx={{
                      display: "flex",
                      cursor: "pointer",
                      alignItems: "center",
                      gap: 1,
                      color: theme.palette.grey[500]
                    }}
                  >
                    <OpenInNewIcon fontSize="small" sx={{ top: spacing(0.5) }} />
                    <ParagraphBold color={theme.palette.grey[500]}>
                      {reservation.displayId}
                    </ParagraphBold>
                  </Box>
                </Tooltip>
              </Grid2>
              {checkinCode && (
                <>
                  <Grid2>
                    <Heading2 ml={1} pr={1} color={theme.palette.grey[300]}>
                      |
                    </Heading2>
                  </Grid2>
                  <Grid2
                    sx={{
                      mr: spacing(1)
                    }}
                  >
                    <Tooltip title={checkinCode} arrow placement="bottom">
                      <span>
                        <Chip
                          color="default"
                          size="small"
                          label={checkinCode}
                          sx={{ maxWidth: 120 }}
                        />
                      </span>
                    </Tooltip>
                  </Grid2>
                </>
              )}
              {beddingNote && (
                <>
                  <Grid2>
                    <Tooltip title={beddingNote} arrow placement="bottom">
                      <span>
                        <Chip
                          color="default"
                          size="small"
                          label={beddingNote}
                          sx={{ maxWidth: 120 }}
                        />
                      </span>
                    </Tooltip>
                  </Grid2>
                </>
              )}
              <Grid2>
                <ReservationActionsMenu
                  reservation={reservation}
                  anchorRef={reservationActionsAnchorRef}
                  iconVertical
                />
              </Grid2>
            </Grid2>
          </Grid2>
        </Grid2>
        <Grid2>
          <Grid2
            container
            direction="row"
            spacing={2}
            sx={{
              alignItems: "center",
              my: 0.5
            }}
          >
            {reservationShorInfoList.map((item) => (
              <React.Fragment key={`reservation-items-${item.id}`}>
                {item.showcase && (
                  <Grid2 key={`reservation-items-${item.id}`}>
                    <Grid2
                      container
                      direction="row"
                      sx={{
                        flexWrap: "nowrap",
                        alignItems: "center"
                      }}
                    >
                      {item.icon}
                      <Box
                        sx={{
                          ml: 0.5,
                          position: "relative"
                        }}
                      >
                        {item.tooltipContent && (
                          <Tooltip
                            title={item.tooltipContent}
                            arrow
                            enterTouchDelay={0}
                            placement="top"
                            slotProps={{
                              tooltip: {
                                sx: {
                                  maxWidth: theme.spacing(32)
                                }
                              }
                            }}
                          >
                            {item.label}
                          </Tooltip>
                        )}
                        {!item.tooltipContent && item.label}
                      </Box>
                    </Grid2>
                  </Grid2>
                )}
              </React.Fragment>
            ))}
          </Grid2>
        </Grid2>
      </Box>
      <Box
        sx={{
          flex: 1
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: isMobile ? "row" : "column",
            alignItems: isMobile ? "center" : "flex-end",
            gap: isMobile ? 1 : 0,
            justifyContent: isMobile ? "flex-start" : "flex-end"
          }}
        >
          <Heading1 sx={{ whiteSpace: "nowrap" }}>{formatPriceToString(balance)}</Heading1>
          <Chip
            label={t(`labels__balance_status_${balanceStatus.status}`)}
            sx={{
              color: `${balanceStatus.color}.dark`,
              backgroundColor: `${balanceStatus.color}.light`,
              width: "fit-content"
            }}
            size="small"
          />
        </Box>
      </Box>
    </Grid2>
  );
};
