import * as Types from "../generated/graphql";

import { ActorGroupFragmentDoc } from "./ActorGroup.generated";
export type ActorFragment = {
  __typename?: "Actor";
  id: number;
  actorId?: string | null;
  employeeOrGuestIdentityOrGroupId?: number | null;
  actorGroupId?: string | null;
  displayName?: string | null;
  actorGroup?: {
    __typename?: "ActorGroup";
    actorGroupType: Types.ActorGroupType;
    name: string;
  } | null;
};

export const ActorFragmentDoc = `
    fragment Actor on Actor {
  id
  actorId
  employeeOrGuestIdentityOrGroupId
  actorGroupId
  actorGroup {
    ...ActorGroup
  }
  displayName
}
    `;
