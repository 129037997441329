import { RegistrationCardFormDataType } from "../../domain/registration-card";
import {
  CommunicationChannel as CommunicationChannelV2,
  Gender,
  PersonInput,
  UpdatePersonInput
} from "../generated/graphql";
import { PersonGender } from "../../domain/person";
import { CommunicationChannel } from "@likemagic-tech/sv-magic-library";
import parsePhoneNumber from "libphonenumber-js/mobile";

export const transformRegistrationCardData = (
  values: RegistrationCardFormDataType,
  pmsReservationId: string
): {
  person: UpdatePersonInput;
  pmsReservationId: string;
  persons: PersonInput[];
  pmsProfileId: string;
} => {
  return {
    person: {
      address: {
        addressLine1: values.mainGuest.addressLine1,
        addressLine2: values.mainGuest.addressLine2,
        city: values.mainGuest.city,
        countryCode: values.mainGuest.country,
        postalCode: values.mainGuest.postcode.toString(),
        region: values.mainGuest.region
      },
      gender: mapPersonGenderToGender(values.mainGuest.gender as PersonGender),
      email: values.mainGuest.email,
      firstName: values.mainGuest.firstName,
      lastName: values.mainGuest.lastName,
      nationality: values.mainGuest.nationalityCountryCode,
      birthdate: values.mainGuest.birthdate || undefined,
      phone: parsePhoneNumber(values.mainGuest.phone)?.number ?? "",
      pmsId: values.mainGuest.id,
      emergencyEvacuationHelpNeeded: values.mainGuest.emergencyEvacuationHelpNeeded,
      emergencyEvacuationHelpNotes: values.mainGuest.emergencyEvacuationHelpNotes,
      enrolledInLoyaltyProgram: values.mainGuest.enrolledInLoyaltyProgram,
      licensePlate: values.mainGuest.licensePlate,
      preferredCommunicationChannel: transformCommunicationChannel(
        values.mainGuest.preferredCommunicationChannel
      ),
      identificationDocumentNumber: values.mainGuest.identificationNumber,
      identificationDocumentType:
        values.mainGuest.identificationNumber && !values.mainGuest.identificationType
          ? "PASSPORT"
          : undefined
    },
    pmsReservationId: pmsReservationId,
    pmsProfileId: values.mainGuest.id,
    persons: values.travelBuddies.map((buddy) => ({
      gender: mapPersonGenderToGender(buddy.gender as PersonGender),
      email: buddy.email,
      firstName: buddy.firstName,
      lastName: buddy.lastName,
      phone: parsePhoneNumber(buddy.phone)?.number,
      pmsId: buddy.id,
      birthdate: buddy.birthdate || null,
      nationality: buddy.nationalityCountryCode,
      identificationDocumentNumber: buddy.identificationNumber,
      identificationDocumentType:
        buddy.identificationNumber && !buddy.identificationType ? "PASSPORT" : undefined,
      address: {
        addressLine1: buddy?.addressLine1,
        city: buddy?.city,
        countryCode: buddy?.countryCode,
        postalCode: buddy?.postalCode?.toString()
      }
    }))
  };
};

export const mapPersonGenderToGender = (
  gender: PersonGender | null | undefined
): Gender | undefined => {
  switch (gender) {
    case PersonGender.MALE:
      return Gender.Male;
    case PersonGender.FEMALE:
      return Gender.Female;
    case PersonGender.OTHER:
      return Gender.Other;
    default:
      return undefined;
  }
};

export const transformCommunicationChannel = (
  channel?: string
): CommunicationChannelV2 | undefined => {
  switch (channel) {
    case CommunicationChannel.EMAIL:
      return CommunicationChannelV2.Email;
    case CommunicationChannel.WHATSAPP:
      return CommunicationChannelV2.Whatsapp;
    case CommunicationChannel.SMS:
      return CommunicationChannelV2.Sms;
    default:
      return undefined;
  }
};
