import { TableCell, TableHead, TableRow } from "@mui/material";
import { ParagraphSmall } from "@likemagic-tech/sv-magic-library";
import * as React from "react";
import { useTranslationWrapper } from "../../../../../../hooks/use-translation-wrapper";

export const DoorAccessLogsTableHead = () => {
  const { t } = useTranslationWrapper();
  return (
    <TableHead>
      <TableRow>
        <TableCell>
          <ParagraphSmall>{t("labels__door")}</ParagraphSmall>
        </TableCell>
        <TableCell>
          <ParagraphSmall>{t("labels__status")}</ParagraphSmall>
        </TableCell>
        <TableCell>
          <ParagraphSmall>{t("labels__requestor")}</ParagraphSmall>
        </TableCell>
        <TableCell>
          <ParagraphSmall>{t("labels__timestamp")}</ParagraphSmall>
        </TableCell>
      </TableRow>
    </TableHead>
  );
};
