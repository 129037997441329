import { FC, useMemo } from "react";
import { Grid2 } from "@mui/material";
import { Button, Chip } from "@likemagic-tech/sv-magic-library";
import { displayFilters } from "../../../utils/filters";
import CloseIcon from "@mui/icons-material/Close";
import * as React from "react";
import { appliableFilters, FilterMapInterface } from "./table-filters";
import { useTranslationWrapper } from "../../../hooks/use-translation-wrapper";

interface AddedFiltersProps {
  filterMap: FilterMapInterface;
  removeFilter: (val: Array<string>, isReset?: boolean) => void;
  filterValuesMapper?: Record<string, Record<string, string>>;
}

export const AddedFilters: FC<AddedFiltersProps> = ({
  filterMap,
  removeFilter,
  filterValuesMapper
}) => {
  const { t, exists } = useTranslationWrapper();

  const filtersToBeApplied = useMemo(() => appliableFilters(filterMap), [filterMap]);

  return (
    <Grid2
      size={{ xs: 12 }}
      spacing={1.25}
      container
      sx={{
        alignItems: "center"
      }}
    >
      {Object.keys(filtersToBeApplied).map((filterKey) => {
        return (
          <Grid2 key={`${filterKey}`}>
            <Chip
              label={displayFilters({
                filter: filterMap[filterKey],
                t: (v) => t(v ?? ""),
                exists: (v) => exists(v ?? ""),
                filterValuesMapper: filterValuesMapper ?? {}
              })}
              rightIcon={<CloseIcon />}
              sx={{ fontVariantLigatures: "none" }}
              onRightIconClick={() => removeFilter([filterKey])}
              variant="outlined"
              color="primary"
            />
          </Grid2>
        );
      })}
      {!!Object.keys(filtersToBeApplied).length && (
        <Grid2 key={"delete-all-filters"}>
          <Button
            variant="ghost"
            size="small"
            color="info"
            onClick={() => removeFilter(Object.keys(filterMap), true)}
          >
            {t("buttons__filter_reset")}
          </Button>
        </Grid2>
      )}
    </Grid2>
  );
};
