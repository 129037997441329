import React, { FC, useCallback, useMemo } from "react";
import {
  Autocomplete,
  Box,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid2,
  IconButton,
  InputAdornment,
  TextField,
  Tooltip,
  useTheme
} from "@mui/material";
import { useTranslationWrapper } from "../../../../hooks/use-translation-wrapper";
import {
  Button,
  Checkbox,
  Chip,
  DateRangePicker,
  Input,
  InputProps,
  ParagraphBold,
  ParagraphSmall
} from "@likemagic-tech/sv-magic-library";
import {
  BaseServiceConfig,
  BookableDay,
  ReservationStatus,
  ServiceConfigType,
  Visibility
} from "../../../../graphql-tenantconfig/generated/graphql";
import { FieldArray, useFormikContext } from "formik";
import {
  sortedBookableDays,
  sortedReservationSatuses,
  sortedVisibilities
} from "../service-config-utils";
import CloseIcon from "@mui/icons-material/Close";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import { getI18nSelectedLanguage } from "../../../../utils/language";
import { useIsMobile } from "../../../../hooks/use-is-mobile";
import Plus from "../../../../icons/Plus";
import { useCanEditServiceConfig } from "../use-can-edit-service-config";
import ClearIcon from "@mui/icons-material/Clear";

interface BaseServiceConfigFieldsProps {}

export const BaseServiceConfigFields: FC<BaseServiceConfigFieldsProps> = () => {
  const theme = useTheme();
  const { t } = useTranslationWrapper();
  const isMobile = useIsMobile();
  const { canEditServiceConfig } = useCanEditServiceConfig();

  const { values, errors, handleChange, setFieldValue } = useFormikContext<BaseServiceConfig>();

  const showMaximumBefore = useMemo(() => {
    return (
      ServiceConfigType.EarlyCheckin !== values.type &&
      ServiceConfigType.LateCheckout !== values.type &&
      ServiceConfigType.AdditionalCleaning !== values.type
    );
  }, [values.type]);

  const showBookingChannels = false;

  const showPayAtCheckout = useMemo(() => {
    return (
      ServiceConfigType.EarlyCheckin !== values.type &&
      ServiceConfigType.LateCheckout !== values.type
    );
  }, [values.type]);

  const disableReservationStatuses = useMemo(() => {
    return ServiceConfigType.EarlyCheckin === values.type;
  }, [values.type]);

  const bookableDaysOptions = sortedBookableDays(Object.values(BookableDay));

  const reservationStatusesOptions = sortedReservationSatuses(Object.values(ReservationStatus));

  const visibilitiesOptions = sortedVisibilities(Object.values(Visibility));

  const onChangeDateRange = useCallback(
    (from: string, to: string, fieldName: string) => {
      setFieldValue(fieldName, { startInclusive: from, endInclusive: to });
    },
    [setFieldValue]
  );

  // special handling for optional int maximum
  // needed because when deleting the value with keyboard delete
  // it would be parsed as an empty string which leads to deserialization problems on be
  const onChangeMaximum = useCallback(
    (value: string) => {
      if ("" === value) {
        setFieldValue("maximum", undefined);
      } else if (value && !isNaN(parseInt(value))) {
        setFieldValue("maximum", parseInt(value));
      }
    },
    [setFieldValue]
  );

  return (
    <>
      <Grid2 size={isMobile ? 12 : 6}>
        <ParagraphBold>{t("labels__service_config_form_baseconfig_minimumbefore")}</ParagraphBold>
        <ParagraphSmall sx={{ mt: theme.spacing(0.5) }}>
          {t("labels__service_config_form_baseconfig_minimumbefore_description")}
        </ParagraphSmall>
        <FormControl fullWidth={true} sx={{ mt: theme.spacing(0.75) }}>
          <Input
            id="minimumBefore"
            name="minimumBefore"
            variant="outlined"
            value={values.minimumBefore ?? ""}
            onChange={handleChange}
            error={errors.minimumBefore?.toString()}
            disabled={!canEditServiceConfig}
            sx={{
              ".MuiInputBase-input": {
                paddingY: theme.spacing(2)
              }
            }}
            endAdornment={
              canEditServiceConfig &&
              !!values.minimumBefore && (
                <InputAdornment sx={{ position: "absolute", right: 7 }} position="end">
                  <IconButton
                    sx={{ p: "4px" }}
                    onClick={() => {
                      setFieldValue("minimumBefore", undefined);
                    }}
                  >
                    <ClearIcon sx={{ fontSize: 20 }}></ClearIcon>
                  </IconButton>
                </InputAdornment>
              )
            }
          />
        </FormControl>
      </Grid2>
      {showMaximumBefore && (
        <Grid2 size={isMobile ? 12 : 6}>
          <ParagraphBold>{t("labels__service_config_form_baseconfig_maximumbefore")}</ParagraphBold>
          <ParagraphSmall sx={{ mt: theme.spacing(0.5) }}>
            {t("labels__service_config_form_baseconfig_maximumbefore_description")}
          </ParagraphSmall>
          <FormControl fullWidth={true} sx={{ mt: theme.spacing(0.75) }}>
            <Input
              id="maximumBefore"
              name="maximumBefore"
              variant="outlined"
              value={values.maximumBefore ?? ""}
              onChange={handleChange}
              error={errors.maximumBefore?.toString()}
              disabled={!canEditServiceConfig}
              sx={{
                ".MuiInputBase-input": {
                  paddingY: theme.spacing(2)
                }
              }}
              endAdornment={
                canEditServiceConfig &&
                !!values.maximumBefore && (
                  <InputAdornment sx={{ position: "absolute", right: 7 }} position="end">
                    <IconButton
                      sx={{ p: "4px" }}
                      onClick={() => {
                        setFieldValue("maximumBefore", undefined);
                      }}
                    >
                      <ClearIcon sx={{ fontSize: 20 }}></ClearIcon>
                    </IconButton>
                  </InputAdornment>
                )
              }
            />
          </FormControl>
        </Grid2>
      )}
      <Grid2 size={isMobile ? 12 : 6}>
        <ParagraphBold>{t("labels__service_config_form_baseconfig_maximum")}</ParagraphBold>
        <ParagraphSmall sx={{ mt: theme.spacing(0.5) }}>
          {t("labels__service_config_form_baseconfig_maximum_description")}
        </ParagraphSmall>
        <FormControl fullWidth={true} sx={{ mt: theme.spacing(0.75) }}>
          <Input
            id="maximum"
            name="maximum"
            variant="outlined"
            value={values.maximum ?? ""}
            type={"number"}
            inputMode={"numeric"}
            onChange={(e) => onChangeMaximum(e.target.value)}
            error={errors.maximum?.toString()}
            disabled={!canEditServiceConfig}
            sx={{
              ".MuiInputBase-input": {
                paddingY: theme.spacing(2)
              }
            }}
            endAdornment={
              canEditServiceConfig &&
              !!values.maximum && (
                <InputAdornment sx={{ position: "absolute", right: 32 }} position="end">
                  <IconButton
                    sx={{ p: "4px" }}
                    onClick={() => {
                      setFieldValue("maximum", undefined);
                    }}
                  >
                    <ClearIcon sx={{ fontSize: 20 }}></ClearIcon>
                  </IconButton>
                </InputAdornment>
              )
            }
          />
        </FormControl>
      </Grid2>
      <Grid2 size={isMobile ? 12 : 6}>
        <ParagraphBold>{t("labels__service_config_form_baseconfig_bookabledays")}</ParagraphBold>
        <ParagraphSmall sx={{ mt: theme.spacing(0.5) }}>
          {t("labels__service_config_form_baseconfig_bookabledays_description")}
        </ParagraphSmall>
        <FormControl fullWidth={true} sx={{ mt: theme.spacing(0.75) }}>
          <Autocomplete
            multiple
            freeSolo={false}
            value={sortedBookableDays(values.bookableDays ?? [])}
            options={bookableDaysOptions}
            disabled={!canEditServiceConfig}
            onChange={(_, newValue) => setFieldValue("bookableDays", newValue)}
            getOptionLabel={(o) => t("labels__settings_service_config_bookableday_" + o)}
            renderTags={(value, getTagProps) => {
              return (
                <>
                  {value.map((item, index) => {
                    return (
                      <Chip
                        sx={{ mx: 0.5 }}
                        color={
                          errors.bookableDays && errors.bookableDays[index] ? "error" : "default"
                        }
                        {...getTagProps({ index })}
                        label={t("labels__settings_service_config_bookableday_" + item)}
                        size="small"
                        rightIcon={canEditServiceConfig ? <CloseIcon /> : <></>}
                        onRightIconClick={() => getTagProps({ index }).onDelete({})}
                      />
                    );
                  })}
                </>
              );
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                error={!!errors.bookableDays}
                disabled={!canEditServiceConfig}
              />
            )}
            defaultValue={[]}
          />
          <FormHelperText error required>
            {errors.bookableDays?.toString()}
          </FormHelperText>
        </FormControl>
      </Grid2>
      {showBookingChannels && (
        <Grid2 size={isMobile ? 12 : 6}>
          <ParagraphBold>
            {t("labels__service_config_form_baseconfig_bookingchannels")}
          </ParagraphBold>
          <ParagraphSmall sx={{ mt: theme.spacing(0.5) }}>
            {t("labels__service_config_form_baseconfig_bookingchannels_description")}
          </ParagraphSmall>
          <FormControl fullWidth={true} sx={{ mt: theme.spacing(0.75) }}>
            <Input
              id="bookingChannels"
              name="bookingChannels"
              variant="outlined"
              value={values.bookingChannels ?? ""}
              onChange={handleChange}
              error={errors.bookingChannels?.toString()}
              disabled={!canEditServiceConfig}
              sx={{
                ".MuiInputBase-input": {
                  paddingY: theme.spacing(2)
                }
              }}
            />
          </FormControl>
        </Grid2>
      )}
      <Grid2 size={isMobile ? 12 : 6}>
        <ParagraphBold>
          {t("labels__service_config_form_baseconfig_reservationstatuses")}
        </ParagraphBold>
        <ParagraphSmall sx={{ mt: theme.spacing(0.5) }}>
          {t("labels__service_config_form_baseconfig_reservationstatuses_description")}
        </ParagraphSmall>
        <FormControl fullWidth={true} sx={{ mt: theme.spacing(0.75) }}>
          <Autocomplete
            multiple
            freeSolo={false}
            value={sortedReservationSatuses(values.reservationStatuses ?? [])}
            options={reservationStatusesOptions}
            onChange={(_, newValue) => setFieldValue("reservationStatuses", newValue)}
            getOptionLabel={(o) => t("labels__settings_service_config_reservationstatus_" + o)}
            disabled={!canEditServiceConfig || disableReservationStatuses}
            renderTags={(value, getTagProps) => {
              return (
                <>
                  {value.map((item, index) => {
                    return (
                      <Chip
                        sx={{ mx: 0.5 }}
                        color={
                          errors.reservationStatuses && errors.reservationStatuses[index]
                            ? "error"
                            : "default"
                        }
                        {...getTagProps({ index })}
                        label={t("labels__settings_service_config_reservationstatus_" + item)}
                        size="small"
                        rightIcon={
                          !canEditServiceConfig || disableReservationStatuses ? (
                            <></>
                          ) : (
                            <CloseIcon />
                          )
                        }
                        onRightIconClick={() =>
                          !disableReservationStatuses && getTagProps({ index }).onDelete({})
                        }
                      />
                    );
                  })}
                </>
              );
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                error={!!errors.reservationStatuses}
                disabled={!canEditServiceConfig || disableReservationStatuses}
              />
            )}
            defaultValue={[]}
          />
          <FormHelperText error required>
            {errors.reservationStatuses?.toString()}
          </FormHelperText>
        </FormControl>
      </Grid2>
      <Grid2 size={isMobile ? 12 : 6}>
        <ParagraphBold>{t("labels__service_config_form_baseconfig_visibilities")}</ParagraphBold>
        <ParagraphSmall sx={{ mt: theme.spacing(0.5) }}>
          {t("labels__service_config_form_baseconfig_visibilities_description")}
        </ParagraphSmall>
        <FormControl fullWidth={true} sx={{ mt: theme.spacing(0.75) }}>
          <Autocomplete
            multiple
            freeSolo={false}
            value={sortedVisibilities(values.visibilities ?? [])}
            options={visibilitiesOptions}
            onChange={(_, newValue) => setFieldValue("visibilities", newValue)}
            getOptionLabel={(o) => t("labels__settings_service_config_visibility_" + o)}
            disabled={!canEditServiceConfig}
            renderTags={(value, getTagProps) => {
              return (
                <>
                  {value.map((item, index) => {
                    return (
                      <Chip
                        sx={{ mx: 0.5 }}
                        color={
                          errors.visibilities && errors.visibilities[index] ? "error" : "default"
                        }
                        {...getTagProps({ index })}
                        label={t("labels__settings_service_config_visibility_" + item)}
                        size="small"
                        rightIcon={!canEditServiceConfig ? <></> : <CloseIcon />}
                        onRightIconClick={() => getTagProps({ index }).onDelete({})}
                      />
                    );
                  })}
                </>
              );
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                error={!!errors.visibilities}
                disabled={!canEditServiceConfig}
              />
            )}
            defaultValue={[]}
          />
          <FormHelperText error required>
            {errors.visibilities?.toString()}
          </FormHelperText>
        </FormControl>
      </Grid2>
      {showPayAtCheckout && (
        <Grid2 size={12}>
          <ParagraphBold>{t("labels__service_config_form_baseconfig_payatcheckout")}</ParagraphBold>
          <ParagraphSmall sx={{ mt: theme.spacing(0.5) }}>
            {t("labels__service_config_form_baseconfig_payatcheckout_description")}
          </ParagraphSmall>
          <FormControl fullWidth={true} sx={{ mt: theme.spacing(0.75), ml: theme.spacing(1.25) }}>
            <FormControlLabel
              disabled={!canEditServiceConfig}
              control={
                <Checkbox
                  id="payAtCheckout"
                  name="payAtCheckout"
                  checked={!!values.payAtCheckout}
                  value={true}
                  onChange={handleChange}
                />
              }
              label={t("labels__service_config_form_baseconfig_payatcheckout_checkbox")}
            />
          </FormControl>
        </Grid2>
      )}
      <Grid2 size={12}>
        <ParagraphBold>
          {t("labels__service_config_form_baseconfig_whitelisteddateranges")}
        </ParagraphBold>
        <ParagraphSmall sx={{ mt: theme.spacing(0.5) }}>
          {t("labels__service_config_form_baseconfig_whitelisteddateranges_description")}
        </ParagraphSmall>
        <Box sx={{ mt: theme.spacing(0.75) }}>
          <FieldArray
            name="whitelistedDateRanges"
            render={(arrayHelpers) => (
              <>
                {values.whitelistedDateRanges?.map((dateRange, index) => (
                  <Box
                    key={`whitelisted-date-range-${index}`}
                    sx={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}
                  >
                    <FormControl fullWidth={true} sx={{ flexGrow: 1, mb: theme.spacing(0.75) }}>
                      {canEditServiceConfig ? (
                        <DateRangePicker
                          arrival={dateRange?.startInclusive}
                          departure={dateRange?.endInclusive}
                          onSubmit={(from, to) =>
                            onChangeDateRange(from, to, `whitelistedDateRanges[${index}]`)
                          }
                          label={""}
                          buttonLabel={t(
                            "labels__service_config_form_baseconfig_apply_date_range_button"
                          )}
                          selectedLanguage={getI18nSelectedLanguage()}
                          isMobile={isMobile}
                          error={
                            errors.whitelistedDateRanges && errors.whitelistedDateRanges[index]
                          }
                          CustomInputComponent={(props: InputProps) => (
                            <Input
                              variant="outlined"
                              {...props}
                              endAdornment={
                                <>
                                  {props.endAdornment}
                                  <Tooltip
                                    title={t(
                                      "labels__service_config_form_baseconfig_delete_date_range_button"
                                    )}
                                  >
                                    <IconButton
                                      size="small"
                                      color="error"
                                      onClick={(e) => {
                                        e.preventDefault();
                                        e.stopPropagation();
                                        arrayHelpers.remove(index);
                                      }}
                                    >
                                      <DeleteForeverIcon />
                                    </IconButton>
                                  </Tooltip>
                                </>
                              }
                            />
                          )}
                        />
                      ) : (
                        <Input
                          value={`${dateRange?.startInclusive} - ${dateRange?.endInclusive}`}
                          variant="outlined"
                          label={""}
                          error={
                            errors.whitelistedDateRanges && errors.whitelistedDateRanges[index]
                              ? errors.whitelistedDateRanges[index].toString()
                              : ""
                          }
                          disabled={true}
                          sx={{
                            ".MuiInputBase-input": {
                              paddingY: theme.spacing(2)
                            }
                          }}
                        />
                      )}
                    </FormControl>
                  </Box>
                ))}

                <Box>
                  {(!values.whitelistedDateRanges || values.whitelistedDateRanges.length === 0) && (
                    <ParagraphSmall
                      sx={{ fontStyle: "italic", color: theme.palette.text.secondary }}
                    >
                      {t("labels__service_config_form_baseconfig_whitelisteddateranges_empty")}
                    </ParagraphSmall>
                  )}
                </Box>
                {canEditServiceConfig && (
                  <Button
                    variant="secondary"
                    size="small"
                    startIcon={<Plus />}
                    sx={{ whiteSpace: "nowrap", mt: theme.spacing(1.25) }}
                    onClick={() => arrayHelpers.push(null)}
                  >
                    {t("labels__service_config_form_baseconfig_add_date_range_button")}
                  </Button>
                )}
              </>
            )}
          />
        </Box>
      </Grid2>
      <Grid2 size={12}>
        <ParagraphBold>
          {t("labels__service_config_form_baseconfig_blacklisteddateranges")}
        </ParagraphBold>
        <ParagraphSmall sx={{ mt: theme.spacing(0.5) }}>
          {t("labels__service_config_form_baseconfig_blacklisteddateranges_description")}
        </ParagraphSmall>
        <Box sx={{ mt: theme.spacing(0.75) }}>
          <FieldArray
            name="blacklistedDateRanges"
            render={(arrayHelpers) => (
              <>
                {values.blacklistedDateRanges?.map((dateRange, index) => (
                  <Box
                    key={`blacklisted-date-range-${index}`}
                    sx={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}
                  >
                    <FormControl fullWidth={true} sx={{ flexGrow: 1, mb: theme.spacing(0.75) }}>
                      {canEditServiceConfig ? (
                        <DateRangePicker
                          arrival={dateRange?.startInclusive}
                          departure={dateRange?.endInclusive}
                          onSubmit={(from, to) =>
                            onChangeDateRange(from, to, `blacklistedDateRanges[${index}]`)
                          }
                          label={""}
                          buttonLabel={t(
                            "labels__service_config_form_baseconfig_apply_date_range_button"
                          )}
                          selectedLanguage={getI18nSelectedLanguage()}
                          isMobile={isMobile}
                          error={
                            errors.blacklistedDateRanges && errors.blacklistedDateRanges[index]
                          }
                          CustomInputComponent={(props: InputProps) => (
                            <Input
                              variant="outlined"
                              {...props}
                              endAdornment={
                                <>
                                  {props.endAdornment}
                                  <Tooltip
                                    title={t(
                                      "labels__service_config_form_baseconfig_delete_date_range_button"
                                    )}
                                  >
                                    <IconButton
                                      size="small"
                                      color="error"
                                      onClick={(e) => {
                                        e.preventDefault();
                                        e.stopPropagation();
                                        arrayHelpers.remove(index);
                                      }}
                                    >
                                      <DeleteForeverIcon />
                                    </IconButton>
                                  </Tooltip>
                                </>
                              }
                            />
                          )}
                        />
                      ) : (
                        <Input
                          value={`${dateRange?.startInclusive} - ${dateRange?.endInclusive}`}
                          variant="outlined"
                          label={""}
                          error={
                            errors.blacklistedDateRanges && errors.blacklistedDateRanges[index]
                              ? errors.blacklistedDateRanges[index].toString()
                              : ""
                          }
                          disabled={true}
                          sx={{
                            ".MuiInputBase-input": {
                              paddingY: theme.spacing(2)
                            }
                          }}
                        />
                      )}
                    </FormControl>
                  </Box>
                ))}

                <Box>
                  {(!values.blacklistedDateRanges || values.blacklistedDateRanges.length === 0) && (
                    <ParagraphSmall
                      sx={{ fontStyle: "italic", color: theme.palette.text.secondary }}
                    >
                      {t("labels__service_config_form_baseconfig_blacklisteddateranges_empty")}
                    </ParagraphSmall>
                  )}
                </Box>
                {canEditServiceConfig && (
                  <Button
                    variant="secondary"
                    size="small"
                    startIcon={<Plus />}
                    sx={{ whiteSpace: "nowrap", mt: theme.spacing(1.25) }}
                    onClick={() => arrayHelpers.push(null)}
                  >
                    {t("labels__service_config_form_baseconfig_add_date_range_button")}
                  </Button>
                )}
              </>
            )}
          />
        </Box>
      </Grid2>
    </>
  );
};
