import React, { FC, useEffect, useMemo, useState } from "react";
import IconButton from "@mui/material/IconButton";
import EditIcon from "@mui/icons-material/Edit";
import {
  MaintenanceInformationProps,
  MaintenanceModal
} from "../maintenance-modal/maintenance-modal";
import { useTranslationWrapper } from "src/hooks/use-translation-wrapper";
import { Notification } from "../notification";
import { useUpdateMaintenanceSlot } from "src/hooks/use-maintenance";
import { MaintenanceSlotArgs } from "src/store/endpoints/unit.endpoints";
import { useProperty } from "src/hooks/use-property";
import { useFetchUnitsToBeCleaned } from "src/hooks/use-fetch-units-to-be-cleaned";
import { useDispatch } from "src/store";
import { openBanner } from "src/slices/banner.slice";
import HeartBrokenIcon from "@mui/icons-material/HeartBroken";
import { MaintenanceSlotType } from "../../graphql-tasks/generated/graphql";
import { Box, Grid2, useTheme } from "@mui/material";
import { useInvalidateHousekeepingCards } from "../../hooks/use-invalidate-housekeeping-cards";
import { formatDate, formatTime } from "@likemagic-tech/sv-magic-library";

interface MaintenanceSlotInfoProps {
  maintenance: MaintenanceInformationProps;
}

export const MaintenanceSlotInfo: FC<MaintenanceSlotInfoProps> = ({ maintenance }) => {
  const { description, status, id, from, to, type, unitIds } = maintenance;
  const [maintenanceModalOpen, setMaintenanceModalOpen] = useState(false);
  const { t } = useTranslationWrapper();
  const [updateMaintenance, updateResult] = useUpdateMaintenanceSlot();
  const { selectedProperty } = useProperty();
  const { fetchAction } = useFetchUnitsToBeCleaned({
    propertyId: selectedProperty?.propertyId ?? "",
    skipInitialLoad: true
  });
  const { palette } = useTheme();
  const dispatch = useDispatch();
  const { invalidateHousekeepingCards } = useInvalidateHousekeepingCards();
  useEffect(() => {
    if (updateResult?.isSuccess) {
      dispatch(
        openBanner({
          type: "success",
          title: t("labels__action_successfully_performed")
        })
      );
      updateResult.reset();
      fetchAction();
      setMaintenanceModalOpen(false);
    }
  }, [dispatch, t, updateResult, fetchAction]);

  const calculateMaintenanceInfo = () => {
    if (to && type) {
      const localizedDate = formatDate(to);
      const localizedTime = formatTime(to);
      return `${t("labels__" + type + "_until")} ${localizedDate} | ${localizedTime}`;
    }
    return "";
  };

  const openMaintenanceModal = () => {
    setMaintenanceModalOpen(true);
  };

  const getColor = useMemo(() => {
    switch (type) {
      case MaintenanceSlotType.OutOfService:
        return "warning";
      case MaintenanceSlotType.OutOfOrder:
        return "error";
      case MaintenanceSlotType.OutOfInventory:
        return "info";
      default:
        return "info";
    }
  }, [type]);

  return (
    <>
      <Grid2
        container
        spacing={2}
        sx={{
          mt: 2,
          alignItems: "center"
        }}
      >
        <Box
          sx={{
            gap: 2,
            alignItems: "center",
            justifyContent: "space-between",
            flexWrap: "nowrap",
            display: "flex",
            width: "100%"
          }}
        >
          <Box sx={{ width: "100%" }}>
            <Notification
              Icon={HeartBrokenIcon}
              showIcon={false}
              type={getColor}
              title={calculateMaintenanceInfo()}
              subtitle={description ?? ""}
            />
          </Box>
          <IconButton onClick={openMaintenanceModal} sx={{ padding: 0, color: palette.grey[600] }}>
            <EditIcon />
          </IconButton>
        </Box>
      </Grid2>
      {maintenanceModalOpen && (
        <MaintenanceModal
          onSubmit={(values: MaintenanceSlotArgs) =>
            updateMaintenance(values).then(() => {
              invalidateHousekeepingCards();
            })
          }
          maintenance={{ description, id, from, to, type, unitIds, status }}
          onClose={() => setMaintenanceModalOpen(false)}
        />
      )}
    </>
  );
};
