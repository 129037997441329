import { DoorType } from "../../../../../api/door-delegate";
import { Grid2 } from "@mui/material";
import { Button } from "@likemagic-tech/sv-magic-library";
import SyncIcon from "@mui/icons-material/Sync";
import * as React from "react";
import { FC, useMemo } from "react";
import { useFetchSupportedSyncStates } from "../../../../../hooks/use-fetch-supported-sync-states";
import { ReservationDetailsDTO } from "../../../../../store/endpoints/reservation-table.endpoints";
import { useTranslationWrapper } from "../../../../../hooks/use-translation-wrapper";
import { useReservationDoors } from "../../../../../hooks/use-reservation-doors";

interface KeySynchronizationProps {
  reservation: ReservationDetailsDTO;
}
export const KeySynchronization: FC<KeySynchronizationProps> = ({ reservation }) => {
  const { t } = useTranslationWrapper();
  const doorTypesWithoutNone = useFetchSupportedSyncStates(reservation.propertyId);
  const { handleSyncDoors } = useReservationDoors(reservation);

  const labelMap = useMemo(() => {
    return {
      [DoorType.PUBLIC]: t("labels__sync_doors_PUBLIC"),
      [DoorType.PUBLIC_INHOUSE]: t("labels__sync_doors_PUBLIC_INHOUSE"),
      [DoorType.PUBLIC_AND_PRIVATE]: t("labels__sync_doors_PUBLIC_AND_PRIVATE")
    };
  }, [t]);

  return (
    <Grid2 container>
      {doorTypesWithoutNone.map((doorType: DoorType) => (
        <Grid2
          size={{ xs: 12 }}
          key={doorType}
          sx={{
            paddingBottom: 2
          }}
        >
          <Button
            variant="secondary"
            fullWidth
            onClick={handleSyncDoors(doorType)}
            sx={{
              justifyContent: "start"
            }}
            startIcon={<SyncIcon fontSize="large" />}
          >
            {labelMap[doorType as keyof typeof labelMap]}
          </Button>
        </Grid2>
      ))}
    </Grid2>
  );
};
