import { Box, Grid2, styled } from "@mui/material";
import * as React from "react";
import { FC } from "react";
import { Paragraph, ParagraphSmall } from "@likemagic-tech/sv-magic-library";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import { useTranslationWrapper } from "../../hooks/use-translation-wrapper";

export enum StepStatus {
  IDLE = "IDLE",
  CURRENT = "CURRENT",
  PASSED = "PASSED"
}

interface StepProps {
  stepName: string;
  status: StepStatus;
  idleColor?: string;
  currentColor?: string;
  passedColor?: string;
  keepSecondaryText?: boolean;
  stepWidth?: string;
}

export const Step: FC<StepProps> = ({
  stepName,
  status,
  idleColor,
  passedColor = "success.main",
  currentColor = "secondary.main",
  keepSecondaryText,
  stepWidth = "14.28%"
}) => {
  const theme = useTheme();
  const { t } = useTranslationWrapper();
  const typographyType = useMediaQuery(theme.breakpoints.down("xl"));
  const idleColorWithDefault = idleColor ?? theme.palette.grey[300];

  const StepLabel = styled(!typographyType ? Paragraph : ParagraphSmall)(({ theme }) => ({
    textAlign: "center",
    wordBreak: "break-word",
    [theme.breakpoints.down("lg")]: {
      display: "none"
    }
  }));

  return (
    <Grid2
      key={stepName}
      sx={{
        width: stepWidth,
        paddingX: 0.5
      }}
    >
      <Box
        sx={{
          height: 8,
          borderRadius: 8,
          bgcolor:
            status === StepStatus.PASSED
              ? passedColor
              : status === StepStatus.CURRENT
                ? currentColor
                : idleColorWithDefault
        }}
      />
      <StepLabel
        p={1}
        pb={0}
        color={
          status === StepStatus.PASSED || keepSecondaryText ? "text.secondary" : "text.primary"
        }
      >
        {t(`labels__step_data_${stepName}`)}
      </StepLabel>
    </Grid2>
  );
};
