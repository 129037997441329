import { Params, useSelectId } from "../../../../hooks/use-select-id";

export const useServiceConfigModal = () => {
  const { selectedId, setSelectId } = useSelectId({
    fieldName: Params.SERVICE_CONFIG_ID_SEARCH_PARAM_NAME
  });

  return {
    serviceConfigId: selectedId ?? undefined,
    openNewServiceConfig: () => setSelectId(Params.CREATE_SERVICE_CONFIG_PARAM),
    openEditServiceConfig: (serviceConfigId: string) => setSelectId(serviceConfigId),
    onClose: (event?: object, reason?: string) => {
      if (reason === "backdropClick" || reason === "escapeKeyDown") {
        // do not close service config modal on backdrop click or escape key
        return;
      }
      setSelectId(
        undefined,
        new URLSearchParams({
          [Params.SERVICE_CONFIG_ID_SEARCH_PARAM_NAME]: ""
        })
      );
    }
  };
};
