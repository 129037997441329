import { PageableResponse } from "../types/pageable";
import { BookingTableDto } from "../domain/booking-table-dto";
import { FilterObject } from "../types/filters/filters";

const item1 = {
  id: "BOOKING_ID",
  displayId: "NICE_BOOKING_ID",
  name: "Group booking name",
  noOfReservations: 1,
  booker: {
    id: "28727",
    pmsId: "3672539",
    firstName: "vvv",
    lastName: "vv",
    email: "vladan+5555@likemagic.tech"
  },
  firstArrival: "2025-03-14T02:30:00Z",
  lastDeparture: "2025-03-15T00:30:00Z"
};

interface UseGetBookingsProps {
  filters: FilterObject[];
}

export const useGetBookings = ({
  filters
}: UseGetBookingsProps): PageableResponse<BookingTableDto> => {
  console.log("call api with filter", filters);
  return {
    content: [item1],
    number: 0,
    pageable: {
      offset: 0,
      pageNumber: 0,
      pageSize: 0,
      paged: false,
      sort: { empty: false, sorted: false, unsorted: false },
      unpaged: false
    },
    size: 0,
    sort: { empty: false, sorted: false, unsorted: false },
    totalElements: 1,
    totalPages: 1
  };
};

export const useGetBooking = (bookingId?: string): BookingTableDto => {
  console.log("get booking with id", bookingId);
  return item1;
};
