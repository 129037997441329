import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import { TranslationType } from "./core/translation.types";

interface I18nInitOptions {
  englishFile: TranslationType;
  germanFile: TranslationType;
  spanishFile: TranslationType;
  italianFile: TranslationType;
  portugueseFile: TranslationType;
  serbianFile: TranslationType;
  turkishFile: TranslationType;
  ukrainianFile: TranslationType;
  fallbackLanguage: string;
}

i18n.use(initReactI18next); // passes i18n down to react-i18next
// detect user language
// learn more: https://github.com/i18next/i18next-browser-languageDetector

export const initI18n = ({
  fallbackLanguage,
  englishFile,
  germanFile,
  spanishFile,
  italianFile,
  portugueseFile,
  serbianFile,
  turkishFile,
  ukrainianFile
}: I18nInitOptions) => {
  return i18n.init({
    // the translations
    // (tip move them in a JSON file and import them,
    // or even better, manage them via a UI: https://react.i18next.com/guides/multiple-translation-files#manage-your-translations-with-a-management-gui)
    resources: {
      en: {
        common: englishFile
      },
      de: {
        common: germanFile
      },
      es: {
        common: spanishFile
      },
      it: {
        common: italianFile
      },
      pt: {
        common: portugueseFile
      },
      sr: {
        common: serbianFile
      },
      tr: {
        common: turkishFile
      },
      uk: {
        common: ukrainianFile
      }
    },
    fallbackLng: fallbackLanguage,
    interpolation: {
      escapeValue: false
    }
  });
};
