import React, { FC } from "react";
import { Box, Grid2 } from "@mui/material";
import { PaymentOption } from "./domain/payment-option";
import {
  PaperRadioButton,
  ParagraphBold,
  RadioButtonGroup,
  TokenizedCardDisplay
} from "@likemagic-tech/sv-magic-library";
import { useTranslationWrapper } from "../../../hooks/use-translation-wrapper";
import { PaymentLink } from "../../../icons/payment-link";
import { PaymentPreAuthorization } from "../../../icons/payment-pre-authorization";
import { PaymentTerminal } from "../../../icons/payment-terminal";
import { PaymentCash } from "../../../icons/payment-cash";
import { PaymentMethodDataItemType } from "./payment-component-handlers/use-payment-method-options";
import { useIsMobile } from "../../../hooks/use-is-mobile";
import { transformTokenizedCardTypeToCommon } from "../../../graphql/transform/transform-payment";

const PaymentOptionIconsMapper = {
  [PaymentOption.PaymentLink]: <PaymentLink />,
  [PaymentOption.Cash]: <PaymentCash />,
  [PaymentOption.TerminalPayment]: <PaymentTerminal />,
  [PaymentOption.PreAuthorization]: <PaymentPreAuthorization />,
  [PaymentOption.StoredPaymentMethod]: <PaymentLink />,
  [PaymentOption.AdyenWidget]: <></>,
  [PaymentOption.MewsPayment]: <></>,
  [PaymentOption.PayAtCheckout]: <></>,
  [PaymentOption.PayAtCheckin]: <></>,
  [PaymentOption.DatatransWidget]: <></>,
  [PaymentOption.PmsTokenizedCard]: <></>
};
interface PaymentSelectionProps {
  value?: string;
  setValue: (option: string) => void;
  filterPaymentOptions?: Array<PaymentOption>;
  options: Array<PaymentMethodDataItemType>;
  cardOnFileLabel?: string;
}
export const PaymentSelection: FC<PaymentSelectionProps> = ({
  value,
  setValue,
  filterPaymentOptions = [],
  options
}) => {
  const { t } = useTranslationWrapper();
  const isMobile = useIsMobile();
  return (
    <Box>
      <RadioButtonGroup value={value} onChange={(_, value) => setValue(value)}>
        <Grid2
          container
          spacing={1}
          sx={{
            width: "100%"
          }}
        >
          {options
            .filter((item) => !filterPaymentOptions.includes(item.type))
            .map((item, index) => (
              <Grid2
                size={{ xs: 12, md: 6 }}
                key={`payment_option_${item}_${index}`}
                sx={{
                  mt: -2,
                  width: "100%"
                }}
              >
                <PaperRadioButton
                  content={
                    item.__typename !== "StoredPaymentMethod" ? (
                      <Grid2
                        container
                        spacing={1}
                        sx={{
                          alignItems: "center"
                        }}
                      >
                        <Grid2>{PaymentOptionIconsMapper[item.type]}</Grid2>
                        <Grid2>
                          <ParagraphBold>{t(`labels__payment_${item?.type}`)}</ParagraphBold>
                        </Grid2>
                      </Grid2>
                    ) : (
                      <Grid2
                        container
                        spacing={1}
                        sx={{
                          alignItems: "center"
                        }}
                      >
                        <TokenizedCardDisplay
                          isMobile={isMobile}
                          paymentAccount={{
                            expiryYear: item?.config?.expiryYear ?? "",
                            expiryMonth: item?.config?.expiryMonth ?? "",
                            accountNumber: item?.config?.lastFour ?? "",
                            //Wrong way to use transform but needs to be there since we do not transform within usePaymentMethodOptions
                            paymentMethod: item?.config?.brand
                              ? transformTokenizedCardTypeToCommon(item?.config?.brand)
                              : undefined,
                            accountHolder: item?.config?.holderName ?? ""
                          }}
                        />
                      </Grid2>
                    )
                  }
                  value={item.hashCode}
                />
              </Grid2>
            ))}
        </Grid2>
      </RadioButtonGroup>
    </Box>
  );
};
