import { FilterObjectType, serializeIdOfFilter } from "src/components/table/filters/table-filters";
import { generateURLSearchParams } from "src/components/table/hooks/use-search-filter-params";
import { FilterOperator } from "src/types/filters/filters";

const taskStatusData = {
  name: "status",
  type: FilterObjectType.ADDITIONAL,
  operator: FilterOperator.In
};

const taskTypeData = {
  name: "type",
  type: FilterObjectType.ADDITIONAL,
  operator: FilterOperator.In
};

const taskAssignedToTeamsOfData = {
  name: "assignedToTeamsOf",
  type: FilterObjectType.AUTOCOMPLETE,
  operator: FilterOperator.Equality
};

const taskSRelatedToReservationData = {
  name: "relatedTo",
  type: FilterObjectType.ADDITIONAL,
  operator: FilterOperator.Equality
};

export const defaultTaskSearchFilters = (
  currentActorId: string,
  foodAndBeverageEnabled: boolean
) => {
  return {
    [serializeIdOfFilter(taskStatusData)]: {
      ...taskStatusData,
      value: "TODO,IN_PROGRESS"
    },
    [serializeIdOfFilter(taskTypeData)]: {
      ...taskTypeData,
      value: foodAndBeverageEnabled
        ? "MANUAL_TASK,SERVICE_TASK,AUTOMATED_TASK,GUEST_TASK,FOOD_AND_BEVERAGE_ORDER,SHOP_ORDER"
        : "MANUAL_TASK,SERVICE_TASK,AUTOMATED_TASK,GUEST_TASK,SHOP_ORDER"
    },
    [serializeIdOfFilter(taskAssignedToTeamsOfData)]: {
      ...taskAssignedToTeamsOfData,
      value: currentActorId
    }
  };
};

export const generateDefaultTaskTableURLSearchParams = (
  foodAndBeverageEnabled: boolean,
  currentActorId: string
) => generateURLSearchParams(defaultTaskSearchFilters(currentActorId, foodAndBeverageEnabled));

export const generateTaskRelatedToQueryParams = (
  reservationId: string,
  foodAndBeverageEnabled: boolean
) => {
  return generateURLSearchParams({
    [serializeIdOfFilter(taskSRelatedToReservationData)]: {
      ...taskSRelatedToReservationData,
      value: reservationId
    },
    [serializeIdOfFilter(taskTypeData)]: {
      ...taskTypeData,
      value: foodAndBeverageEnabled
        ? "MANUAL_TASK,SERVICE_TASK,AUTOMATED_TASK,GUEST_TASK,FOOD_AND_BEVERAGE_ORDER,SHOP_ORDER"
        : "MANUAL_TASK,SERVICE_TASK,AUTOMATED_TASK,GUEST_TASK,SHOP_ORDER"
    }
  });
};
