import * as Types from "../generated/graphql";

import { UnitGroupContractFragmentDoc } from "./UnitGroupContract.generated";
export type UnitGroupIdentifierFragment = {
  __typename?: "UnitGroupIdentifier";
  unitGroupId: string;
  details?: {
    __typename?: "UnitGroupContract";
    active?: boolean | null;
    capacity?: number | null;
    extraCapacity?: number | null;
    id?: string | null;
    name?: string | null;
    pmsId: string;
    pmsPropertyId?: string | null;
    pmsUpdatedAt?: any | null;
    type?: string | null;
  } | null;
};

export const UnitGroupIdentifierFragmentDoc = `
    fragment UnitGroupIdentifier on UnitGroupIdentifier {
  unitGroupId
  details {
    ...UnitGroupContract
  }
}
    `;
