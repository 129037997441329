import * as React from "react";
import { FC } from "react";
import { Grid2, Paper, useTheme } from "@mui/material";
import { Heading3 } from "@likemagic-tech/sv-magic-library";

interface ReservationDetailsWidgetProps {
  size?: { xs?: number; sm?: number; md?: number; lg?: number };
  title: string;
  body: JSX.Element;
  endButton?: JSX.Element;
}

export const ReservationDetailsWidget: FC<ReservationDetailsWidgetProps> = ({
  size,
  title,
  body,
  endButton
}) => {
  const theme = useTheme();
  return (
    <Grid2 size={size}>
      <Paper sx={{ height: "100%" }} elevation={0}>
        <Grid2
          container
          direction="column"
          sx={{
            p: 3
          }}
        >
          <Grid2
            container
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            width="100%"
            flexWrap="nowrap"
            pb={2}
          >
            <Grid2 size={{ xs: endButton ? 6 : 12 }}>
              <Heading3 sx={{ color: theme.palette.text.primary }}>{title}</Heading3>
            </Grid2>
            <Grid2
              size={{ xs: 6 }}
              sx={{
                display: "flex",
                justifyContent: "end"
              }}
            >
              {endButton}
            </Grid2>
          </Grid2>
          {body}
        </Grid2>
      </Paper>
    </Grid2>
  );
};
