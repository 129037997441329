import { TableCell, TableRow, Tooltip } from "@mui/material";
import { FC, useEffect, useRef, useState } from "react";
import { DoorAccessLog } from "../../../../../../domain/door-access-log";

interface DoorAccessLogsTableRowProps {
  doorAccessLog: DoorAccessLog;
}

export const DoorAccessLogsTableRow: FC<DoorAccessLogsTableRowProps> = ({ doorAccessLog }) => {
  const cellRef = useRef<HTMLTableCellElement>(null);
  const [isTruncated, setIsTruncated] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      const cell = cellRef.current;
      if (cell) {
        setIsTruncated(cell.scrollWidth > cell.clientWidth);
      }
    };

    handleResize();
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [doorAccessLog.doorId]);

  return (
    <TableRow>
      <Tooltip
        title={doorAccessLog.doorId}
        placement="top-start"
        disableHoverListener={!isTruncated}
        disableFocusListener={!isTruncated}
        disableTouchListener={!isTruncated}
        enterTouchDelay={0}
        leaveTouchDelay={1500}
        slotProps={{
          popper: {
            sx: {
              "& .MuiTooltip-tooltip": {
                maxWidth: "none",
                width: 300
              }
            }
          }
        }}
      >
        <TableCell
          ref={cellRef}
          size="small"
          sx={{
            width: 300,
            maxWidth: 300,
            textOverflow: "ellipsis",
            overflow: "hidden",
            whiteSpace: "nowrap"
          }}
        >
          {doorAccessLog.name ?? doorAccessLog.doorId}
        </TableCell>
      </Tooltip>
      <TableCell>{doorAccessLog.status}</TableCell>
      <TableCell>{doorAccessLog.guestType}</TableCell>
      <TableCell>{doorAccessLog.timestamp}</TableCell>
    </TableRow>
  );
};
