import { useMemo } from "react";
import { useTranslationWrapper } from "./use-translation-wrapper";
import yup from "../config/yup.config";

export const useCheckinWizardFormValidations = () => {
  const { t } = useTranslationWrapper();

  const personValidationFields = useMemo(() => {
    const fields: Record<string, any> = {
      firstName: yup.string().required(t("validation__wizard_first_name_required") || undefined),
      lastName: yup.string().required(t("validation__wizard_last_name_required") || undefined),
      email: yup
        .string()
        .required(t("validation__email_required") || undefined)
        .email(t("validation__wizard_email_format") || undefined),
      phone: yup
        .string()
        .nullable()
        .phone(t("validation__wizard_phone_format") || undefined),
      preferredCommunicationChannel: yup
        .string()
        .required(t("validation__preferred_communication_channel_required") || undefined),
      frequentFlyerPrograms: yup.array().of(
        yup.object().shape({
          frequentFlyerNumber: yup.string().when("frequentFlyerProgram", {
            is: (value: string) => !!value,
            then: yup.string().required(t("labels__required") || undefined)
          })
        })
      )
    };

    return fields;
  }, [t]);

  const travelBuddyValidationFields = useMemo(() => {
    return {
      firstName: yup.string().required(t("validation__wizard_first_name_required") || undefined),
      lastName: yup.string().required(t("validation__wizard_last_name_required") || undefined),
      email: yup
        .string()
        .email(t("validation__wizard_email_format"))
        .when("phone", {
          is: (phone: string) => !phone || phone.length === 0,
          then: yup.string().nullable().email(t("validation__wizard_email_format")),
          otherwise: yup.string().nullable().email(t("validation__wizard_email_format"))
        }),
      phone: yup
        .string()
        .phone()
        .when("email", {
          is: (email: string) => !email || email.length === 0,
          then: yup.string().nullable().phone(t("validation__wizard_phone_format")),
          otherwise: yup.string().nullable().phone(t("validation__wizard_phone_format"))
        })
    };
  }, [t]);

  const buddyValidation = useMemo(
    () => yup.object().shape(travelBuddyValidationFields, [["email", "phone"]]),
    [travelBuddyValidationFields]
  );

  const manualCheckinValidation = useMemo(() => {
    const mainGuestSchema = yup.object().shape(
      {
        ...personValidationFields
      },
      [["email", "phone"]]
    );

    return yup.object().shape({
      mainGuest: mainGuestSchema.required(),
      travelBuddies: yup.array().of(buddyValidation)
    });
  }, [personValidationFields, buddyValidation]);

  return {
    manualCheckinValidation
  };
};
