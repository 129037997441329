import * as React from "react";
import { FC, useCallback, useMemo } from "react";
import { Autocomplete, Box, createFilterOptions, TextField, useTheme } from "@mui/material";
import {
  ServiceContract,
  ServiceIdentifier
} from "../../../../graphql-tenantconfig/generated/graphql";
import { LIKE_MAGIC_FREE_CLEANING_ID, sortedServices } from "../service-config-utils";
import { Paragraph, ParagraphSmall } from "@likemagic-tech/sv-magic-library";
import { useTranslationWrapper } from "../../../../hooks/use-translation-wrapper";

interface SingleServiceInputProps {
  value: ServiceIdentifier | undefined;
  setValue: (arg: ServiceIdentifier | null) => void;
  error?: boolean;
  options?: Array<ServiceContract>;
  disabled?: boolean;
}

export const SingleServiceInput: FC<SingleServiceInputProps> = ({
  value,
  setValue,
  error,
  options,
  disabled = false
}) => {
  const theme = useTheme();
  const { t } = useTranslationWrapper();
  const serviceIdentifierOptions = useMemo(() => {
    const serviceIdentifiers = options?.map((o) => ({ serviceId: o.pmsId, details: o })) ?? [];
    return sortedServices(serviceIdentifiers);
  }, [options]);

  const onChange = useCallback(
    (newValue: ServiceIdentifier | null) => {
      setValue(newValue);
    },
    [setValue]
  );

  const filterOptions = createFilterOptions({
    stringify: (option: ServiceIdentifier) => `${option.details?.displayName} ${option.serviceId}`
  });

  return (
    <Autocomplete
      freeSolo={false}
      value={value}
      options={serviceIdentifierOptions}
      filterOptions={filterOptions}
      getOptionKey={(option) => option.serviceId}
      onChange={(_, newValue) => {
        onChange(newValue);
      }}
      renderOption={(props, option) => {
        const { key, ...optionProps } = props;
        return (
          <Box key={key} component="li" {...optionProps}>
            <Box display="inline-flex" flexDirection="column">
              <Paragraph>{option.details?.name || option.serviceId}</Paragraph>
              <ParagraphSmall sx={{ color: theme.palette.text.secondary }}>
                {option.serviceId}
              </ParagraphSmall>
            </Box>
          </Box>
        );
      }}
      getOptionLabel={(option) =>
        LIKE_MAGIC_FREE_CLEANING_ID === option.serviceId
          ? t("labels__service_config_likemagic_free_cleaning")
          : option.details?.displayName || option.serviceId
      }
      renderInput={(params) => (
        <TextField {...params} variant="outlined" error={error} disabled={disabled} />
      )}
      isOptionEqualToValue={(option, value) => option.serviceId === value.serviceId}
      disableClearable={true}
      disabled={disabled}
    />
  );
};
