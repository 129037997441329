import * as Types from "../generated/graphql";

import { ServiceContractFragmentDoc } from "./ServiceContract.generated";
export type ServiceIdentifierFragment = {
  __typename?: "ServiceIdentifier";
  serviceId: string;
  details?: {
    __typename?: "ServiceContract";
    active?: boolean | null;
    chargeMode?: Types.ChargeMode | null;
    chargeUnit?: Types.ChargeUnit | null;
    description?: string | null;
    displayName?: string | null;
    id?: string | null;
    included?: boolean | null;
    name?: string | null;
    pmsCategoryId?: string | null;
    pmsId: string;
    pmsUpdatedAt?: any | null;
    price?: {
      __typename?: "Price";
      currency?: string | null;
      grossPrice?: any | null;
      netPrice?: any | null;
      taxes?: Array<{ __typename?: "Tax"; amount?: any | null; code?: string | null }> | null;
    } | null;
  } | null;
};

export const ServiceIdentifierFragmentDoc = `
    fragment ServiceIdentifier on ServiceIdentifier {
  serviceId
  details {
    ...ServiceContract
  }
}
    `;
