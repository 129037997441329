import { Box, Divider, Grid2, IconButton, Tooltip, useTheme } from "@mui/material";
import {
  Checkbox,
  formatDateTime,
  Paragraph,
  ParagraphBold,
  ParagraphSmall,
  usePropertyConfig
} from "@likemagic-tech/sv-magic-library";
import { ActionsType, AddActionsButtons } from "./add-actions-buttons";
import { isFolioClosed } from "./payment-utils";
import { ChecklistRtl, CreditCard, LocalAtm, Replay, Wallet } from "@mui/icons-material";
import { PricePreview } from "../../../components/price-preview/price-preview";
import { PaymentsActionsMenu } from "./action-menus/payment-actions-menu";
import React, { FC, useMemo, useState } from "react";
import {
  FolioPreview,
  ReservationDetailsDTO
} from "src/store/endpoints/reservation-table.endpoints";
import { useTranslationWrapper } from "../../../hooks/use-translation-wrapper";
import { PaymentTypeEnum } from "../domain/enums";
import { getI18nSelectedLanguage } from "src/utils/language";

interface FolioItemsDetailsPaymentsProps {
  folio: FolioPreview;
  folios: Array<FolioPreview>;
  magicId: string;
  reservation: ReservationDetailsDTO;
  propertyTimeZone: string;
  setShowPaymentOption: (value: boolean) => void;
  paymentComponentRef: React.RefObject<HTMLDivElement>;
}

const paymentIconLookup: Record<PaymentTypeEnum, any> = {
  [PaymentTypeEnum.CC]: CreditCard,
  [PaymentTypeEnum.CASH]: LocalAtm,
  [PaymentTypeEnum.OTHERS]: Wallet,
  [PaymentTypeEnum.INVOICE]: null,
  [PaymentTypeEnum.VCC]: null,
  [PaymentTypeEnum.NONE]: null
};

const paymentIcon = (paymentType: PaymentTypeEnum) => {
  const PaymentIcon: any = paymentIconLookup[paymentType];
  return PaymentIcon && <PaymentIcon sx={{ mr: 1 }} fontSize="small" />;
};

const refundColor: Record<string, any> = {
  INITIATED: { color: "gold" },
  REFUNDED: { color: "green" },
  FAILED: { color: "red" }
};

export const FolioItemsDetailsPayments: FC<FolioItemsDetailsPaymentsProps> = ({
  folio,
  folios,
  magicId,
  reservation,
  propertyTimeZone,
  setShowPaymentOption,
  paymentComponentRef
}) => {
  const { t } = useTranslationWrapper();
  const [paymentsSelectionEnabled, setPaymentsSelectionEnabled] = useState(false);
  const [selectedPayments, setSelectedPayments] = useState<string[]>([]);
  const language = getI18nSelectedLanguage();
  const { features } = usePropertyConfig({
    propertyId: reservation?.propertyId
  });
  const { palette } = useTheme();

  const sortedFolioPayments = useMemo(() => {
    return folio.payments.sort((a, b) => {
      return new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime();
    });
  }, [folio.payments]);

  const sortedFolioPendingPayments = useMemo(() => {
    return folio.pendingPayments.sort((a, b) => {
      return new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime();
    });
  }, [folio.pendingPayments]);

  return (
    <Grid2
      size={{ xs: 12, md: 6 }}
      sx={{
        p: 2
      }}
    >
      <Grid2
        container
        sx={{
          justifyContent: "space-between",
          alignItems: "center",
          minHeight: 40
        }}
      >
        <ParagraphBold>{t("labels__payments")}</ParagraphBold>
        {features?.billingTabAddPaymentEnabled && (
          <AddActionsButtons
            type={ActionsType.Payment}
            showActions={paymentsSelectionEnabled}
            folio={folio}
            folios={folios}
            reservationId={reservation.id}
            propertyId={reservation.propertyId}
            selectedIds={selectedPayments}
            addDisabled={isFolioClosed(folio)}
            onAddClick={() => {
              setShowPaymentOption(true);
              setTimeout(() => {
                paymentComponentRef.current?.scrollIntoView({ behavior: "smooth" });
              }, 0);
            }}
            onDoneClick={() => {
              setSelectedPayments([]);
              setPaymentsSelectionEnabled(false);
            }}
          />
        )}
      </Grid2>
      <Grid2
        container
        sx={{
          justifyContent: "space-between",
          alignItems: "center",
          minHeight: 24
        }}
      >
        <ParagraphSmall color={palette.grey[600]}>{t("labels__payment")}</ParagraphSmall>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            mr: features?.billingTabPaymentOptionsEnabled ? -1 : -0.3
          }}
        >
          <ParagraphSmall color={palette.grey[600]} mr={0.25}>
            {t("labels__amount")}
          </ParagraphSmall>
          {features?.billingTabPaymentOptionsEnabled && !paymentsSelectionEnabled && (
            <IconButton
              sx={{ px: 0.75, py: 0 }}
              size="small"
              color="primary"
              onClick={() => setPaymentsSelectionEnabled(true)}
              disableRipple
              disabled={folio.payments.length < 2}
            >
              <ChecklistRtl />
            </IconButton>
          )}
          {features?.billingTabPaymentOptionsEnabled && paymentsSelectionEnabled && (
            <Checkbox
              sx={{ px: 0.75, py: 0, ml: 1.375 }}
              id="select-all-payments"
              checked={folio.payments.every((payment) => selectedPayments.includes(payment.id))}
              onChange={(event, checked) => {
                if (checked) {
                  setSelectedPayments(folio.payments.map((payment) => payment.id));
                } else {
                  setSelectedPayments([]);
                }
              }}
              disableRipple
            />
          )}
        </Box>
      </Grid2>
      <Divider sx={{ my: 1 }} />
      {sortedFolioPayments.map((payment, index) => (
        <div key={`${payment.id}-${index}`}>
          <Grid2
            container
            sx={{
              justifyContent: "space-between",
              alignItems: "center"
            }}
          >
            <Grid2
              size={{ xs: 6 }}
              sx={{
                flexGrow: "1"
              }}
            >
              <Grid2 container>
                {paymentIcon(payment.type)}
                <Paragraph>{payment.cardsDetail}</Paragraph>
              </Grid2>
              <ParagraphSmall color={palette.grey[600]}>
                {formatDateTime(payment.createdAt, language, propertyTimeZone)}
              </ParagraphSmall>
            </Grid2>
            <Grid2 size={{ xs: 6 }}>
              <Grid2
                container
                sx={{
                  alignItems: "center",
                  justifyContent: "flex-end"
                }}
              >
                {payment.refundStatus && (
                  <Tooltip title={t(`labels__refund_${payment.refundStatus}`)}>
                    <Replay
                      sx={{ mr: 1, ...refundColor[payment.refundStatus ?? ""] }}
                      fontSize="small"
                    />
                  </Tooltip>
                )}
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center"
                  }}
                >
                  <PricePreview price={payment.price} />
                  {features?.billingTabPaymentOptionsEnabled && !paymentsSelectionEnabled && (
                    <PaymentsActionsMenu
                      reservation={reservation}
                      folios={folios}
                      paymentPmsIds={[payment.id]}
                      folioId={folio.id}
                      folioMetadata={folio.metadata}
                      isFolioClosed={isFolioClosed(folio)}
                      magicId={magicId}
                      hasSelect={folio.payments.length > 1}
                      onSelectPayment={() => {
                        setPaymentsSelectionEnabled(true);
                        setSelectedPayments([...selectedPayments, payment.id]);
                      }}
                    />
                  )}
                  {features?.billingTabPaymentOptionsEnabled && paymentsSelectionEnabled && (
                    <Checkbox
                      sx={{ px: 1, py: 0, pr: 0, ml: 1.375 }}
                      id={`select-${payment.id}`}
                      checked={selectedPayments.includes(payment.id)}
                      onChange={(_, checked) => {
                        if (checked) {
                          setSelectedPayments([...selectedPayments, payment.id]);
                        } else {
                          setSelectedPayments(selectedPayments.filter((id) => id !== payment.id));
                        }
                      }}
                      disableRipple
                    />
                  )}
                </Box>
              </Grid2>
            </Grid2>
          </Grid2>
          <Divider sx={{ my: 1 }} />
        </div>
      ))}
      {sortedFolioPendingPayments.map((pendingPayment, index) => (
        <div key={`${pendingPayment.id}-${index}`}>
          <Grid2
            container
            sx={{
              justifyContent: "space-between",
              alignItems: "center"
            }}
          >
            <Grid2
              size={{ xs: 6 }}
              sx={{
                flexGrow: "1"
              }}
            >
              <Grid2 container>
                <Paragraph color={palette.grey[600]}>{pendingPayment.cardsDetail}</Paragraph>
              </Grid2>
              <ParagraphSmall color={palette.grey[400]}>
                {formatDateTime(pendingPayment.createdAt, language, propertyTimeZone)}
              </ParagraphSmall>
            </Grid2>
            <Grid2 size={{ xs: 6 }}>
              <Grid2
                container
                sx={{
                  alignItems: "center",
                  justifyContent: "flex-end",
                  color: palette.grey[600]
                }}
              >
                <PricePreview price={pendingPayment.price} />
              </Grid2>
            </Grid2>
          </Grid2>
          <Divider sx={{ my: 1 }} />
        </div>
      ))}
      {!folio.payments.length && !folio.pendingPayments.length && (
        <ParagraphSmall color={palette.grey[600]}>
          {t("labels__no_items_available")}{" "}
        </ParagraphSmall>
      )}
    </Grid2>
  );
};
