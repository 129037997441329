import { Address } from "./address";
import { GuestPreferences } from "./reservation-table-dto";
import { MembershipAccount } from "../graphql/generated/graphql";

export enum PersonGender {
  MALE = "Male",
  FEMALE = "Female",
  OTHER = "Other",
  UNKNOW = "Unknow"
}
export interface BillingAddress {
  address: Address;
  company: { name: string; taxId: string };
  firstName: string;
  lastName: string;
}

export interface GuestNote {
  pmsId: string;
  title?: string | null;
  text?: string | null;
  pmsPropertyId?: string | null;
  pmsProfileNoteCategoryId?: string | null;
  category?: {
    pmsId?: string;
    name?: string | null;
  } | null;
}

export interface Person {
  [key: string]: any;
  address: Address;
  billingAddress?: BillingAddress;
  birthdate: string;
  email: string;
  firstName: string;
  gender?: PersonGender;
  guestPreferences: GuestPreferences;
  guestPreferencesText: string;
  identificationNumber: string;
  identificationType: string;
  isRecurring: boolean;
  lastName: string;
  nationalityCountryCode: string;
  phone: string;
  preferredCommunicationChannel: string;
  preferredLanguage: string;
  userProfileId?: number;
  hasAccount: boolean;
  id?: string;
  eClubMembershipNumber?: string;
  vipCode?: string;
  isBlacklisted: boolean;
  emergencyEvacuationHelpNeeded?: boolean;
  emergencyEvacuationHelpNotes?: string;
  enrolledInLoyaltyProgram?: boolean;
  guestNotes?: GuestNote[];
  membershipAccounts?: MembershipAccount[];
  licensePlate?: string;
  frequentFlyerPrograms?: MembershipAccount[];
}
