import React, { FC } from "react";
import { useUnitGroupById } from "../../../../../hooks/use-unit-group-by-id";
import { Grid2 } from "@mui/material";
import { Paragraph, ParagraphBold } from "@likemagic-tech/sv-magic-library";
import { formatPriceToString } from "../../../../../utils/price";
import { transformFullPrice } from "../../../../../graphql/transform/transform-utils";
import { UpsellUnitGroup } from "./upsell-unit-group.domain";

interface UpsellUnitGroupItemProps {
  propertyId: string;
  upsellUnitGroup: UpsellUnitGroup;
}
export const UpsellUnitGroupItem: FC<UpsellUnitGroupItemProps> = ({
  propertyId,
  upsellUnitGroup
}) => {
  const unitGroup = useUnitGroupById(propertyId, upsellUnitGroup.pmsUnitGroupId);

  return (
    <Grid2
      container
      spacing={1}
      sx={{
        width: "100%"
      }}
    >
      <Grid2 size={{ xs: 6 }}>
        <Paragraph textAlign="left">{unitGroup.name}</Paragraph>
      </Grid2>
      <Grid2 size={{ xs: 3 }}>
        <ParagraphBold textAlign="left">
          {upsellUnitGroup.calculatedUpsellInformation?.averagePerNightAmountDifference &&
            formatPriceToString(
              transformFullPrice(
                upsellUnitGroup.calculatedUpsellInformation?.averagePerNightAmountDifference
              )
            )}
        </ParagraphBold>
      </Grid2>
      <Grid2 size={{ xs: 3 }}>
        <ParagraphBold textAlign="left">
          {upsellUnitGroup?.calculatedUpsellInformation?.totalAmountDifference &&
            formatPriceToString(
              transformFullPrice(
                upsellUnitGroup?.calculatedUpsellInformation?.totalAmountDifference
              )
            )}
        </ParagraphBold>
      </Grid2>
    </Grid2>
  );
};
