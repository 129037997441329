import { TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import { FC, MouseEvent, useMemo, useState } from "react";
import Table from "@mui/material/Table";
import { useTranslationWrapper } from "../../../hooks/use-translation-wrapper";
import { useTheme } from "@mui/material/styles";
import { ParagraphSmall } from "@likemagic-tech/sv-magic-library";
import TableSortLabel from "@mui/material/TableSortLabel";
import { Order } from "../../../types/pageable";
import TableBody from "@mui/material/TableBody";
import AdditionalServicesTableRow from "./additional-services-table-row";
import { getComparator } from "./service-config-utils";
import { ServiceConfig } from "../../../graphql-tenantconfig/generated/graphql";

interface AdditionalServicesTableProps {
  data: Array<ServiceConfig>;
  onDelete: (serviceConfig: ServiceConfig) => void;
}

const AdditionalServicesTable: FC<AdditionalServicesTableProps> = ({ data, onDelete }) => {
  const { t } = useTranslationWrapper();
  const { spacing } = useTheme();

  const [order, setOrder] = useState<Order>("asc");
  const [orderBy, setOrderBy] = useState<string>("service.details.displayName");

  const createSortHandler = (property: string) => (event: MouseEvent<unknown>) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const sortedRows = useMemo(
    () => (order && orderBy ? [...data].sort(getComparator(order, orderBy, t)) : data),
    [data, order, orderBy, t]
  );

  return (
    <>
      <TableContainer sx={{ paddingTop: spacing(1) }}>
        <Table sx={{ minWidth: 750 }}>
          <TableHead>
            <TableRow>
              <TableCell sx={{ paddingLeft: spacing(6.5) }}>
                <TableSortLabel
                  active={orderBy === "service.details.displayName"}
                  direction={orderBy === "service.details.displayName" ? order : "asc"}
                  onClick={createSortHandler("service.details.displayName")}
                >
                  {t("labels__settings_service_config_name")}
                </TableSortLabel>
              </TableCell>
              <TableCell>
                <TableSortLabel
                  active={orderBy === "service.serviceId"}
                  direction={orderBy === "service.serviceId" ? order : "asc"}
                  onClick={createSortHandler("service.serviceId")}
                >
                  {t("labels__settings_service_config_pms_id")}
                </TableSortLabel>
              </TableCell>
              <TableCell>
                <ParagraphSmall display="flex" gap={0.5}>
                  {t("labels__settings_service_config_price")}
                </ParagraphSmall>
              </TableCell>
              <TableCell>
                <ParagraphSmall display="flex" gap={0.5}>
                  {t("labels__settings_service_config_vat")}
                </ParagraphSmall>
              </TableCell>
              <TableCell>
                <ParagraphSmall display="flex" gap={0.5}>
                  {t("labels__settings_service_config_charge_mode")}
                </ParagraphSmall>
              </TableCell>
              <TableCell>
                <ParagraphSmall display="flex" gap={0.5}>
                  {t("labels__settings_service_config_charge_unit")}
                </ParagraphSmall>
              </TableCell>
              <TableCell>
                <TableSortLabel
                  active={orderBy === "type"}
                  direction={orderBy === "type" ? order : "asc"}
                  onClick={createSortHandler("type")}
                >
                  {t("labels__settings_service_config_type")}
                </TableSortLabel>
              </TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {sortedRows.map((row) => (
              <AdditionalServicesTableRow
                key={`service-config-${row.id}`}
                data={row}
                onDelete={onDelete}
              />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default AdditionalServicesTable;
