import React, { FC, useCallback, useMemo, useState } from "react";
import { Box, Dialog, DialogActions, DialogContent, DialogTitle, useTheme } from "@mui/material";
import { Button, Heading3, ParagraphSmall, useGlobalModal } from "@likemagic-tech/sv-magic-library";
import { useTranslationWrapper } from "../../../../hooks/use-translation-wrapper";
import { Form, Formik } from "formik";
import {
  DefaultServiceConfig,
  ServiceConfigurationContainer
} from "../../../../graphql-tenantconfig/generated/graphql";
import { Params } from "../../../../hooks/use-select-id";
import { Notification } from "../../../../components/notification";
import { useCanEditServiceConfig } from "../use-can-edit-service-config";
import { DefaultServiceForm } from "./default-service-form";
import { useDefaultServiceFormValidations } from "./use-default-service-form-validations";
import { useDefaultServiceModal } from "./use-default-service-modal";
import { toDefaultServiceTranslationProps } from "../service-config-utils";

interface DefaultServiceModalProps {
  defaultServices: Array<DefaultServiceConfig>;
  serviceConfiguration: ServiceConfigurationContainer;
  onSubmit: (defaultService: DefaultServiceConfig) => void;
  onDelete: (defaultServiceId: string) => void;
}

export const DefaultServiceModal: FC<DefaultServiceModalProps> = ({
  defaultServices,
  serviceConfiguration,
  onSubmit,
  onDelete
}) => {
  const theme = useTheme();
  const { t } = useTranslationWrapper();
  const { canEditServiceConfig } = useCanEditServiceConfig();

  const { defaultServiceFormValidation } = useDefaultServiceFormValidations();
  const { defaultServiceId, onClose } = useDefaultServiceModal();

  const { open } = useGlobalModal();
  const [isConfirmDeleteModalOpen, setConfirmDeleteModalOpen] = useState(false);

  const isCreateNew = useMemo(() => {
    return defaultServiceId === Params.CREATE_DEFAULT_SERVICE_PARAM;
  }, [defaultServiceId]);

  const defaultService = useMemo(() => {
    return defaultServices.find((defaultService) => defaultService.id === defaultServiceId);
  }, [defaultServices, defaultServiceId]);

  const initalValues: DefaultServiceConfig = useMemo(() => {
    return defaultService
      ? { ...defaultService }
      : ({ service: null, unitGroups: [] } as any as DefaultServiceConfig);
  }, [defaultService]);

  const onSubmitForm = useCallback(
    (values: DefaultServiceConfig) => {
      onSubmit(values);
      onClose();
    },
    [onClose, onSubmit]
  );

  const handleWithConfirmDeleteModal = useCallback(() => {
    setConfirmDeleteModalOpen(true);
    return open({
      modalActions: [
        {
          variant: "primary",
          color: "error",
          result: true,
          label: t(
            "labels__settings_service_config_delete_default_service_confirmation_button_delete"
          )
        },
        {
          variant: "secondary",
          result: false,
          label: t("buttons__cancel")
        }
      ],
      modalProps: {
        title: t("labels__settings_service_config_delete_default_service_confirmation_title"),
        content: (
          <>
            <span>
              {t("labels__settings_service_config_delete_default_service_confirmation_msg1", {
                name:
                  defaultService?.service?.details?.displayName ||
                  defaultService?.service?.serviceId ||
                  ""
              })}
            </span>
            <span style={{ color: theme.palette.error.main }}>
              {t("labels__settings_service_config_delete_default_service_confirmation_msg2")}
            </span>
          </>
        )
      }
    });
  }, [t, open, defaultService, theme.palette.error.main]);

  const onDeleteDefaultService = useCallback(async () => {
    const confirmDeleteResult = await handleWithConfirmDeleteModal();
    if (confirmDeleteResult) {
      onDelete(defaultService!.id);
      onClose();
    }
    setConfirmDeleteModalOpen(false);
  }, [defaultService, handleWithConfirmDeleteModal, onDelete, onClose]);

  const [showJson, setShowJson] = useState<boolean>(false);

  return (
    <Dialog
      open={!isConfirmDeleteModalOpen && !!defaultServiceId && (!!defaultService || isCreateNew)}
      onClose={onClose}
      maxWidth={"lg"}
      PaperProps={{
        sx: {
          display: "flex",
          width: "100%"
        }
      }}
    >
      <Formik
        initialValues={initalValues}
        onSubmit={onSubmitForm}
        validationSchema={defaultServiceFormValidation}
        validateOnChange={!isCreateNew}
        validateOnMount={!isCreateNew}
      >
        {(formik) => {
          return (
            <>
              <DialogTitle sx={{ justifyContent: "space-between", display: "flex" }}>
                <Box sx={{ flexGrow: 1 }}>
                  <Box>
                    <Heading3>
                      {isCreateNew
                        ? t("labels__create_default_service")
                        : defaultService?.service?.details?.displayName ||
                          defaultService?.service?.serviceId ||
                          ""}
                    </Heading3>
                  </Box>
                  <Box>
                    <ParagraphSmall>
                      {t("labels__default_service_modal_description")}
                    </ParagraphSmall>
                  </Box>
                </Box>
                <Box>
                  <Button
                    variant="ghost"
                    size="small"
                    color="info"
                    onClick={() => setShowJson(!showJson)}
                  >
                    {showJson
                      ? t("buttons__service_config_hide_json")
                      : t("buttons__service_config_show_json")}
                  </Button>
                </Box>
              </DialogTitle>
              <DialogContent>
                {showJson ? (
                  <pre style={{ fontSize: "0.75rem" }}>
                    {JSON.stringify(formik.values, null, 2)}
                  </pre>
                ) : (
                  <>
                    {defaultService &&
                      defaultService.conflicts &&
                      defaultService.conflicts.map((conflict, index) => (
                        <Box
                          key={`dependency_group-modal-conflict-${defaultService.id}-${index}`}
                          sx={{ mb: theme.spacing(2) }}
                        >
                          <Notification
                            title={t(
                              "labels__settings_service_config_conflict_info_" + conflict.type,
                              toDefaultServiceTranslationProps(conflict, defaultService)
                            )}
                            type="error"
                          ></Notification>
                        </Box>
                      ))}
                    <Form noValidate id="ServiceConfigForm" style={{ width: "100%" }}>
                      <DefaultServiceForm
                        serviceConfiguration={serviceConfiguration}
                        isCreateNew={isCreateNew}
                        conflicts={defaultService?.conflicts || []}
                      />
                    </Form>
                  </>
                )}
              </DialogContent>
              {!showJson && (
                <>
                  {canEditServiceConfig ? (
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        width: "100%",
                        px: 3,
                        py: 2
                      }}
                    >
                      <DialogActions sx={{ p: 0 }}>
                        {defaultService && defaultService.id && (
                          <Button
                            size="large"
                            variant="secondary"
                            color="error"
                            onClick={(event) => onDeleteDefaultService()}
                            fullWidth
                            sx={{ mt: theme.spacing(2), mr: 1 }}
                          >
                            {t("labels__delete")}
                          </Button>
                        )}
                      </DialogActions>
                      <DialogActions sx={{ p: 0 }}>
                        <Button
                          size="large"
                          variant="ghost"
                          onClick={(event) => onClose()}
                          fullWidth
                          sx={{ mt: theme.spacing(2), mr: 1 }}
                        >
                          {t("buttons__cancel")}
                        </Button>
                        <Button
                          type="submit"
                          variant="primary"
                          size="large"
                          fullWidth
                          sx={{ marginTop: theme.spacing(2) }}
                          onClick={() => formik.handleSubmit()}
                        >
                          {t("buttons__save")}
                        </Button>
                      </DialogActions>
                    </Box>
                  ) : (
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "flex-end",
                        width: "100%",
                        px: 3,
                        py: 2
                      }}
                    >
                      <DialogActions sx={{ p: 0 }}>
                        <Button
                          size="large"
                          variant="primary"
                          onClick={(event) => onClose()}
                          fullWidth
                          sx={{ mt: theme.spacing(2), mr: 1 }}
                        >
                          {t("buttons__close")}
                        </Button>
                      </DialogActions>
                    </Box>
                  )}
                </>
              )}
            </>
          );
        }}
      </Formik>
    </Dialog>
  );
};
