import { FC, useCallback, useEffect, useMemo } from "react";
import { Box, Fade, Grid2, useTheme } from "@mui/material";
import { useDispatch } from "../store";
import { useIsMobile } from "../hooks/use-is-mobile";
import { setIsOpenSideNavigation } from "../slices/dashboard-sidebar";
import { DrawerDetails } from "../components/drawer-details/drawer-details";
import { Chat } from "./components/chat";
import { ChatPreview } from "./components/chat-preview";
import { useSubscribeToMessages } from "./hooks/use-subscribe-to-messages";
import { useGetConversationDetailsQueryEnhanced } from "../graphql-messaging/queries/enhanced-queries/get-conversation-details-enhanced";
import { useConversationSelectId } from "./use-conversation-select-id";
import { useProperty } from "../hooks/use-property";
import { Params } from "../hooks/use-select-id";
import { useGetRelatedToQueryEnhanced } from "src/graphql-tasks/query/enhanced-queries/get-related-to-enhanced";
import { Task } from "src/features/tasks/domain/task";

export const ConversationDetails: FC = () => {
  const { selectedConversationId, closeDrawer } = useConversationSelectId();
  const dispatch = useDispatch();
  const isMobile = useIsMobile();
  const { selectedPropertyIds } = useProperty();
  const theme = useTheme();

  const setOpenSideNavigation = useCallback(
    (openSidebar: boolean) => {
      dispatch(setIsOpenSideNavigation({ isOpenSideNavigation: openSidebar }));
    },
    [dispatch]
  );

  const { data } = useGetConversationDetailsQueryEnhanced(
    {
      filter: {
        conversationId: selectedConversationId,
        pageable: { pageNumber: 0, pageSize: 1, sort: "createdAt,asc" }
      }
    },
    {
      skip: !selectedConversationId,
      refetchOnMountOrArgChange: true
    }
  );
  const conversation = data?.GetConversations?.content?.[0];

  const reservationInContext = useMemo(
    () =>
      conversation?.guestIdentity?.reservations
        ? conversation?.guestIdentity?.reservations[0]?.pmsId
        : "",
    [conversation?.guestIdentity?.reservations]
  );

  const { data: tasksData } = useGetRelatedToQueryEnhanced(
    {
      pmsPropertyId: selectedPropertyIds[0],
      pmsReservationId: reservationInContext ?? ""
    },
    {
      skip: !reservationInContext
    }
  );

  const tasks: Array<Task> = tasksData?.GetRelatedToTasks.slice(0, 3) ?? [];

  useSubscribeToMessages({
    conversationId: selectedConversationId,
    pageable: { pageNumber: 0, pageSize: 1, sort: "createdAt,asc" }
  });

  useEffect(() => {
    if (selectedConversationId || isMobile) {
      setOpenSideNavigation(false);
    }
  }, [selectedConversationId, setOpenSideNavigation, isMobile]);

  return (
    <DrawerDetails
      isOpen={!!selectedConversationId}
      onClose={closeDrawer}
      drawerId={Params.SELECTED_CONVERSATION_ID}
    >
      {conversation && selectedPropertyIds?.length && (
        <Box>
          <Fade in={!!conversation.conversationId}>
            <Grid2 container direction="row" wrap="nowrap">
              <Grid2
                height={isMobile ? `calc(100dvh - ${theme.spacing(7)})` : "100dvh"}
                flexGrow={1}
                width="-webkit-fill-available"
              >
                <Chat conversation={conversation} propertyIds={selectedPropertyIds} />
              </Grid2>
              {!isMobile && (
                <Grid2
                  sx={{
                    minWidth: 320,
                    width: 320,
                    height: "100vh"
                  }}
                >
                  <ChatPreview conversation={conversation} tasks={tasks} />
                </Grid2>
              )}
            </Grid2>
          </Fade>
        </Box>
      )}
    </DrawerDetails>
  );
};
