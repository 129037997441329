import React, { useCallback, useMemo } from "react";
import Paper from "@mui/material/Paper";
import { Button, EmptyState, Heading3, useGlobalModal } from "@likemagic-tech/sv-magic-library";
import AdditionalServicesTable from "./additional-services-table";
import { Box, useTheme } from "@mui/material";
import DefaultServicesTable from "./default-services-table";
import DependencyGroupsTable from "./dependency-groups-table";
import {
  DefaultServiceConfig,
  ServiceConfig,
  ServiceDependencyGroup
} from "../../../graphql-tenantconfig/generated/graphql";
import { useTranslationWrapper } from "../../../hooks/use-translation-wrapper";
import Plus from "../../../icons/Plus";
import { ServiceConfigModal } from "./service-config-modal/service-config-modal";
import { useServiceConfigModal } from "./service-config-modal/use-service-config-modal";
import { Notification } from "../../../components/notification";
import { useServiceConfigurationSandbox } from "../../../slices/use-service-configuration-sandbox";
import { useIsMobile } from "../../../hooks/use-is-mobile";
import { useCanEditServiceConfig } from "./use-can-edit-service-config";
import { useDependencyGroupModal } from "./dependency-group-modal/use-dependency-group-modal";
import { DependencyGroupModal } from "./dependency-group-modal/dependency-group-modal";
import { useDefaultServiceModal } from "./default-service-modal/use-default-service-modal";
import { DefaultServiceModal } from "./default-service-modal/default-service-modal";

const ServiceConfigurationOverview = () => {
  const theme = useTheme();
  const { t } = useTranslationWrapper();
  const isMobile = useIsMobile();
  const { canEditServiceConfig } = useCanEditServiceConfig();
  const { openNewServiceConfig } = useServiceConfigModal();
  const { openNewDependencyGroup } = useDependencyGroupModal();
  const { openNewDefaultService } = useDefaultServiceModal();

  const { open } = useGlobalModal();

  const {
    data,
    sandbox,
    dirty,
    published,
    deleteServiceConfig,
    addOrReplaceServiceConfig,
    deleteDependencyGroup,
    addOrReplaceDependencyGroup,
    deleteDefaultService,
    addOrReplaceDefaultService,
    publishServiceConfiguration
  } = useServiceConfigurationSandbox();

  const handleWithConfirmDeleteModal = useCallback(
    (title: string, messagePart1: string, messagePart2: string, confirmBtnLabel: string) => {
      return open({
        modalActions: [
          {
            variant: "primary",
            color: "error",
            result: true,
            label: confirmBtnLabel
          },
          {
            variant: "secondary",
            result: false,
            label: t("buttons__cancel")
          }
        ],
        modalProps: {
          title: title,
          content: (
            <>
              <span>{messagePart1}</span>
              <span style={{ color: theme.palette.error.main }}>{messagePart2}</span>
            </>
          )
        }
      });
    },
    [t, open, theme.palette.error.main]
  );

  const onDeleteServiceConfig = useCallback(
    async (serviceConfig: ServiceConfig) => {
      const confirmDeleteResult = await handleWithConfirmDeleteModal(
        t("labels__settings_service_config_delete_service_config_confirmation_title"),
        t("labels__settings_service_config_delete_service_config_confirmation_msg1", {
          name:
            serviceConfig?.service?.details?.displayName || serviceConfig?.service?.serviceId || ""
        }),
        t("labels__settings_service_config_delete_service_config_confirmation_msg2"),
        t("labels__settings_service_config_delete_service_config_confirmation_button_delete")
      );
      if (confirmDeleteResult) {
        deleteServiceConfig(serviceConfig.id);
      }
    },
    [t, deleteServiceConfig, handleWithConfirmDeleteModal]
  );

  const onDeleteDependencyGroup = useCallback(
    async (dependencyGroup: ServiceDependencyGroup) => {
      const confirmDeleteResult = await handleWithConfirmDeleteModal(
        t("labels__settings_service_config_delete_dependency_group_confirmation_title"),
        t("labels__settings_service_config_delete_dependency_group_confirmation_msg1", {
          key: dependencyGroup?.key || ""
        }),
        t("labels__settings_service_config_delete_dependency_group_confirmation_msg2"),
        t("labels__settings_service_config_delete_dependency_group_confirmation_button_delete")
      );
      if (confirmDeleteResult) {
        deleteDependencyGroup(dependencyGroup.id);
      }
    },
    [t, deleteDependencyGroup, handleWithConfirmDeleteModal]
  );

  const onDeleteDefaultService = useCallback(
    async (defaultService: DefaultServiceConfig) => {
      const confirmDeleteResult = await handleWithConfirmDeleteModal(
        t("labels__settings_service_config_delete_default_service_confirmation_title"),
        t("labels__settings_service_config_delete_default_service_confirmation_msg1", {
          name:
            defaultService?.service?.details?.displayName ||
            defaultService?.service?.serviceId ||
            ""
        }),
        t("labels__settings_service_config_delete_default_service_confirmation_msg2"),
        t("labels__settings_service_config_delete_default_service_confirmation_button_delete")
      );
      if (confirmDeleteResult) {
        deleteDefaultService(defaultService.id);
      }
    },
    [t, deleteDefaultService, handleWithConfirmDeleteModal]
  );

  const headerNotificationComponent = useMemo(() => {
    if (sandbox?.conflicts && sandbox?.conflicts?.length > 0) {
      return (
        <Notification
          title={t("labels__settings_service_config_conflict_error", {
            count: sandbox.conflicts.length.toString()
          })}
          type="error"
        />
      );
    } else if (published) {
      return <Notification title={t("labels__settings_service_config_published")} type="success" />;
    } else if (dirty) {
      return (
        <Notification
          title={t("labels__settings_service_config_ready_to_publish")}
          type="warning"
        />
      );
    } else {
      return (
        <Notification
          title={t("labels__settings_service_config_initial_no_conflict")}
          type="info"
        />
      );
    }
  }, [sandbox, dirty, published, t]);

  return (
    <>
      {sandbox && (
        <>
          <Box sx={{ mb: theme.spacing(2), display: "flex" }}>
            <Box sx={{ flexGrow: 1 }}>
              <Box
                sx={{
                  ".MuiPaper-root": {
                    py: 0.25
                  },
                  ".MuiAlert-icon": {
                    py: 0
                  }
                }}
              >
                {headerNotificationComponent}
              </Box>
            </Box>
            {canEditServiceConfig && (
              <Box sx={{ flexGrow: 0, pl: theme.spacing(1) }}>
                <Button
                  type="submit"
                  variant="primary"
                  size="large"
                  fullWidth
                  onClick={publishServiceConfiguration}
                  disabled={!dirty || sandbox.conflicts?.length > 0}
                  sx={{ borderRadius: "6px" }}
                >
                  {t("buttons__publish_service_configuration")}
                </Button>
              </Box>
            )}
          </Box>

          <Paper sx={{ width: "100%", mb: 2, borderRadius: isMobile ? 0 : 2 }} elevation={0}>
            <Box
              sx={{
                p: 2,
                pb: 0,
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between"
              }}
            >
              <Heading3>{t("labels__settings_service_config_heading_service_configs")}</Heading3>
              {canEditServiceConfig && (
                <Button
                  variant="secondary"
                  size="medium"
                  startIcon={<Plus />}
                  sx={{ whiteSpace: "nowrap" }}
                  onClick={openNewServiceConfig}
                >
                  {t("buttons__add_service_config")}
                </Button>
              )}
            </Box>
            {sandbox.serviceConfigs && sandbox.serviceConfigs.length > 0 ? (
              <AdditionalServicesTable
                data={sandbox.serviceConfigs}
                onDelete={onDeleteServiceConfig}
              />
            ) : (
              <Box sx={{ pb: 1 }}>
                <EmptyState title={t("labels__settings_service_config_empty_service_configs")} />
              </Box>
            )}
          </Paper>

          {data?.GetServiceConfiguration?.supportsDefaultServices && (
            <Paper sx={{ width: "100%", mb: 2, borderRadius: 2 }} elevation={0}>
              <Box
                sx={{
                  p: 2,
                  pb: 0,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between"
                }}
              >
                <Heading3>{t("labels__settings_service_config_heading_default_services")}</Heading3>
                {canEditServiceConfig && (
                  <Button
                    variant="secondary"
                    size="medium"
                    startIcon={<Plus />}
                    sx={{ whiteSpace: "nowrap" }}
                    onClick={openNewDefaultService}
                  >
                    {t("buttons__add_default_service")}
                  </Button>
                )}
              </Box>
              {sandbox.defaultServices && sandbox.defaultServices.length > 0 ? (
                <DefaultServicesTable
                  data={sandbox.defaultServices}
                  onDelete={onDeleteDefaultService}
                />
              ) : (
                <Box sx={{ pb: 1 }}>
                  <EmptyState title={t("labels__settings_service_config_empty_default_services")} />
                </Box>
              )}
            </Paper>
          )}

          <Paper sx={{ width: "100%", mb: 2, borderRadius: 2 }} elevation={0}>
            <Heading3>
              <Box
                sx={{
                  p: 2,
                  pb: 0,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between"
                }}
              >
                {t("labels__settings_service_config_heading_dependency_groups")}
                {canEditServiceConfig && (
                  <Button
                    variant="secondary"
                    size="medium"
                    startIcon={<Plus />}
                    sx={{ whiteSpace: "nowrap" }}
                    onClick={openNewDependencyGroup}
                  >
                    {t("buttons__add_dependency_group")}
                  </Button>
                )}
              </Box>
            </Heading3>
            {sandbox.dependencyGroups && sandbox.dependencyGroups.length > 0 ? (
              <DependencyGroupsTable
                data={sandbox.dependencyGroups}
                onDelete={onDeleteDependencyGroup}
              />
            ) : (
              <Box sx={{ pb: 1 }}>
                <EmptyState title={t("labels__settings_service_config_empty_dependency_groups")} />
              </Box>
            )}
          </Paper>

          <ServiceConfigModal
            serviceConfigs={sandbox.serviceConfigs as ServiceConfig[]}
            serviceConfiguration={data?.GetServiceConfiguration!}
            onSubmit={addOrReplaceServiceConfig}
            onDelete={deleteServiceConfig}
          />

          <DependencyGroupModal
            dependencyGroups={sandbox.dependencyGroups}
            serviceConfiguration={data?.GetServiceConfiguration!}
            onSubmit={addOrReplaceDependencyGroup}
            onDelete={deleteDependencyGroup}
          />

          <DefaultServiceModal
            defaultServices={sandbox.defaultServices}
            serviceConfiguration={data?.GetServiceConfiguration!}
            onSubmit={addOrReplaceDefaultService}
            onDelete={deleteDefaultService}
          />
        </>
      )}
    </>
  );
};

export default ServiceConfigurationOverview;
