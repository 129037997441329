import { Grid2 } from "@mui/material";
import { FC } from "react";
import { regionNames } from "./reservation-details-guest-insight-utils";
import { Input } from "@likemagic-tech/sv-magic-library";
import { BillingAddress } from "src/domain/person";
import { useTranslationWrapper } from "src/hooks/use-translation-wrapper";

interface PersonCardInfoBillingInfoProps {
  billingAddress?: BillingAddress;
}

export const PersonCardInfoBillingInfo: FC<PersonCardInfoBillingInfoProps> = ({
  billingAddress
}) => {
  const { t } = useTranslationWrapper();
  return (
    <Grid2 container direction="row" spacing={2} px={3}>
      <Grid2 size={{ xs: 6, md: 4 }}>
        <Input
          disabled
          variant="outlined"
          forceShrink
          value={billingAddress?.firstName}
          label={t("labels__first_name")}
        />
      </Grid2>
      <Grid2 size={{ xs: 6, md: 4 }}>
        <Input
          disabled
          variant="outlined"
          forceShrink
          value={billingAddress?.lastName}
          label={t("labels__last_name")}
        />
      </Grid2>
      <Grid2 size={{ xs: 6, md: 4 }}>
        <Input
          disabled
          variant="outlined"
          forceShrink
          value={billingAddress?.company?.name}
          label={t("labels__company_name")}
        />
      </Grid2>
      <Grid2 size={{ xs: 6, md: 4 }}>
        <Input
          disabled
          variant="outlined"
          forceShrink
          value={billingAddress?.company?.taxId}
          label={t("labels__company_vat_id")}
        />
      </Grid2>
      <Grid2 size={{ xs: 6, md: 4 }}>
        <Input
          disabled
          variant="outlined"
          forceShrink
          value={
            billingAddress?.address && billingAddress?.address?.addressLine1
              ? `${billingAddress?.address?.addressLine1} ${
                  billingAddress?.address?.addressLine2 ?? ""
                }`
              : ""
          }
          label={t("labels__guest__insight__PRIVATE_ADDRESS")}
        />
      </Grid2>
      <Grid2 size={{ xs: 6, md: 4 }}>
        <Input
          disabled
          variant="outlined"
          forceShrink
          value={
            billingAddress?.address && billingAddress?.address.postalCode
              ? billingAddress?.address.postalCode
              : ""
          }
          label={t("labels__postal_code")}
        />
      </Grid2>
      <Grid2 size={{ xs: 6, md: 4 }}>
        <Input
          disabled
          variant="outlined"
          forceShrink
          value={
            billingAddress?.address && billingAddress?.address.city
              ? billingAddress?.address.city
              : ""
          }
          label={t("labels__city")}
        />
      </Grid2>
      <Grid2 size={{ xs: 6, md: 4 }}>
        <Input
          disabled
          variant="outlined"
          forceShrink
          value={
            billingAddress?.address && billingAddress?.address.countryCode
              ? regionNames.of(billingAddress?.address.countryCode)
              : ""
          }
          label={t("labels__country")}
        />
      </Grid2>
    </Grid2>
  );
};
